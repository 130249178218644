package com.diyoffer.negotiation.ui.landing

object GetAlertContract {
  data class State(
    val firstName: String? = null,
    val lastName: String? = null,
    val email: String? = null,
    val popupOpen: Boolean = false,
  )

  sealed class Inputs {
    data object PopupOpen : Inputs()
    data object PopupDismissed : Inputs()
    data object Submit : Inputs()
    data class UpdateFirstName(val firstName: String?) : Inputs()
    data class UpdateLastName(val lastName: String?) : Inputs()
    data class UpdateEmail(val email: String?) : Inputs()
  }

  sealed class Events {
    data class OnError(val message: String) : Events()
  }
}
