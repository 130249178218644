package com.diyoffer.negotiation.monitoring.sentry

@JsModule("@sentry/browser")
@JsNonModule
@JsName("Sentry")
@Suppress("UnusedPrivateMember")
external object SentryJs {
  fun init(options: SentryOptions)

  fun captureMessage(message: String): dynamic

  fun captureMessage(message: String, captureContext: (scope: dynamic) -> Unit): dynamic

  fun captureException(exception: Any): dynamic

  fun captureException(exception: Any, captureContext: (scope: dynamic) -> Unit): dynamic

  fun addBreadcrumb(breadcrumb: JsBreadcrumb)

  fun configureScope(callback: (scope: dynamic) -> Unit)

  fun close()

  @JsName("Replay")
  class Replay
}

@JsModule("@sentry/tracing")
@JsNonModule
external object SentryTracingJs {
  @JsName("BrowserTracing")
  class BrowserTracing(options: BrowserTracingOptions)
}

/** https://docs.sentry.io/platforms/javascript/configuration/options/#common-options */
external interface SentryOptions {
  var dsn: String
  var debug: Boolean
  var environment: String
  var release: String
  var tracesSampleRate: Double
  var replaysSessionSampleRate: Double
  var replaysOnErrorSampleRate: Double
  var integrations: Array<dynamic>
}

external interface JsBreadcrumb {
  var type: String?
  var category: String?
  var message: String?
  var level: JsSentryLevel?
  var data: dynamic
}

/** https://docs.sentry.io/platforms/javascript/performance/instrumentation/automatic-instrumentation/#configuration-options */
external interface BrowserTracingOptions {
  var beforeNavigate: (context: dynamic) -> dynamic
}

@Suppress("UnusedReceiverParameter")
fun Sentry.init(browserTracingOptions: BrowserTracingOptions, configuration: (SentryOptions) -> Unit) {
  val options = js("{}").unsafeCast<SentryOptions>().apply {
    integrations = arrayOf(SentryTracingJs.BrowserTracing(browserTracingOptions), SentryJs.Replay())
    configuration(this)
  }
  SentryJs.init(options)
}
