package com.diyoffer.negotiation.model

import com.diyoffer.negotiation.model.auth.*
import com.diyoffer.negotiation.model.serdes.*
import kotlinx.serialization.Serializable

@Serializable
data class User(
  @Serializable(with = UidSerializer::class) val _id: Uid<User>,
  val identity: Identity,
  val settings: UserSettings,
  val enabled: Boolean,
)

// Role of the user in the current session
enum class UserRole {
  VISITOR,
  BUYER,
  SELLER,
}
