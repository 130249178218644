package layout

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import style.DiyStyleSheet
import style.SignFormStyleSheet

@Composable
fun LoginContainer(content: ContentBuilder<HTMLDivElement>) {
  Div({
    id("diy")
    classes(DiyStyleSheet.loginContainer)
  }) {
    Style(SignFormStyleSheet)

    Div({
      classes(DiyStyleSheet.loginForm, SignFormStyleSheet.signContainer)
    }) {
      Div({
        classes(SignFormStyleSheet.signOption)
      }, content = content)
    }
  }
}
