package com.diyoffer.negotiation.common

import com.diyoffer.negotiation.model.*
import com.soywiz.kbignum.BigInt
import com.soywiz.kbignum.BigNum

@Suppress("MagicNumber")
fun bnDivAsPercent(numerator: BigNum, denominator: BigNum, scale: Int = 2): Percent = Percent(
  numerator
    .div(denominator, 5)
    .times(BigNum(BigInt(100), 0))
    .convertToScale(scale)
    .toString()
    .toDouble()
)
