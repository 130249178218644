package com.diyoffer.negotiation.model.serdes

import kotlinx.serialization.modules.SerializersModule
import org.kodein.di.DI
import org.kodein.di.bindSet
import org.kodein.di.bindSingleton
import org.kodein.di.inBindSet
import org.kodein.di.instance
import org.kodein.di.singleton

val serdesModule = DI.Module("serdesModule") {
  bindSet<SerializersModule>()

  bindSingleton { instance<Set<SerializersModule>>().toList() }

  inBindSet { add { singleton { AuditableSerializationModule } } }
  inBindSet { add { singleton { OptionalSerializationModule } } }
  inBindSet { add { singleton { PolymorphicLiteralsSerializationModule } } }
}
