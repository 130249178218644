package com.diyoffer.negotiation.model.auth

import kotlinx.serialization.Serializable

sealed interface IdentityInfo {
  val uid: String
  val displayName: String?
  val photoURL: String?
  val email: String?
  val phoneNumber: String?
  val providerId: ProviderId
}

@Serializable
data class IdentityMetadata(
  override val uid: String,
  override val displayName: String?,
  override val photoURL: String?,
  override val email: String?,
  override val phoneNumber: String?,
  override val providerId: ProviderId,
  val emailVerified: Boolean,
  val providerData: List<IdentityInfo>,
) : IdentityInfo

@Serializable
data class ProviderIdentityInfo(
  override val uid: String,
  override val displayName: String?,
  override val photoURL: String?,
  override val email: String?,
  override val phoneNumber: String?,
  override val providerId: ProviderId,
) : IdentityInfo

// https://github.com/firebase/firebase-js-sdk/blob/master/packages/auth/src/model/enums.ts
@Serializable
enum class ProviderId(val providerKey: String) {
  ANONYMOUS("anonymous"),
  CUSTOM("custom"),
  FACEBOOK("facebook.com"),
  FIREBASE("firebase"),
  GITHUB("github.com"),
  GOOGLE("google.com"),
  PASSWORD("password"),
  PHONE("phone"),
  TWITTER("twitter.com"),
  ;

  companion object {
    fun fromProviderKey(providerKey: String): ProviderId =
      values().single { it.providerKey == providerKey }

    fun ProviderId.displayName(): String = when (this) {
      ANONYMOUS -> "Anonymous"
      CUSTOM -> "a custom method"
      FACEBOOK -> "Facebook"
      FIREBASE -> "Firebase"
      GITHUB -> "GitHub"
      GOOGLE -> "Google"
      PASSWORD -> "username and password"
      PHONE -> "a phone number"
      TWITTER -> "Twitter"
    }
  }
}
