package forms

import androidx.compose.runtime.Composable
import builders.OfferFixtureExcludedListBuilder
import com.diyoffer.negotiation.common.replaceAt
import com.diyoffer.negotiation.model.*
import common.FlatList
import common.Row
import components.color
import components.icon

@Composable
fun OfferFixturesExcludedListEdit(
  builder: OfferFixtureExcludedListBuilder,
  setBuilder: (OfferFixtureExcludedListBuilder) -> Unit,
) {
  builder.fixturesExcluded?.mapIndexed { idx, b ->
    val fixture = b.term.currentValue.get()
    NegotiableItemInteract(
      optionKey = fixture.optionKey,
      builder = b,
      setBuilder = {
        setBuilder(
          builder.copy(
            fixturesExcluded = builder.fixturesExcluded!!.replaceAt(idx, it)
          )
        )
      }
    ) {
      ItemDisplay("Location", fixture.location)
    }
  }
}

@Composable
fun OfferFixturesExcludedListDisplay(items: OfferFixturesExcluded) {
  if (items.fixturesExcluded.isEmpty()) {
    FlatList("No Fixture or Exclusion")
  } else {
    items.fixturesExcluded.map {
      Row {
        FixtureExcludedDisplay(
          item = it.currentValue.get(),
          icon = it.state.icon(),
          iconColor = it.state.color(),
          lineThrough = it.state == NegotiatedTerm.State.REMOVED
        )
      }
    }
  }
}
