package com.diyoffer.negotiation.environment

interface EnvironmentConfig {
  val environment: String
}

enum class Env {
  DEV,
  TEST,
  PROD,
  ;

  companion object {
    fun fromString(s: String) = values().single { it.name.lowercase() == s.lowercase() }
  }
}
