//
// GENERATED by KVision
//
package com.diyoffer.negotiation.rpcs

import org.w3c.fetch.RequestInit
import io.kvision.remote.KVRemoteAgent
import kotlinx.serialization.modules.SerializersModule
import com.diyoffer.negotiation.model.Listing
import com.diyoffer.negotiation.model.UidValue
import com.diyoffer.negotiation.model.rpcs.ListingLoadResult
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone

actual class ListingAnonRpcService(serializersModules: List<SerializersModule>? = null, requestFilter: (suspend RequestInit.() -> Unit)? = null) : IListingAnonRpcService, KVRemoteAgent<ListingAnonRpcService>(ListingAnonRpcServiceManager, serializersModules, requestFilter) {
    override suspend fun loadListingByPermalink(permalink: String) = call(IListingAnonRpcService::loadListingByPermalink, permalink)
    override suspend fun loadListingById(listingId: UidValue<Listing>) = call(IListingAnonRpcService::loadListingById, listingId)
    override suspend fun bookAViewing(listingId: UidValue<Listing>, name: String, email: String, phone: String?, proposedTime: List<LocalDateTime>, timeZone: TimeZone) = call(IListingAnonRpcService::bookAViewing, listingId, name, email, phone, proposedTime, timeZone)
}
