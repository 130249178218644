package components

import androidx.compose.runtime.Composable
import app.softwork.routingcompose.Router
import com.diyoffer.negotiation.messages.MessageKey
import common.Row
import model.RoutingParams
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import style.DiyStyleSheet.errorText

@Composable
fun MessageFromQuery() {
  val router = Router.current
  router.currentPath.parameters?.map?.get(RoutingParams.ParamKeys.messageKey)?.map {
    val messageKey = MessageKey.fromKey(it)
    if (messageKey != MessageKey.NONE) {
      Row({ style { textAlign("center") } }) {
        Span({ classes(errorText) }) { Text(messageKey.message) }
      }
    }
  }
}
