package prototypes

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.diyoffer.negotiation.common.cast
import com.diyoffer.negotiation.common.services.offers.createCounterOfferDraftFromPublishedOffer
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.Listing.State.Companion.TEST_PUBLISHED_LISTING_UID
import com.diyoffer.negotiation.model.Offer.State.Companion.TEST_DRAFT_OFFER_UID
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.prototypes.baseTestContacts
import com.diyoffer.negotiation.rpcs.IOfferAnonRpcService
import com.diyoffer.negotiation.rpcs.IOfferAuthRpcService
import com.diyoffer.negotiation.services.tryRpc
import com.diyoffer.negotiation.workflows.ListingToOfferWorkflow
import kotlinx.datetime.Clock
import org.jetbrains.compose.web.dom.Text
import org.kodein.di.compose.rememberInstance
import services.LoadOfferState
import services.OfferAnonLoader
import services.OfferAuthLoader
import services.SignatureLoadOfferState
import kotlin.time.Duration.Companion.days

// these signed link components will expire and may need to be updated by doing the generate signed offer link prototype/test
val testSignatureComponents = OfferAnonLoader.Companion.SignatureComponents(
  id = TEST_DRAFT_OFFER_UID.toString(),
  random = "C2nsEIsPJlVj0C8",
  signature = "ZGV2LWRpeW9mZmVyXDE2NjE5MTEwMTJcT3AtV1VwaHhyU3NXUHRadzQ2akNXZzZjRmtKYUlnZUxBRXRYdDljWXhZRVw"
)

@Composable
fun PrototypeCreateOfferDraft() {
  val clock by rememberInstance<Clock>()
  val listingToOfferWorkflow by rememberInstance<ListingToOfferWorkflow>()
  val (result, setResult) = remember { mutableStateOf<String?>(null) }

  if (result != null) {
    Text("Result = $result")
  }

  val offerContacts = baseTestContacts(clock)

  LaunchedEffect(Unit) {
    val r = listingToOfferWorkflow.createOfferDraft(TEST_PUBLISHED_LISTING_UID, offerContacts)
    setResult(r.saveResult.message)
  }
}

@Suppress("ComplexMethod")
@Composable
fun PrototypeUpdateOfferDraft() {
  val offerAnonLoader by rememberInstance<OfferAnonLoader>()
  val offerAnonRpcService by rememberInstance<IOfferAnonRpcService>()
  val (resultState, setResultState) = remember { mutableStateOf<String?>(null) }

  val offerState = offerAnonLoader.load(testSignatureComponents)

  if (resultState != null) {
    Text("Result = $resultState")
  }

  @Suppress("TooGenericExceptionCaught")
  LaunchedEffect(offerState.value) {
    when (val value = offerState.value) {
      is SignatureLoadOfferState.Success -> {
        tryRpc(onException = { message, _ -> setResultState(message) }) {
          val updatedOffer = value.offer.cast<Offer.Draft>().copy(expiry = Expiry.Core(4.days))
          val result = offerAnonRpcService.save(
            updatedOffer,
            testSignatureComponents.random,
            testSignatureComponents.signature
          )
          setResultState(
            when (result) {
              is OfferSignatureSaveResult.ValidSignature -> when (val saveResult = result.saveResult) {
                is OfferSaveResult.Success -> {
                  "Save Success (updated offer = ${saveResult.offer})"
                }

                else -> saveResult.message
              }

              else -> result.message()
            },
          )
        }
      }

      else -> setResultState("Offer load error: ${value::class.simpleName}")
    }
  }
}

@Suppress("ComplexMethod")
@Composable
fun PrototypeUpdateContacts() {
  val clock by rememberInstance<Clock>()
  val offerAnonLoader by rememberInstance<OfferAnonLoader>()
  val offerAnonRpcService by rememberInstance<IOfferAnonRpcService>()
  val (resultState, setResultState) = remember { mutableStateOf<String?>(null) }

  val offerState = offerAnonLoader.load(testSignatureComponents)

  if (resultState != null) {
    Text("Result = $resultState")
  }

  @Suppress("TooGenericExceptionCaught")
  LaunchedEffect(offerState.value) {
    when (val value = offerState.value) {
      is SignatureLoadOfferState.Success -> {
        tryRpc(onException = { message, _ -> setResultState(message) }) {
          val contacts = baseTestContacts(clock).run {
            copy(
              buyers = buyers.copy(
                contacts = buyers.contacts + Contact(
                  name = "New Contact",
                  methods = listOf(
                    ContactMethod.Email(
                      "newcontact@gmail.com",
                      Auditable.Core(Optional.of(false), clock.now())
                    ),
                  ),
                ),
              ),
            )
          }
          val result = offerAnonRpcService.saveContacts(
            UidValue(value.offer._id),
            contacts,
            testSignatureComponents.random,
            testSignatureComponents.signature
          )
          setResultState(result.message())
        }
      }

      else -> setResultState("Offer load error: ${value::class.simpleName}")
    }
  }
}

@Suppress("ComplexMethod")
@Composable
fun PrototypePublishOffer() {
  val offerAnonLoader by rememberInstance<OfferAnonLoader>()
  val offerAnonRpcService by rememberInstance<IOfferAnonRpcService>()
  val (resultState, setResultState) = remember { mutableStateOf<String?>(null) }

  val offerState = offerAnonLoader.load(testSignatureComponents)

  if (resultState != null) {
    Text("Result = $resultState")
  }

  LaunchedEffect(offerState.value) {
    when (val value = offerState.value) {
      is SignatureLoadOfferState.Success -> {
        tryRpc(onException = { message, _ -> setResultState(message) }) {
          val result = offerAnonRpcService.publish(
            value.offer,
            testSignatureComponents.random,
            testSignatureComponents.signature
          )
          setResultState(result.message())
        }
      }

      else -> setResultState("Offer load error: ${value::class.simpleName}")
    }
  }
}

@Composable
fun PrototypeCreateCounterOfferDraftBuyer() {
  val clock by rememberInstance<Clock>()
  val offerAnonLoader by rememberInstance<OfferAnonLoader>()
  val offerAnonRpcService by rememberInstance<IOfferAnonRpcService>()
  val (resultState, setResultState) = remember { mutableStateOf<String?>(null) }

  val offerState = offerAnonLoader.load(testSignatureComponents)

  if (resultState != null) {
    Text("Result = $resultState")
  }

  @Suppress("TooGenericExceptionCaught")
  LaunchedEffect(offerState.value) {
    when (val value = offerState.value) {
      is SignatureLoadOfferState.Success -> {
        tryRpc(onException = { message, _ -> setResultState(message) }) {
          val updatedOffer = createCounterOfferDraftFromPublishedOffer(
            offer = value.offer.cast(),
            role = Party.BUYER,
            clock = clock,
            defaultState = NegotiatedTerm.State.ACCEPTED,
          ).copy(
            state = Offer.State.DRAFT,
            expiry = Expiry.Core(6.days),
          )
          val result = offerAnonRpcService.save(
            updatedOffer,
            testSignatureComponents.random,
            testSignatureComponents.signature
          )
          setResultState(result.message())
        }
      }

      else -> setResultState("Offer load error: ${value::class.simpleName}")
    }
  }
}

@Composable
fun PrototypeCreateCounterOfferDraftSeller() {
  val clock by rememberInstance<Clock>()
  val offerAuthLoader by rememberInstance<OfferAuthLoader>()
  val offerAuthRpcService by rememberInstance<IOfferAuthRpcService>()
  val (resultState, setResultState) = remember { mutableStateOf<String?>(null) }

  val offerState = offerAuthLoader.load(testSignatureComponents.id.toUid())

  if (resultState != null) {
    Text("Result = $resultState")
  }

  @Suppress("TooGenericExceptionCaught")
  LaunchedEffect(offerState.value) {
    when (val value = offerState.value) {
      is LoadOfferState.Success -> {
        tryRpc(onException = { message, _ -> setResultState(message) }) {
          val updatedOffer = createCounterOfferDraftFromPublishedOffer(
            offer = value.offer.cast(),
            role = Party.SELLER,
            clock = clock,
            defaultState = NegotiatedTerm.State.ACCEPTED,
          ).copy(
            state = Offer.State.DRAFT,
            expiry = Expiry.Core(7.days),
          )
          val result = offerAuthRpcService.save(updatedOffer)
          setResultState(result.message)
        }
      }

      else -> setResultState("Offer load error: ${value::class.simpleName}")
    }
  }
}

@Composable
fun PrototypePublishCounterOfferSeller() {
  val offerAuthLoader by rememberInstance<OfferAuthLoader>()
  val offerAuthRpcService by rememberInstance<IOfferAuthRpcService>()
  val (resultState, setResultState) = remember { mutableStateOf<String?>(null) }

  val offerState = offerAuthLoader.load(testSignatureComponents.id.toUid())

  if (resultState != null) {
    Text("Result = $resultState")
  }

  @Suppress("TooGenericExceptionCaught")
  LaunchedEffect(offerState.value) {
    when (val value = offerState.value) {
      is LoadOfferState.Success -> {
        tryRpc(onException = { message, _ -> setResultState(message) }) {
          val result = offerAuthRpcService.publish(value.offer)
          setResultState(result.message)
        }
      }

      else -> setResultState("Offer load error: ${value::class.simpleName}")
    }
  }
}

@Composable
fun PrototypePublishCounterOfferBuyer() {
  val offerAnonLoader by rememberInstance<OfferAnonLoader>()
  val offerAnonRpcService by rememberInstance<IOfferAnonRpcService>()
  val (resultState, setResultState) = remember { mutableStateOf<String?>(null) }

  val offerState = offerAnonLoader.load(testSignatureComponents)

  if (resultState != null) {
    Text("Result = $resultState")
  }

  @Suppress("TooGenericExceptionCaught")
  LaunchedEffect(offerState.value) {
    when (val value = offerState.value) {
      is SignatureLoadOfferState.Success -> {
        tryRpc(onException = { message, _ -> setResultState(message) }) {
          val result = offerAnonRpcService.publish(
            value.offer,
            testSignatureComponents.random,
            testSignatureComponents.signature,
          )
          setResultState(result.message())
        }
      }

      else -> setResultState("Offer load error: ${value::class.simpleName}")
    }
  }
}

@Suppress("TooGenericExceptionCaught")
suspend fun createOffer(
  offerAnonRpcService: IOfferAnonRpcService,
  draftOffer: Offer.Draft,
  contacts: OfferContacts,
  setResult: (String?) -> Unit,
) {
  tryRpc(onException = { message, _ -> setResult(message) }) {
    val result = offerAnonRpcService.create(
      draftOffer,
      contacts,
    )
    setResult(
      when (val r = result.saveResult) {
        is OfferSaveResult.Success -> "Success, id = ${draftOffer._id}, offer = ${r.offer}"
        else -> r.message
      } + " random: ${result.random} signature: ${result.signature}"
    )
  }
}

@Suppress("TooGenericExceptionCaught")
suspend fun saveOfferAsBuyer(
  offerAnonRpcService: IOfferAnonRpcService,
  offer: Offer.Published,
  setResult: (String?) -> Unit,
) {
  tryRpc(onException = { message, _ -> setResult(message) }) {
    val result = offerAnonRpcService.save(
      offer,
      testSignatureComponents.random,
      testSignatureComponents.signature
    )
    setResult(result.message())
  }
}
