//
// GENERATED by KVision
//
package com.diyoffer.negotiation.rpcs

import org.w3c.fetch.RequestInit
import io.kvision.remote.KVRemoteAgent
import kotlinx.serialization.modules.SerializersModule
import com.diyoffer.negotiation.model.Listing
import com.diyoffer.negotiation.model.Offer
import com.diyoffer.negotiation.model.Offer.Draft
import com.diyoffer.negotiation.model.OfferContacts
import com.diyoffer.negotiation.model.UidValue
import com.diyoffer.negotiation.model.rpcs.ChecklistResult
import com.diyoffer.negotiation.model.rpcs.ListingOffersLoadResult
import com.diyoffer.negotiation.model.rpcs.LoadOfferVersionOption
import com.diyoffer.negotiation.model.rpcs.OfferContactsSaveResult
import com.diyoffer.negotiation.model.rpcs.OfferLoadResult
import com.diyoffer.negotiation.model.rpcs.OfferSaveResult
import kotlinx.datetime.TimeZone

actual class OfferAuthRpcService(serializersModules: List<SerializersModule>? = null, requestFilter: (suspend RequestInit.() -> Unit)? = null) : IOfferAuthRpcService, KVRemoteAgent<OfferAuthRpcService>(OfferAuthRpcServiceManager, serializersModules, requestFilter) {
    override suspend fun create(offer: Draft, contacts: OfferContacts) = call(IOfferAuthRpcService::create, offer, contacts)
    override suspend fun save(offer: Offer) = call(IOfferAuthRpcService::save, offer)
    override suspend fun saveContacts(id: UidValue<Offer>, contacts: OfferContacts) = call(IOfferAuthRpcService::saveContacts, id, contacts)
    override suspend fun load(id: UidValue<Offer>, versionOption: LoadOfferVersionOption) = call(IOfferAuthRpcService::load, id, versionOption)
    override suspend fun publish(offer: Offer) = call(IOfferAuthRpcService::publish, offer)
    override suspend fun loadOffersForListings(listingIds: List<UidValue<Listing>>) = call(IOfferAuthRpcService::loadOffersForListings, listingIds)
    override suspend fun checklist(offer: Offer, timeZone: TimeZone) = call(IOfferAuthRpcService::checklist, offer, timeZone)
    override suspend fun complete(offerId: UidValue<Offer>) = call(IOfferAuthRpcService::complete, offerId)
    override suspend fun accept(offerId: UidValue<Offer>) = call(IOfferAuthRpcService::accept, offerId)
    override suspend fun reject(offerId: UidValue<Offer>) = call(IOfferAuthRpcService::reject, offerId)
    override suspend fun cancel(offerId: UidValue<Offer>, reason: String) = call(IOfferAuthRpcService::cancel, offerId, reason)
}
