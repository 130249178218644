package com.diyoffer.negotiation.model.rpcs

import kotlinx.serialization.Serializable

/**
 * Specifies which version of an offer to load.
 */
@Serializable
enum class LoadOfferVersionOption {
  /** Load the latest version visible to the user, whether this is a published offer or draft. */
  LATEST,

  /** Load the latest published version. All published offers are visible to both parties. */
  LATEST_PUBLISHED,

  /** Load the latest draft version visible to the user. Must be a draft *after* the latest published version. */
  LATEST_DRAFT,
}
