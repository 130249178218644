package common

import androidx.compose.runtime.Composable
import kotlinx.browser.document
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import style.DiyStyleSheet

@Composable
fun Overlay() {
  Div({
    id("overlay")
    classes(DiyStyleSheet.overlay)
    onClick { displayOverlay(false) }
  })
}

fun displayOverlay(display: Boolean) {
  document.getElementById("overlay")?.let { overlay ->
    (overlay as HTMLDivElement).style.display = (if (display) DisplayStyle.Block else DisplayStyle.None).toString()
  }
}
