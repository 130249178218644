package com.diyoffer.negotiation.ui.checklist

import com.copperleaf.ballast.repository.cache.Cached
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.ui.offer.NegotiatedSummaryRow
import com.diyoffer.negotiation.ui.offer.OfferActionType
import com.diyoffer.negotiation.ui.state.LoadingState
import kotlinx.datetime.LocalDateTime

object ChecklistDetailContract {
  data class State(
    val initialized: Boolean = false,
    val loadingState: LoadingState = LoadingState.NOT_LOADED,
    val offerId: Uid<Offer>? = null,
    val signature: String? = null,
    val random: String? = null,
    val party: Party? = null,
    val offer: Offer? = null,
    val withheld: Boolean = false,
    val withheldUntil: LocalDateTime? = null,
    val listing: Listing.Published? = null,
    val listingUrl: String? = null,
    val counterRejectList: List<NegotiatedSummaryRow> = emptyList(),
    val rejectedList: List<String>? = null,
    val lastOfferEditTimeString: String? = null,
    val offerStatusLabel: String? = null,
    val error: String? = null,
    val sessionUser: SessionUser? = null,
  )

  sealed class Inputs {
    data class InitializeForBuyer(val offerId: Uid<Offer>) : Inputs()
    data class OfferReceived(val party: Party, val cachedOffer: Cached<Pair<Offer, OfferContacts>>) : Inputs()
    data class ListingReceived(val listingRes: ListingLoadResult.Success?, val user: SessionUser?) : Inputs()
    data class Clicked(val action: OfferActionType) : Inputs()
    data class SetError(val error: String?) : Inputs()
  }

  sealed class Events {
    data class OnNavigate(val url: String) : Events()
  }
}
