package style

import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.StyleSheet
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.left
import org.jetbrains.compose.web.css.margin
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.minHeight
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.style
import org.jetbrains.compose.web.css.top
import org.jetbrains.compose.web.css.width

object WelcomeCardStyleSheet : StyleSheet() {
  val container by style {
    borderRadius(4.px)
    padding(24.px)
    display(DisplayStyle.Flex)
    flexDirection(FlexDirection.Column)
    width(316.px)
    position(Position.Relative)
  }
  val border by style {
    border {
      style(LineStyle.Solid)
      width(1.px)
      color(DiyStyleSheet.Colors.lightGrey)
    }
  }
  val highlightBorder by style {
    border {
      style(LineStyle.Solid)
      width(1.px)
      color(DiyStyleSheet.Colors.green)
    }
  }

  val message by style {
    color(DiyStyleSheet.Colors.darkGrey)
    marginBottom(16.px)
  }

  val title by style {
    fontWeight(DiyStyleSheet.Weights.darkNormal)
    fontSize(16.px)
    marginBottom(8.px)
  }

  val highlight by style {
    position(Position.Absolute)
    top((10.unaryMinus()).px)
    left(28.px)
    padding(4.px)
    backgroundColor(DiyStyleSheet.Colors.green)
    fontSize(10.px)
    color(Color.white)
    fontWeight(DiyStyleSheet.Weights.darkNormal)
    property("text-transform", "uppercase")
  }

  val sideBarContainer by style {
    display(DisplayStyle.Flex)
    flexDirection(FlexDirection.Column)
    width(354.px)
    padding(32.px, 0.px)
    backgroundColor(DiyStyleSheet.Colors.lightDark)
    minHeight(600.px)
  }

  val sideBarTitle by style {
    marginBottom(12.px)
    padding(0.px, 32.px, 12.px, 32.px)
    color(DiyStyleSheet.Colors.grey)
    fontWeight(DiyStyleSheet.Weights.darkNormal)
    fontSize(16.px)
    borderRadius(0.px)
    property("border-bottom", "1px solid #E1E7ED")
  }

  val sideBarListings by style {
    display(DisplayStyle.Flex)
    flexDirection(FlexDirection.Column)
    gap(20.px)
  }

  val sideBarListingItem by style {
    display(DisplayStyle.Flex)
    flexDirection(FlexDirection.Column)
    margin(0.px, 32.px)
    backgroundColor(DiyStyleSheet.Colors.white)
  }

  val sideBarListingInfo by style {
    display(DisplayStyle.Flex)
    flexDirection(FlexDirection.Column)
    padding(12.px)
    property("border", "1px solid ${DiyStyleSheet.Colors.green}")
    property("border-bottom", "0")
    property("border-bottom-left-radius", "0")
    property("border-bottom-right-radius", "0")
  }

  val sideBarListingAddress by style {
    display(DisplayStyle.Flex)
    flexDirection(FlexDirection.Column)
    gap(4.px)
  }

  val sideBarListedDate by style {
    fontSize(12.px)
    color(Color("#969696"))
  }

  val sideBarListingStreet by style {
    fontSize(16.px)
    color(DiyStyleSheet.Colors.darkBlue)
    fontWeight(DiyStyleSheet.Weights.bold)
  }

  val sideBarLocation by style {
    marginTop(8.px)
    display(DisplayStyle.Flex)
    alignItems(AlignItems.Center)
    gap(8.px)
  }

  val sideBarListingItemExpand by style {
    display(DisplayStyle.Flex)
    justifyContent(JustifyContent.Center)
    padding(9.px, 0.px)
    property("border", "1px solid ${DiyStyleSheet.Colors.green}")
    property("border-top", "1px solid #E1E7ED")
    property("border-top-left-radius", "0")
    property("border-top-right-radius", "0")
  }
}
