package forms

import androidx.compose.runtime.Composable
import builders.AssumableContractBuilder
import com.diyoffer.negotiation.common.formatCurrency
import com.diyoffer.negotiation.common.formatDate
import com.diyoffer.negotiation.model.*
import common.DatePicker
import common.FlatList
import common.MoneyField
import common.Row
import components.DiySelect
import components.WithOptionKeyDisplay
import components.WithOptionKeyEdit
import dev.petuska.kmdcx.icons.MDCIcon
import org.jetbrains.compose.web.css.CSSColorValue
import style.DiyStyleSheet.Colors.green

@Composable
@Suppress("LongMethod")
fun ListingAssumableContractEdit(
  currency: Currency,
  builder: AssumableContractBuilder,
  setBuilder: (AssumableContractBuilder) -> Unit,
) {
  WithOptionKeyEdit(opts = {
    label = "Contract"
    value = builder.option
    onModified = { setBuilder(builder.copy(option = it)) }
    predefined = ContractOption.values.associate { it as OptionKey.Predefined to it.title }
  }) {
    Row {
      DiySelect(
        label = "Financing",
        items = AssumableContractDetails.Type.values().toList(),
        initialSelected = builder.type,
        renderer = { it.title },
      ) {
        setBuilder(builder.copy(type = it))
      }
    }

    builder.type?.let { f ->
      Row {
        MoneyField(currency, {
          label = "Monthly fee"
          value = builder.monthlyFee
          onModified = { setBuilder(builder.copy(monthlyFee = it)) }
        })
      }
      Row {
        DatePicker({
          label = "Expiry Date (leave blank for month-to-month rentals)"
          value = builder.expiry
          onModified = { setBuilder(builder.copy(expiry = it)) }
        })
      }
      if (f.hasBuyout) {
        Row {
          MoneyField(currency, {
            label = "Buyout Fee"
            value = builder.buyoutFee
            onModified = { setBuilder(builder.copy(buyoutFee = it)) }
          })
        }
      }
    }
  }
}

@Composable
fun AssumableContractDisplay(
  item: AssumableContract,
  currency: Currency,
  icon: MDCIcon = MDCIcon.Feed,
  iconColor: CSSColorValue = green,
  lineThrough: Boolean = false,
) {
  WithOptionKeyDisplay(opts = {
    optionKey = item.contract
    predefinedTitle = (item.contract as? ContractOption)?.title
    this.icon = icon
    this.iconColor = iconColor
    this.lineThrough = lineThrough
  }) {
    AssumableContractFlatList(item.contractDetails, currency)
  }
}

@Composable
private fun AssumableContractFlatList(contractDetails: AssumableContractDetails, currency: Currency) {
  FlatList(
    contractDetails.type.title,
    formatCurrency(contractDetails.monthlyFee, currency),
    if (contractDetails.expiry != null) formatDate(contractDetails.expiry!!) else null,
    if (contractDetails is AssumableContractDetails.HasBuyout) {
      formatCurrency(contractDetails.buyoutFee, currency)
    } else {
      null
    }
  )
}
