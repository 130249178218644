//
// GENERATED by KVision
//
package com.diyoffer.negotiation.rpcs

import io.kvision.remote.HttpMethod
import io.kvision.remote.KVServiceManager
import io.kvision.remote.registerKVisionServiceExceptions

expect class OfferAnonRpcService : IOfferAnonRpcService

object OfferAnonRpcServiceManager : KVServiceManager<OfferAnonRpcService>(OfferAnonRpcService::class) {
    init {
        registerKVisionServiceExceptions()
        bind(IOfferAnonRpcService::create, HttpMethod.POST, "offer/anon/create")
        bind(IOfferAnonRpcService::save, HttpMethod.POST, "offer/anon/save")
        bind(IOfferAnonRpcService::saveContacts, HttpMethod.POST, "offer/anon/save_contacts")
        bind(IOfferAnonRpcService::load, HttpMethod.POST, "offer/anon/load")
        bind(IOfferAnonRpcService::publish, HttpMethod.POST, "offer/anon/publish")
        bind(IOfferAnonRpcService::reject, HttpMethod.POST, "offer/anon/reject")
        bind(IOfferAnonRpcService::accept, HttpMethod.POST, "offer/accept")
        bind(IOfferAnonRpcService::checklist, HttpMethod.POST, "offer/anon/checklist")
    }
}
