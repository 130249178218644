package com.diyoffer.negotiation.logic

import com.diyoffer.negotiation.model.*
import kotlinx.datetime.Instant
import kotlin.time.Duration.Companion.days

val MINIMUM_DAYS_AFTER_OTHER_OFFER_EXPIRY = 1.days

fun minimumOfferExpirationDays(otherActiveOfferExpiry: Instant, relativeTo: Instant): Days {
  val minimumExpiry = otherActiveOfferExpiry + MINIMUM_DAYS_AFTER_OTHER_OFFER_EXPIRY
  val daysUntilMinimumExpiryOrNull = generateSequence(0) { it + 1 }
    .takeWhile { (relativeTo + it.days) < minimumExpiry }
    .lastOrNull()
  return Days((daysUntilMinimumExpiryOrNull ?: Int.MIN_VALUE) + 1)
}
