package com.diyoffer.negotiation.ui.condition

import com.copperleaf.ballast.EventHandler
import com.copperleaf.ballast.EventHandlerScope
import com.diyoffer.negotiation.common.Ignore
import com.diyoffer.negotiation.ui.condition.ConditionListContract.Events
import com.diyoffer.negotiation.ui.condition.ConditionListContract.Inputs
import com.diyoffer.negotiation.ui.condition.ConditionListContract.State

class ConditionListEventHandler : EventHandler<Inputs, Events, State> {
  override suspend fun EventHandlerScope<Inputs, Events, State>.handleEvent(event: Events) = Ignore
}
