package com.diyoffer.negotiation.ui.state

enum class LoadingState {
  NOT_LOADED,
  FETCHING,
  READY,
  UNAUTHORIZED,
  ERROR,
  ;

  fun transitionToFetching() = when (this) {
    NOT_LOADED -> NOT_LOADED
    else -> FETCHING
  }

  fun isLoading() = when (this) {
    NOT_LOADED, FETCHING -> true
    else -> false
  }
}
