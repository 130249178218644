package forms

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import co.touchlab.kermit.Logger
import com.diyoffer.negotiation.messages.CommonMessages
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.ConditionParamContent.Companion.paramClass
import com.diyoffer.negotiation.ui.condition.ConditionListContract.Inputs
import com.diyoffer.negotiation.ui.condition.ConditionListContract.State
import com.diyoffer.negotiation.ui.condition.ConditionListEventHandler
import com.diyoffer.negotiation.ui.condition.getOptions
import com.diyoffer.negotiation.ui.condition.getPopupRef
import com.diyoffer.negotiation.ui.condition.getTitle
import com.diyoffer.negotiation.ui.state.LoadingState
import common.Button
import common.DarkBlueButton
import common.FlatList
import common.HelpChip
import common.Row
import common.TextArea
import common.TextButton
import common.TextField
import components.AsciidocContent
import components.DiySelect
import components.Loading
import components.WithOptionKeyDisplay
import components.snackbar.Snackbar
import dev.petuska.kmdcx.icons.MDCIcon
import kotlinx.datetime.Clock
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.paddingBottom
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.DateInput
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Hr
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.NumberInput
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.TextInput
import org.kodein.di.compose.rememberInstance
import org.w3c.dom.HTMLDivElement
import style.DiyStyleSheet
import style.DiyStyleSheet.Colors.green
import style.DiyStyleSheet.Sizes.paddingXs
import style.GridStyleSheet.alignItemsCenter
import style.GridStyleSheet.flex
import style.GridStyleSheet.flexColumn
import style.GridStyleSheet.justifyContentBetween
import style.GridStyleSheet.justifyContentEnd
import vm.listing.ConditionListViewModel
import vm.listing.ConditionListViewModelConfiguration
import vm.listing.NegotiatedConditionListViewModel
import kotlin.math.absoluteValue

@Composable
fun ConditionListEdit(
  state: State,
  sendVM: (Inputs) -> Unit,
) {
  if (state.unselectedConditionOptions().isNotEmpty()) {
    Div {
      SubTitle("Select as many as you want from our list to add below.")
      ConditionOptionPicker(sendVM, state)
    }
  }

  SubTitle("Don't see what you're looking for?")

  Row({ style { marginTop(12.px) } }) {
    Button({
      classes(DiyStyleSheet.onLightBlue, DiyStyleSheet.button)
      style {
        fontWeight(DiyStyleSheet.Weights.semiBold)
        fontSize(14.px)
      }
      this.onClick { sendVM(Inputs.CustomConditionClicked) }
    }) {
      Text("+ Add your own condition to fulfill your requirements.")
    }
  }

  if (state.newCondition != null) {
    AddCustomCondition(sendVM, state)
  }

  Hr()
  ConditionList(sendVM, state) { idx, content ->
    ConditionEditWrapper(idx, sendVM, state, content)
  }
}

@Composable
fun WithConditionVM(contentBuilder: @Composable (vm: ConditionListViewModel, state: State) -> Unit) {
  val clock by rememberInstance<Clock>()
  val scope = rememberCoroutineScope()
  val vmConfig by rememberInstance<ConditionListViewModelConfiguration>()
  val vm = remember(scope) {
    ConditionListViewModel(
      scope,
      vmConfig,
      ConditionListEventHandler(),
      clock
    )
  }
  val state by vm.observeStates().collectAsState()

  Snackbar(state.error) { vm.trySend(Inputs.ClearError) }

  if (state.loadingState != LoadingState.READY) {
    Loading("Loading Conditions")
  }

  contentBuilder(vm, state)
}

@Composable
fun WithNegotiatedConditionVM(
  contentBuilder: @Composable (vm: NegotiatedConditionListViewModel, state: State) -> Unit,
) {
  val scope = rememberCoroutineScope()
  val vmConfig by rememberInstance<ConditionListViewModelConfiguration>()
  val vm = remember(scope) {
    NegotiatedConditionListViewModel(
      scope,
      vmConfig,
      ConditionListEventHandler(),
    )
  }
  val state by vm.observeStates().collectAsState()

  Snackbar(state.error) { vm.trySend(Inputs.ClearError) }

  if (state.loadingState != LoadingState.READY) {
    Loading("Loading Conditions")
  }

  contentBuilder(vm, state)
}

@Composable
private fun ConditionOptionPicker(sendVM: (Inputs) -> Unit, state: State) {
  Div({
    style {
      marginTop(8.px)
      marginBottom(24.px)
    }
  }) {
    DiySelect(
      label = "Common Conditions",
      items = state.unselectedConditionOptions(),
      renderer = { it.title },
    ) {
      if (it != null) sendVM(Inputs.Select(it))
    }

    val addDisabled = state.selectedOption == null
    Row({ style { marginTop(12.px) } }) {
      Button({
        if (addDisabled) disabled()
        classes(
          if (!addDisabled) DiyStyleSheet.onLightBlue else DiyStyleSheet.onLightGrey,
          DiyStyleSheet.button
        )
        onClick { sendVM(Inputs.AddSelected) }
      }) {
        Text("+ Add selected Common Condition")
      }
    }
  }
}

@Composable
private fun SubTitle(text: String) {
  Row({
    style {
      color(DiyStyleSheet.Colors.darkBlue)
      fontWeight(DiyStyleSheet.Weights.semiBold)
    }
  }) {
    Text(text)
  }
}

@Composable
private fun AddCustomCondition(sendVM: (Inputs) -> Unit, state: State) {
  Div({
    classes(flex, flexColumn)
    style {
      marginTop(24.px)
      gap(12.px)
    }
  }) {
    TextField(opts = {
      label = "Your condition title"
      value = state.newCondition?.title
      onModified = { sendVM(Inputs.CustomConditionTitle(it ?: "")) }
    })
    TextArea(opts = {
      label = "Your condition detail"
      value = state.newCondition?.body
      onModified = { sendVM(Inputs.CustomConditionText(it ?: "")) }
    })
    Div({
      classes(flex, justifyContentEnd)
      style { gap(16.px) }
    }) {
      DarkBlueButton(attrs = { onClick { sendVM(Inputs.CustomConditionSave) } }) {
        Text("Add")
      }
      TextButton(opts = {
        label = "Cancel"
        color = DiyStyleSheet.Colors.darkGrey
      }) {
        onClick { sendVM(Inputs.CustomConditionCancel) }
      }
    }
  }
}

@Composable
private fun ConditionEditWrapper(
  index: Int,
  sendVM: (Inputs) -> Unit,
  state: State,
  content: ContentBuilder<HTMLDivElement>,
) {
  Div({
    classes(flex, flexColumn)
    style {
      gap(16.px)
      paddingLeft(16.px)
      borderRadius(0.px)
      property("border-left", "2px solid $green")
    }
  }) {
    ConditionItemTitle(index, state.conditions!![index], sendVM, state)
    Div(content = content)
  }
}

@Composable
fun ConditionList(
  sendVM: (Inputs) -> Unit,
  state: State,
  conditionWrapper: @Composable (
    idx: Int,
    content: ContentBuilder<HTMLDivElement>,
  ) -> Unit,
) {
  if (!state.conditions.isNullOrEmpty()) {
    Div({
      classes(flex, flexColumn)
    }) {
      SubTitle("Conditions List")
      Div({
        classes(flex, flexColumn)
        style { gap(24.px) }
      }) {
        state.conditions!!.mapIndexed { index, c ->
          conditionWrapper(index) {
            ConditionItemBody(c, sendVM, state)
          }
        }
      }
    }
  }
}

@Composable
private fun ConditionItemTitle(
  index: Int,
  condition: Condition,
  sendVM: (Inputs) -> Unit,
  state: State,
) {
  val title = state.getTitle(condition)
  Div({
    classes(flex, flexColumn)
    style {
      gap(20.px)
    }
  }) {
    Div({
      classes(flex, justifyContentBetween)
    }) {
      Div({
        classes(flex, alignItemsCenter)
        style { gap(10.px) }
      }) {
        Img("images/gavel.svg")
        Span({ style { fontWeight(DiyStyleSheet.Weights.semiBold) } }) {
          Text(title)
        }
        state.getPopupRef(condition.optionKey)?.let { cmsReference ->
          HelpChip(cmsReference.id, title = title, titleIcon = MDCIcon.Gavel)
        }
      }
      Div({
        classes(flex, alignItemsCenter)
        style { gap(16.px) }
      }) {
        if (state.draftMode) {
          if (condition.default) {
            Span({ classes(DiyStyleSheet.italicGreyText) }) { Text("Default") }
          } else {
            TextButton(opts = {
              label = "Remove"
              color = DiyStyleSheet.Colors.red
            }) {
              onClick { sendVM(Inputs.Remove(index)) }
            }
          }
        }
      }
    }
  }
}

@Composable
private fun ConditionItemBody(
  condition: Condition,
  sendVM: (Inputs) -> Unit,
  state: State,
) {
  Row {
    when (val key = condition.optionKey) {
      is OptionKey.Dynamic -> {
        state.getOptions(key)?.template?.let { template ->
          if (state.draftMode) {
            template.content.map { DynamicConditionEditArea(condition, it, sendVM) }
          } else {
            AsciidocContent(template.toAsciiDoc(condition.parameters))
          }
        }
      }

      is OptionKey.Custom -> Text(key.value.text)
      else -> Logger.e("Conditions should be either Dynamic or custom")
    }
  }
}

@Composable
@Suppress("LongMethod")
fun DynamicConditionEditArea(
  condition: Condition,
  conditionContent: ConditionContent,
  sendVM: (Inputs) -> Unit,
) {
  val conditionKey = condition.optionKey.unsafeCast<OptionKey.Dynamic>()._id
  when (conditionContent) {
    is ConditionAsciidocContent -> Text(conditionContent.content.text.trim('*'))
    is ConditionParamContent -> {
      val currValue = condition.parameters[conditionContent.param] ?: ""
      when (conditionContent.paramType.paramClass) {
        // For now, simply use the Number input for numbers and money. The MoneyField is not great for inlining atm
        ConditionParamContent.ConditionParamClass.INT,
        ConditionParamContent.ConditionParamClass.MONEY,
        -> {
          NumberInput(
            value = currValue.toIntOrNull()?.absoluteValue ?: 0,
            min = 0
          ) {
            classes("input-inline")
            style { width(conditionContent.inputSize.px) }
            conditionContent.placeholder?.let { placeholder(it) }
            onInput {
              sendVM(
                Inputs.DynamicConditionParamChange(
                  conditionKey,
                  conditionContent.param,
                  it.value.toString()
                )
              )
            }
          }
        }

        ConditionParamContent.ConditionParamClass.TEXT -> {
          TextInput(value = currValue, attrs = {
            classes("input-inline")
            style { width(conditionContent.inputSize.px) }
            conditionContent.placeholder?.let { placeholder(it) }
            onInput {
              sendVM(
                Inputs.DynamicConditionParamChange(conditionKey, conditionContent.param, it.value)
              )
            }
          })
        }

        ConditionParamContent.ConditionParamClass.DATETIME,
        ConditionParamContent.ConditionParamClass.DATE,
        -> {
          DateInput(value = currValue, attrs = {
            classes("input-inline", "none-icon")
            style { width(conditionContent.inputSize.px) }
            conditionContent.placeholder?.let { placeholder(it) }
            onInput {
              sendVM(
                Inputs.DynamicConditionParamChange(conditionKey, conditionContent.param, it.value)
              )
            }
          })
        }
        else -> Text(" | ${conditionContent.param} | ")
      }
    }
  }
}

data class ConditionUI(
  val condition: Condition,
  val icon: MDCIcon = MDCIcon.Gavel,
  val iconColor: CSSColorValue = green,
  val removed: Boolean = false,
  val title: String? = null,
)

@Composable
fun ConditionsDisplay(conditions: List<ConditionUI>, editMode: Boolean) {
  if (conditions.isEmpty()) {
    FlatList("No condition were provided. " + if (editMode) CommonMessages.clickCardToAddMore else "")
  } else {
    conditions.map {
      WithOptionKeyDisplay(opts = {
        optionKey = it.condition.optionKey
        predefinedTitle = it.title
        lineThrough = it.removed
        this.icon = it.icon
        this.iconColor = it.iconColor
      }, attrs = { style { paddingBottom(paddingXs) } })
    }
  }
}
