@file:Suppress("TooManyFunctions")

package com.diyoffer.negotiation.common.services.validation

import com.diyoffer.negotiation.model.*

fun sellerTermAcceptCounterStateModel(stage: NegotiationStage): List<ValidStateChange> = when (stage) {
  NegotiationStage.SELLER_DRAFTING_LISTING -> listOf(
    null to NegotiatedTerm.State.NEW
  )
  NegotiationStage.BUYER_DRAFTING_OFFER -> listOf(
    NegotiatedTerm.State.NEW to NegotiatedTerm.State.ACCEPTED,
    NegotiatedTerm.State.NEW to NegotiatedTerm.State.COUNTERED,
  )
  NegotiationStage.SELLER_COUNTERING -> listOf(
    NegotiatedTerm.State.COUNTERED to NegotiatedTerm.State.ACCEPTED,
    NegotiatedTerm.State.COUNTERED to NegotiatedTerm.State.COUNTERED,
    NegotiatedTerm.State.COUNTERED to NegotiatedTerm.State.REJECTED,
    NegotiatedTerm.State.REJECTED to NegotiatedTerm.State.COUNTERED,
    NegotiatedTerm.State.REJECTED to NegotiatedTerm.State.ACCEPTED,
  )
  NegotiationStage.BUYER_COUNTERING -> listOf(
    NegotiatedTerm.State.COUNTERED to NegotiatedTerm.State.ACCEPTED,
    NegotiatedTerm.State.COUNTERED to NegotiatedTerm.State.COUNTERED,
    NegotiatedTerm.State.COUNTERED to NegotiatedTerm.State.REJECTED,
    NegotiatedTerm.State.REJECTED to NegotiatedTerm.State.COUNTERED,
    NegotiatedTerm.State.REJECTED to NegotiatedTerm.State.ACCEPTED,
  )
}

fun sellerTermAcceptRejectModel(stage: NegotiationStage): List<ValidStateChange> = when (stage) {
  NegotiationStage.SELLER_DRAFTING_LISTING -> listOf(
    // During validation, a null prevState is deemed new, so here, we allow going from null to NEW
    NegotiatedTerm.State.NEW to NegotiatedTerm.State.NEW,
  )
  NegotiationStage.BUYER_DRAFTING_OFFER -> listOf(
    NegotiatedTerm.State.NEW to NegotiatedTerm.State.ACCEPTED,
    NegotiatedTerm.State.NEW to NegotiatedTerm.State.REJECTED,
  )
  NegotiationStage.SELLER_COUNTERING -> listOf(
    NegotiatedTerm.State.REJECTED to NegotiatedTerm.State.REMOVED,
    NegotiatedTerm.State.REJECTED to NegotiatedTerm.State.REJECTED,
  )
  NegotiationStage.BUYER_COUNTERING -> listOf(
    NegotiatedTerm.State.NEW to NegotiatedTerm.State.ACCEPTED,
    NegotiatedTerm.State.NEW to NegotiatedTerm.State.REJECTED,
    NegotiatedTerm.State.REJECTED to NegotiatedTerm.State.ACCEPTED,
    NegotiatedTerm.State.REJECTED to NegotiatedTerm.State.REJECTED,
  )
}

fun buyerTermAcceptRejectStateModel(stage: NegotiationStage): List<ValidStateChange> = when (stage) {
  NegotiationStage.SELLER_DRAFTING_LISTING -> emptyList()
  NegotiationStage.BUYER_DRAFTING_OFFER -> listOf(
    // During validation, a null prevState is deemed new, so here, we allow going from null to NEW
    NegotiatedTerm.State.NEW to NegotiatedTerm.State.NEW,
  )
  NegotiationStage.SELLER_COUNTERING -> listOf(
    // First time countering
    NegotiatedTerm.State.NEW to NegotiatedTerm.State.ACCEPTED,
    NegotiatedTerm.State.NEW to NegotiatedTerm.State.REJECTED,
    // Counter-Counter
    NegotiatedTerm.State.REJECTED to NegotiatedTerm.State.ACCEPTED,
    NegotiatedTerm.State.REJECTED to NegotiatedTerm.State.REJECTED,
  )
  NegotiationStage.BUYER_COUNTERING -> listOf(
    NegotiatedTerm.State.REJECTED to NegotiatedTerm.State.REMOVED,
    NegotiatedTerm.State.REJECTED to NegotiatedTerm.State.REJECTED,
  )
}

fun priceStateModel(stage: NegotiationStage) = sellerTermAcceptCounterStateModel(stage)

fun depositStateModel(stage: NegotiationStage) = sellerTermAcceptCounterStateModel(stage)

fun closingDateStateModel(stage: NegotiationStage) = sellerTermAcceptCounterStateModel(stage)

fun bindingAgreementDaysStateModel(stage: NegotiationStage) = sellerTermAcceptCounterStateModel(stage)

fun assumableContractStateModel(stage: NegotiationStage) = sellerTermAcceptRejectModel(stage)

fun fixturesExcludedStateModel(stage: NegotiationStage) = sellerTermAcceptRejectModel(stage)

fun chattelIncludedStateModel(stage: NegotiationStage) = sellerTermAcceptRejectModel(stage)

fun buyerConditionStateModel(stage: NegotiationStage) = buyerTermAcceptRejectStateModel(stage)

fun sellerConditionStateModel(stage: NegotiationStage) = sellerTermAcceptRejectModel(stage)

fun additionalRequestStateModel(stage: NegotiationStage) = buyerTermAcceptRejectStateModel(stage)
