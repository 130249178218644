package com.diyoffer.negotiation.common.services.links

import com.diyoffer.negotiation.environment.isHosted
import com.diyoffer.negotiation.environment.isNakedProd
import com.diyoffer.negotiation.environment.isNakedTest
import com.diyoffer.negotiation.model.*
import io.ktor.http.*

@Suppress("ComplexMethod")
fun LandingUrl.linkFor(party: Party): String {
  val urlBuilder = URLBuilder(url)
  val isHosted = urlBuilder.isHosted()
  val (host, paramBuilder) = when {
    urlBuilder.isNakedProd() && party == Party.BUYER ->
      "buy.${urlBuilder.host}" to urlBuilder.parameters

    urlBuilder.isNakedProd() && party == Party.SELLER ->
      "sell.${urlBuilder.host}" to urlBuilder.parameters

    urlBuilder.isNakedTest() && party == Party.BUYER ->
      "buy-${urlBuilder.host}" to urlBuilder.parameters

    urlBuilder.isNakedTest() && party == Party.SELLER ->
      "sell-${urlBuilder.host}" to urlBuilder.parameters

    isHosted && party == Party.BUYER ->
      urlBuilder.host.replace("sell", "buy") to urlBuilder.parameters

    isHosted && party == Party.SELLER ->
      urlBuilder.host.replace("buy", "sell") to urlBuilder.parameters

    !isHosted && party == Party.BUYER ->
      urlBuilder.host to urlBuilder.parameters.apply {
        remove("party")
        append("party", "buyer")
      }

    !isHosted && party == Party.SELLER ->
      urlBuilder.host to urlBuilder.parameters.apply {
        remove("party")
        append("party", "seller")
      }

    else ->
      urlBuilder.host to urlBuilder.parameters
  }
  return URLBuilder(
    urlBuilder.protocol,
    host,
    urlBuilder.port,
    parameters = paramBuilder.build(),
    pathSegments = urlBuilder.pathSegments,
    fragment = urlBuilder.fragment
  ).buildString()
}

fun LandingUrl.getParty() = try {
  if (url.isHosted()) {
    when {
      url.host.startsWith("buy") -> Party.BUYER
      url.host.startsWith("sell") -> Party.SELLER
      else -> null
    }
  } else {
    val q = url.parameters
    when {
      q.contains("party", "buyer") || q.contains("party", "BUYER") -> Party.BUYER
      q.contains("party", "seller") || q.contains("party", "SELLER") -> Party.SELLER
      else -> null
    }
  }
} catch (_: URLParserException) {
  null
}

fun LandingUrl.trimAuth() = try {
  LandingUrl(
    URLBuilder(url).apply {
      parameters.remove("signature")
      parameters.remove("random")
    }.build()
  )
} catch (_: URLParserException) {
  this
}
