package com.diyoffer.negotiation.rpcs

import com.diyoffer.negotiation.common.RetryAttemptHandler
import com.diyoffer.negotiation.model.Optional
import com.diyoffer.negotiation.model.rpcs.*
import io.kvision.remote.ServiceException

/**
 * An implementation of [RetryAttemptHandler] that logs if a log lambda is provided, and continues retrying if the
 * exception is not a [ServiceException] that has timed out. See
 * https://kvision.gitbook.io/kvision-guide/6.-full-stack-development-guide/exception-handling.
 */
fun <T> onRpcAttempt(log: ((attempt: Int, e: Exception) -> Unit)? = null): RetryAttemptHandler<T> =
  { attempt, e ->
    val retry = e !is ServiceException || e.toRpcError() is RpcError.BackendTimeout
    if (log != null && retry) log(attempt, e)
    if (retry) Optional.absent() else throw e
  }
