package com.diyoffer.negotiation.model.rpcs

import io.kvision.remote.ServiceException
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json

/**
 * This is a work-around for the lack of a good exception handling mechanism in KVision remoting, which
 * reduces everything down to one exception type `ServiceException` and a message.
 * See https://github.com/rjaros/kvision/issues/354.
 *
 * This mechanism stuffs a serialized error object [RpcError] into the [ServiceException] message, and
 * makes it easy to use on the server-side and client-side.
 *
 * For the backend, see ServiceRpcException.
 */
@Serializable
sealed class RpcError {
  companion object {
    // used by the frontend e.g. RpcError(e) where e is a ServiceException
    operator fun invoke(serviceException: ServiceException) =
      Json.decodeFromString<RpcError>(serviceException.message!!)
  }

  @Serializable
  @SerialName("ua")
  data object Unauthorized : RpcError()

  @Serializable
  @SerialName("bt")
  data object BackendTimeout : RpcError()

  @Serializable
  @SerialName("ue")
  class UnexpectedError(val errorId: String, val message: String, val stackTrace: String) : RpcError()
}

fun ServiceException.toRpcError(): RpcError = RpcError(this)
