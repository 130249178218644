package com.diyoffer.negotiation.common.services.offers

import com.diyoffer.negotiation.model.*
import kotlinx.datetime.Clock

@Suppress("UNCHECKED_CAST")
fun <T : Offer> T.transformAuditablesGeneric(
  comparedWithOffer: Offer?,
  transform: (current: Auditable<*>?, existing: Auditable<*>?) -> Auditable<*>?,
): T {
  // casts are a workaround for https://youtrack.jetbrains.com/issue/KT-21908
  return when (val o = this as Offer) {
    is Offer.Draft -> o.transformAuditables(comparedWithOffer as? Offer.Draft, transform)
    is Offer.Published -> o.transformAuditables(comparedWithOffer as? Offer.Published, transform)
  } as T
}

/**
 * Provides the buyer agent commission entry in summary form.
 */
fun Offer.Published.buyerAgentCommissionSummary(): AgentCommissionSummary =
  when (val value = buyerAgent.commission.core.value) {
    Absent, is Empty -> AgentCommissionSummary.NoAgent
    is Present -> if (value.value.estimate) {
      AgentCommissionSummary.Estimate(value.value.commission)
    } else {
      AgentCommissionSummary.Known(value.value.commission)
    }
  }

/**
 * Determines the current [NegotiationStage] of an [Offer].
 *
 * If the [Offer] is an [Offer.Draft], then the stage is always [NegotiationStage.BUYER_DRAFTING_OFFER].
 *
 * If the [Offer] is an [Offer.Published], then whether the buyer or seller is countering is based on the authoring
 * party of the published offer, and the offer's state.
 */
fun negotiationStageOf(offer: Offer): NegotiationStage {
  return when (offer) {
    is Offer.Draft -> NegotiationStage.BUYER_DRAFTING_OFFER
    is Offer.Published -> when (offer.authoredBy) {
      Party.BUYER ->
        if (offer.state == Offer.State.DRAFT) NegotiationStage.BUYER_COUNTERING else NegotiationStage.SELLER_COUNTERING
      Party.SELLER ->
        if (offer.state == Offer.State.DRAFT) NegotiationStage.SELLER_COUNTERING else NegotiationStage.BUYER_COUNTERING
    }
  }
}

/**
 * Creates an offer draft from a listing by setting as initial values all the negotiable terms from the listing,
 * with the change history appropriately set. Setting these terms is required by the rules of the model.
 *
 * The frontend code should validate that the listing is in an appropriate state before allowing the user to start
 * an offer i.e. the UI state should not even allow a new offer to be created in that situation, but we check the
 * precondition here to fail fast.
 */
fun createOfferDraftFromListing(listing: Listing.Published, offerId: Uid<Offer>? = null): Offer.Draft {
  require(listing.state in Listing.State.statesAcceptingOffers) { "Listing not in valid state to accept an offer" }

  fun <T : Any> newNegotiatedTerm(value: Auditable<T>) = NegotiatedTerm(
    currentValue = value.core.value,
    changeHistory = listOf(
      NegotiatedTermValue(value, Party.SELLER, NegotiatedTerm.State.NEW),
    ),
    state = NegotiatedTerm.State.NEW,
  )

  return Offer.Draft(
    _id = offerId ?: newUid(),
    version = 0,
    currency = listing.currency,
    onListing = listing._id,
    price = OfferPrice(
      deposit = newNegotiatedTerm(listing.details.deposit),
      price = newNegotiatedTerm(listing.details.price),
    ),
    closing = OfferClosing(
      date = newNegotiatedTerm(listing.details.closingDate)
    ),
    assumableContracts = OfferAssumableContracts(
      contracts = listing.assumableContracts.contracts.map {
        newNegotiatedTerm(it)
      }
    ),
    fixturesExcluded = OfferFixturesExcluded(
      fixturesExcluded = listing.fixturesExcluded.fixturesExcluded.map {
        newNegotiatedTerm(it)
      }
    ),
    chattelsIncluded = OfferChattelsIncluded(
      chattelsIncluded = listing.chattelsIncluded.chattelsIncluded.map {
        newNegotiatedTerm(it)
      }
    ),
    sellerConditions = OfferSellerConditions(
      conditions = listing.sellerConditions.conditions.map {
        newNegotiatedTerm(it)
      }
    ),
    bindingAgreementTerms = OfferBindingAgreementTerms(
      days = newNegotiatedTerm(listing.bindingAgreementTerms.days),
    ),
    additionalRequest = OfferAdditionalRequest(emptyList())
  )
}

/**
 * Creates an offer draft from a published offer by setting as initial values all the negotiable terms from the
 * published offer, with the change history appropriately set. Setting these terms is required by the rules of the model.
 *
 * The frontend code should validate that the offer is in an appropriate state before allowing the user to start
 * a draft offer i.e. the UI state should not even allow a draft offer to be created in that situation, but we check the
 * precondition here to fail fast.
 *
 * By default, we default to [NegotiatedTerm.State.NEW] for all terms -- the user must set the proper state explicitly
 * for each term.
 */
fun createCounterOfferDraftFromPublishedOffer(
  offer: Offer.Published,
  role: Party,
  clock: Clock,
  defaultState: NegotiatedTerm.State = NegotiatedTerm.State.NEW,
): Offer.Published {
  require(offer.state in Offer.State.statesAllowingCounters) { "Offer not in valid state to accept a counter-offer" }

  val now = clock.now()

  fun <T : Any> newNegotiatedTerm(value: NegotiatedTerm<T>) = NegotiatedTerm(
    currentValue = value.currentValue,
    changeHistory = value.changeHistory + NegotiatedTermValue(Auditable.Core(value.currentValue, now), role),
    state = defaultState,
  )

  return offer.copy(
    price = OfferPrice(
      deposit = newNegotiatedTerm(offer.price.deposit),
      price = newNegotiatedTerm(offer.price.price),
    ),
    closing = OfferClosing(
      date = newNegotiatedTerm(offer.closing.date)
    ),
    assumableContracts = OfferAssumableContracts(
      contracts = offer.assumableContracts.contracts.map {
        newNegotiatedTerm(it)
      }
    ),
    fixturesExcluded = OfferFixturesExcluded(
      fixturesExcluded = offer.fixturesExcluded.fixturesExcluded.map {
        newNegotiatedTerm(it)
      }
    ),
    chattelsIncluded = OfferChattelsIncluded(
      chattelsIncluded = offer.chattelsIncluded.chattelsIncluded.map {
        newNegotiatedTerm(it)
      }
    ),
    buyerConditions = OfferBuyerConditions(
      conditions = offer.buyerConditions.conditions.map {
        newNegotiatedTerm(it)
      }
    ),
    sellerConditions = OfferSellerConditions(
      conditions = offer.sellerConditions.conditions.map {
        newNegotiatedTerm(it)
      }
    ),
    bindingAgreementTerms = OfferBindingAgreementTerms(
      days = newNegotiatedTerm(offer.bindingAgreementTerms.days),
    ),
    additionalRequest = OfferAdditionalRequest(emptyList())
  )
}
