package com.diyoffer.negotiation.model

import kotlinx.datetime.TimeZone

// State of the user during a web session. This is not persisted
sealed class SessionUser(val role: UserRole) {
  data object NotLoaded : SessionUser(UserRole.VISITOR)
  data class AuthUser(val user: User) : SessionUser(UserRole.SELLER)
  data class SignedUser(val signature: String, val random: String) : SessionUser(UserRole.BUYER)
  data class AnonUser(val inferredParty: Party? = null) : SessionUser(UserRole.VISITOR)
  val party: Party? get() = when (role) {
    UserRole.SELLER -> Party.SELLER
    UserRole.BUYER -> Party.BUYER
    else -> null
  }
}

fun SessionUser?.country() = (this as? SessionUser.AuthUser)?.user?.settings?.country
fun SessionUser?.currency() = (this as? SessionUser.AuthUser)?.user?.settings?.currency ?: Currency.CAD
fun SessionUser?.timeZone() = (this as? SessionUser.AuthUser)?.user?.settings?.timeZone ?: TimeZone.currentSystemDefault()
