package com.diyoffer.negotiation.ui.checklist

import co.touchlab.kermit.Logger
import com.copperleaf.ballast.InputHandler
import com.copperleaf.ballast.InputHandlerScope
import com.copperleaf.ballast.observeFlows
import com.copperleaf.ballast.postInput
import com.copperleaf.ballast.repository.cache.Cached
import com.copperleaf.ballast.repository.cache.getCachedOrNull
import com.copperleaf.ballast.repository.cache.isLoading
import com.diyoffer.negotiation.messages.CommonMessages
import com.diyoffer.negotiation.messages.InfoPopup
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.repository.offer.BuyerOfferRepository
import com.diyoffer.negotiation.repository.user.UserRepository
import com.diyoffer.negotiation.services.tryRpc
import com.diyoffer.negotiation.ui.checklist.ChecklistDetailContract.Events
import com.diyoffer.negotiation.ui.checklist.ChecklistDetailContract.Inputs
import com.diyoffer.negotiation.ui.checklist.ChecklistDetailContract.State
import com.diyoffer.negotiation.ui.offer.OfferActionType
import com.diyoffer.negotiation.ui.offer.counterRejectList
import com.diyoffer.negotiation.ui.offer.statusSummary
import com.diyoffer.negotiation.ui.state.LoadingState
import io.ktor.http.*
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.map
import kotlinx.datetime.Clock
import kotlinx.datetime.toLocalDateTime

typealias ChecklistDetailInputHandlerScope = InputHandlerScope<Inputs, Events, State>

class ChecklistDetailInputHandler(
  private val buyerOfferRepo: BuyerOfferRepository,
  private val userRepo: UserRepository,
  private val clock: Clock,
) : InputHandler<Inputs, Events, State> {
  @Suppress("ComplexMethod", "LongMethod")
  override suspend fun ChecklistDetailInputHandlerScope.handleInput(input: Inputs) = when (input) {
    is Inputs.InitializeForBuyer -> {
      val s = getAndUpdateState { it.copy(offerId = input.offerId, initialized = true) }
      if (!s.initialized) {
        observeFlows(
          "ChecklistDetailInputHandler",
          buyerOfferRepo.fetchOffer(input.offerId)
            .map {
              Inputs.OfferReceived(Party.BUYER, it)
            },
          buyerOfferRepo.relatedListing()
            .filter { !it.isLoading() }
            .combine(userRepo.getUser()) { listing, user ->
              Inputs.ListingReceived(listing.getCachedOrNull(), user)
            },
        )
      } else {
        noOp()
      }
    }

    is Inputs.OfferReceived -> {
      when (input.cachedOffer) {
        is Cached.FetchingFailed -> updateState { it.copy(loadingState = LoadingState.UNAUTHORIZED) }
        is Cached.Value -> input.cachedOffer.value.first.let { offer ->
          updateState {
            it.copy(
              loadingState = LoadingState.READY,
              party = input.party,
              offer = offer,
              counterRejectList = offer.counterRejectList()
            )
          }
        }

        else -> Unit
      }
    }

    is Inputs.ListingReceived -> {
      val listing = input.listingRes?.listing as? Listing.Published
      val withheldUntil = listing?.details?.offerWithholding?.until?.valueOrNull()
      updateState { state ->
        val tz = input.user.timeZone()
        state.copy(
          listing = listing,
          listingUrl = listing?.let {
            if (listing.state == Listing.State.COMPLETED) null else Url(listing.permalink).fullPath
          },
          offerStatusLabel = if (listing != null && state.offer != null) {
            state.offer.statusSummary(
              state.party!!,
              listing.propertyOwners.contacts[0].name,
              tz,
            )
          } else {
            null
          },
          withheldUntil = withheldUntil,
          withheld = withheldUntil != null && clock.now().toLocalDateTime(tz) < withheldUntil,
          sessionUser = input.user,
        )
      }
    }

    is Inputs.Clicked -> {
      val s = getCurrentState()
      require(s.offerId != null)

      when (input.action) {
        OfferActionType.REVIEW -> postEvent(
          Events.OnNavigate("/offer/edit/${s.offerId}")
        )

        OfferActionType.REJECT -> saveOffer(saveHandler = {
          buyerOfferRepo.rejectOffer(s.offerId) to null
        })

        OfferActionType.ACCEPT -> saveOffer(saveHandler = {
          buyerOfferRepo.acceptOffer(s.offerId) to InfoPopup.OFFER_SUBMIT_SUCCESS
        })

        else -> noOp()
      }
    }

    is Inputs.SetError -> updateState { it.copy(error = input.error, loadingState = LoadingState.ERROR) }
  }

  private suspend fun InputHandlerScope<Inputs, Events, State>.saveOffer(
    actionType: OfferActionType? = null,
    saveHandler: suspend () -> Pair<OfferSaveResult, InfoPopup?>,
  ) {
    updateState { it.copy(loadingState = LoadingState.FETCHING) }
    fun userErrorMsg(e: Exception? = null) =
      "An error occurred while saving your offer. ${CommonMessages.contactAdministrator(e)}"
    tryRpc(onException = { _, e -> postInput(Inputs.SetError(userErrorMsg(e))) }) {
      val r = saveHandler()
      when (val offerSaveResult = r.first) {
        is OfferSaveResult.Success,
        is OfferSaveResult.NoChange,
        -> {
          updateState { it.copy(loadingState = LoadingState.READY) }
          userRepo.queuePopup(r.second)
        }
        is OfferSaveResult.ListingInvalidState -> {
          if (actionType == OfferActionType.ACCEPT) {
            postInput(
              Inputs.SetError(
                "The listing state is ${offerSaveResult.state}. Another offer may have been accepted."
              )
            )
          } else {
            Logger.e(offerSaveResult.message)
            postInput(Inputs.SetError(userErrorMsg()))
          }
        }
        else -> {
          Logger.e(offerSaveResult.message)
          postInput(Inputs.SetError(userErrorMsg()))
        }
      }
    }
  }
}
