package com.diyoffer.negotiation.monitoring.sentry

import com.diyoffer.negotiation.common.mapOf
import kotlin.js.Json
import kotlin.js.json

/**
 * From https://github.com/getsentry/sentry-kotlin-multiplatform/blob/feat/add-js-support/sentry-kotlin-multiplatform/src/jsMain/kotlin/io/sentry/kotlin/multiplatform/ScopeJsImpl.kt.
 */
class ScopeJsImpl(private val scope: dynamic) : ISentryScope {
  override var level: SentryLevel?
    set(value) {
      value?.let { scope.setLevel(level?.name) }
    }
    get() {
      return scope._level
    }

  override var user: User?
    set(value) {
      val user: dynamic = Any()
      user.id = value?.id
      user.username = value?.username
      user.email = value?.email
      user.ip_address = value?.ipAddress
      scope.setUser(user)
    }
    get() {
      val jsUser = scope.getUser()
      return User().apply {
        id = jsUser.id as String
        username = jsUser.username as String
        email = jsUser.email as String
        ipAddress = jsUser.ip_address as? String
      }
    }

  override fun getContexts(): MutableMap<String, Any> {
    return mapOf<Any>(scope._contexts).toMutableMap()
  }

  override fun getTags(): MutableMap<String, String> {
    return mapOf<String>(scope._tags).toMutableMap()
  }

  override fun addBreadcrumb(breadcrumb: Breadcrumb) {
    scope.addBreadcrumb(breadcrumb.asJs())
  }

  override fun clearBreadcrumbs() {
    scope.clearBreadcrumbs()
  }

  private fun setContextForPrimitiveValues(key: String, value: Any) {
    scope.setContext(key, json(("value" to value)))
  }

  var jsonValue: Json? = null

  // Recursively builds the json from the provided context map
  private fun buildJsonFromMap(key: String, value: Any?) {
    @Suppress("UNCHECKED_CAST")
    val nextMap = value as? Map<Any?, Any>
    if (nextMap == null) {
      if (jsonValue == null) {
        jsonValue = json(key to value)
      } else {
        jsonValue?.set(key, value)
      }
      return
    } else {
      nextMap.forEach {
        buildJsonFromMap(it.key as String, it.value)
      }
    }
  }

  override fun setContext(key: String, value: Any) {
    @Suppress("TooGenericExceptionCaught", "SwallowedException")
    try {
      @Suppress("UNCHECKED_CAST")
      (value as? Map<Any?, Any>)?.let {
        buildJsonFromMap(key, value)
        scope.setContext(key, jsonValue)
      }
    } catch (e: Throwable) {
      setContextForPrimitiveValues(key, value)
    }
  }

  override fun setContext(key: String, value: String) {
    setContextForPrimitiveValues(key, value)
  }

  override fun setContext(key: String, value: Boolean) {
    setContextForPrimitiveValues(key, value)
  }

  override fun setContext(key: String, value: Number) {
    setContextForPrimitiveValues(key, value)
  }

  override fun setContext(key: String, value: Char) {
    setContextForPrimitiveValues(key, value)
  }

  override fun setContext(key: String, value: Array<*>) {
    setContextForPrimitiveValues(key, value)
  }

  override fun setContext(key: String, value: Collection<*>) {
    setContextForPrimitiveValues(key, value.toList())
  }

  override fun removeContext(key: String) {
    scope._contexts = json()
  }

  override fun setTag(key: String, value: String) {
    scope.setTag(key, value)
  }

  @Suppress("ForbiddenComment")
  override fun removeTag(key: String) {
    // TODO: js sdk doesnt have dedicated remove, maybe we need to clear the variable directly
  }

  override fun setExtra(key: String, value: String) {
    scope.setExtra(value, key)
  }

  @Suppress("ForbiddenComment")
  override fun removeExtra(key: String) {
    // TODO: js sdk doesnt have dedicated remove, maybe we need to clear the variable directly
  }

  override fun clear() {
    scope.clear()
  }
}
