package com.diyoffer.negotiation.messages

enum class YoutubeVideo(val id: String) {
  BUYER_OFFER("RxrdjfH6C18"),
  CONNECTING_WITH_BUYERS("YQcY060RSZw"),
  OVERVIEW_HIGH_LEVEL("i2-Fvr4N4hs"),
  SELLER_NEGOTIATING("3z1iR13qTC4"),
  SELLER_LISTING("mwMKZr3whQA"),
  ;
}
