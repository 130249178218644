package pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import app.softwork.routingcompose.Router
import com.diyoffer.negotiation.messages.CommonMessages
import com.diyoffer.negotiation.ui.listing.ListingCreatePageContract.Inputs
import com.diyoffer.negotiation.ui.listing.ListingCreatePageEventHandler
import common.ValidationMessage
import components.snackbar.Snackbar
import dev.petuska.kmdc.circular.progress.MDCCircularProgress
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.kodein.di.compose.rememberInstance
import vm.listing.ListingCreatePageViewModel
import vm.listing.ListingCreatePageViewModelConfiguration

/**
 * Simple page that create a new listing for the user and redirects. only a loading icon is being displayed
 */
@Composable
fun ListingCreatePage() {
  val router = Router.current
  val scope = rememberCoroutineScope()
  val vmConfig by rememberInstance<ListingCreatePageViewModelConfiguration>()
  val vm = remember(scope) {
    ListingCreatePageViewModel(
      scope,
      vmConfig,
      ListingCreatePageEventHandler(
        onSuccess = {
          router.navigate("/listing/edit/$it")
        }
      )
    )
  }

  val state by vm.observeStates().collectAsState()

  state.error?.let {
    Snackbar(it) { vm.trySend(Inputs.SetError(null)) }
  }

  Div(attrs = {
    style {
      display(DisplayStyle.Flex)
      flexDirection(FlexDirection.Column)
      alignItems(AlignItems.Center)
      justifyContent(JustifyContent.Center)
      width(100.percent)
      height(400.px)
    }
  }) {
    if (state.error == null) {
      MDCCircularProgress()
      Text("Your listing is being created.")
    } else {
      ValidationMessage("There was an error creating the listing. ${CommonMessages.contactAdministrator}")
    }
  }
}
