package com.diyoffer.negotiation.ui.listing

import com.copperleaf.ballast.InputHandler
import com.copperleaf.ballast.InputHandlerScope
import com.copperleaf.ballast.observeFlows
import com.copperleaf.ballast.repository.cache.getCachedOrEmptyList
import com.diyoffer.negotiation.common.formatCurrency
import com.diyoffer.negotiation.common.formatDate
import com.diyoffer.negotiation.common.formatDateTime
import com.diyoffer.negotiation.common.removeAt
import com.diyoffer.negotiation.common.services.listings.closingDate
import com.diyoffer.negotiation.common.services.listings.sortedByClosingDate
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.repository.listing.SellerListingRepository
import com.diyoffer.negotiation.repository.user.UserRepository
import com.diyoffer.negotiation.ui.listing.SideBarListingsContract.Events
import com.diyoffer.negotiation.ui.listing.SideBarListingsContract.Inputs
import com.diyoffer.negotiation.ui.listing.SideBarListingsContract.SideBarListingUI
import com.diyoffer.negotiation.ui.listing.SideBarListingsContract.State
import com.diyoffer.negotiation.ui.state.LoadingState
import io.ktor.http.*
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.mapNotNull
import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime

class SideBarListingsInputHandler(
  private val clock: Clock,
  private val listingAuthRepo: SellerListingRepository,
  private val userRepo: UserRepository,
) : InputHandler<Inputs, Events, State> {
  @Suppress("LongMethod", "ComplexMethod")
  override suspend fun InputHandlerScope<Inputs, Events, State>.handleInput(input: Inputs) = when (input) {
    is Inputs.FetchListing -> {
      observeFlows(
        "Fetch Auth Listings",
        listingAuthRepo.userListings()
          .combine(userRepo.getUser()) { listings, user -> listings to user }
          .mapNotNull { (listings, user) ->
            if (user == null) null else Inputs.ListingsUpdated(listings, user)
          },
        userRepo.getUser().map { Inputs.UserUpdated(it) },
      )
    }

    is Inputs.ListingsUpdated -> {
      val s = getCurrentState()
      val timeZone = input.user.timeZone()
      val vms = input.listings
        .getCachedOrEmptyList()
        .sortedByClosingDate(clock.now(), timeZone)
        .mapIndexed { index, listingRes ->
          listingRes.toSideBarListing(
            state = s.sideBarListings.firstOrNull {
              it.uid == listingRes.listing._id
            },
            currency = listingRes.listing.currency,
            timeZone = timeZone,
            expanded = index == 0
          )
        }
      updateState { it.copy(sideBarListings = vms, loadingState = LoadingState.READY) }
    }

    // Change the expanded state of the selected listing
    is Inputs.ExpandClicked -> {
      val previousState = getCurrentState()
      updateState {
        it.copy(
          sideBarListings = previousState.sideBarListings.map { vm ->
            if (vm.uid == input.listingId) vm.copy(expanded = input.open) else vm
          }
        )
      }
    }

    is Inputs.ViewListing -> {
      val sideBarListingUI = getCurrentState().sideBarListings.single { it.uid == input.listingId }
      if (sideBarListingUI.isDraft) {
        postEvent(Events.NavigateToListingEditScreen(sideBarListingUI.uid!!, getCurrentState().returnRouteData))
      } else if (!sideBarListingUI.isDraft && input.editMode) {
        postEvent(Events.NavigateToListingEditScreen(sideBarListingUI.uid!!, getCurrentState().returnRouteData))
      } else {
        sideBarListingUI.publicUrl?.let {
          postEvent(
            Events.NavigateToListingDetailsScreen(
              it.fullPath,
              getCurrentState().returnRouteData
            )
          )
        } ?: noOp()
      }
    }

    is Inputs.CancellationConfirmed -> {
      when (val r = listingAuthRepo.cancelListing(input.listingId)) {
        is ListingCancelResult.Success -> {
          val s = getCurrentState()
          val idx = s.sideBarListings.indexOfFirst { it.uid == input.listingId }
          updateState { it.copy(sideBarListings = it.sideBarListings.removeAt(idx)) }
        }

        else -> updateState { it.copy(error = r.message()) }
      }
    }

    is Inputs.ReturnRouteData -> updateState { it.copy(returnRouteData = input.returnRouteData) }
    is Inputs.ClearError -> updateState { it.copy(error = null) }
    is Inputs.UserUpdated -> updateState { it.copy(sessionUser = input.user) }
  }

  @Suppress("ComplexMethod")
  private fun ListingLoadResult.Success.toSideBarListing(
    state: SideBarListingUI?,
    currency: Currency,
    timeZone: TimeZone,
    expanded: Boolean = false,
  ): SideBarListingUI {
    val lastEventTimeStr = listing.events.lastOrNull()?.timestamp?.instant?.toLocalDateTime(timeZone)?.let {
      formatDateTime(it)
    }
    return (state ?: SideBarListingUI()).copy(
      uid = listing._id,
      priceLabel = listing.details?.price?.valueOrNull()?.let { formatCurrency(it, currency) },
      streetLabel = listing.propertyDetails?.address?.shortestSummary(),
      city = listing.propertyDetails?.address?.city,
      provinceState = listing.propertyDetails?.address?.provinceState,
      offerCount = offerCount,
      expanded = expanded
    ).run {
      when (listing) {
        is Listing.Draft -> this.copy(
          isDraft = true,
          publicUrl = null,
          listedDateLabel = listing.closingDate()?.let {
            "Draft terms closing on ${formatDate(it)}"
          } ?: lastEventTimeStr?.let {
            "Draft terms created on $it"
          } ?: "Upcoming",
        )

        is Listing.Published -> this.copy(
          isDraft = false,
          // Completed listing are now publicly reachable
          publicUrl = if (listing.state != Listing.State.COMPLETED && listing.permalink != null) {
            Url(listing.permalink!!)
          } else {
            null
          },
          listedDateLabel = when (listing.state) {
            Listing.State.COMPLETED -> "Listing completed on $lastEventTimeStr"
            else -> "Published terms (closing ${formatDate(listing.closingDate()!!)})"
          },
          cancellable = listing.state != Listing.State.COMPLETED,
        )
      }
    }
  }
}
