package com.diyoffer.negotiation.model.rpcs

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
sealed class StartContactVerificationResult {
  @Serializable
  @SerialName("s")
  data object Success : StartContactVerificationResult()

  @Serializable
  @SerialName("nf")
  data object NotFound : StartContactVerificationResult()
}

@Serializable
sealed class VerificationOobCodeResult {
  @Serializable
  @SerialName("s")
  data object Success : VerificationOobCodeResult()

  @Serializable
  @SerialName("f")
  data object Failure : VerificationOobCodeResult()

  @Serializable
  @SerialName("nf")
  data object NotFound : VerificationOobCodeResult()
}

@Serializable
sealed class VerificationLinkResult {
  @Serializable
  @SerialName("s")
  data object Success : VerificationLinkResult()

  @Serializable
  @SerialName("nf")
  data object NotFound : VerificationLinkResult()

  @Serializable
  data object ExpiredSignature : VerificationLinkResult()

  @Serializable
  data object InvalidSignature : VerificationLinkResult()
}
