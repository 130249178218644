package com.diyoffer.negotiation.model

import kotlinx.serialization.Serializable

@Serializable
enum class CmsFieldType(val type: String) {
  POPUP_HELP("popupHelp"),
}

@Serializable
data class CmsReference(
  val fieldType: CmsFieldType,
  val id: String,
)
