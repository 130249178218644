package pages

import androidx.compose.runtime.Composable
import app.softwork.routingcompose.Router
import com.diyoffer.negotiation.model.*
import common.ErrorMessageInline
import common.FlexColumn
import common.FlexRow
import common.Row
import layout.AppContainer
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import style.DiyStyleSheet
import style.DiyStyleSheet.Colors.darkGrey
import vm.login.UserViewModel

@Composable
fun Unauthorized(userVm: UserViewModel, user: SessionUser, content: ContentBuilder<HTMLDivElement>? = null) {
  val router = Router.current

  AppContainer(userVm) {
    FlexColumn({
      classes(DiyStyleSheet.mainForm)
    }) {
      ErrorMessageInline(attrs = {
        style {
          justifyContent(JustifyContent.Center)
        }
      }) {
        Span({
          style {
            color(darkGrey)
            fontSize(18.px)
          }
        }) {
          Text("This content does not exist or you are not authorized to view it.")
        }
      }
      content?.let { Row(content = content) }

      FlexRow({
        style {
          justifyContent(JustifyContent.Center)
          fontSize(18.px)
        }
      }) {
        P {
          Text("Please navigate back ")
          A(
            href = "javascript:void(0);",
            attrs = { onClick { router.navigate("/home", true) } },
          ) { Text("home") }
          if (user is SessionUser.AuthUser) {
            Text(" and try again.")
          } else {
            Text(" or ")
            A(
              href = "javascript:void(0);",
              attrs = { onClick { router.navigate("/auth/sign-in", true) } },
            ) { Text("sign in") }
            Text(" to try again.")
          }
        }
      }
    }
  }
}
