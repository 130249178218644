package com.diyoffer.negotiation.common

import com.diyoffer.negotiation.model.*

const val DEFAULT_MAXIMUM_FRACTION_DIGITS = 3

/**
 * Missing cross-platform number formatting primitive: https://youtrack.jetbrains.com/issue/KT-21644.
 * Implement for now with expect/actual.
 */
expect fun formatPercent(percent: Percent, decimal: Int = 2, locale: String = "en-CA"): String
