package com.diyoffer.negotiation.repository.listing

import com.copperleaf.ballast.BallastViewModelConfiguration
import com.copperleaf.ballast.build
import com.copperleaf.ballast.core.FifoInputStrategy
import com.copperleaf.ballast.repository.BallastRepository
import com.copperleaf.ballast.repository.bus.EventBus
import com.copperleaf.ballast.repository.cache.Cached
import com.copperleaf.ballast.repository.withRepository
import com.diyoffer.negotiation.auth.FirebaseAuth
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.repository.listing.SellerListingRepositoryContract.Inputs
import com.diyoffer.negotiation.repository.listing.SellerListingRepositoryContract.State
import com.diyoffer.negotiation.repository.user.UserRepository
import com.diyoffer.negotiation.rpcs.IListingAuthRpcService
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.distinctUntilChanged
import kotlinx.coroutines.flow.map
import kotlinx.datetime.Clock

/**
 * This class holds the application-level state for the user session, including the Firebase instances
 * and other user-specific data. Many components may need the username or the authentication state so
 * these are shared here.
 */
@Suppress("LongParameterList")
class SellerListingRepositoryImpl(
  private val listingAuthRpcService: IListingAuthRpcService,
  private val firebaseAuth: FirebaseAuth,
  private val clock: Clock,
  userRepo: UserRepository,
  coroutineScope: CoroutineScope,
  eventBus: EventBus,
  configBuilder: BallastViewModelConfiguration.Builder,
) : SellerListingRepository, BallastRepository<Inputs, State>(
  coroutineScope = coroutineScope,
  eventBus = eventBus,
  config = configBuilder
    .apply {
      initialState = State()
      inputHandler = SellerListingRepositoryInputHandler(listingAuthRpcService, userRepo, eventBus)
      inputStrategy = FifoInputStrategy()
      name = SellerListingRepository::class.simpleName
    }.withRepository().build()
) {
  init {
    trySend(Inputs.Initialize)
  }

  override suspend fun invalidateCacheAndFetchListings() {
    send(Inputs.FetchListings(true))
  }

  override fun userListings(): Flow<Cached<List<ListingLoadResult.Success>>> {
    trySend(Inputs.FetchListings())
    return observeStates().map { it.listings }.distinctUntilChanged()
  }

  // Create a listing and inject logged-in user as first contact
  override suspend fun createListing(user: SessionUser.AuthUser): ListingSaveResult {
    val newListing = Listing.Draft(
      _id = newUid(),
      version = 0,
      currency = user.currency(),
      zone = user.timeZone(),
      propertyOwners = firebaseAuth.currentUser()?.let { fbUser ->
        ListingPropertyOwners(
          listOf(
            Contact(
              name = fbUser.displayName ?: "",
              methods = fbUser.email?.let {
                listOf(
                  ContactMethod.Email(
                    email = it,
                    verified = Auditable.Core(Optional.of(fbUser.isEmailVerified), clock.now())
                  )
                )
              } ?: emptyList()
            )
          ),
          userCertifiedLegalAuthority = Auditable.Core(Optional.of(false), clock.now()),
        )
      },
    )
    return listingAuthRpcService.save(newListing).also {
      if (it is ListingSaveResult.Success) send(Inputs.InvalidateCache)
    }
  }

  override suspend fun invalidateCache() {
    send(Inputs.InvalidateCache)
  }

  override suspend fun saveListing(listing: Listing): ListingSaveResult {
    return listingAuthRpcService.save(listing).also {
      if (it is ListingSaveResult.Success) {
        send(Inputs.InvalidateCache)
      }
    }
  }

  override suspend fun publishListing(listing: Listing): ListingSaveResult {
    return listingAuthRpcService.publish(listing).also {
      if (it is ListingSaveResult.Success) send(Inputs.InvalidateCache)
    }
  }

  override suspend fun cancelListing(listingId: Uid<Listing>): ListingCancelResult {
    return listingAuthRpcService.cancel(UidValue(listingId)).also {
      if (it is ListingCancelResult.Success) {
        send(Inputs.InvalidateCache)
      }
    }
  }
}
