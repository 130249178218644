package com.diyoffer.negotiation.model.rpcs

import com.diyoffer.negotiation.model.*
import kotlinx.serialization.Serializable

@Serializable
sealed class OfferLoadResult {
  @Serializable
  data class Success(val offer: Offer, val contacts: OfferContacts) : OfferLoadResult()

  @Serializable
  data object NotFound : OfferLoadResult()
}

@Serializable
sealed class ListingOffersLoadResult {
  @Serializable
  data class Success(val offers: List<Pair<Offer.Published, OfferContacts>>) : ListingOffersLoadResult()

  @Serializable
  data object Unauthorized : ListingOffersLoadResult()

  @Serializable
  data object NotFound : ListingOffersLoadResult()
}
