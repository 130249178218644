package com.diyoffer.negotiation.model.rpcs

import com.diyoffer.negotiation.model.*
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
sealed class ListingSaveResult {
  @Serializable
  @SerialName("s")
  data class Success(val listing: Listing) : ListingSaveResult()

  @Serializable
  @SerialName("nf")
  data object NotFound : ListingSaveResult()

  @Serializable
  @SerialName("nc")
  data object NoChange : ListingSaveResult()

  @Serializable
  @SerialName("il")
  data class Incomplete(val incompleteSections: List<ListingSection>) : ListingSaveResult()

  @Serializable
  @SerialName("ilf")
  data class InvalidListing(val invalidFields: List<InvalidListingField>) : ListingSaveResult()

  @Serializable
  @SerialName("cao")
  data class ChangesNotAllowedActiveOffers(
    val readOnlySections: List<ListingSection>,
  ) : ListingSaveResult()

  @Serializable
  @SerialName("cm")
  data object ConcurrentModification : ListingSaveResult()
}

fun ListingSaveResult.message() = when (this) {
  is ListingSaveResult.Success -> "Success"
  is ListingSaveResult.ConcurrentModification -> "Another user may have modified the listing since you loaded it"
  is ListingSaveResult.NoChange -> "No change to listing since last save"
  is ListingSaveResult.NotFound -> "Not found (existing listing version > 0, not in db)"
  is ListingSaveResult.Incomplete ->
    "Listing is incomplete, sections remaining ${incompleteSections.joinToString { it.displayName() }}"
  is ListingSaveResult.InvalidListing ->
    "Listing has invalid fields: ${invalidFields.joinToString { "${it.field} in ${it.section.displayName()}" }}"
  is ListingSaveResult.ChangesNotAllowedActiveOffers ->
    "Changes cannot be made to ${readOnlySections.asString()} if offers exist — " +
      "offers may not yet be visible if they are being drafted"
}

fun List<ListingSection>.asString() = joinToString(", ") { section ->
  section.displayName()
}
