package forms

import androidx.compose.runtime.Composable
import builders.ListingBindingAgreementTermsBuilder
import com.diyoffer.negotiation.common.toHumanCount
import com.diyoffer.negotiation.model.*
import common.FlatList

@Composable
fun ListingBindingAgreementTermsEdit(
  builder: ListingBindingAgreementTermsBuilder,
  setBuilder: (ListingBindingAgreementTermsBuilder) -> Unit,
) {
  BindingAgreementTermPicker(builder.days) {
    setBuilder(builder.copy(days = it))
  }
}

@Composable
fun ListingBindingAgreementTermsDisplay(item: Days) {
  FlatList("Agreed terms not legally bound will expire after ${"business day".toHumanCount(item.value)}")
}
