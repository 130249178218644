package forms

import androidx.compose.runtime.Composable
import com.diyoffer.negotiation.common.toHumanCount
import com.diyoffer.negotiation.model.*
import components.DiySelect

@Composable
fun BindingAgreementTermPicker(value: Days?, setValue: (Days?) -> Unit) {
  DiySelect(
    label = "The number of business days to have both parties sign the binding agreement",
    items = listOf(Days(value = 1), Days(value = 2), Days(value = 3), Days(value = 4), Days(value = 5)),
    initialSelected = value,
    renderer = { "business day".toHumanCount(it.value) },
  ) {
    setValue(it)
  }
}
