package common

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.attributes.AttrsScope
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.marginRight
import org.jetbrains.compose.web.dom.Input
import org.w3c.dom.HTMLDivElement
import style.DiyStyleSheet.Sizes.paddingSm
import style.GridStyleSheet.alignItemsCenter
import style.GridStyleSheet.flex

@Composable
fun Checkbox(
  checked: Boolean = false,
  attrs: (AttrsScope<HTMLDivElement>.() -> Unit)? = null,
  onChange: (Boolean) -> Unit = {},
  text: @Composable () -> Unit = {},
) {
  Row({
    classes(flex, alignItemsCenter, "pointer")
    onClick { onChange(!checked) }
    attrs?.invoke(this)
  }) {
    Input(
      type = InputType.Checkbox,
      attrs = {
        checked(checked)
        style { marginRight(paddingSm) }
      }
    )
    text()
  }
}
