package com.diyoffer.negotiation.ui.login

import com.copperleaf.ballast.InputHandler
import com.copperleaf.ballast.InputHandlerScope
import com.copperleaf.ballast.observeFlows
import com.diyoffer.negotiation.model.client.*
import com.diyoffer.negotiation.repository.user.UserRepository
import com.diyoffer.negotiation.ui.login.SignUpScreenContract.Events
import com.diyoffer.negotiation.ui.login.SignUpScreenContract.Inputs
import com.diyoffer.negotiation.ui.login.SignUpScreenContract.State
import com.diyoffer.negotiation.ui.state.StateValidation
import com.diyoffer.negotiation.ui.state.doValidations
import kotlinx.coroutines.flow.map

typealias SignUpInputHandlerScope = InputHandlerScope<Inputs, Events, State>

class SignUpScreenInputHandler(
  private val userRepo: UserRepository,
) : InputHandler<Inputs, Events, State> {
  companion object {
    const val MAX_PASSWORD_LENGTH = 6
  }

  private val validations = listOf<StateValidation<State>>(
    StateValidation(
      { copy(emailError = "You must supply a valid email.") },
      { copy(emailError = null) },
    ) { email.isNullOrBlank() },
    StateValidation(
      { copy(passwordError = "Password must be 6 characters or more.") },
      { copy(passwordError = null) },
    ) { password.isNullOrBlank() || password.length < MAX_PASSWORD_LENGTH },
    StateValidation(
      { copy(confirmPasswordError = "Your entered passwords do not match.") },
      { copy(confirmPasswordError = null) },
    ) { confirmPassword != password },
    StateValidation(
      { copy(acceptUserAgreementTermsError = "You must accept our Terms of Service to use our platform.") },
      { copy(acceptUserAgreementTermsError = null) },
    ) { !acceptUserAgreementTerms },
  )

  @Suppress("CyclomaticComplexMethod")
  override suspend fun SignUpInputHandlerScope.handleInput(input: Inputs) = when (input) {
    Inputs.Initialize -> {
      val s = getAndUpdateState { it.copy(initialized = true) }
      if (!s.initialized) {
        userRepo.initializeAuth()
        updateState {
          it.clearErrors()
        }
        observeFlows(
          "SignUpScreenInputHandler.observeFlows",
          userRepo
            .getAuthResult()
            .map {
              when (it) {
                is SuccessOrFailure.Failure -> Inputs.SignUpFailure(it.message)
                SuccessOrFailure.Success -> Inputs.SignUpSuccess
                null -> Inputs.ClearErrors
              }
            },
          userRepo
            .getLoadingState()
            .map { Inputs.LoadingStateChange(it) },
        )
      } else {
        noOp()
      }
    }
    Inputs.ClearErrors -> {
      updateState { it.clearErrors() }
    }
    Inputs.SignUpWithSocial -> {
      val state = updateStateAndGet { it.clearErrors() }
      userRepo.startSocialSignup(state.country)
    }
    Inputs.SignUpSuccess -> {
      updateState { it.clearErrors() }
      postEvent(Events.OnSuccess)
    }
    is Inputs.SignUpFailure -> {
      updateState { it.clearErrors().copy(error = input.message) }
    }
    is Inputs.LoadingStateChange -> {
      updateState { it.copy(loadingState = input.loadingState) }
    }
    is Inputs.EmailChanged -> { updateState { it.copy(email = input.value) } }
    is Inputs.PasswordChanged -> { updateState { it.copy(password = input.value) } }
    is Inputs.CountryChanged -> { updateState { it.copy(country = input.value) } }
    is Inputs.ConfirmPasswordChanged -> { updateState { it.copy(confirmPassword = input.value) } }
    is Inputs.AcceptMarketingContent -> { updateState { it.copy(acceptMarketingContent = input.value) } }
    is Inputs.AcceptUserAgreementTerms -> { updateState { it.copy(acceptUserAgreementTerms = input.value) } }
    is Inputs.SignUpWithEmailAndPasswordClicked -> {
      updateState {
        it.clearErrors()
      }
      signUp()
    }
  }

  private suspend fun SignUpInputHandlerScope.signUp() {
    if (!doValidations(this, validations)) return
    val state = updateStateAndGet { it.clearErrors() }
    userRepo.signUpWithEmailAndPassword(
      email = state.email!!,
      password = state.password!!,
      country = state.country,
      acceptMarketingContent = state.acceptMarketingContent,
      acceptUserAgreementTerms = state.acceptUserAgreementTerms
    )
  }
}
