package vm.login

import com.copperleaf.ballast.BallastViewModelConfiguration
import com.copperleaf.ballast.core.BasicViewModel
import com.diyoffer.negotiation.ui.login.SignUpScreenContract.Events
import com.diyoffer.negotiation.ui.login.SignUpScreenContract.Inputs
import com.diyoffer.negotiation.ui.login.SignUpScreenContract.State
import com.diyoffer.negotiation.ui.login.SignUpScreenEventHandler
import kotlinx.coroutines.CoroutineScope

typealias SignUpViewModelConfiguration = BallastViewModelConfiguration<Inputs, Events, State>

class SignUpViewModel(
  viewModelCoroutineScope: CoroutineScope,
  config: SignUpViewModelConfiguration,
  eventHandler: SignUpScreenEventHandler,
) : BasicViewModel<Inputs, Events, State>(
  config = config,
  eventHandler = eventHandler,
  coroutineScope = viewModelCoroutineScope,
)
