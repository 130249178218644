package com.diyoffer.negotiation.ui.landing

import com.copperleaf.ballast.EventHandler
import com.copperleaf.ballast.EventHandlerScope
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.ui.landing.YourInformationContract.Events
import com.diyoffer.negotiation.ui.landing.YourInformationContract.Inputs
import com.diyoffer.negotiation.ui.landing.YourInformationContract.State

class YourInformationEventHandler(
  private val onNavigateToOffer: (offerId: Uid<Offer>) -> Unit,
) : EventHandler<Inputs, Events, State> {
  override suspend fun EventHandlerScope<Inputs, Events, State>.handleEvent(event: Events) = when (event) {
    is Events.OnNavigateToOffer -> onNavigateToOffer(event.offerId)
  }
}
