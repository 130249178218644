package com.diyoffer.negotiation.repository.checklist

import com.copperleaf.ballast.BallastViewModelConfiguration
import com.copperleaf.ballast.build
import com.copperleaf.ballast.core.FifoInputStrategy
import com.copperleaf.ballast.repository.BallastRepository
import com.copperleaf.ballast.repository.bus.EventBus
import com.copperleaf.ballast.repository.cache.Cached
import com.copperleaf.ballast.repository.withRepository
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.repository.checklist.ChecklistRepositoryContract.Inputs
import com.diyoffer.negotiation.repository.checklist.ChecklistRepositoryContract.State
import com.diyoffer.negotiation.repository.listing.SellerListingRepository
import com.diyoffer.negotiation.repository.offer.BuyerOfferRepository
import com.diyoffer.negotiation.repository.offer.SellerOfferRepository
import com.diyoffer.negotiation.repository.user.UserRepository
import com.diyoffer.negotiation.rpcs.IListingAuthRpcService
import com.diyoffer.negotiation.rpcs.IOfferAnonRpcService
import com.diyoffer.negotiation.rpcs.IOfferAuthRpcService
import com.diyoffer.negotiation.ui.checklist.ChecklistUI
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.distinctUntilChanged
import kotlinx.coroutines.flow.map
import kotlinx.datetime.Clock

/**
 * Managers access to the checklists visible to current users
 */
@Suppress("LongParameterList")
class ChecklistRepositoryImpl(
  private val sellerOfferRepo: SellerOfferRepository,
  private val buyerOfferRepo: BuyerOfferRepository,
  private val sellerListingRepo: SellerListingRepository,
  private val offerAuthRpcService: IOfferAuthRpcService,
  private val offerAnonRpcService: IOfferAnonRpcService,
  private val listingAuthRpcService: IListingAuthRpcService,
  private val userRepo: UserRepository,
  private val clock: Clock,
  coroutineScope: CoroutineScope,
  private val eventBus: EventBus,
  configBuilder: BallastViewModelConfiguration.Builder,
) : ChecklistRepository, BallastRepository<Inputs, State>(
  coroutineScope = coroutineScope,
  eventBus = eventBus,
  config = configBuilder
    .apply {
      initialState = State()
      inputHandler = ChecklistRepositoryInputHandler(
        sellerOfferRepo,
        buyerOfferRepo,
        sellerListingRepo,
        offerAuthRpcService,
        offerAnonRpcService,
        listingAuthRpcService,
        userRepo,
        clock,
        eventBus
      )
      inputStrategy = FifoInputStrategy()
      name = ChecklistRepository::class.simpleName
    }.withRepository().build()
) {

  override fun sellerListingChecklists(): Flow<Cached<List<ChecklistUI.ListingChecklistUI>>> {
    trySend(Inputs.InitializeForSeller)
    return observeStates().map { it.listingChecklists }.distinctUntilChanged()
  }

  override fun sellerOfferChecklists(): Flow<Cached<List<ChecklistUI.OfferChecklistUI>>> {
    trySend(Inputs.InitializeForSeller)
    return observeStates().map { it.offerChecklists }.distinctUntilChanged()
  }

  override fun buyerChecklists(offerId: Uid<Offer>): Flow<Cached<List<ChecklistUI.OfferChecklistUI>>> {
    trySend(Inputs.InitializeForBuyer(offerId))
    return observeStates().map { it.offerChecklists }.distinctUntilChanged()
  }

  override suspend fun saveLegalContact(offerId: Uid<Offer>, contact: Contact, forParty: Party): OfferContactsSaveResult {
    return when (forParty) {
      Party.BUYER -> buyerOfferRepo.saveLegalContact(offerId, contact)
      Party.SELLER -> sellerOfferRepo.saveLegalContact(offerId, contact)
    }
  }
}
