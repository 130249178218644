package com.diyoffer.negotiation.repository

import com.copperleaf.ballast.repository.bus.EventBus
import com.copperleaf.ballast.repository.bus.EventBusImpl
import com.diyoffer.negotiation.repository.checklist.ChecklistRepository
import com.diyoffer.negotiation.repository.checklist.ChecklistRepositoryImpl
import com.diyoffer.negotiation.repository.listing.SellerListingRepository
import com.diyoffer.negotiation.repository.listing.SellerListingRepositoryImpl
import com.diyoffer.negotiation.repository.offer.BuyerOfferRepository
import com.diyoffer.negotiation.repository.offer.BuyerOfferRepositoryImpl
import com.diyoffer.negotiation.repository.offer.SellerOfferRepository
import com.diyoffer.negotiation.repository.offer.SellerOfferRepositoryImpl
import com.diyoffer.negotiation.repository.user.UserRepository
import com.diyoffer.negotiation.repository.user.UserRepositoryImpl
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import org.kodein.di.DI
import org.kodein.di.bindSingleton
import org.kodein.di.instance
import org.kodein.di.new

val repositoryModule = DI.Module("repositoryModule") {
  bindSingleton { CoroutineScope(SupervisorJob() + Dispatchers.Default) }

  bindSingleton<BuyerOfferRepository> { new(::BuyerOfferRepositoryImpl) }

  bindSingleton<ChecklistRepository> {
    ChecklistRepositoryImpl(
      instance(),
      instance(),
      instance(),
      instance(),
      instance(),
      instance(),
      instance(),
      instance(),
      instance(),
      instance(),
      instance(),
    )
  }

  bindSingleton<EventBus> { new(::EventBusImpl) }

  bindSingleton<SellerListingRepository> { new(::SellerListingRepositoryImpl) }

  bindSingleton<SellerOfferRepository> { new(::SellerOfferRepositoryImpl) }

  bindSingleton<UserRepository> { new(::UserRepositoryImpl) }
}
