package com.diyoffer.negotiation.ui.listing

import com.copperleaf.ballast.EventHandler
import com.copperleaf.ballast.EventHandlerScope
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.ui.listing.ListingCreatePageContract.Events
import com.diyoffer.negotiation.ui.listing.ListingCreatePageContract.Inputs
import com.diyoffer.negotiation.ui.listing.ListingCreatePageContract.State

class ListingCreatePageEventHandler(
  private val onSuccess: (Uid<Listing>) -> Unit,
) : EventHandler<Inputs, Events, State> {
  override suspend fun EventHandlerScope<Inputs, Events, State>.handleEvent(
    event: Events,
  ) = when (event) {
    is Events.OnSuccess -> onSuccess(event.draftListingId)
  }
}
