package com.diyoffer.negotiation.ui.user

import com.copperleaf.ballast.repository.cache.Cached
import com.diyoffer.negotiation.common.services.links.LandingUrl
import com.diyoffer.negotiation.messages.InfoPopup
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*

// Interfaces with the UserRepository to bring UI elements to the front-end, such as the sign-in button
object UserContract {
  data class State(
    val initialized: Boolean = false,
    val landingUrl: LandingUrl? = null,
    val user: SessionUser = SessionUser.NotLoaded,
    val listings: Cached<List<ListingLoadResult.Success>> = Cached.NotLoaded(),
    val draftListing: Listing.Draft? = null,
    val popup: InfoPopup? = null,
  )

  sealed class Inputs {
    data class InitializeUser(val url: String) : Inputs()
    data class QueuePopup(val popup: InfoPopup?) : Inputs()
    data object ClearPopup : Inputs()
    data object SignOut : Inputs()
    data class UserUpdated(val user: SessionUser) : Inputs()
    data class ListingsUpdated(val listings: Cached<List<ListingLoadResult.Success>>) : Inputs()
  }

  sealed class Events {
    data class UserLoaded(val user: SessionUser, val redirect: String?) : Events()
    data object SignOut : Events()
  }
}
