package vm.home

import com.copperleaf.ballast.BallastViewModelConfiguration
import com.copperleaf.ballast.core.BasicViewModel
import com.diyoffer.negotiation.ui.home.SellerHomeScreenContract.Events
import com.diyoffer.negotiation.ui.home.SellerHomeScreenContract.Inputs
import com.diyoffer.negotiation.ui.home.SellerHomeScreenContract.State
import com.diyoffer.negotiation.ui.home.SellerHomeScreenEventHandler
import kotlinx.coroutines.CoroutineScope

typealias SellerHomeScreenViewModelConfiguration = BallastViewModelConfiguration<Inputs, Events, State>

class SellerHomeScreenViewModel(
  viewModelCoroutineScope: CoroutineScope,
  config: SellerHomeScreenViewModelConfiguration,
  eventHandler: SellerHomeScreenEventHandler = SellerHomeScreenEventHandler(),
) : BasicViewModel<Inputs, Events, State>(
  config = config,
  eventHandler = eventHandler,
  coroutineScope = viewModelCoroutineScope,
)
