package vm.login

import com.copperleaf.ballast.BallastViewModelConfiguration
import com.copperleaf.ballast.core.BasicViewModel
import com.diyoffer.negotiation.ballast.VmConfigFactoryParams
import com.diyoffer.negotiation.ui.user.UserContract.Events
import com.diyoffer.negotiation.ui.user.UserContract.Inputs
import com.diyoffer.negotiation.ui.user.UserContract.State
import com.diyoffer.negotiation.ui.user.UserEventHandler
import kotlinx.coroutines.CoroutineScope

typealias UserVmConfigParams = VmConfigFactoryParams<Inputs, Events, State>

typealias UserVmConfig = BallastViewModelConfiguration<Inputs, Events, State>

class UserViewModel(
  viewModelCoroutineScope: CoroutineScope,
  config: UserVmConfig,
  eventHandler: UserEventHandler,
) : BasicViewModel<Inputs, Events, State>(
  config = config,
  eventHandler = eventHandler,
  coroutineScope = viewModelCoroutineScope,
)
