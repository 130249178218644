package js

import web.dom.document
import web.scroll.ScrollBehavior
import web.scroll.ScrollIntoViewOptions
import web.scroll.ScrollLogicalPosition

@Suppress("UNUSED_PARAMETER")
fun scrollTo(id: String) {
  val el = document.getElementById(id) ?: return
  val scrollIntoViewOptions = js("{}").unsafeCast<ScrollIntoViewOptions>().apply {
    behavior = ScrollBehavior.smooth
    block = ScrollLogicalPosition.center
  }
  el.scrollIntoView(scrollIntoViewOptions)
}
