package common

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.flex
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import style.DiyStyleSheet
import style.GridStyleSheet
import style.GridStyleSheet.col4
import style.GridStyleSheet.col8

@Composable
fun GridLayout(
  attrs: AttrBuilderContext<HTMLDivElement>? = null,
  rightPanelContent: ContentBuilder<HTMLDivElement>? = null,
  mainContent: ContentBuilder<HTMLDivElement>? = null,
) {
  FlexRow({
    classes(GridStyleSheet.flex, GridStyleSheet.justifyContentBetween)
    style {
      marginBottom(DiyStyleSheet.Sizes.padding)
      alignItems(AlignItems.FlexStart)
    }
    attrs?.invoke(this)
  }) {
    Div({
      classes(GridStyleSheet.flex, GridStyleSheet.flexColumn)
      style { flex(1) }
    }, content = mainContent)
    Div(
      { classes(GridStyleSheet.flex, GridStyleSheet.alignItemsEnd, GridStyleSheet.flexColumn) },
      content = rightPanelContent
    )
  }
}

@Composable
fun GridLayout84(
  attrs: AttrBuilderContext<HTMLDivElement>? = null,
  rightPanelContent: ContentBuilder<HTMLDivElement>? = null,
  mainContent: ContentBuilder<HTMLDivElement>? = null,
) {
  FlexRow(attrs = {
    style { alignItems(AlignItems.Start) }
    attrs?.invoke(this)
  }) {
    FlexColumn({ classes(col8) }, content = mainContent)
    FlexColumn({ classes(col4) }, content = rightPanelContent)
  }
}
