package com.diyoffer.negotiation.ui.listing

import com.copperleaf.ballast.InputHandler
import com.copperleaf.ballast.InputHandlerScope
import com.copperleaf.ballast.observeFlows
import com.copperleaf.ballast.postInput
import com.diyoffer.negotiation.messages.CommonMessages
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.repository.listing.SellerListingRepository
import com.diyoffer.negotiation.repository.user.UserRepository
import com.diyoffer.negotiation.services.tryRpc
import com.diyoffer.negotiation.ui.listing.ListingCreatePageContract.Events
import com.diyoffer.negotiation.ui.listing.ListingCreatePageContract.Inputs
import com.diyoffer.negotiation.ui.listing.ListingCreatePageContract.State
import kotlinx.coroutines.flow.filterNotNull
import kotlinx.coroutines.flow.map

class ListingCreatePageInputHandler(
  private val sellerListingRepo: SellerListingRepository,
  private val userRepo: UserRepository,
) : InputHandler<Inputs, Events, State> {
  override suspend fun InputHandlerScope<Inputs, Events, State>.handleInput(input: Inputs) = when (input) {
    is Inputs.Initialize -> {
      val s = getAndUpdateState { it.copy(initialized = true) }
      if (!s.initialized) {
        observeFlows(
          "ListingCreatePageInputHandler",
          userRepo.getUser().filterNotNull().map { Inputs.UserUpdated(it) },
        )
      } else {
        noOp()
      }
    }
    is Inputs.UserUpdated -> {
      updateState { it.copy(user = input.user) }
      if (input.user is SessionUser.AuthUser) {
        tryRpc(onException = { _, e ->
          postInput(Inputs.SetError("Could not create terms. ${CommonMessages.contactAdministrator(e)}"))
        }) {
          when (val listingSaveResult = sellerListingRepo.createListing(input.user)) {
            is ListingSaveResult.Success -> {
              postEvent(Events.OnSuccess(listingSaveResult.listing._id))
            }
            else -> updateState { it.copy(error = listingSaveResult.message()) }
          }
        }
      } else {
        noOp()
      }
    }
    is Inputs.SetError -> updateState { it.copy(error = input.error) }
  }
}
