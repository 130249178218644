//
// GENERATED by KVision
//
package com.diyoffer.negotiation.rpcs

import org.w3c.fetch.RequestInit
import io.kvision.remote.KVRemoteAgent
import kotlinx.serialization.modules.SerializersModule
import com.diyoffer.negotiation.model.Offer
import com.diyoffer.negotiation.model.Offer.Draft
import com.diyoffer.negotiation.model.OfferContacts
import com.diyoffer.negotiation.model.UidValue
import com.diyoffer.negotiation.model.rpcs.ChecklistResult
import com.diyoffer.negotiation.model.rpcs.LoadOfferVersionOption
import com.diyoffer.negotiation.model.rpcs.OfferContactsSignatureSaveResult
import com.diyoffer.negotiation.model.rpcs.OfferCreateResult
import com.diyoffer.negotiation.model.rpcs.OfferSignatureLoadResult
import com.diyoffer.negotiation.model.rpcs.OfferSignatureSaveResult
import kotlinx.datetime.TimeZone

actual class OfferAnonRpcService(serializersModules: List<SerializersModule>? = null, requestFilter: (suspend RequestInit.() -> Unit)? = null) : IOfferAnonRpcService, KVRemoteAgent<OfferAnonRpcService>(OfferAnonRpcServiceManager, serializersModules, requestFilter) {
    override suspend fun create(offer: Draft, contacts: OfferContacts) = call(IOfferAnonRpcService::create, offer, contacts)
    override suspend fun save(offer: Offer, random: String, signature: String) = call(IOfferAnonRpcService::save, offer, random, signature)
    override suspend fun saveContacts(id: UidValue<Offer>, contacts: OfferContacts, random: String, signature: String) = call(IOfferAnonRpcService::saveContacts, id, contacts, random, signature)
    override suspend fun load(id: UidValue<Offer>, random: String, signature: String, versionOption: LoadOfferVersionOption) = call(IOfferAnonRpcService::load, id, random, signature, versionOption)
    override suspend fun publish(offer: Offer, random: String, signature: String) = call(IOfferAnonRpcService::publish, offer, random, signature)
    override suspend fun reject(offerId: UidValue<Offer>, random: String, signature: String) = call(IOfferAnonRpcService::reject, offerId, random, signature)
    override suspend fun accept(offerId: UidValue<Offer>, random: String, signature: String) = call(IOfferAnonRpcService::accept, offerId, random, signature)
    override suspend fun checklist(offer: Offer, timeZone: TimeZone) = call(IOfferAnonRpcService::checklist, offer, timeZone)
}
