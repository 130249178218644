package com.diyoffer.negotiation.model.rpcs

import com.diyoffer.negotiation.model.*
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
sealed class UserCreateResult {
  @Serializable
  @SerialName("s")
  data class Success(val user: User) : UserCreateResult()
}

fun UserCreateResult.message() = when (this) {
  is UserCreateResult.Success -> "Success"
}
