package com.diyoffer.negotiation.common

import com.diyoffer.negotiation.model.*
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.delay

typealias RetryAttemptHandler<T> = (attempt: Int, e: Exception) -> Optional<T>
typealias ControlledAttemptParams = (nextAttempt: Int?, actualDelay: Long?) -> Unit
typealias RetryAttemptControlledHandler<T> = (attempt: Int, e: Exception, params: ControlledAttemptParams) -> Optional<T>
typealias RetryErrorHandler<T> = (attempt: Int, e: Exception) -> T
typealias RetryAction<T> = suspend () -> T

const val DEFAULT_MAX_ATTEMPTS = 3

// Time given to the backend to come back with a response
const val DEFAULT_DELAY: Long = 5000

@Suppress("UNUSED_PARAMETER")
fun <T> onAttemptRetryAlways(attempt: Int, e: Exception): Optional<T> = Optional.absent()

@Suppress("UNUSED_PARAMETER")
fun <T> onAttemptControlledRetryAlways(attempt: Int, e: Exception, params: ControlledAttemptParams): Optional<T> =
  Optional.absent()

@Suppress("UNUSED_PARAMETER")
fun <T> onErrorRethrow(attempt: Int, e: Exception): T = throw e

/**
 * An implementation of [RetryAttemptHandler] that just logs, and continues retrying.
 */
fun <T> logAttempt(log: (attempt: Int, e: Exception) -> Unit): RetryAttemptHandler<T> = { attempt, e ->
  log(attempt, e)
  Optional.absent()
}

/**
 * A simple retry mechanism.
 *
 * Retries the operation a max number of times (maxAttempts = 1 is equivalent to the same operation without retry),
 * with the given attempt and error callbacks for logging and/or returning a default value.
 *
 * To continue retrying after an attempt, `onAttempt` must return `Optional.absent()` as the default `onAttempt` does.
 * Otherwise `onAttempt` may return a value, in which case it will be returned immediately without further retry, OR
 * onAttempt may re-throw an Exception, which will not be caught and retried. This can be used to flexibly skip
 * retries based on an analysis of the Exception that has occurred. For the simple case of retry with logging,
 * pass the return value of [logAttempt] for the `onAttempt` parameter.
 *
 * There is also an `onAttemptControlled` parameter for cases in which extra control is required over the retry
 * counter and delay -- for example certain retries that one may want to execute without raising the attempt count,
 * and/or with a custom delay. Use it as `onAttempt`, but call the `params` lambda with the desired values for
 * the next attempt counter and custom delay (either one or both can be null to use the default).
 *
 * Finally [onError] determines what to do in the case of retries being exhausted without a successful result,
 * which defaults to re-throwing the exception.
 *
 * As this is a suspend function it will very efficiently delay the coroutine between attempts.
 *
 * The defaults are to retry [DEFAULT_MAX_ATTEMPTS] for a delay of [DEFAULT_DELAY], retry on any exception, and
 * to rethrow the exception after the last retry.
 */
@Suppress("LongParameterList")
suspend fun <T> retry(
  maxAttempts: Int = DEFAULT_MAX_ATTEMPTS,
  delay: Long = DEFAULT_DELAY,
  onAttempt: RetryAttemptHandler<T> = ::onAttemptRetryAlways,
  onAttemptControlled: RetryAttemptControlledHandler<T>? = null,
  onError: RetryErrorHandler<T> = ::onErrorRethrow,
  action: RetryAction<T>,
): T = tryAction(0, maxAttempts, delay, onAttempt, onAttemptControlled, onError, action)

@Suppress("LongParameterList", "ReturnCount")
private suspend fun <T> tryAction(
  currentAttempt: Int,
  maxAttempts: Int,
  delay: Long,
  onAttempt: RetryAttemptHandler<T>,
  onAttemptControlled: RetryAttemptControlledHandler<T>?,
  onError: RetryErrorHandler<T>,
  action: RetryAction<T>,
): T {
  @Suppress("TooGenericExceptionCaught")
  return try {
    action.invoke()
  } catch (e: CancellationException) {
    // we don't retry on cancellations, there is no point in doing so
    return onError(currentAttempt, e)
  } catch (e: Exception) {
    if (currentAttempt + 1 < maxAttempts) {
      val (attemptT, nextAttempt, actualDelay) = if (onAttemptControlled != null) {
        // default nextAttempt in case the lambda to set it is not executed
        var nextAttempt = currentAttempt + 1
        var actualDelay = delay
        val attemptControlledT = onAttemptControlled(currentAttempt, e) { n, d ->
          n?.let { nextAttempt = it }
          d?.let { actualDelay = it }
        }
        Triple(attemptControlledT, nextAttempt, actualDelay)
      } else {
        Triple(onAttempt(currentAttempt, e), currentAttempt + 1, delay)
      }

      @Suppress("UNCHECKED_CAST")
      when (attemptT) {
        is Present -> return attemptT.get()
        is Empty -> return null as T
        Absent -> {
          delay(actualDelay)
          tryAction(nextAttempt, maxAttempts, delay, onAttempt, onAttemptControlled, onError, action)
        }
      }
    } else {
      onError(currentAttempt, e)
    }
  }
}
