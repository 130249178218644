package builders

import com.diyoffer.negotiation.model.*

interface IBuilder<T> {
  fun hydrate(item: T?): IBuilder<T>
  fun build(): BuildResult<T>
  fun buildResult(
    errors: List<String> = emptyList(),
    warnings: List<String> = emptyList(),
    negotiationStates: List<Pair<Party, NegotiatedTerm.State>>? = null,
    onValid: (() -> T)? = null,
  ): BuildResult<T> = BuildResult(errors, warnings, negotiationStates, onValid)
}

sealed class BuildResult<T> {
  companion object {
    operator fun <T> invoke(
      errors: List<String> = emptyList(),
      warnings: List<String> = emptyList(),
      negotiationStates: List<Pair<Party, NegotiatedTerm.State>>? = null,
      onValid: (() -> T)? = null,
    ): BuildResult<T> = when {
      errors.any() -> Failure(errors.distinct(), warnings, negotiationStates)
      onValid == null -> Transient(errors.distinct(), warnings.distinct(), negotiationStates)
      else -> Success(
        warnings = warnings.distinct(),
        negotiationStates = negotiationStates,
        result = onValid(),
      )
    }
  }

  abstract val warnings: List<String>
  abstract val negotiationStates: List<Pair<Party, NegotiatedTerm.State>>?

  data class Success<T>(
    val result: T,
    override val warnings: List<String>,
    override val negotiationStates: List<Pair<Party, NegotiatedTerm.State>>? = null,
  ) : BuildResult<T>()

  data class Failure<T>(
    val errors: List<String>,
    override val warnings: List<String>,
    override val negotiationStates: List<Pair<Party, NegotiatedTerm.State>>? = null,
  ) : BuildResult<T>()

  // Used when merging builds before assessing if valid or not
  data class Transient<T>(
    val errors: List<String>,
    override val warnings: List<String>,
    override val negotiationStates: List<Pair<Party, NegotiatedTerm.State>>? = null,
  ) : BuildResult<T>()

  // Combines buildResults with other dependent buildResults
  fun <T> mergeAndRun(
    onValid: () -> T,
    vararg dependencies: BuildResult<out Any>?,
  ): BuildResult<T> {
    val warnings = mutableListOf<String>()
    val errors = mutableListOf<String>()
    val terms = mutableListOf<Pair<Party, NegotiatedTerm.State>>()

    (dependencies.asList() + this).filterNotNull().forEach {
      warnings.addAll(it.warnings)
      terms.addAll(it.negotiationStates ?: emptyList())
      if (it is Failure<*>) errors.addAll(it.errors)
      if (it is Transient<*>) errors.addAll(it.errors)
    }

    // Only merge states if previous build result specified any
    return when {
      errors.isNotEmpty() -> Failure(errors, warnings, terms)
      else -> Success(onValid(), warnings, terms)
    }
  }

  fun withStates(negotiationStates: List<Pair<Party, NegotiatedTerm.State>>?) = when (this) {
    is Success -> copy(negotiationStates = negotiationStates)
    is Failure -> copy(negotiationStates = negotiationStates)
    is Transient -> copy(negotiationStates = negotiationStates)
  }
}
