package vm.listing

import builders.BuildResult
import builders.IViewModelBuilder
import com.copperleaf.ballast.BallastViewModelConfiguration
import com.copperleaf.ballast.core.BasicViewModel
import com.diyoffer.negotiation.messages.CommonMessages
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.ui.condition.ConditionListContract.Events
import com.diyoffer.negotiation.ui.condition.ConditionListContract.Inputs
import com.diyoffer.negotiation.ui.condition.ConditionListContract.State
import com.diyoffer.negotiation.ui.condition.ConditionListEventHandler
import kotlinx.coroutines.CoroutineScope
import kotlinx.datetime.Clock

typealias ConditionListViewModelConfiguration = BallastViewModelConfiguration<Inputs, Events, State>

class ConditionListViewModel(
  viewModelCoroutineScope: CoroutineScope,
  config: ConditionListViewModelConfiguration,
  eventHandler: ConditionListEventHandler,
  private val clock: Clock,
) : BasicViewModel<Inputs, Events, State>(
  config = config,
  eventHandler = eventHandler,
  coroutineScope = viewModelCoroutineScope,
),
  IViewModelBuilder<List<Auditable<Condition>>, State> {

  @Suppress("ComplexCondition")
  override fun build(state: State): BuildResult<List<Auditable<Condition>>> = validateAndBuild(state) {
    state.conditions?.map { condition ->
      Auditable.Core(
        Optional.of(condition),
        clock.now().lowRes()
      )
    } ?: emptyList()
  }

  private fun validateAndBuild(state: State, onValid: () -> List<Auditable<Condition>>): BuildResult<List<Auditable<Condition>>> {
    val errors = mutableListOf<String>()
    val conditions = state.conditions
    if (conditions.isNullOrEmpty()) {
      errors.add("The default conditions are missing. ${CommonMessages.contactAdministrator}")
    } else {
      val missingParams = conditions.flatMap { condition ->
        condition.parameters.filter { it.value.isBlank() }.map { it.key }
      }
      if (missingParams.isNotEmpty()) {
        errors.add("Please fill every field before continuing. ${missingParams.joinToString()}")
      }
    }
    return BuildResult(errors, onValid = onValid)
  }
}
