package com.diyoffer.negotiation.ui

import com.diyoffer.negotiation.ballast.ballastModule
import com.diyoffer.negotiation.ballast.bindVmConfig
import com.diyoffer.negotiation.ballast.bindVmConfigFactory
import com.diyoffer.negotiation.ui.checklist.ChecklistDetailContract
import com.diyoffer.negotiation.ui.checklist.ChecklistDetailInputHandler
import com.diyoffer.negotiation.ui.checklist.ChecklistTabContract
import com.diyoffer.negotiation.ui.checklist.ChecklistTabInputHandler
import com.diyoffer.negotiation.ui.condition.ConditionListContract
import com.diyoffer.negotiation.ui.condition.ConditionListInputHandler
import com.diyoffer.negotiation.ui.home.SellerHomeScreenContract
import com.diyoffer.negotiation.ui.home.SellerHomeScreenInputHandler
import com.diyoffer.negotiation.ui.landing.GetAlertContract
import com.diyoffer.negotiation.ui.landing.GetAlertInputHandler
import com.diyoffer.negotiation.ui.landing.YourInformationContract
import com.diyoffer.negotiation.ui.landing.YourInformationInputHandler
import com.diyoffer.negotiation.ui.listing.ListingCreatePageContract
import com.diyoffer.negotiation.ui.listing.ListingCreatePageInputHandler
import com.diyoffer.negotiation.ui.listing.ListingEditScreenContract
import com.diyoffer.negotiation.ui.listing.ListingEditScreenInputHandler
import com.diyoffer.negotiation.ui.listing.ListingLandingScreenContract
import com.diyoffer.negotiation.ui.listing.ListingLandingScreenInputHandler
import com.diyoffer.negotiation.ui.listing.SideBarListingsContract
import com.diyoffer.negotiation.ui.listing.SideBarListingsInputHandler
import com.diyoffer.negotiation.ui.login.ResetPasswordScreenContract
import com.diyoffer.negotiation.ui.login.ResetPasswordScreenInputHandler
import com.diyoffer.negotiation.ui.login.SignInScreenContract
import com.diyoffer.negotiation.ui.login.SignInScreenInputHandler
import com.diyoffer.negotiation.ui.login.SignUpScreenContract
import com.diyoffer.negotiation.ui.login.SignUpScreenInputHandler
import com.diyoffer.negotiation.ui.offer.OfferContactsContract
import com.diyoffer.negotiation.ui.offer.OfferContactsInputHandler
import com.diyoffer.negotiation.ui.offer.OfferEditScreenContract
import com.diyoffer.negotiation.ui.offer.OfferEditScreenInputHandler
import com.diyoffer.negotiation.ui.offer.SellerOfferTabContract
import com.diyoffer.negotiation.ui.offer.SellerOfferTabInputHandler
import com.diyoffer.negotiation.ui.user.UserContract
import com.diyoffer.negotiation.ui.user.UserInputHandler
import org.kodein.di.DI
import org.kodein.di.new

val vmConfigModule = DI.Module("vmConfigModule") {
  import(ballastModule)

  bindVmConfig("Checklist", ChecklistTabContract.State()) { new(::ChecklistTabInputHandler) }

  bindVmConfigFactory("ChecklistDetail", ChecklistDetailContract.State()) { new(::ChecklistDetailInputHandler) }

  bindVmConfig("GetAlert", GetAlertContract.State()) { new(::GetAlertInputHandler) }

  bindVmConfig("ListingConditions", ConditionListContract.State()) { new(::ConditionListInputHandler) }

  bindVmConfig(
    "ListingCreatePage",
    ListingCreatePageContract.State(),
    initialInputs = { ListingCreatePageContract.Inputs.Initialize }
  ) { new(::ListingCreatePageInputHandler) }

  bindVmConfig("ListingEditScreen", ListingEditScreenContract.State()) { new(::ListingEditScreenInputHandler) }

  bindVmConfig("ListingLandingScreen", ListingLandingScreenContract.State()) { new(::ListingLandingScreenInputHandler) }

  bindVmConfig("OfferEditScreen", OfferEditScreenContract.State()) { new(::OfferEditScreenInputHandler) }

  bindVmConfig("OfferContacts", OfferContactsContract.State()) { new(::OfferContactsInputHandler) }

  bindVmConfig("SellerHomeScreenContract", SellerHomeScreenContract.State()) { new(::SellerHomeScreenInputHandler) }

  bindVmConfig("SellerOfferTabContract", SellerOfferTabContract.State()) { new(::SellerOfferTabInputHandler) }

  bindVmConfig("SideBarListing", SideBarListingsContract.State()) { new(::SideBarListingsInputHandler) }

  bindVmConfig(
    "SignUp",
    SignUpScreenContract.State(),
    initialInputs = { SignUpScreenContract.Inputs.Initialize }
  ) { new(::SignUpScreenInputHandler) }

  bindVmConfig(
    "SignIn",
    SignInScreenContract.State(),
    initialInputs = { SignInScreenContract.Inputs.Initialize }
  ) { new(::SignInScreenInputHandler) }

  bindVmConfig("ResetPassword", ResetPasswordScreenContract.State()) { new(::ResetPasswordScreenInputHandler) }

  bindVmConfigFactory("User", UserContract.State()) { new(::UserInputHandler) }

  bindVmConfigFactory("YourInformation", YourInformationContract.State()) { new(::YourInformationInputHandler) }
}
