package services

import androidx.compose.runtime.Composable
import androidx.compose.runtime.State
import androidx.compose.runtime.produceState
import co.touchlab.kermit.Logger
import com.diyoffer.negotiation.common.retry
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.rpcs.IListingAuthRpcService
import com.diyoffer.negotiation.rpcs.onRpcAttempt
import kotlinx.serialization.Serializable

@Serializable
sealed class LoadListingState {
  @Serializable
  data class Success(val listing: Listing) : LoadListingState()

  @Serializable
  data object Loading : LoadListingState()

  @Serializable
  data object NotFound : LoadListingState()

  @Serializable
  data object Error : LoadListingState()
}

/**
 * Load an offer anonymously using a signed link. The link is obtained from the window location. The
 * path should have the form:
 *
 * ```
 * /offer/{id}/{random}/signed/{signature}
 * ```
 *
 * Example link: http://localhost:9093/offer/acc8fabb-c5da-4e62-93f5-bcb55d44d24a/HC0_fo8hn317wLs/signed/ZGV2LWRpeW9mZmVyXDE2NDQ2MjEzNzFcdEN0Nnd0WXQtclJvbWF5eGpJVDJYZnBBbk5BV1hZR1VEZVpJRTdjVXhsUVw
 */
class ListingAuthLoader(
  private val listingAuthRpcService: IListingAuthRpcService,
) {
  @Composable
  fun load(id: Uid<Listing>): State<LoadListingState> {
    @Suppress("TooGenericExceptionCaught")
    return produceState<LoadListingState>(initialValue = LoadListingState.Loading, id) {
      value = try {
        @Suppress("MaxLineLength")
        retry(
          onAttempt = onRpcAttempt { a, e ->
            Logger.w(e) {
              "Error loading buyer listing via secure link, attempt=$a, will retry"
            }
          }
        ) {
          when (val result = listingAuthRpcService.load(id = UidValue(id))) {
            is ListingLoadResult.Success -> LoadListingState.Success(result.listing)
            ListingLoadResult.NotFound -> LoadListingState.NotFound
          }
        }
      } catch (e: Exception) {
        Logger.e(e) { "Error loading listing information" }
        LoadListingState.Error
      }
    }
  }
}
