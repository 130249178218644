package components

import androidx.compose.runtime.Composable
import app.softwork.routingcompose.Router
import app.softwork.routingcompose.navigate
import com.diyoffer.negotiation.model.*
import common.ActionButton
import model.backRouteParam
import model.routeData
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Text

@Composable
fun CreateEditTermsActionButton(draftListing: Listing.Draft?, router: Router) {
  ActionButton(attrs = {
    style { padding(14.px, 24.px) }
    onClick {
      draftListing?.let { listing ->
        val routeData = router.currentPath.routeData()
        router.navigate("/listing/edit/${listing._id}", routeData.backRouteParam())
      } ?: router.navigate("/listing/create", hide = true)
    }
  }) {
    Text(if (draftListing == null) "Create terms" else "Edit draft terms")
  }
}
