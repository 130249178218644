package model

import app.softwork.routingcompose.Path
import com.diyoffer.negotiation.messages.MessageKey
import com.diyoffer.negotiation.model.client.*

object RoutingParams {
  object ParamKeys {
    const val backRoute = "b"
    const val nextAction = "n"
    const val messageKey = "m"
  }
  object BackRoutes {
    val checklist = RouteData("/home/checklist", "c")
    val offers = RouteData("/home/offers", "o")
  }
}

fun nextActionParam(nextPath: String?, messageKey: MessageKey? = null): Map<String, String> {
  return if (nextPath != null) {
    mapOf(
      RoutingParams.ParamKeys.nextAction to nextPath,
      RoutingParams.ParamKeys.messageKey to (messageKey ?: MessageKey.NONE).key
    )
  } else {
    emptyMap()
  }
}

fun RouteData?.backRouteParam(): Map<String, String> {
  val p = this?.param
  return if (p != null) mapOf(RoutingParams.ParamKeys.backRoute to p) else emptyMap()
}

fun Path.routeData(): RouteData? = when (path) {
  RoutingParams.BackRoutes.checklist.route -> RoutingParams.BackRoutes.checklist
  RoutingParams.BackRoutes.offers.route -> RoutingParams.BackRoutes.offers
  else -> null
}

fun returnMetadataFromBackRoute(backRoute: String?): NavButtonMetadata? = when (backRoute) {
  RoutingParams.BackRoutes.checklist.param -> NavButtonMetadata(
    RoutingParams.BackRoutes.checklist,
    "Return to Checklist"
  )
  RoutingParams.BackRoutes.offers.param -> NavButtonMetadata(RoutingParams.BackRoutes.offers, "Return to Offers")
  else -> null
}
