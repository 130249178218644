package com.diyoffer.negotiation.cms

import com.diyoffer.negotiation.cms.serdes.SpanMarkSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonClassDiscriminator

/**
 * Just a wrapper around a list of text blocks, as a helper for the serialization system.
 */
@Serializable
data class PortableTextBlockList(val blocks: List<PortableTextBlock>)

/**
 * A portable text block a text block or a custom block, as defined by the portable text specification. Since
 * a portable text block can encapsulate rich text or custom blocks, with the only commonality being the `_type`
 * discriminator field, it is a sealed class. While the specification does not require it, Sanity.io always gives
 * blocks a _key field, so we add that as well.
 */
@Serializable
@JsonClassDiscriminator("_type")
sealed class PortableTextBlock {
  @SerialName("_key")
  abstract val key: String
}

/**
 * Typically a section of text or a heading.
 */
@Serializable
@SerialName("block")
data class RichTextBlock(
  @SerialName("_key")
  override val key: String,
  val style: BlockStyle,
  val children: List<BlockChild> = emptyList(),
  val markDefs: List<MarkDef> = emptyList(),
  val listItem: BlockListItem? = null,
  val level: Int? = null,
) : PortableTextBlock() {
  init {
    require(listItem == null) { "lists not yet supported" }
    require(level == null) { "lists not yet supported" }
  }
}

@Serializable
@SerialName("youtube")
data class YoutubeEmbedBlock(
  @SerialName("_key")
  override val key: String,
  val id: String,
) : PortableTextBlock()

@Serializable
enum class BlockStyle {
  @SerialName("normal")
  NORMAL,

  @SerialName("h1")
  H1,
}

@Serializable
enum class BlockListItem {
  @SerialName("bullet")
  BULLETED,

  @SerialName("number")
  NUMBERED,
}

@Serializable
sealed class SpanMark

@Serializable
data class SpanStyleMark(val style: StyleMark) : SpanMark()

@Serializable
data class SpanMarkDefMark(val key: String) : SpanMark()

@Serializable
enum class StyleMark {
  @SerialName("strong")
  STRONG,

  @SerialName("em")
  EM,
}

@Serializable
@JsonClassDiscriminator("_type")
sealed class MarkDef {
  @SerialName("_key")
  abstract val key: String
}

@Serializable
@SerialName("link")
data class MarkDefLink(
  @SerialName("_key")
  override val key: String,
  val href: String,
) : MarkDef()

@Serializable
@JsonClassDiscriminator("_type")
sealed class BlockChild {
  @SerialName("_key")
  abstract val key: String
}

@Serializable
@SerialName("span")
data class BlockChildSpan(
  @SerialName("_key")
  override val key: String,
  val text: String,
  val marks: List<@Serializable(with = SpanMarkSerializer::class) SpanMark> = emptyList(),
) : BlockChild()
