package builders

import com.diyoffer.negotiation.model.*
import kotlinx.datetime.Clock

data class OfferBuyerInformationBuilder(
  val description: String? = null,
  val clock: Clock,
) : IBuilder<OfferBuyerInformation> {
  override fun hydrate(item: OfferBuyerInformation?) = copy(
    description = item?.description?.core?.value?.getOrNull(),
  )

  override fun build(): BuildResult<OfferBuyerInformation> = validateAndBuild {
    OfferBuyerInformation(
      description = Auditable.Core(
        Optional.of(if (description.isNullOrBlank()) null else description),
        clock.now().lowRes()
      ),
    )
  }

  private fun validateAndBuild(onValid: () -> OfferBuyerInformation): BuildResult<OfferBuyerInformation> {
    return buildResult(emptyList(), onValid = onValid)
  }
}
