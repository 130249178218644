package com.diyoffer.negotiation.ui.state

import co.touchlab.kermit.Logger
import com.copperleaf.ballast.InputHandlerScope

/**
 * Defines a validation for a field.
 */
data class StateValidation<State : Any>(
  /**
   * State update to make if the result is invalid.
   */
  val onInvalid: State.() -> State,
  /**
   * State update to make if the result is valid.
   */
  val onValid: State.() -> State,
  /**
   * Return true if the field is invalid, false if the field is valid (this is a bit backward but avoids
   * negating most logic that checks for invalid conditions).
   */
  val validation: State.() -> Boolean,
)

/**
 * Run all validations, update state for each as defined by [StateValidation], and return the overall validation
 * result.
 */
suspend fun <Inputs : Any, Events : Any, State : Any> doValidations(
  inputScope: InputHandlerScope<Inputs, Events, State>,
  validations: List<StateValidation<State>>,
): Boolean {
  val result = validations.map { validation ->
    val state = inputScope.getCurrentState()
    validation.validation(state).also { result ->
      inputScope.updateState { if (result) validation.onInvalid(state) else validation.onValid(state) }
    }
  }
  return result.all { !it }.also {
    Logger.d { "Result of validations: $result (overall = $it)" }
  }
}
