package com.diyoffer.negotiation.ui.offer

import com.copperleaf.ballast.repository.cache.Cached
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.client.*
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.ui.state.LoadingState
import kotlinx.datetime.LocalDateTime

object OfferEditScreenContract {
  data class State(
    val initialized: Boolean = false,
    val party: Party? = null,
    val offerId: Uid<Offer>? = null,
    val offer: Offer? = null,
    val negotiationStage: NegotiationStage? = null,
    val offerWithheld: Boolean = false,
    val listings: Cached<List<ListingLoadResult.Success>> = Cached.NotLoaded(),
    val relatedListing: Listing.Published? = null,
    val relatedListingWithholdingDateTime: LocalDateTime? = null,
    val offerExpiryTime: LocalDateTime? = null,
    val contacts: OfferContacts? = null,
    val canSubmit: Boolean = false,
    val hasPen: Boolean = false,
    val otherAcceptedOfferExpiryTime: InstantLr? = null,
    // Signal that logged-in user (party) has modified / agreed to the expiry
    val hasChangedExpiry: Boolean = false,
    val loadingState: LoadingState = LoadingState.NOT_LOADED,
    val rpcMessage: String? = null,
    val error: String? = null,
    val sessionUser: SessionUser? = null,
  ) {
    fun otherPartyStr() = when (party) {
      Party.BUYER -> "Seller"
      Party.SELLER -> "Buyer"
      null -> "Other party"
    }

    fun hasAcceptedOtherOffer() = otherAcceptedOfferExpiryTime != null
  }

  sealed class Inputs {
    data class FetchEditOffer(val offerId: Uid<Offer>) : Inputs()
    data class UserUpdated(val user: SessionUser) : Inputs()
    data class EditOfferUpdated(val party: Party, val offer: Pair<Offer, OfferContacts>?) : Inputs()
    data class ListingsReceived(val listings: Cached<List<ListingLoadResult.Success>>, val user: SessionUser?) : Inputs()
    data class BuyerRelatedListingUpdated(val listingRes: ListingLoadResult.Success?) : Inputs()
    data class DraftOfferUpdated(val offer: Offer, val contacts: OfferContacts? = null) : Inputs()
    data class SaveOffer(val offer: Offer) : Inputs()
    data class OfferActionClicked(val action: OfferActionType) : Inputs()
    data class SendSecureLinkByEmail(val offerId: Uid<Offer>) : Inputs()
    data class SetError(val error: String?) : Inputs()
  }

  sealed class Events {
    data class OnNavigate(val url: String) : Events()
  }
}

val OfferEditScreenContract.State.navButton get() = when (party) {
  Party.BUYER -> NavButtonMetadata(RouteData("/offer/checklist/$offerId"), label = "Checklist")
  Party.SELLER -> NavButtonMetadata(RouteData("/home"), label = "Dashboard")
  else -> NavButtonMetadata(RouteData("/home"), label = "Home")
}
