package builders

import com.diyoffer.negotiation.common.LINK_VALIDATION_REGEX
import com.diyoffer.negotiation.model.*

data class ListingExternalLinksBuilder(
  val links: List<ListingLinks.Link>? = null,
) : IBuilder<ListingLinks> {

  override fun hydrate(item: ListingLinks?) = copy(
    links = item?.links
  )

  override fun build(): BuildResult<ListingLinks> = validateAndBuild {
    ListingLinks(
      links = links?.map { link ->
        link.copy(
          provider = ListingLinks.Link.ListingProvider.values().firstOrNull { link.url.contains(it.domain) }
            ?: ListingLinks.Link.ListingProvider.OTHER
        )
      } ?: emptyList()
    )
  }

  private fun validateAndBuild(onValid: () -> ListingLinks): BuildResult<ListingLinks> {
    val errors = mutableListOf<String>()
    val warnings = mutableListOf<String>()

    if (!links.isNullOrEmpty()) {
      val invalidUrl = links.any { !LINK_VALIDATION_REGEX.matches(it.url) }
      if (invalidUrl) errors.add("That doesn't look like a valid link.")
    }

    return buildResult(errors, warnings, onValid = onValid)
  }
}
