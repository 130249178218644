package com.diyoffer.negotiation.model.rpcs

import com.diyoffer.negotiation.model.*
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
sealed class OfferContactsSaveResult : IRpcResult<OfferContacts> {
  @Serializable
  @SerialName("s")
  data class Success(val offerContacts: OfferContacts) : OfferContactsSaveResult()

  @Serializable
  @SerialName("ic")
  data class InvalidContacts(val reason: String) : OfferContactsSaveResult()

  @Serializable
  @SerialName("nf")
  data object OfferNotFound : OfferContactsSaveResult()

  @Serializable
  @SerialName("nc")
  data object NoChange : OfferContactsSaveResult()

  @Serializable
  @SerialName("ua")
  data object Unauthorized : OfferContactsSaveResult()

  override val value: OfferContacts?
    get() = (this as? Success)?.offerContacts

  override val message: String
    get() = when (this) {
      is Success -> "Success"
      is InvalidContacts -> "Contacts are invalid $reason"
      is NoChange -> "No change to contacts since last save"
      is OfferNotFound -> "Not found"
      is Unauthorized -> "User is unauthorized to do this action"
    }
}
