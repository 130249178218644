package com.diyoffer.negotiation.model

import com.diyoffer.negotiation.model.serdes.*
import kotlinx.serialization.Serializable

/**
 * This is a sealed class rather than a sealed interface due to
 * https://github.com/Kotlin/kotlinx.serialization/issues/1417.
 */
@Serializable
sealed class ListingEvent {
  abstract val timestamp: InstantLr
  abstract val version: Int

  @Serializable(with = UidSerializer::class)
  abstract val userId: Uid<User>?
  abstract val userIpAddress: String?

  abstract fun derich(): ListingEvent
}

@Serializable
data class ListingDraftSavedEvent(
  override val timestamp: InstantLr,
  override val version: Int,
  @Serializable(with = UidSerializer::class) override val userId: Uid<User>?,
  override val userIpAddress: String?,
  val sectionsComplete: List<ListingSection>,
  val sectionsIncomplete: List<ListingSection>,
) : ListingEvent() {
  companion object {
    operator fun invoke(
      timestamp: InstantLr,
      version: Int,
      userId: Uid<User>?,
      userIpAddress: String?,
      sectionsComplete: List<ListingSection>,
    ): ListingDraftSavedEvent {
      val sectionsIncomplete = ListingSection.allExcept(sectionsComplete)
      return ListingDraftSavedEvent(
        timestamp,
        version,
        userId,
        userIpAddress,
        sectionsComplete,
        sectionsIncomplete
      )
    }
  }

  val totalSections = sectionsComplete.size + sectionsIncomplete.size

  override fun derich() = copy(userId = null, userIpAddress = null)
}

@Serializable
data class ListingPublishedEvent(
  override val timestamp: InstantLr,
  override val version: Int,
  @Serializable(with = UidSerializer::class) override val userId: Uid<User>?,
  override val userIpAddress: String?,
  val totalSections: Int,
) : ListingEvent() {
  override fun derich() = copy(userId = null, userIpAddress = null)
}

@Serializable
data class ListingLockedEvent(
  override val timestamp: InstantLr,
  @Serializable(with = UidSerializer::class) override val userId: Uid<User>?,
  override val userIpAddress: String?,
  override val version: Int,
) : ListingEvent() {
  override fun derich() = copy(userId = null, userIpAddress = null)
}

@Serializable
data class ListingCanceledEvent(
  override val timestamp: InstantLr,
  @Serializable(with = UidSerializer::class) override val userId: Uid<User>?,
  override val userIpAddress: String?,
  override val version: Int,
) : ListingEvent() {
  override fun derich() = copy(userId = null, userIpAddress = null)
}

@Serializable
data class ListingWithholdingExpired(
  override val timestamp: InstantLr,
  @Serializable(with = UidSerializer::class) override val userId: Uid<User>?,
  override val userIpAddress: String?,
  override val version: Int,
) : ListingEvent() {
  override fun derich() = copy(userId = null, userIpAddress = null)
}

@Serializable
data class ListingCompletedEvent(
  override val timestamp: InstantLr,
  @Serializable(with = UidSerializer::class) override val userId: Uid<User>?,
  override val userIpAddress: String?,
  override val version: Int,
) : ListingEvent() {
  override fun derich() = copy(userId = null, userIpAddress = null)
}

@Serializable
data class ListingOfferAcceptedEvent(
  override val timestamp: InstantLr,
  @Serializable(with = UidSerializer::class) override val userId: Uid<User>?,
  override val userIpAddress: String?,
  override val version: Int,
  @Serializable(with = UidSerializer::class) val offerId: Uid<Offer>,
  val offerVersion: Int,
) : ListingEvent() {
  override fun derich() = copy(userId = null, userIpAddress = null)
}

@Serializable
data class ListingOfferCanceledEvent(
  override val timestamp: InstantLr,
  @Serializable(with = UidSerializer::class) override val userId: Uid<User>?,
  override val userIpAddress: String?,
  override val version: Int,
  @Serializable(with = UidSerializer::class) val offerId: Uid<Offer>,
  val offerVersion: Int,
  val reason: OptionKey,
) : ListingEvent() {
  override fun derich() = copy(userId = null, userIpAddress = null)
}
