package forms

import androidx.compose.runtime.Composable
import builders.OfferBuyerInformationBuilder
import com.diyoffer.negotiation.model.*
import common.FlatList
import common.Row
import common.TextArea

@Composable
fun OfferBuyerInformationEdit(
  builder: OfferBuyerInformationBuilder,
  setBuilder: (OfferBuyerInformationBuilder) -> Unit,
) {
  Row {
    TextArea(
      opts = {
        label = "Personalize your offer by telling the Seller why you love the property (optional)"
        value = builder.description
        onModified = { setBuilder(builder.copy(description = it)) }
      },
      rowSpan = 5u
    )
  }
}

@Composable
fun OfferBuyerInformationDisplay(item: OfferBuyerInformation) {
  val desc = item.description.core.value.getOrNull()
  FlatList(if (!desc.isNullOrBlank()) desc else "No description has been provided.")
}
