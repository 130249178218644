//
// GENERATED by KVision
//
package com.diyoffer.negotiation.rpcs

import io.kvision.remote.HttpMethod
import io.kvision.remote.KVServiceManager
import io.kvision.remote.registerKVisionServiceExceptions

expect class ListingAuthRpcService : IListingAuthRpcService

object ListingAuthRpcServiceManager : KVServiceManager<ListingAuthRpcService>(ListingAuthRpcService::class) {
    init {
        registerKVisionServiceExceptions()
        bind(IListingAuthRpcService::save, HttpMethod.POST, "listing/auth/save")
        bind(IListingAuthRpcService::load, HttpMethod.POST, "listing/auth/load")
        bind(IListingAuthRpcService::loadAuthoredBySelf, HttpMethod.POST, "listing/auth/load-authored-by-self")
        bind(IListingAuthRpcService::publish, HttpMethod.POST, "listing/auth/publish")
        bind(IListingAuthRpcService::cancel, HttpMethod.POST, "listing/auth/cancel")
        bind(IListingAuthRpcService::checklist, HttpMethod.POST, "listing/auth/checklist")
    }
}
