package common

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.flexGrow
import org.jetbrains.compose.web.dom.Span

@Composable
fun SpacerFlexGrow(grow: Number = 1) {
  Span({
    style {
      flexGrow(grow)
    }
  })
}
