@file:Suppress("Wrapping")

package com.diyoffer.negotiation.ui.condition

import builders.NegotiatedTermBuilder
import com.copperleaf.ballast.InputHandler
import com.copperleaf.ballast.InputHandlerScope
import com.copperleaf.ballast.postInput
import com.diyoffer.negotiation.common.removeAt
import com.diyoffer.negotiation.common.safeCast
import com.diyoffer.negotiation.common.services.validation.buyerConditionStateModel
import com.diyoffer.negotiation.common.services.validation.sellerConditionStateModel
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.rpcs.IDynamicOptionsRpcService
import com.diyoffer.negotiation.ui.condition.ConditionListContract.Events
import com.diyoffer.negotiation.ui.condition.ConditionListContract.Inputs
import com.diyoffer.negotiation.ui.condition.ConditionListContract.NewConditionUI
import com.diyoffer.negotiation.ui.condition.ConditionListContract.State
import com.diyoffer.negotiation.ui.state.LoadingState
import kotlinx.datetime.Clock
import kotlin.time.Duration.Companion.days

class ConditionListInputHandler(
  private val optionRpcService: IDynamicOptionsRpcService,
  private val clock: Clock,
) : InputHandler<Inputs, Events, State> {
  @Suppress("LongMethod", "ComplexMethod")
  override suspend fun InputHandlerScope<Inputs, Events, State>.handleInput(input: Inputs): Unit = when (input) {
    is Inputs.LoadInitialConditions -> {
      val updatedState = updateStateAndGet {
        it.copy(
          party = input.party,
          draftMode = input.draftMode,
          conditions = input.conditions,
          filters = input.filters,
          negotiationState = input.negotiationState,
          loadingState = LoadingState.NOT_LOADED
        )
      }
      if (input.draftMode) {
        hydrateEditConditions(updatedState)
      } else {
        hydrateDisplayConditions(updatedState)
      }
    }
    is Inputs.Select -> {
      updateState { it.copy(selectedOption = input.option) }
    }
    is Inputs.AddSelected -> {
      val updatedState = updateStateAndGet { state ->
        require(state.selectedOption != null)
        state.copy(
          selectedOption = null,
          conditions = ((state.conditions ?: emptyList()) + newDynamicCondition(state.selectedOption))
            .sortedByTitle(state),
        )
      }
      updatedState.negotiationState?.let { hydrateNegotiatedConditionBuilders(updatedState, true) } ?: Unit
    }
    is Inputs.Remove -> {
      val updatedState = updateStateAndGet { it.copy(conditions = it.conditions?.removeAt(input.index)) }
      updatedState.negotiationState?.let { hydrateNegotiatedConditionBuilders(updatedState, true) } ?: Unit
    }
    is Inputs.NegotiatedBuilderUpdated -> {
      val updatedState = updateStateAndGet {
        it.copy(
          negotiationState = it.negotiationState?.copy(
            negotiatedConditionBuilderList = input.builderList
          )
        )
      }
      postEvent(
        Events.OnNegotiatedBuilderListUpdated(
          updatedState.negotiationState?.negotiatedConditionBuilderList ?: emptyList()
        )
      )
    }
    // Flag that the user is entering a new condition
    is Inputs.CustomConditionClicked -> updateState { it.copy(newCondition = NewConditionUI()) }
    is Inputs.CustomConditionText -> {
      updateState { it.copy(newCondition = it.newCondition?.copy(body = input.text)) }
    }
    is Inputs.CustomConditionTitle -> {
      updateState { it.copy(newCondition = it.newCondition?.copy(title = input.title)) }
    }
    is Inputs.CustomConditionSave -> {
      val s = getCurrentState()
      if (s.newCondition == null) {
        postInput(Inputs.SetError("Your condition cannot be empty."))
      } else if (s.newCondition.title.isBlank()) {
        postInput(Inputs.SetError("Your condition must have a title."))
      } else if (s.newCondition.body.isBlank()) {
        postInput(Inputs.SetError("Your condition must have a body text."))
      } else {
        val updatedState = updateStateAndGet { state ->
          state.copy(
            conditions = ((s.conditions ?: emptyList()) + Condition(
              optionKey = OptionKey.Custom(s.newCondition.title, Asciidoc(s.newCondition.body)),
              expiry = Expiry.Core(0.days),
              parameters = emptyMap()
            )).sortedByTitle(state),
            // Close the editing experience
            newCondition = null
          )
        }
        updatedState.negotiationState?.let { hydrateNegotiatedConditionBuilders(updatedState, true) } ?: Unit
      }
    }

    is Inputs.CustomConditionCancel -> updateState { it.copy(newCondition = null) }
    // Update the parameter of the matching condition
    is Inputs.DynamicConditionParamChange -> {
      val updatedState = updateStateAndGet { state ->
        state.copy(
          conditions = state.conditions?.map {
            if ((it.optionKey is OptionKey.Dynamic) && (it.optionKey.safeCast<OptionKey.Dynamic>()?._id == input.id)) {
              it.copy(parameters = it.parameters.map { p ->
                if (p.key == input.paramName) {
                  p.key to input.paramValue
                } else {
                  p.toPair()
                }
              }.toMap())
            } else {
              it
            }
          }
        )
      }
      // NB: if we need the ability to change the fields during negotiation, we'll need to set overwriteHistory
      // to false and figure how to merge. Not a MVP concern for now.
      updatedState.negotiationState?.let { hydrateNegotiatedConditionBuilders(updatedState, true) } ?: Unit
    }
    is Inputs.SetError -> updateState { it.copy(error = input.message) }
    is Inputs.ClearError -> updateState { it.copy(error = null) }
  }

  // The edit module needs a list of the user conditions and determines what are the default conditions that should
  // be added to make sure all default conditions are present. Negotiated conditions, such as the buyer, are mapped
  // to their core components for the purposes of figuring the default conditions. Even though the negotiation
  // management is not required here, we hydrate the negotiation builders so the state is coherent if the user
  // simply saves the window without doing any changes.
  private suspend fun InputHandlerScope<Inputs, Events, State>.hydrateEditConditions(state: State) {
    val coreConditions =
      (state.negotiationState?.negotiatedConditions?.map { it.currentValue.get() } ?: state.conditions) ?: emptyList()

    val defaultConditions = optionRpcService.listDynamicDefaultConditions(
      state.party!!,
      state.filters!!.criteria,
      state.filters.jurisdiction
    )
    val selectableConditions = optionRpcService.listDynamicSelectableConditions(
      state.party,
      state.filters.criteria,
      state.filters.jurisdiction
    )
    val defaultConditionKeys = defaultConditions.map { it.key }
    val conditionsState =
      (coreConditions.filter { !it.default || it.key in defaultConditionKeys } +
        defaultConditions.filter { it.key !in coreConditions.keys }
          .map { newDynamicCondition(it, default = true) }).sortedByTitle(state)

    val updatedState = updateStateAndGet {
      it.copy(
        conditions = conditionsState,
        conditionOptions = defaultConditions + selectableConditions,
        loadingState = LoadingState.READY
      )
    }
    updatedState.negotiationState?.let { hydrateNegotiatedConditionBuilders(updatedState, false) } ?: Unit
  }

  // Hydrate all conditions that are available in the scope in a single call
  private suspend fun InputHandlerScope<Inputs, Events, State>.hydrateDisplayConditions(state: State) {
    val coreConditions =
      (state.negotiationState?.negotiatedConditions?.map { it.currentValue.get() } ?: state.conditions) ?: emptyList()
    val conditionOptions = optionRpcService.hydrateDynamicConditionOptionKeys(coreConditions.dynamicIds)
      .filterIsInstance<HydrateDynamicConditionResult.Success>().map { it.value }
    val updatedState = updateStateAndGet {
      it.copy(
        conditions = coreConditions,
        conditionOptions = conditionOptions,
        loadingState = LoadingState.READY
      )
    }

    updatedState.negotiationState?.let { hydrateNegotiatedConditionBuilders(updatedState, false) } ?: Unit
  }

  // For each condition, create a NegotiatedCondition. If the NegotiatedCondition exists, use this one
  // unless an overwrite is requested. If not (ie: the default condition was loaded), map the core condition
  // to a new negotiated one.
  private suspend fun InputHandlerScope<Inputs, Events, State>.hydrateNegotiatedConditionBuilders(
    state: State,
    overwriteHistory: Boolean = false,
  ) {
    val existingNegotiatedConditions =
      if (state.negotiationState == null || overwriteHistory) {
        emptyList()
      } else {
        state.negotiationState.negotiatedConditions
      }

    val negotiatedConditionOptionKeys = existingNegotiatedConditions.map { it.currentValue.get().optionKey }

    // Those are the conditions we have in our list, such as default ones, but that are not yet part
    // of our negotiated conditions
    val newNegotiatedConditions = (state.conditions ?: emptyList())
      .filterNot { negotiatedConditionOptionKeys.contains(it.optionKey) }
      .map { condition ->
        val conditionTermValue = NegotiatedTermValue(
          Auditable.Core(Optional.of(condition), clock.now()),
          state.party!!
        )
        NegotiatedTerm(
          Optional.of(condition),
          listOf(conditionTermValue),
          NegotiatedTerm.State.NEW
        )
      }

    state.negotiationState?.let { negotiationState ->
      postInput(
        Inputs.NegotiatedBuilderUpdated(
          (existingNegotiatedConditions + newNegotiatedConditions)
            .map { negotiatedCondition ->
              NegotiatedTermBuilder(
                party = state.party!!,
                negotiationStage = negotiationState.negotiationStage,
                term = negotiatedCondition,
                currency = negotiationState.currency,
                stateValidation = when (negotiationState.authoringParty) {
                  Party.SELLER -> ::sellerConditionStateModel
                  Party.BUYER -> ::buyerConditionStateModel
                }
              )
            }
        )
      )
    }
  }

  private fun List<Condition>.sortedByTitle(state: State) = sortedBy { state.getTitle(it) }
}

private fun newDynamicCondition(option: ConditionOption, default: Boolean = false) = Condition(
  optionKey = OptionKey.Dynamic(option.core._id),
  expiry = Expiry.Core(0.days),
  parameters = option.core.template.content.filterIsInstance<ConditionParamContent>().associate { it.param to "" },
  default = default,
  key = option.core.key
)
