package components

import androidx.compose.runtime.Composable
import com.diyoffer.negotiation.common.formatCurrency
import com.diyoffer.negotiation.model.*
import common.FlexColumn
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLSpanElement
import style.DiyStyleSheet

@Composable
fun PriceDisplayColumn(
  label: String = "",
  price: Money,
  currency: Currency,
  attrs: AttrBuilderContext<HTMLSpanElement>? = null,
) {
  FlexColumn {
    if (label != "") {
      Span({
        style {
          color(DiyStyleSheet.Colors.darkGrey)
          marginBottom(8.px)
        }
      }) { Text(label) }
    }
    Span({
      style {
        color(DiyStyleSheet.Colors.darkBlue)
        fontSize(32.px)
        fontWeight(value = 500)
      }
      attrs?.invoke(this)
    }) { Text(formatCurrency(price, currency)) }
  }
}
