package style

import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.CSSBuilder
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.StyleSheet
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.backgroundImage
import org.jetbrains.compose.web.css.backgroundPosition
import org.jetbrains.compose.web.css.backgroundRepeat
import org.jetbrains.compose.web.css.backgroundSize
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.style
import org.jetbrains.compose.web.css.width

object SignFormStyleSheet : StyleSheet() {
  val container by style {
    width(400.px)
    property("margin", "0 auto")
  }

  val signContainer by style {
    backgroundImage("url('../images/login-background.png')")
    backgroundPosition("center")
    backgroundRepeat("no-repeat")
    backgroundSize("cover")
  }

  val signOption by style {
    display(DisplayStyle.Flex)
    flexDirection(FlexDirection.Column)
    padding(32.px, 89.px)
    backgroundColor(DiyStyleSheet.Colors.white)
    marginTop(64.px)
    property("height", "fit-content")
  }

  val authActionButton by style {
    height(44.px)
    width(100.percent)
    display(DisplayStyle.Flex)
    alignItems(AlignItems.Center)
  }

  val SocialButton by style {
    commonSocialButton()
    fontWeight(DiyStyleSheet.Weights.darkNormal)
    border {
      width(1.px)
      style(LineStyle.Solid)
      color(DiyStyleSheet.Colors.lightGrey)
    }
  }

  val SocialButtonEmphasis by style {
    commonSocialButton()
    fontWeight(DiyStyleSheet.Weights.bold)
    border {
      width(2.px)
      style(LineStyle.Solid)
      color(DiyStyleSheet.Colors.green)
    }
  }

  private fun CSSBuilder.commonSocialButton() {
    display(DisplayStyle.Flex)
    justifyContent(JustifyContent.SpaceBetween)
    alignItems(AlignItems.Center)
    backgroundColor(Color.transparent)
    color(DiyStyleSheet.Colors.darkGrey)
    height(44.px)
  }
}
