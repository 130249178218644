package com.diyoffer.negotiation.rpcs

import com.diyoffer.negotiation.cms.PortableTextBlockList
import com.diyoffer.negotiation.model.CmsReference
import io.kvision.annotations.KVBindingRoute
import io.kvision.annotations.KVService

@KVService
interface ICmsRpcService {
  @KVBindingRoute("cms/load_popup_help")
  suspend fun loadPopupHelp(cmsReference: CmsReference, language: String = "en"): PortableTextBlockList
}
