package com.diyoffer.negotiation.common.services.links

import io.ktor.http.*
import kotlin.jvm.JvmInline

@JvmInline
value class LandingUrl(val url: Url) {
  companion object {
    operator fun invoke(url: String): LandingUrl = LandingUrl(Url(url))
  }
}
