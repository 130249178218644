package forms

import androidx.compose.runtime.Composable
import builders.NegotiatedTermBuilder
import com.diyoffer.negotiation.model.*
import common.GridLayout
import components.NegotiatedTermEdit
import components.WithOptionKeyDisplay
import components.color
import components.icon
import org.jetbrains.compose.web.dom.ContentBuilder
import org.w3c.dom.HTMLDivElement
import style.DiyStyleSheet

@Composable
fun <T : Any> NegotiableItemInteract(
  optionKey: OptionKey,
  builder: NegotiatedTermBuilder<T>,
  setBuilder: (NegotiatedTermBuilder<T>) -> Unit,
  predefinedTitle: String? = null,
  content: ContentBuilder<HTMLDivElement>,
) {
  GridLayout(
    attrs = { classes(DiyStyleSheet.withGreenLeftBorder) },
    rightPanelContent = { NegotiatedTermEdit(builder, setBuilder) }
  ) {
    WithOptionKeyDisplay(opts = {
      this.optionKey = optionKey
      this.predefinedTitle = predefinedTitle
      icon = (builder.state ?: NegotiatedTerm.State.NEW).icon()
      iconColor = (builder.state ?: NegotiatedTerm.State.NEW).color()
      lineThrough = builder.state == NegotiatedTerm.State.REMOVED
    }, content = content)
  }
}
