package common

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.paddingBottom
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import style.DiyStyleSheet.Sizes.paddingSm

@Composable
fun TextBold(text: String, attrs: AttrBuilderContext<HTMLDivElement>? = null) {
  Div({
    style {
      fontWeight("bold")
      paddingBottom(paddingSm)
    }
    attrs?.invoke(this)
  }) {
    Text(text)
  }
}
