package com.diyoffer.negotiation.auth

import co.touchlab.kermit.Logger
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.FirebaseOptions
import dev.gitlive.firebase.auth.auth
import dev.gitlive.firebase.initialize
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlin.time.Duration

/**
 * Firebase auth functionality. For now, this does not implement any type of generic auth interface, but if we
 * ever decide to add more auth providers, we could abstract out a common interface.
 */
class FirebaseAuth(firebaseOptions: FirebaseOptions) {
  val app by lazy { Firebase.initialize(options = firebaseOptions) }
  val auth by lazy { Firebase.auth(app) }

  fun authStateChanged() = auth.authStateChanged

  fun currentUser() = auth.currentUser

  suspend fun createUserWithEmailAndPassword(email: String, password: String) =
    auth.createUserWithEmailAndPassword(email, password)

  suspend fun signInWithEmailAndPassword(email: String, password: String) =
    auth.signInWithEmailAndPassword(email, password)

  suspend fun resetWithEmail(email: String) =
    auth.sendPasswordResetEmail(email)

  suspend fun signOut() = auth.signOut()

  @Suppress("ReturnCount")
  suspend fun currentTokenWithOptionalRefresh(refreshExpiresIn: Duration): TokenResult? {
    val currentUser = auth.currentUser ?: return null
    var idToken = currentUser.getIdTokenResult(false).toTokenResult()
    val expiry = idToken.claims["exp"]?.toString()?.toLong()?.let { Instant.fromEpochSeconds(it) } ?: return null
    // ok to use system clock, the token comes from an external system where we can't control the clock either
    if (expiry < Clock.System.now().minus(refreshExpiresIn)) {
      idToken = refreshToken() ?: return null
    }
    return idToken
  }

  suspend fun refreshToken(): TokenResult? {
    val currentUser = auth.currentUser
    Logger.d { "Forcing token refresh for current user uid=${currentUser?.uid} email=${currentUser?.email}" }
    return currentUser?.getIdTokenResult(true)?.toTokenResult()?.also {
      Logger.v {
        "Refreshed token for current user uid=${currentUser.uid} email=${currentUser.email} " +
          "claims=${it.claims}"
      }
    }
  }
}
