package com.diyoffer.negotiation.ui.listing

import com.copperleaf.ballast.EventHandler
import com.copperleaf.ballast.EventHandlerScope
import com.diyoffer.negotiation.ui.listing.ListingEditScreenContract.Events
import com.diyoffer.negotiation.ui.listing.ListingEditScreenContract.Inputs
import com.diyoffer.negotiation.ui.listing.ListingEditScreenContract.State

@Suppress("EmptyFunctionBlock")
class ListingEditScreenEventHandler(
  private val onPublishSuccess: () -> Unit,
) : EventHandler<Inputs, Events, State> {
  override suspend fun EventHandlerScope<Inputs, Events, State>.handleEvent(
    event: Events,
  ) = when (event) {
    is Events.OnPublishSuccess -> onPublishSuccess()
  }
}
