package com.diyoffer.negotiation.model

import kotlinx.serialization.Serializable

@Serializable
data class InvalidOfferField(
  val section: OfferSection,
  val field: String,
  val reasons: List<String>,
)
