package com.diyoffer.negotiation.model

import kotlinx.datetime.LocalDate
import kotlinx.serialization.Serializable

@Serializable
data class Holidays(
  val province: HolidayProvince,
)

@Serializable
data class HolidayProvince(
  val id: String,
  val nameEn: String,
  val nameFr: String,
  val sourceEn: String,
  val sourceLink: String,
  val holidays: List<HolidayDate>,
  val nextHoliday: HolidayDate,
)

@Serializable
data class HolidayDate(
  val id: Int,
  val date: LocalDate,
  val nameEn: String,
  val nameFr: String,
  val observedDate: LocalDate,
  val federal: Int,
)
