package common

import androidx.compose.runtime.Composable
import com.diyoffer.negotiation.model.*
import components.color
import components.icon
import dev.petuska.kmdcx.icons.MDCIconSpan
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.flex
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLSpanElement
import style.DiyStyleSheet
import style.DiyStyleSheet.Colors.materialGrey

@Composable
fun FlatList(vararg items: String?, separator: String = "·", state: NegotiatedTerm.State? = null) = FlatListContent(
  items.filterNot { it.isNullOrBlank() }.filterNotNull().map { text -> { Text(text) } },
  separator,
  state,
)

@Composable
fun FlatListContent(
  vararg items: ContentBuilder<HTMLSpanElement>,
  separator: String = "·",
  state: NegotiatedTerm.State? = null,
) = FlatListContent(items.toList(), separator, state)

@Composable
fun FlatListContent(
  items: List<ContentBuilder<HTMLSpanElement>>,
  separator: String = "·",
  state: NegotiatedTerm.State? = null,
) {
  var hasPrevious = false
  FlexRow({
    style {
      flex(0)
      gap(DiyStyleSheet.Sizes.paddingXs)
      marginBottom(2.pt)
      color(materialGrey)
    }
  }) {
    state?.let { MDCIconSpan(icon = it.icon()) { style { color(it.color()) } } }
    items.map {
      if (hasPrevious) Span { Text(separator) } else hasPrevious = true
      Span(content = it)
    }
  }
}
