package builders

import com.diyoffer.negotiation.common.services.validation.bindingAgreementDaysStateModel
import com.diyoffer.negotiation.model.*
import kotlinx.datetime.Clock

data class OfferBindingAgreementTermsBuilder(
  val party: Party,
  val negotiationStage: NegotiationStage,
  val currency: Currency,
  val clock: Clock,
  val daysTermBuilder: NegotiatedTermBuilder<Days>? = null,
) : IBuilder<OfferBindingAgreementTerms> {
  override fun hydrate(item: OfferBindingAgreementTerms?) = copy(
    daysTermBuilder = item?.let {
      NegotiatedTermBuilder(
        party = party,
        negotiationStage = negotiationStage,
        term = item.days,
        counterValueBuilder = ValueBuilder(it.days.currentValue.getOrNull()),
        currency = currency,
        stateValidation = ::bindingAgreementDaysStateModel,
      )
    }
  )

  override fun build(): BuildResult<OfferBindingAgreementTerms> = validateAndBuild {
    OfferBindingAgreementTerms(
      days = (daysTermBuilder!!.build() as BuildResult.Success).result
    )
  }

  private fun validateAndBuild(onValid: () -> OfferBindingAgreementTerms): BuildResult<OfferBindingAgreementTerms> {
    require(daysTermBuilder != null)
    return buildResult().mergeAndRun(onValid, daysTermBuilder.build())
  }
}
