package com.diyoffer.negotiation.model.client

import com.diyoffer.negotiation.model.auth.*
import dev.gitlive.firebase.auth.AuthCredential

data class LinkCredentials(
  val providerId: ProviderId,
  val currentProviderId: ProviderId,
  val credential: AuthCredential,
  val loginHint: String? = null,
)
