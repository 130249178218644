package com.diyoffer.negotiation.analytics.gtm

import com.diyoffer.negotiation.analytics.AnalyticsClient
import com.diyoffer.negotiation.analytics.AnalyticsEvent
import com.diyoffer.negotiation.model.*
import kotlinx.browser.window
import kotlin.js.Json
import kotlin.js.json

class GtmAnalyticsClient : AnalyticsClient {
  @Suppress("ComplexMethod")
  override fun logEvent(event: AnalyticsEvent) {
    when (event) {
      AnalyticsEvent.BuyerGuideClicked ->
        dataLayerPush(eventWithName("buyer_guide_clicked"))
      AnalyticsEvent.SellerGuideClicked ->
        dataLayerPush(eventWithName("seller_guide_clicked"))
      is AnalyticsEvent.RequestViewingOptions ->
        dataLayerPush(eventWithName("request_viewing_options").withListingId(event.id))
      is AnalyticsEvent.RequestViewing ->
        dataLayerPush(eventWithName("request_viewing").withListingId(event.id))
      is AnalyticsEvent.WalkMeThroughTheOffer ->
        dataLayerPush(eventWithName("wmtto").withListingId(event.id))
      is AnalyticsEvent.ListingPublished ->
        dataLayerPush(eventWithName("listing_publish").withListingId(event.id))
      is AnalyticsEvent.EmailVerified ->
        dataLayerPush(eventWithName("email_verified").withOfferId(event.offerId))
      is AnalyticsEvent.OfferAccept ->
        dataLayerPush(eventWithName("offer_accept").withOfferId(event.offerId).withParty(event.party))
      is AnalyticsEvent.OfferPublish ->
        dataLayerPush(eventWithName("offer_publish").withOfferId(event.offerId).withParty(event.party))
      is AnalyticsEvent.OfferReject ->
        dataLayerPush(eventWithName("offer_reject").withOfferId(event.offerId).withParty(event.party))
      is AnalyticsEvent.OfferSave ->
        dataLayerPush(eventWithName("offer_save").withOfferId(event.offerId).withParty(event.party))
      is AnalyticsEvent.OfferCancel ->
        dataLayerPush(eventWithName("offer_cancel").withOfferId(event.offerId))
      is AnalyticsEvent.OfferComplete ->
        dataLayerPush(eventWithName("offer_complete").withOfferId(event.offerId))
      is AnalyticsEvent.OfferSaveLegal ->
        dataLayerPush(eventWithName("offer_save_legal").withOfferId(event.offerId).withParty(event.party))
    }
  }

  private fun eventWithName(name: String) = json("event" to name)

  private fun Json.withListingId(id: Uid<Listing>) = apply {
    this["listingId"] = id.toString()
  }

  private fun Json.withOfferId(id: Uid<Offer>) = apply {
    this["offerId"] = id.toString()
  }

  private fun Json.withParty(party: Party) = apply {
    this["party"] = party.label
  }

  private fun dataLayerPush(event: Json) {
    window.asDynamic().dataLayer.push(event)
  }
}
