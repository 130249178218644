package com.diyoffer.negotiation.repository.user

import co.touchlab.kermit.Logger
import com.copperleaf.ballast.InputHandler
import com.copperleaf.ballast.InputHandlerScope
import com.copperleaf.ballast.observeFlows
import com.copperleaf.ballast.postInput
import com.diyoffer.negotiation.auth.FirebaseAuth
import com.diyoffer.negotiation.common.services.links.LandingUrl
import com.diyoffer.negotiation.common.services.links.getParty
import com.diyoffer.negotiation.messages.CommonMessages
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.client.*
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.monitoring.sentry.Sentry
import com.diyoffer.negotiation.monitoring.sentry.User
import com.diyoffer.negotiation.repository.user.UserRepositoryContract.Inputs
import com.diyoffer.negotiation.repository.user.UserRepositoryContract.State
import com.diyoffer.negotiation.rpcs.IUserRpcService
import com.diyoffer.negotiation.services.tryRpc
import com.diyoffer.negotiation.ui.state.LoadingState
import io.ktor.http.*
import kotlinx.coroutines.flow.map

class UserRepositoryInputHandler(
  private val auth: FirebaseAuth,
  private val userRpcService: IUserRpcService,
) : InputHandler<Inputs, Any, State> {

  @Suppress("LongMethod", "ComplexMethod")
  override suspend fun InputHandlerScope<Inputs, Any, State>.handleInput(
    input: Inputs,
  ) = when (input) {
    Inputs.Initialize -> {
      val s = getAndUpdateState { it.copy(initialized = true) }
      if (!s.initialized) {
        updateState {
          it.copy(
            createUser = false,
            createUserSettings = null,
            authResult = null,
            authLoadingState = LoadingState.READY,
          )
        }
      } else {
        noOp()
      }
    }
    is Inputs.InitializeUserAtLoad -> {
      val s = getAndUpdateState { it.copy(initializedUserAtLoad = true) }
      if (!s.initializedUserAtLoad) {
        observeFlows(
          "InitializeAuthFromUrl",
          auth.authStateChanged().map { user ->
            if (user != null) Inputs.AuthStateUser(user) else Inputs.AuthStateUrl(input.url)
          },
        )
      } else {
        noOp()
      }
    }

    is Inputs.UserSignInLinkCredentials -> {
      updateState { it.copy(linkCredentials = input.linkCredentials, authLoadingState = LoadingState.READY) }
    }

    is Inputs.SetSignature -> updateState {
      it.copy(user = SessionUser.SignedUser(input.signature, input.random))
    }

    is Inputs.QueuePopup -> updateState { it.copy(queuedPopup = input.popup) }

    is Inputs.AuthStateUrl -> updateState { it.copy(user = sessionUserFromUrl(input.url, it.user)) }

    is Inputs.AuthStateUser -> {
      val state = getCurrentState()
      if ((state.user as? SessionUser.AuthUser)?.user?.identity?.uid != input.firebaseUser.uid) {
        postInput(Inputs.UserSignedIn(input.firebaseUser))
      } else {
        noOp()
      }
    }

    Inputs.SignInStarted -> {
      updateState {
        it.copy(
          createUser = false,
          createUserSettings = null,
          authResult = null,
          authLoadingState = LoadingState.FETCHING,
        )
      }
    }

    is Inputs.SignUpStarted -> {
      updateState {
        it.copy(
          createUser = true,
          createUserSettings = input.userSettings,
          authResult = null,
          authLoadingState = LoadingState.FETCHING,
        )
      }
    }

    is Inputs.UserSignedIn -> {
      val state = getCurrentState()

      suspend fun loadSelf() {
        if (auth.currentUser() != null) {
          val result = tryRpc(
            onException = { _, e ->
              updateState {
                it.copy(
                  authResult = SuccessOrFailure.Failure(
                    "Unexpected error signing in. ${CommonMessages.contactAdministrator(e)}"
                  ),
                  authLoadingState = LoadingState.ERROR,
                )
              }
              null
            },
          ) {
            userRpcService.loadSelf()
          }

          when (result) {
            is UserLoadResult.Success -> {
              Sentry.configureScope {
                it.user = User(
                  id = result.user._id.toString(),
                  email = result.user.settings.email ?: "",
                  username = result.user.identity.uid,
                )
              }
              updateState {
                it.copy(
                  authResult = SuccessOrFailure.Success,
                  user = SessionUser.AuthUser(result.user),
                  authLoadingState = LoadingState.READY,
                )
              }
              noOp()
            }

            UserLoadResult.NotFound -> {
              postInput(Inputs.UserSignedInNotExists(input.firebaseUser))
            }

            null -> noOp()
          }
        } else {
          noOp()
        }
      }

      val linkCredentials = state.linkCredentials

      if (linkCredentials != null) {
        Logger.i {
          "Linking credentials from new provider ${linkCredentials.providerId} to current " +
            "${linkCredentials.currentProviderId} credentials"
        }
        @Suppress("TooGenericExceptionCaught")
        try {
          input.firebaseUser.linkWithCredential(linkCredentials.credential)
        } catch (e: Exception) {
          // a possible exception here is auth/email-already-in-use if the user tried to log in to a different
          //  account than the one we are trying to link -- that's fine, just ignore it
          Logger.i { "Unable to link current sign-in with ${linkCredentials.providerId} credential: ${e.message}" }
        }
      }

      val userSettings = if (state.createUser) {
        // For a social user that was created, we have country from the user, but the email comes from the auth layer
        state.createUserSettings?.copy(
          email = state.createUserSettings.email ?: input.firebaseUser.email
        ) ?: UserSettings.default()
      } else {
        null
      }

      val createUser = state.createUser && userSettings != null
      val result = if (createUser) {
        tryRpc(
          onException = { _, e ->
            updateState {
              it.copy(
                authResult = SuccessOrFailure.Failure(
                  "Unexpected error signing up. ${CommonMessages.contactAdministrator(e)}"
                ),
                authLoadingState = LoadingState.ERROR,
              )
            }
            null
          },
        ) {
          userRpcService.createSelf(userSettings!!)
        }
      } else {
        null
      }

      if (result is UserCreateResult.Success) {
        // when the user is created, the token should be refreshed to get the new claims
        auth.refreshToken()
      }

      updateState {
        it.copy(
          createUser = false,
          createUserSettings = null,
          linkCredentials = null,
        )
      }

      if (createUser && result is UserCreateResult.Success || !createUser) {
        loadSelf()
      } else {
        updateState {
          it.copy(
            authLoadingState = LoadingState.READY,
          )
        }
        noOp()
      }
    }

    is Inputs.UserSignInFailed -> {
      updateState {
        it.copy(
          authResult = SuccessOrFailure.Failure(input.message),
          authLoadingState = LoadingState.ERROR,
        )
      }
    }

    is Inputs.UserSignUpFailed -> {
      updateState {
        it.copy(
          authResult = SuccessOrFailure.Failure(input.message),
          authLoadingState = LoadingState.ERROR,
        )
      }
    }

    is Inputs.AuthCredentialsFailed -> {
      println("State in AuthCredentialsFailed is ${getCurrentState()}")
      updateState {
        println("State in AuthCredentialsFailed updateState is $it")
        it.copy(
          authResult = SuccessOrFailure.Failure(input.message),
          authLoadingState = LoadingState.ERROR,
        ).also {
          println("State in AuthCredentialsFailed after updateState is $it")
        }
      }
    }

    is Inputs.UserSignedInNotExists -> {
      updateState {
        it.copy(
          authResult = SuccessOrFailure.Failure("User not found, maybe you want to sign up instead?"),
          authLoadingState = LoadingState.ERROR,
        )
      }
      // we may have used social auth to sign in, but the user doesn't exist in our system,
      // delete them from Firebase also so that the sign-up state is clean -- the flow affected is as follows:
      // 1. sign-in with Google, get to this error
      // 2. sign-up with Facebook, now system says already signed in with Google (eh?)
      // To prevent this confusing situation, we delete the user from Firebase at step 1
      @Suppress("TooGenericExceptionCaught")
      try {
        input.firebaseUser.delete()
      } catch (e: Exception) {
        Logger.w(e) { "Failed to delete Firebase user on user does not exist" }
      }
    }

    is Inputs.UserResetPassword -> {
      input.deferred.complete(SuccessOrFailure.Success)
      postInput(Inputs.UserSignedOut)
    }

    is Inputs.UserSignedOut -> {
      updateState {
        it.copy(
          user = SessionUser.AnonUser(),
          authResult = null,
          authLoadingState = LoadingState.READY,
        )
      }
    }
  }

  private fun sessionUserFromUrl(url: String, currentUser: SessionUser?): SessionUser = Url(url).let {
    val signature = it.parameters["signature"]
    val random = it.parameters["random"]
    val inferredParty = LandingUrl(url).getParty()

    when {
      // The signature in URL overrides your local state. This is not a case that should happen
      !signature.isNullOrBlank() && !random.isNullOrBlank() -> SessionUser.SignedUser(signature, random)
      // If already auth from browser state, return as such regardless
      currentUser is SessionUser.AuthUser -> currentUser
      // Inspect URL so see if we have an inferred party
      inferredParty != null -> SessionUser.AnonUser(inferredParty)
      // Return existing user
      else -> currentUser ?: SessionUser.AnonUser(inferredParty)
    }
  }
}
