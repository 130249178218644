package components

import androidx.compose.runtime.Composable
import com.diyoffer.negotiation.model.*
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.kodein.di.compose.rememberInstance
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import services.AsciidocConverter
import services.ConversionError
import services.Converted
import services.Converting

@Composable
fun AsciidocContent(
  asciidoc: Asciidoc,
  convertingContentBuilder: ContentBuilder<HTMLDivElement>? = null,
  conversionErrorContentBuilder: ContentBuilder<HTMLDivElement>? = null,
) {
  val asciiDocConverter by rememberInstance<AsciidocConverter>()

  val converted = asciiDocConverter.convert(asciidoc.text).value
  when (converted) {
    Converting -> Div { convertingContentBuilder?.invoke(this) }
    ConversionError -> Div { conversionErrorContentBuilder?.invoke(this) }
    is Converted -> Div({
      prop(HTMLElement::innerHTML::set, converted.html)
    })
  }
}
