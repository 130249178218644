package com.diyoffer.negotiation.model.rpcs

import com.diyoffer.negotiation.model.*
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
sealed class UserLoadResult {
  @Serializable
  @SerialName("s")
  data class Success(val user: User) : UserLoadResult()

  @Serializable
  data object NotFound : UserLoadResult()
}

fun UserLoadResult.message() = when (this) {
  is UserLoadResult.Success -> "Success"
  is UserLoadResult.NotFound -> "Not Found"
}
