package style

import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.StyleSheet
import org.jetbrains.compose.web.css.position

object InputDateStyleSheet : StyleSheet() {
  val Container by style {
    position(Position.Relative)
  }
}
