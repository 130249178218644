package com.diyoffer.negotiation

import com.diyoffer.negotiation.analytics.gtm.gtmAnalyticsModule
import com.diyoffer.negotiation.auth.firebaseAuthOptionsModule
import com.diyoffer.negotiation.ballast.ballastClientModule
import org.kodein.di.DI

val clientJsModule = DI.Module("clientJsModule") {
  import(ballastClientModule)
  import(firebaseAuthOptionsModule)
  import(gtmAnalyticsModule)
}
