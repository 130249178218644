package com.diyoffer.negotiation.ballast

import co.touchlab.kermit.Logger
import com.copperleaf.ballast.BallastInterceptor
import com.copperleaf.ballast.BallastInterceptorScope
import com.copperleaf.ballast.BallastNotification
import com.copperleaf.ballast.awaitViewModelStart
import com.diyoffer.negotiation.common.Ignore
import kotlinx.coroutines.CoroutineStart
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.flow.onEach
import kotlinx.coroutines.launch

class BallastInputDroppedLoggingInterceptor<Inputs : Any, Events : Any, State : Any> :
  BallastInterceptor<Inputs, Events, State> {

  override fun BallastInterceptorScope<Inputs, Events, State>.start(
    notifications: Flow<BallastNotification<Inputs, Events, State>>,
  ) {
    launch(start = CoroutineStart.UNDISPATCHED) {
      notifications.awaitViewModelStart()
      notifications
        .onEach {
          when (it) {
            is BallastNotification.InputDropped -> Logger.e("Dropped input: ${it.input::class.simpleName}")
            else -> Ignore
          }
        }
        .collect()
    }
  }
}
