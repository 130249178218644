package com.diyoffer.negotiation.ui.listing

import com.copperleaf.ballast.InputHandler
import com.copperleaf.ballast.InputHandlerScope
import com.copperleaf.ballast.observeFlows
import com.copperleaf.ballast.postInput
import com.copperleaf.ballast.repository.cache.getCachedOrEmptyList
import com.copperleaf.ballast.repository.cache.isLoading
import com.diyoffer.negotiation.analytics.AnalyticsClient
import com.diyoffer.negotiation.analytics.AnalyticsEvent
import com.diyoffer.negotiation.messages.CommonMessages
import com.diyoffer.negotiation.messages.InfoPopup
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.repository.listing.SellerListingRepository
import com.diyoffer.negotiation.repository.user.UserRepository
import com.diyoffer.negotiation.services.tryRpc
import com.diyoffer.negotiation.ui.listing.ListingEditScreenContract.Events
import com.diyoffer.negotiation.ui.listing.ListingEditScreenContract.Inputs
import com.diyoffer.negotiation.ui.listing.ListingEditScreenContract.State
import com.diyoffer.negotiation.ui.state.LoadingState
import kotlinx.coroutines.flow.combine

/**
 * This allows to interact with a draft listing on the ListingDetails page. As the user may have multiple draft
 * we will open then page with the specific listingDd (ie: /listings/{uid}/edit. All the listings of the users
 * are currently available on the listingRepo, so we will use this data. The main benefit is that all components
 * that display listing data from the same repo will be in sync. Once the data returns from the repo,
 * we filter for that particular listingId and materialize it in the state. If the listingId is not found,
 * then an error occurred somewhere.
 */
class ListingEditScreenInputHandler(
  private val sellerListingRepo: SellerListingRepository,
  private val userRepo: UserRepository,
  private val analyticsClient: AnalyticsClient,
) : InputHandler<Inputs, Events, State> {
  @Suppress("ComplexMethod", "LongMethod")
  override suspend fun InputHandlerScope<Inputs, Events, State>.handleInput(input: Inputs) = when (input) {
    is Inputs.FetchListing -> {
      val s = getAndUpdateState {
        it.copy(
          initialized = true,
          listingId = input.listingId,
          loadingState = it.loadingState.transitionToFetching()
        )
      }
      if (!s.initialized) {
        observeFlows(
          "ListingEditScreenInputHandler",
          sellerListingRepo.userListings().combine(userRepo.getUser()) { listings, user ->
            Inputs.ListingsUpdated(listings, user)
          },
        )
      } else {
        noOp()
      }
    }

    is Inputs.ListingsUpdated -> {
      val s = updateStateAndGet {
        it.copy(sessionUser = input.user)
      }
      if (input.listings.isLoading() || input.user !is SessionUser.AuthUser) {
        noOp()
      } else {
        val listingRes = input.listings
          .getCachedOrEmptyList()
          .singleOrNull { it.listing._id == s.listingId }

        listingRes?.let {
          postInput(Inputs.DraftListingUpdated(listingRes.listing, listingRes.offerCount))
        } ?: updateState { it.copy(loadingState = LoadingState.UNAUTHORIZED) }
      }
    }

    is Inputs.DraftListingUpdated -> {
      val canSubmit = when (input.draftListing) {
        is Listing.Draft -> input.draftListing.allSectionsFilled()
        else -> (input.offerCount ?: 0) == 0
      }
      updateState {
        it.copy(
          listingId = input.draftListing._id,
          listing = input.draftListing,
          canSubmit = canSubmit,
          offerCount = input.offerCount,
          loadingState = LoadingState.READY
        )
      }
    }

    is Inputs.SaveListing -> {
      val s = getAndUpdateState { it.copy(loadingState = LoadingState.FETCHING) }
      fun userErrorMessage(e: Exception) =
        "There has been an error saving your listing. ${CommonMessages.contactAdministrator(e)}"
      tryRpc(onException = { _, e -> postInput(Inputs.SetError(userErrorMessage(e))) }) {
        when (val r = sellerListingRepo.saveListing(input.listing)) {
          is ListingSaveResult.Success -> postInput(Inputs.DraftListingUpdated(r.listing, s.offerCount))
          is ListingSaveResult.NoChange -> updateState { it.copy(loadingState = LoadingState.READY) }
          else -> updateState { it.copy(error = r.message(), loadingState = LoadingState.READY) }
        }
      }
    }

    is Inputs.PublishListing -> {
      updateState { it.copy(loadingState = LoadingState.FETCHING) }
      fun userErrorMessage(e: Exception) =
        "There has been an error publishing your listing. ${CommonMessages.contactAdministrator(e)}"
      tryRpc(onException = { _, e -> postInput(Inputs.SetError(userErrorMessage(e))) }) {
        when (val r = sellerListingRepo.publishListing(input.listing)) {
          is ListingSaveResult.Success -> {
            analyticsClient.logEvent(AnalyticsEvent.ListingPublished(r.listing._id))
            userRepo.queuePopup(InfoPopup.LISTING_PUBLISH_SUCCESS)
            postEvent(Events.OnPublishSuccess)
          }
          else -> updateState { it.copy(error = r.message(), loadingState = LoadingState.READY) }
        }
      }
    }

    is Inputs.SetError -> {
      updateState { it.copy(error = input.error, loadingState = LoadingState.ERROR) }
    }
  }

  // We could consider moving completedSection of backend ListingService in a common area
  private fun Listing.Draft.allSectionsFilled() =
    (propertyOwners != null && propertyOwners!!.contacts.all { it.name.isNotBlank() }) &&
      details != null &&
      propertyDetails != null &&
      assumableContracts != null &&
      sellerConditions != null &&
      fixturesExcluded != null &&
      chattelsIncluded != null &&
      bindingAgreementTerms != null
}
