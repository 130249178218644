package com.diyoffer.negotiation.ui.offer

import com.copperleaf.ballast.repository.cache.Cached
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.client.*
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.ui.state.LoadingState

object SellerOfferTabContract {
  data class State(
    val initialized: Boolean = false,
    val loadingState: LoadingState = LoadingState.NOT_LOADED,
    val offers: List<OfferUI>? = null,
    val listings: Map<Uid<Listing>, ListingLoadResult.Success> = mapOf(),
    val error: String? = null,
    val sessionUser: SessionUser? = null,
  )

  sealed class Inputs {
    data object Fetch : Inputs()
    data class ListingsUpdated(val listings: Cached<List<ListingLoadResult.Success>>) : Inputs()
    data class OffersUpdated(val offers: Cached<List<Pair<Offer, OfferContacts>>>) : Inputs()
    data class OfferActionClicked(val offerId: Uid<Offer>, val action: OfferActionType) : Inputs()
    data class SetError(val error: String?) : Inputs()
    data class UserUpdated(val user: SessionUser?) : Inputs()
  }

  sealed class Events {
    data class OnNavigate(val url: String) : Events()
  }
}
