package auth

import androidx.compose.runtime.NoLiveLiterals
import com.diyoffer.negotiation.auth.FirebaseAuth
import com.diyoffer.negotiation.frontend.RequestAuthInterceptor
import com.diyoffer.negotiation.repository.user.UserRepository
import org.w3c.fetch.RequestInit
import kotlin.time.Duration.Companion.minutes

/**
 * An auth interceptor that uses an available user based on a [UserRepository].
 */
class RequestAuthUserInterceptor(
  private val firebaseAuth: FirebaseAuth,
) : RequestAuthInterceptor {
  @NoLiveLiterals
  override fun withRequestInit(): suspend RequestInit.() -> Unit = {
    val token = firebaseAuth.currentTokenWithOptionalRefresh(5.minutes)?.token
    if (token != null) {
      headers["Authorization"] = "Bearer $token"
    }
  }
}
