package com.diyoffer.negotiation.common

fun String.toHumanCount(count: Int, includeCount: Boolean = true) = when {
  this == "is" || this == "are" -> if (count <= 1) "is" else "are"
  count <= 0 -> ""
  count == 1 && includeCount -> "one $this"
  count == 1 -> this
  includeCount -> "$count ${this}s"
  else -> "${this}s"
}
