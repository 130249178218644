package vm.listing

import com.copperleaf.ballast.BallastViewModelConfiguration
import com.copperleaf.ballast.core.BasicViewModel
import com.diyoffer.negotiation.ui.listing.SideBarListingsContract.Events
import com.diyoffer.negotiation.ui.listing.SideBarListingsContract.Inputs
import com.diyoffer.negotiation.ui.listing.SideBarListingsContract.State
import com.diyoffer.negotiation.ui.listing.SideBarListingsEventHandler
import kotlinx.coroutines.CoroutineScope

typealias SideBarListingsViewModelConfiguration = BallastViewModelConfiguration<Inputs, Events, State>

class SideBarListingsViewModel(
  viewModelCoroutineScope: CoroutineScope,
  config: SideBarListingsViewModelConfiguration,
  eventHandler: SideBarListingsEventHandler,
) : BasicViewModel<Inputs, Events, State>(
  config = config,
  eventHandler = eventHandler,
  coroutineScope = viewModelCoroutineScope,
)
