package common

import androidx.compose.runtime.Composable
import dev.petuska.kmdcx.icons.MDCIcon
import dev.petuska.kmdcx.icons.MDCIconSpan
import dev.petuska.kmdcx.icons.MDCIconType
import org.jetbrains.compose.web.css.AlignContent
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.alignContent
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Span
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLSpanElement
import style.DiyStyleSheet

@Composable
fun MessageInline(
  attrs: AttrBuilderContext<HTMLDivElement>? = null,
  icon: MDCIcon,
  twoToneIconColorFilters: DiyStyleSheet.TwoToneColorFilters,
  content: ContentBuilder<HTMLDivElement>? = null,
) {
  FlexRow({
    style {
      marginTop(DiyStyleSheet.Sizes.paddingXs)
      marginBottom(DiyStyleSheet.Sizes.paddingXs)
      gap(DiyStyleSheet.Sizes.paddingXs)
      alignContent(AlignContent.Center)
    }
    attrs?.invoke(this)
  }) {
    MDCIconSpan(
      type = MDCIconType.TwoTone,
      icon = icon,
      attrs = {
        classes(twoToneIconColorFilters.styleClass)
        style {
          fontSize(18.px)
          height(16.px)
        }
      }
    )
    content?.invoke(this)
  }
}

@Composable
fun InlineWithLeadingIcon(
  attrs: AttrBuilderContext<HTMLDivElement>? = null,
  icon: MDCIcon,
  iconColor: CSSColorValue,
  content: ContentBuilder<HTMLSpanElement>? = null,
) {
  FlexRow({
    style { marginBottom(DiyStyleSheet.Sizes.paddingXs) }
    attrs?.invoke(this)
  }) {
    MDCIconSpan(
      type = MDCIconType.Outlined,
      icon = icon,
      attrs = {
        style {
          color(iconColor)
          fontSize(18.px)
        }
      }
    )
    Span(content = content)
  }
}

@Composable
fun InfoMessageInline(
  attrs: AttrBuilderContext<HTMLDivElement>? = null,
  content: ContentBuilder<HTMLDivElement>? = null,
) = MessageInline(attrs, MDCIcon.Help, DiyStyleSheet.TwoToneColorFilters.BLUE, content)

@Composable
fun HelpMessageInline(
  attrs: AttrBuilderContext<HTMLDivElement>? = null,
  content: ContentBuilder<HTMLDivElement>? = null,
) = MessageInline(attrs, MDCIcon.Warning, DiyStyleSheet.TwoToneColorFilters.YELLOW, content)

@Composable
fun ErrorMessageInline(
  attrs: AttrBuilderContext<HTMLDivElement>? = null,
  content: ContentBuilder<HTMLDivElement>? = null,
) = MessageInline(attrs, MDCIcon.Error, DiyStyleSheet.TwoToneColorFilters.RED, content)
