@file:Suppress("unused")

package com.diyoffer.negotiation.common

typealias Ignore = Unit

@Suppress("FunctionName")
fun Ignore(@Suppress("UNUSED_PARAMETER") reason: String) = Unit

/**
 * A syntax sugar for casting e.g. instead of `(foo as Bar).baz()` which requires extra brackets to do the
 * cast, we can write it like `foo.cast<Bar>().baz()`.
 */
inline fun <reified T> Any.cast() = this as T

/**
 * Similar to [cast], but uses the safe cast `as?` operator instead, which means the result is nullable.
 */
inline fun <reified T> Any.safeCast() = this as? T

/**
 * If condition is true, runs the block to provide a copy of the receiver of type T, otherwise returns the
 * receiver itself.
 */
inline fun <T> T.copyIf(condition: Boolean, block: T.() -> T): T = if (condition) run(block) else this

/**
 * Given some value, if it is not null, executes te given function inline.
 */
inline fun <reified T, R> T?.andIfPresent(fn: (T) -> R) = this?.let { fn(it) }

fun <A, B> nonNullPairOrNull(first: A?, second: B?): Pair<A, B>? =
  if (first != null && second != null) first to second else null
