package com.diyoffer.negotiation.ui.user

import com.copperleaf.ballast.EventHandler
import com.copperleaf.ballast.EventHandlerScope
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.ui.user.UserContract.Events
import com.diyoffer.negotiation.ui.user.UserContract.Inputs
import com.diyoffer.negotiation.ui.user.UserContract.State

class UserEventHandler(
  private val onUserLoaded: (user: SessionUser?, redirect: String?) -> Unit,
  private val onSignOut: suspend () -> Unit,
) : EventHandler<Inputs, Events, State> {
  override suspend fun EventHandlerScope<Inputs, Events, State>.handleEvent(event: Events) = when (event) {
    is Events.UserLoaded -> onUserLoaded(event.user, event.redirect)
    is Events.SignOut -> onSignOut()
  }
}
