package vm.offer

import com.copperleaf.ballast.BallastViewModelConfiguration
import com.copperleaf.ballast.core.BasicViewModel
import com.diyoffer.negotiation.ui.offer.SellerOfferTabContract.Events
import com.diyoffer.negotiation.ui.offer.SellerOfferTabContract.Inputs
import com.diyoffer.negotiation.ui.offer.SellerOfferTabContract.State
import com.diyoffer.negotiation.ui.offer.SellerOfferTabEventHandler
import kotlinx.coroutines.CoroutineScope

typealias SellerOfferTabViewModelConfiguration = BallastViewModelConfiguration<Inputs, Events, State>

class SellerOfferTabViewModel(
  viewModelCoroutineScope: CoroutineScope,
  config: SellerOfferTabViewModelConfiguration,
  eventHandler: SellerOfferTabEventHandler,
) : BasicViewModel<Inputs, Events, State>(
  config = config,
  eventHandler = eventHandler,
  coroutineScope = viewModelCoroutineScope,
)
