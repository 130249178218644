package builders

import com.diyoffer.negotiation.common.toAuditable
import com.diyoffer.negotiation.model.*
import kotlinx.datetime.Clock

data class OfferBuyerConfirmationBuilder(
  val over18: Boolean? = null,
  val userCertifiedLegalAuthority: Boolean? = null,
) : IBuilder<OfferBuyerConfirmation> {

  companion object {
    fun create(item: OfferBuyerConfirmation?): IBuilder<OfferBuyerConfirmation> {
      return item?.let {
        OfferBuyerConfirmationBuilder(
          over18 = it.over18.core.value.getOrNull(),
          userCertifiedLegalAuthority = it.userCertifiedLegalAuthority.core.value.getOrNull()
        )
      } ?: OfferBuyerConfirmationBuilder()
    }
  }

  override fun hydrate(item: OfferBuyerConfirmation?) = create(item)

  override fun build(): BuildResult<OfferBuyerConfirmation> = validateAndBuild {
    OfferBuyerConfirmation(
      over18 = over18.toAuditable(Clock.System.now()),
      userCertifiedLegalAuthority = userCertifiedLegalAuthority.toAuditable(Clock.System.now())
    )
  }

  private fun validateAndBuild(onValid: () -> OfferBuyerConfirmation): BuildResult<OfferBuyerConfirmation> {
    val errors = mutableListOf<String>()
    val warnings = mutableListOf<String>()

    if (over18 != true) {
      errors.add("You must over 18 year olds before proceeding.")
    }

    if (userCertifiedLegalAuthority != true) {
      errors.add("You must certify you have the legal authority before proceeding.")
    }

    return buildResult(errors, warnings, onValid = onValid)
  }
}
