package com.diyoffer.negotiation.model

import com.diyoffer.negotiation.model.serdes.*
import kotlinx.serialization.Serializable

/**
 * This is a sealed class rather than a sealed interface due to
 * https://github.com/Kotlin/kotlinx.serialization/issues/1417.
 */
@Serializable
sealed class OfferEvent {
  abstract val timestamp: InstantLr
  abstract val version: Int

  @Serializable(with = UidSerializer::class)
  abstract val userId: Uid<User>?
  abstract val userIpAddress: String?

  abstract fun derich(): OfferEvent
}

@Serializable
data class OfferDraftSavedEvent(
  override val timestamp: InstantLr,
  override val version: Int,
  @Serializable(with = UidSerializer::class) override val userId: Uid<User>?,
  override val userIpAddress: String?,
  val sectionsComplete: List<OfferSection>,
  val sectionsIncomplete: List<OfferSection>,
) : OfferEvent() {
  companion object {
    operator fun invoke(
      timestamp: InstantLr,
      version: Int,
      userId: Uid<User>?,
      userIpAddress: String?,
      sectionsComplete: List<OfferSection>,
    ): OfferDraftSavedEvent {
      val sectionsIncomplete = OfferSection.allExcept(sectionsComplete)
      return OfferDraftSavedEvent(timestamp, version, userId, userIpAddress, sectionsComplete, sectionsIncomplete)
    }
  }

  val totalSections = sectionsComplete.size + sectionsIncomplete.size

  override fun derich() = copy(userId = null, userIpAddress = null)
}

@Serializable
data class OfferPublishedEvent(
  override val timestamp: InstantLr,
  override val version: Int,
  @Serializable(with = UidSerializer::class) override val userId: Uid<User>?,
  override val userIpAddress: String?,
  val by: Party,
  val totalSections: Int,
) : OfferEvent() {
  override fun derich() = copy(userId = null, userIpAddress = null)
}

@Serializable
data class OfferReviewedEvent(
  override val timestamp: InstantLr,
  override val version: Int,
  @Serializable(with = UidSerializer::class) override val userId: Uid<User>?,
  override val userIpAddress: String?,
  val by: Party,
) : OfferEvent() {
  override fun derich() = copy(userId = null, userIpAddress = null)
}

@Serializable
data class OfferCounteredEvent(
  override val timestamp: InstantLr,
  override val version: Int,
  @Serializable(with = UidSerializer::class) override val userId: Uid<User>?,
  override val userIpAddress: String?,
  val by: Party,
) : OfferEvent() {
  override fun derich() = copy(userId = null, userIpAddress = null)
}

@Serializable
data class OfferExpiredEvent(
  override val timestamp: InstantLr,
  override val version: Int,
  @Serializable(with = UidSerializer::class) override val userId: Uid<User>?,
  override val userIpAddress: String?,
  val authoredByAtExpiry: Party,
) : OfferEvent() {
  override fun derich() = copy(userId = null, userIpAddress = null)
}

@Serializable
data class OfferRejectedEvent(
  override val timestamp: InstantLr,
  override val version: Int,
  @Serializable(with = UidSerializer::class) override val userId: Uid<User>?,
  override val userIpAddress: String?,
  val by: Party,
) : OfferEvent() {
  override fun derich() = copy(userId = null, userIpAddress = null)
}

@Serializable
data class OfferAcceptedEvent(
  override val timestamp: InstantLr,
  override val version: Int,
  @Serializable(with = UidSerializer::class) override val userId: Uid<User>?,
  override val userIpAddress: String?,
  val by: Party,
) : OfferEvent() {
  override fun derich() = copy(userId = null, userIpAddress = null)
}

@Serializable
data class OfferConditionFulfilledEvent(
  override val timestamp: InstantLr,
  override val version: Int,
  @Serializable(with = UidSerializer::class) override val userId: Uid<User>?,
  override val userIpAddress: String?,
  val conditionOptionKey: OptionKey,
) : OfferEvent() {
  override fun derich() = copy(userId = null, userIpAddress = null)
}

@Serializable
data class OfferConditionWaivedEvent(
  override val timestamp: InstantLr,
  override val version: Int,
  @Serializable(with = UidSerializer::class) override val userId: Uid<User>?,
  override val userIpAddress: String?,
  val conditionOptionKey: OptionKey,
) : OfferEvent() {
  override fun derich() = copy(userId = null, userIpAddress = null)
}

@Serializable
data class OfferFirmEvent(
  override val timestamp: InstantLr,
  override val version: Int,
  @Serializable(with = UidSerializer::class) override val userId: Uid<User>?,
  override val userIpAddress: String?,
) : OfferEvent() {
  override fun derich() = copy(userId = null, userIpAddress = null)
}

@Serializable
@Deprecated("This event is no longer used, legal contacts added affects only the offer envelope, not the offer itself.")
data class OfferLegalAddedEvent(
  override val timestamp: InstantLr,
  override val version: Int,
  @Serializable(with = UidSerializer::class) override val userId: Uid<User>?,
  override val userIpAddress: String?,
  val by: Party,
) : OfferEvent() {
  override fun derich() = copy(userId = null, userIpAddress = null)
}

@Serializable
data class OfferEscapeClauseTriggeredEvent(
  override val timestamp: InstantLr,
  override val version: Int,
  @Serializable(with = UidSerializer::class) override val userId: Uid<User>?,
  override val userIpAddress: String?,
  val waiverDeadline: InstantLr,
) : OfferEvent() {
  override fun derich() = copy(userId = null, userIpAddress = null)
}

@Serializable
data class OfferCanceledEvent(
  override val timestamp: InstantLr,
  override val version: Int,
  @Serializable(with = UidSerializer::class) override val userId: Uid<User>?,
  override val userIpAddress: String?,
  val reason: OptionKey,
) : OfferEvent() {
  override fun derich() = copy(userId = null, userIpAddress = null)
}

@Serializable
data class OfferCompletedEvent(
  override val timestamp: InstantLr,
  override val version: Int,
  @Serializable(with = UidSerializer::class) override val userId: Uid<User>?,
  override val userIpAddress: String?,
) : OfferEvent() {
  override fun derich() = copy(userId = null, userIpAddress = null)
}

@Serializable
data class OfferLostEvent(
  override val timestamp: InstantLr,
  override val version: Int,
  @Serializable(with = UidSerializer::class) override val userId: Uid<User>?,
  override val userIpAddress: String?,
) : OfferEvent() {
  override fun derich() = copy(userId = null, userIpAddress = null)
}
