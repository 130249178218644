package builders

import com.diyoffer.negotiation.common.toAuditable
import com.diyoffer.negotiation.model.*
import kotlinx.datetime.Clock

data class OfferBuyerAgentInformationBuilder(
  val ownAgent: Boolean? = null,
  val agentCommissionPercent: Percent? = null,
  val userCertifiedCommissionOverflowResponsibility: Boolean? = null,
  val jurisdiction: Jurisdiction,
  val clock: Clock,
) : IBuilder<OfferBuyerAgent> {
  override fun hydrate(item: OfferBuyerAgent?) = copy(
    ownAgent = item?.ownAgent?.valueOrNull(),
    agentCommissionPercent = item?.commission?.core?.valueOrNull()?.let { if (it.estimate) null else it.commission },
    userCertifiedCommissionOverflowResponsibility = item?.userCertifiedCommissionOverflowResponsibility?.valueOrNull()
  )

  override fun build(): BuildResult<OfferBuyerAgent> = validateAndBuild {
    OfferBuyerAgent(
      ownAgent = ownAgent.toAuditable(Clock.System.now()),
      commission = Auditable.Core(
        if (ownAgent == true) Optional.of(agentCommissionOrEstimate()) else Optional.absent(),
        clock.now().lowRes()
      ),
      userCertifiedCommissionOverflowResponsibility = Auditable.Core(
        if (ownAgent == true) Optional.of(userCertifiedCommissionOverflowResponsibility) else Optional.absent(),
        clock.now().lowRes()
      )
    )
  }

  private fun validateAndBuild(onValid: () -> OfferBuyerAgent): BuildResult<OfferBuyerAgent> {
    val errors = mutableListOf<String>()

    if (ownAgent == null) {
      errors.add("You must confirm if you have an agent.")
    } else if (ownAgent == true) {
      if (agentCommissionPercent != null && agentCommissionPercent.value < 0) {
        errors.add("The commission must be a positive percentage.")
      }
      if (userCertifiedCommissionOverflowResponsibility != true) {
        errors.add("Acknowledge responsibility of excess fees.")
      }
    }

    return buildResult(errors, onValid = onValid)
  }

  fun agentCommissionOrEstimate() = agentCommissionPercent?.let {
    AgentCommission(commission = it, estimate = false)
  } ?: AgentCommission.createEstimated(jurisdiction)
}
