package com.diyoffer.negotiation.ui.login

object ResetPasswordScreenContract {
  data class State(
    val email: String? = null,
    val emailError: String? = null,
    val error: String? = null,
    val success: Boolean? = null,
  )

  sealed class Inputs {
    data class EmailChanged(val value: String?) : Inputs()
    data object ResetStarted : Inputs()
    data object ResetWithEmail : Inputs()
  }

  sealed class Events
}
