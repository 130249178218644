package builders

import com.diyoffer.negotiation.common.services.validation.fixturesExcludedStateModel
import com.diyoffer.negotiation.model.*

data class OfferFixtureExcludedListBuilder(
  val party: Party,
  val negotiationStage: NegotiationStage,
  val currency: Currency,
  val fixturesExcluded: List<NegotiatedTermBuilder<FixtureExclusion>>? = null,
) : IBuilder<OfferFixturesExcluded> {

  override fun hydrate(item: OfferFixturesExcluded?) = copy(
    fixturesExcluded = item?.fixturesExcluded?.map { fixture ->
      NegotiatedTermBuilder(
        party = party,
        negotiationStage = negotiationStage,
        term = fixture,
        currency = currency,
        stateValidation = ::fixturesExcludedStateModel,
      )
    }
  )

  override fun build(): BuildResult<OfferFixturesExcluded> = validateAndBuild {
    OfferFixturesExcluded(
      fixturesExcluded = fixturesExcluded!!.mapNotNull {
        (it.build() as? BuildResult.Success)?.result
      }
    )
  }

  @Suppress("SpreadOperator")
  private fun validateAndBuild(onValid: () -> OfferFixturesExcluded): BuildResult<OfferFixturesExcluded> {
    require(fixturesExcluded != null) { "OfferFixtureExcludedListBuilder has not been hydrated" }
    return buildResult().mergeAndRun(
      onValid,
      *(fixturesExcluded.map { it.build() }.toTypedArray())
    )
  }
}
