package com.diyoffer.negotiation.monitoring.sentry

import co.touchlab.kermit.Logger
import com.diyoffer.negotiation.BuildConfig
import com.diyoffer.negotiation.environment.Env
import com.diyoffer.negotiation.environment.environment
import com.diyoffer.negotiation.monitoring.RouteMapping

class SentryInitializer {
  companion object {
    private const val SENTRY_REPLAY_SESSION_SAMPLE_RATE_PROD = 0.1
  }

  init {
    if (environment == Env.DEV) {
      Logger.d { "Sentry is disabled in DEV" }
    } else {
      initSentry(BuildConfig.GIT_VERSION)
    }
  }

  private fun initSentry(release: String) {
    val dsn = dsnByEnv()

    Logger.d { "Initializing Sentry with dsn $dsn in environment $environment with release $release" }

    val browserTracingOptions = js("{}").unsafeCast<BrowserTracingOptions>().apply {
      beforeNavigate = { context ->
        val currentName = context.name as? String
        if (currentName != null) {
          // until we improve our navigation story, we'll modify routes manually
          context.name = RouteMapping.mapLocationToRoute(currentName)
        }
        context
      }
    }

    Sentry.init(browserTracingOptions) { options ->
      options.dsn = dsn
      options.release = BuildConfig.GIT_VERSION
      options.environment = environment.name
      options.tracesSampleRate = 1.0
      options.replaysSessionSampleRate = if (environment == Env.PROD) SENTRY_REPLAY_SESSION_SAMPLE_RATE_PROD else 1.0
      options.replaysOnErrorSampleRate = 1.0
      options.debug = false
    }
  }

  private fun dsnByEnv() = when (environment) {
    Env.DEV ->
      // https://diy-offer.sentry.io/projects/frontend-dev/
      "https://c829101b5a5f4c3eb9753052a013765d@o4504849421303808.ingest.sentry.io/4504884152827904"
    Env.TEST,
    Env.PROD,
    ->
      // https://diy-offer.sentry.io/projects/frontend/
      "https://5289b88edea94cbe98259fb96745c561@o4504849421303808.ingest.sentry.io/4504849424842752"
  }
}
