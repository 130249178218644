package com.diyoffer.negotiation.model

import com.diyoffer.negotiation.model.serdes.*
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.time.Duration

/**
 * All the possible Listing sections. Used for tracking completion at a section level.
 */
enum class OfferSection {
  /**
   * The buyers section. This isn't actually a "real" offer section, as this data is stored outside the offer itself,
   * and is not versioned together with the offer. However, we keep it here for compatibility with the frontend model
   * of an offer.
   */
  BUYERS,
  BUYER_CONFIRMATION,
  LISTING_DETAILS_ACKNOWLEDGED,
  BUYER_AGENT,
  PRICE,
  CLOSING,
  ASSUMABLE_CONTRACTS,
  FIXTURES_EXCLUDED,
  CHATTELS_INCLUDED,
  SELLER_CONDITIONS,
  BUYER_CONDITIONS,
  BINDING_AGREEMENT_TERMS,
  BUYER_INFORMATION,
  ADDITIONAL_REQUEST,
  EXPIRY,
  ;

  companion object {
    fun allExcept(input: List<OfferSection>): List<OfferSection> {
      return (values().toSet() - input.toSet()).toList()
    }
  }
}

@Serializable
sealed class Offer {
  @Serializable(with = UidSerializer::class)
  abstract val _id: Uid<Offer>
  abstract val version: Int
  abstract val state: State
  abstract val currency: Currency
  abstract val authoredBy: Party

  @Serializable(with = UidSerializer::class)
  abstract val onListing: Uid<Listing>
  abstract val events: List<OfferEvent>

  // Fields, optional on Draft but mandatory on Published
  abstract val buyerConfirmation: OfferBuyerConfirmation?
  abstract val buyerAgent: OfferBuyerAgent?
  abstract val price: OfferPrice?
  abstract val closing: OfferClosing?
  abstract val assumableContracts: OfferAssumableContracts?
  abstract val fixturesExcluded: OfferFixturesExcluded?
  abstract val chattelsIncluded: OfferChattelsIncluded?
  abstract val sellerConditions: OfferSellerConditions?
  abstract val buyerConditions: OfferBuyerConditions?
  abstract val bindingAgreementTerms: OfferBindingAgreementTerms?
  abstract val buyerInformation: OfferBuyerInformation?
  abstract val additionalRequest: OfferAdditionalRequest?
  abstract val expiry: Expiry?
  abstract val listingDetailsAcknowledged: Boolean

  /**
   * The state model for an offer negotiation is as follows:
   *
   * ```mermaidjs
   * stateDiagram-v2
   *   Draft: Offer.Draft(state=DRAFT,authoredBy=Buyer)
   *   Published: Offer — Offer.Published(state=PUBLISHED,authoredBy=Buyer)
   *   Countering: Offer.Published(state=DRAFT,authoredBy=Seller)
   *   Countered: Counter-Offer — Offer.Published(state=PUBLISHED,authoredBy=Seller)
   *   CounterCountering: Offer.Published(state=DRAFT,authoredBy=Buyer)
   *   CounterCountered: Counter-Counter Offer — Offer.Published(state=PUBLISHED,authoredBy=Buyer)
   *   [*] --> Draft
   *   Draft --> Draft: Save (Buyer)
   *   Draft --> Published: Publish (Buyer)
   *   Published --> Countering: Save (Seller)
   *   Published --> [*]: Reject (Seller)
   *   Published --> [*]: Expire (Seller)
   *   Published --> [*]: Accept (Seller)
   *   Countering --> Countered: Publish (Seller)
   *   Countered --> CounterCountering: Save (Buyer)
   *   Countered --> [*]: Reject (Buyer)
   *   Countered --> [*]: Expire (Buyer)
   *   Countered --> [*]: Accept (Buyer)
   *   CounterCountering --> CounterCountered: Publish (Buyer)
   *   CounterCountered --> Countering
   *   CounterCountered --> [*]: Reject (Seller)
   *   CounterCountered --> [*]: Expire (Seller)
   *   CounterCountered --> [*]: Accept (Seller)
   * ```
   */
  enum class State {
    DRAFT,
    PUBLISHED,
    REJECTED,
    EXPIRED,

    /** Accepted, pending condition fulfillment */
    ACCEPTED_PENDING,

    /** Accepted, firm */
    ACCEPTED,

    /**
     * CANCELED is for after an offer is accepted, and then lets
     * say the parties cannot reach agreement with their lawyers involved
     **/
    CANCELED,
    COMPLETED,

    /** Another competing offer has fully completed **/
    LOST,
    ;

    val isAccepted get() = this in listOf(ACCEPTED, ACCEPTED_PENDING, COMPLETED)
    val isLocked get() = this in listOf(ACCEPTED, ACCEPTED, COMPLETED, LOST, CANCELED, EXPIRED, REJECTED)

    companion object {
      /**
       * Any offers in one of these states will block the price from being changed on the corresponding listing
       * the offer is attached to.
       */
      val statesBlockingListingPriceUpdate = setOf(PUBLISHED, ACCEPTED, ACCEPTED_PENDING)

      /**
       * Any offer versions in these states are visible to all parties. Notice that this *does not* include drafts.
       */
      val statesVisibleToAllParties =
        setOf(PUBLISHED, REJECTED, EXPIRED, ACCEPTED, ACCEPTED_PENDING, CANCELED, COMPLETED)

      /**
       * Any offers in one of these states will block other offers from being created with any overlap of contact
       * information.
       */
      val statesBlockingContactOverlap = setOf(PUBLISHED, ACCEPTED, ACCEPTED_PENDING, COMPLETED)

      /**
       * These states allow a counter to be created.
       */
      val statesAllowingCounters = setOf(PUBLISHED)

      /**
       * These states allow an offer to be accepted or rejected.
       */
      val statesAllowingAcceptsRejects = setOf(PUBLISHED, DRAFT)

      /**
       * These states allow an offer to be completed or canceled
       */
      val statesAllowingCompleteCancel = setOf(ACCEPTED)

      /**
       * These states to not allow any actions to be taken.
       */
      val statesNoActionAllowed = setOf(REJECTED, ACCEPTED, CANCELED, COMPLETED)

      val statesAllowingTermSheetGeneration = setOf(ACCEPTED, COMPLETED)

      val statesThatCanExpire = setOf(PUBLISHED, DRAFT)

      val statesWithSellerChecklist = setOf(DRAFT, PUBLISHED, ACCEPTED, ACCEPTED_PENDING)

      // For common tests scenarios
      val TEST_DRAFT_OFFER_UID = "c0000001-c5da-4e62-93f5-bcb55d44d24a".toUid<Offer>()
      val TEST_PUBLISHED_OFFER_UID = "d0000001-c5da-4e62-93f5-bcb55d44d24a".toUid<Offer>()
    }
  }

  /**
   * A draft offer differs from an [Offer.Published] in the following ways:
   * * [Offer.authoredBy] is always [Party.BUYER], and
   * * all data fields are nullable as they may not yet be created.
   *
   * Note that a Draft offer is only used prior to the first publishing event. After that point, counters are
   * of type [Offer.Published], but with state [Offer.State.DRAFT].
   */
  @Serializable
  @SerialName("Draft")
  data class Draft(
    @Serializable(with = UidSerializer::class) override val _id: Uid<Offer>,
    override val version: Int,
    override val currency: Currency,
    @Serializable(with = UidSerializer::class) override val onListing: Uid<Listing>,
    override val buyerConfirmation: OfferBuyerConfirmation? = null,
    override val buyerAgent: OfferBuyerAgent? = null,
    override val price: OfferPrice? = null,
    override val closing: OfferClosing? = null,
    override val assumableContracts: OfferAssumableContracts? = null,
    override val fixturesExcluded: OfferFixturesExcluded? = null,
    override val chattelsIncluded: OfferChattelsIncluded? = null,
    override val sellerConditions: OfferSellerConditions? = null,
    override val buyerConditions: OfferBuyerConditions? = null,
    override val bindingAgreementTerms: OfferBindingAgreementTerms? = null,
    override val buyerInformation: OfferBuyerInformation? = null,
    override val additionalRequest: OfferAdditionalRequest? = null,
    override val expiry: Expiry? = null,
    override val events: List<OfferEvent> = emptyList(),
    override val state: State = State.DRAFT,
    override val listingDetailsAcknowledged: Boolean = false,
  ) : Offer() {
    init {
      fun OfferEvent.valid() = this is OfferDraftSavedEvent
      require(events.all(OfferEvent::valid)) {
        "Offer.Draft cannot contain event(s) ${events.filterNot(OfferEvent::valid)}}"
      }
    }

    override val authoredBy: Party = Party.BUYER
  }

  /**
   * A published offer -- all information is filled in and available.
   *
   * Note that the type "Published" is used loosely here — the actual state of an [Offer.Published] could in fact be
   * [Offer.State.DRAFT], for example if a published offer is being countered.
   */
  @Serializable
  @SerialName("Published")
  data class Published(
    @Serializable(with = UidSerializer::class) override val _id: Uid<Offer>,
    override val version: Int,
    override val state: State,
    override val currency: Currency,
    override val authoredBy: Party,
    @Serializable(with = UidSerializer::class) override val onListing: Uid<Listing>,
    val number: Int,
    /** The count of reviews, 0 when an offer is first published, 1 for the first counter-offer, etc. */
    val reviewCount: Int,
    override val buyerConfirmation: OfferBuyerConfirmation,
    override val buyerAgent: OfferBuyerAgent,
    override val price: OfferPrice,
    override val closing: OfferClosing,
    override val assumableContracts: OfferAssumableContracts,
    override val fixturesExcluded: OfferFixturesExcluded,
    override val chattelsIncluded: OfferChattelsIncluded,
    override val sellerConditions: OfferSellerConditions,
    override val buyerConditions: OfferBuyerConditions,
    override val bindingAgreementTerms: OfferBindingAgreementTerms,
    override val buyerInformation: OfferBuyerInformation?,
    override val additionalRequest: OfferAdditionalRequest,
    override val expiry: Expiry,
    override val events: List<OfferEvent>,
    override val listingDetailsAcknowledged: Boolean = true,
  ) : Offer()

  // Allow to copy abstract fields with the right offer type
  @Suppress("LongParameterList", "ComplexMethod")
  fun baseCopy(
    state: State? = null,
    buyerConfirmation: OfferBuyerConfirmation? = null,
    buyerAgent: OfferBuyerAgent? = null,
    listingDetailsAcknowledged: Boolean? = null,
    price: OfferPrice? = null,
    closing: OfferClosing? = null,
    assumableContracts: OfferAssumableContracts? = null,
    fixturesExcluded: OfferFixturesExcluded? = null,
    chattelsIncluded: OfferChattelsIncluded? = null,
    sellerConditions: OfferSellerConditions? = null,
    buyerConditions: OfferBuyerConditions? = null,
    bindingAgreementTerms: OfferBindingAgreementTerms? = null,
    buyerInformation: OfferBuyerInformation? = null,
    additionalRequest: OfferAdditionalRequest? = null,
    expiry: Expiry? = null,
  ): Offer = when (this) {
    is Draft -> copy(
      state = state ?: this.state,
      buyerConfirmation = buyerConfirmation ?: this.buyerConfirmation,
      buyerAgent = buyerAgent ?: this.buyerAgent,
      listingDetailsAcknowledged = listingDetailsAcknowledged ?: this.listingDetailsAcknowledged,
      price = price ?: this.price,
      closing = closing ?: this.closing,
      assumableContracts = assumableContracts ?: this.assumableContracts,
      fixturesExcluded = fixturesExcluded ?: this.fixturesExcluded,
      chattelsIncluded = chattelsIncluded ?: this.chattelsIncluded,
      sellerConditions = sellerConditions ?: this.sellerConditions,
      buyerConditions = buyerConditions ?: this.buyerConditions,
      bindingAgreementTerms = bindingAgreementTerms ?: this.bindingAgreementTerms,
      buyerInformation = buyerInformation ?: this.buyerInformation,
      additionalRequest = additionalRequest ?: this.additionalRequest,
      expiry = expiry ?: this.expiry,
    )

    is Published -> copy(
      state = state ?: this.state,
      buyerConfirmation = buyerConfirmation ?: this.buyerConfirmation,
      buyerAgent = buyerAgent ?: this.buyerAgent,
      listingDetailsAcknowledged = listingDetailsAcknowledged ?: this.listingDetailsAcknowledged,
      price = price ?: this.price,
      closing = closing ?: this.closing,
      assumableContracts = assumableContracts ?: this.assumableContracts,
      fixturesExcluded = fixturesExcluded ?: this.fixturesExcluded,
      chattelsIncluded = chattelsIncluded ?: this.chattelsIncluded,
      sellerConditions = sellerConditions ?: this.sellerConditions,
      buyerConditions = buyerConditions ?: this.buyerConditions,
      bindingAgreementTerms = bindingAgreementTerms ?: this.bindingAgreementTerms,
      buyerInformation = buyerInformation ?: this.buyerInformation,
      additionalRequest = additionalRequest ?: this.additionalRequest,
      expiry = expiry ?: this.expiry,
    )
  }
}

/**
 * The contacts associated with an offer. These are not "negotiable" items, therefore they are not part of
 * the [Offer] itself, but rather associated to an offer and maintained within the offer's envelope or meta-data.
 * Maintaining them separately allows them to be updated at any time regardless of offer state, and apply to all
 * offer versions.
 */
@Serializable
data class OfferContacts(
  val buyers: OfferBuyers,
  val buyerLegal: Contact?,
  val sellerLegal: Contact?,
)

@Serializable
data class OfferBuyers(
  val contacts: List<Contact>,
)

@Serializable
data class OfferBuyerConfirmation(
  val over18: Auditable<Boolean>,
  val userCertifiedLegalAuthority: Auditable<Boolean>,
)

@Serializable
data class OfferBuyerAgent(
  val ownAgent: Auditable<Boolean>,
  val commission: Auditable<AgentCommission>,
  val userCertifiedCommissionOverflowResponsibility: Auditable<Boolean>,
)

@Serializable
data class AgentCommission(
  val commission: Percent,
  val estimate: Boolean,
) {
  companion object {

    /**
     * Creates an [AgentCommission] instance using the [defaultCommission]. This value is intentionally set
     * to be about as high as most buyer agent commissions are likely to be, so that when the estimated value is used,
     * the offer is presented as conservatively as possible, which benefits both buyer and seller. From the seller's
     * perspective, if the commission is lower than estimated, the seller gets a better deal than they expect from the
     * negotiation. From the buyer's perspective, underestimating the commission means the buyer needs to pick up the
     * shortfall, which doesn't hurt the seller directly, but would not be ideal for the buyer.
     */
    fun createEstimated(jurisdiction: Jurisdiction) = AgentCommission(jurisdiction.defaultCommission(), true)
  }
}

@Serializable
data class OfferPrice(
  /**
   * The first [Auditable] in the negotiated term is the seller's desired deposit, the second (if applicable) would
   * be the offer counter (buyer's proposed deposit).
   */
  val deposit: NegotiatedTerm<Money>,
  /**
   * The first [Auditable] in the negotiated term is the listing price (asking), the second (if applicable) would
   * be the offer counter (buyer's proposed price).
   */
  val price: NegotiatedTerm<Money>,
)

@Serializable
data class OfferClosing(
  /**
   * The first [Auditable] in the negotiated term is the listing closing date, the second (if applicable) would
   * be the offer counter (buyer's proposed closing date).
   */
  val date: NegotiatedTerm<LocalDate>,
)

@Serializable
data class OfferAssumableContracts(
  /**
   * The first [Auditable] in each negotiated term is the listing contract, the second (if applicable) would
   * be the offer counter (buyer's proposal to accept or reject the contract).
   */
  val contracts: List<NegotiatedTerm<AssumableContract>>,
)

@Serializable
data class OfferFixturesExcluded(
  val fixturesExcluded: List<NegotiatedTerm<FixtureExclusion>>,
)

@Serializable
data class OfferChattelsIncluded(
  val chattelsIncluded: List<NegotiatedTerm<ChattelInclusion>>,
)

@Serializable
data class OfferSellerConditions(
  val conditions: List<NegotiatedTerm<Condition>>,
)

@Serializable
@SerialName("OptionKey.Predefined.ConditionEscapeClause")
data class ConditionEscapeClause(val waiverDeadline: Hours) : OptionKey.Predefined()

@Serializable
data class OfferBuyerConditions(
  val conditions: List<NegotiatedTerm<Condition>>,
)

@Serializable
data class OfferBindingAgreementTerms(
  val days: NegotiatedTerm<Days>,
  @Deprecated("For backward compatibility only. This is not used as the clock starts at offer acceptance.")
  @SerialName("from")
  @Suppress("PropertyName", "ConstructorParameterNaming")
  val __from: InstantLr = InstantLr(Instant.DISTANT_PAST),
)

@Serializable
data class OfferBuyerInformation(
  val description: Auditable<String>,
)

@Serializable
data class OfferAdditionalRequest(
  val additionalRequests: List<NegotiatedTerm<OptionKey>>,
)

enum class NegotiationStage(val authoringParty: Party) {
  SELLER_DRAFTING_LISTING(Party.SELLER),
  BUYER_DRAFTING_OFFER(Party.BUYER), // "offer"
  SELLER_COUNTERING(Party.SELLER), // "counter-offer, counter-counter-counter-offer, etc."
  BUYER_COUNTERING(Party.BUYER), // "counter-counter-offer, counter-counter-counter-counter-offer, etc."
}

sealed class AgentCommissionSummary {
  object NoAgent : AgentCommissionSummary()
  data class Known(val percent: Percent) : AgentCommissionSummary()
  data class Estimate(val percent: Percent) : AgentCommissionSummary()
}

sealed class OfferExpirySummary {
  sealed interface MinimumDuration {
    val minimumDuration: Duration
  }

  data class RelativeToPublishingTime(
    override val minimumDuration: Duration,
  ) : OfferExpirySummary(), MinimumDuration

  data class RelativeToWithholdingTime(
    val withholdingTime: LocalDateTime,
    override val minimumDuration: Duration,
  ) : OfferExpirySummary(), MinimumDuration

  data class RequiresReset(
    override val minimumDuration: Duration,
  ) : OfferExpirySummary(), MinimumDuration

  object NoChangeNeeded : OfferExpirySummary()
}

fun Offer.Published.acceptedAt(): InstantLr {
  require(state.isAccepted) { "Offer is not in an accepted or completed state" }
  return events.reversed().firstNotNullOf { if (it is OfferAcceptedEvent) it.timestamp else null }
}

fun Offer.Published.termSheetExpiresAt(): InstantLr =
  acceptedAt().plus(bindingAgreementTerms.days.currentValue.get().asDuration())
