package components

import androidx.compose.runtime.Composable
import common.FlexColumn
import common.FlexRow
import dev.petuska.kmdc.circular.progress.MDCCircularProgress
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.dom.Br
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import style.DiyStyleSheet
import style.DiyStyleSheet.Sizes.padding
import style.DiyStyleSheet.ZIndex
import style.DiyStyleSheet.fillAbsolute
import style.DiyStyleSheet.fontWeightBold

@Composable
fun Loading(label: String? = null) {
  FlexRow({
    style {
      padding(padding)
      justifyContent(JustifyContent.Center)
    }
  }) {
    FlexColumn({ style { alignItems(AlignItems.Center) } }) {
      MDCCircularProgress()
      label?.let {
        Br()
        Span({ classes(fontWeightBold) }) { Text(it) }
      }
    }
  }
}

@Composable
fun WithLabeledLoadingOverlay(loadingLabel: String? = null, content: ContentBuilder<HTMLDivElement>) {
  Div({
    style { position(Position.Relative) }
  }) {
    content.invoke(this)
    loadingLabel?.let {
      Div({
        classes(fillAbsolute)
        style {
          backgroundColor(DiyStyleSheet.Colors.lightOverlay)
          property("z-index", ZIndex.OVERLAY)
        }
      }) {
        Loading(loadingLabel)
      }
    }
  }
}

@Composable
fun WithLoadingOverlay(loading: Boolean, content: ContentBuilder<HTMLDivElement>) {
  Div({
    style { position(Position.Relative) }
  }) {
    content.invoke(this)
    if (loading) {
      Div({
        classes(fillAbsolute)
        style {
          backgroundColor(DiyStyleSheet.Colors.lightOverlay)
          property("z-index", ZIndex.OVERLAY)
        }
      }) {
        Loading(null)
      }
    }
  }
}
