package com.diyoffer.negotiation.model

import kotlinx.datetime.TimeZone
import kotlinx.serialization.Serializable

@Serializable
data class UserSettings(
  val email: String?,
  val timeZone: TimeZone,
  val currency: Currency,
  val country: Country,
  val acceptUserAgreementTerms: Boolean,
  val acceptMarketingContent: Boolean,
) {
  companion object {
    fun default() = UserSettings(
      email = null,
      timeZone = TimeZone.currentSystemDefault(),
      // for now hard-code this, later on could be a user-selected value or set via geo-ip lookup or
      //  handled at the server level for white-labeled apps
      currency = Currency.USD,
      country = Country.US,
      // when a user is created through social, for now we use an implicit acceptance of the terms on the UX
      acceptMarketingContent = false,
      acceptUserAgreementTerms = true,
    )
  }
}
