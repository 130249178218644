package com.diyoffer.negotiation.repository.listing

import com.copperleaf.ballast.InputHandler
import com.copperleaf.ballast.InputHandlerScope
import com.copperleaf.ballast.observeFlows
import com.copperleaf.ballast.postInput
import com.copperleaf.ballast.repository.bus.EventBus
import com.copperleaf.ballast.repository.bus.observeInputsFromBus
import com.copperleaf.ballast.repository.cache.fetchWithCache
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.repository.listing.SellerListingRepositoryContract.Inputs
import com.diyoffer.negotiation.repository.listing.SellerListingRepositoryContract.State
import com.diyoffer.negotiation.repository.user.UserRepository
import com.diyoffer.negotiation.rpcs.IListingAuthRpcService
import com.diyoffer.negotiation.services.tryRpc
import kotlinx.coroutines.flow.map

/**
 * Listing to the UserRepositoryChanges and
 */
class SellerListingRepositoryInputHandler(
  private val listingAuthRpcService: IListingAuthRpcService,
  private val userRepo: UserRepository,
  private val eventBus: EventBus,
) : InputHandler<Inputs, Any, State> {
  override suspend fun InputHandlerScope<Inputs, Any, State>.handleInput(input: Inputs) = when (input) {
    is Inputs.Initialize -> {
      val s = getAndUpdateState { it.copy(initialized = true) }
      if (!s.initialized) {
        observeFlows(
          "SellerListingRepositoryInputHandler",
          eventBus.observeInputsFromBus(),
          userRepo.getUser().map { Inputs.UserUpdated(it) },
        )
      } else {
        noOp()
      }
    }

    is Inputs.InvalidateCache -> {
      updateState { it.copy(cacheInvalidated = true) }
    }

    is Inputs.UserUpdated -> {
      updateState { it.copy(user = input.user, cacheInvalidated = true) }
      postInput(Inputs.FetchListings(true))
    }

    is Inputs.FetchListings -> {
      val s = getCurrentState()
      if (s.user is SessionUser.AuthUser) {
        fetchWithCache(
          input = input,
          forceRefresh = input.forceRefresh || s.cacheInvalidated,
          getValue = { it.listings },
          updateState = { Inputs.ListingsFetched(it) },
          doFetch = { fetchListingsAuthoredBySelf(s) },
        )
      } else {
        noOp()
      }
    }

    is Inputs.ListingsFetched -> {
      updateState { it.copy(listings = input.listings, cacheInvalidated = false) }
    }
  }

  /**
   * Loads all listings authored by the currently authenticated user.
   */
  @Suppress("TooGenericExceptionCaught")
  private suspend fun fetchListingsAuthoredBySelf(s: State): List<ListingLoadResult.Success> {
    return if (s.user == null) {
      emptyList()
    } else {
      tryRpc(onException = { _, _ -> emptyList() }, onCancellation = { emptyList() }) {
        when (val r = listingAuthRpcService.loadAuthoredBySelf()) {
          is ListingListLoadResult.Success -> r.listings
        }
      }
    }
  }
}
