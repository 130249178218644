package builders

data class ValueBuilder<T>(
  val value: T? = null,
  val nullMessage: String = "You must specify a value",
) : IBuilder<T> {
  override fun hydrate(item: T?) = ValueBuilder(value = item)

  override fun build(): BuildResult<T> = validateAndBuild {
    value!!
  }

  private fun validateAndBuild(onValid: () -> T): BuildResult<T> {
    return buildResult(errors = if (value == null) listOf(nullMessage) else emptyList(), onValid = onValid)
  }
}
