package prototypes

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import co.touchlab.kermit.Logger
import com.diyoffer.negotiation.common.retry
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.rpcs.IContactVerificationRpcService
import com.diyoffer.negotiation.rpcs.onRpcAttempt
import org.jetbrains.compose.web.dom.Text
import org.kodein.di.compose.rememberInstance
import services.OfferAnonLoader
import services.SignatureLoadOfferState

@Composable
fun PrototypeStartContactVerification() {
  val offerAnonLoader by rememberInstance<OfferAnonLoader>()
  val contactVerificationRpcService by rememberInstance<IContactVerificationRpcService>()
  val (resultState, setResultState) = remember { mutableStateOf<String?>(null) }
  val offerState = offerAnonLoader.load(testSignatureComponents)

  if (resultState != null) {
    Text("Result = $resultState")
  }

  @Suppress("TooGenericExceptionCaught")
  LaunchedEffect(offerState.value) {
    when (val value = offerState.value) {
      is SignatureLoadOfferState.Success -> {
        val contact = when (value.offer) {
          is Offer.Draft -> value.contacts.buyers.contacts.single { it.name == "Joe" }
          is Offer.Published -> value.contacts.buyers.contacts.single { it.name == "Joe" }
        }.run { copy(methods = methods.filter { it is ContactMethod.Email && it.email == "joe2@joe.com" }) }

        val onAttempt = onRpcAttempt<StartContactVerificationResult> { a, e ->
          Logger.w(e) { "Error starting contact verification, attempt=$a, will retry" }
        }
        retry(onAttempt = onAttempt) {
          try {
            val result = contactVerificationRpcService.startVerification(
              id = UidValue("acc8fabb-c5da-4e62-93f5-bcb55d44d24a".toUid()),
              contact = contact,
            )
            setResultState(
              when (result) {
                StartContactVerificationResult.Success -> "Success!"
                StartContactVerificationResult.NotFound -> "Not found"
              },
            )
          } catch (e: Exception) {
            Logger.e(e) { "Error sending buyer offer contact verification" }
            setResultState("Error")
          }
        }
      }
      else -> setResultState("Offer load error: ${value::class.simpleName}")
    }
  }
}

@Composable
@Suppress("ComplexMethod")
fun PrototypeCompleteContactVerification() {
  val offerAnonLoader by rememberInstance<OfferAnonLoader>()
  val contactVerificationRpcService by rememberInstance<IContactVerificationRpcService>()
  val (resultState, setResultState) = remember { mutableStateOf<String?>(null) }
  val offerState = offerAnonLoader.load(testSignatureComponents)

  if (resultState != null) {
    Text("Result = $resultState")
  }

  @Suppress("TooGenericExceptionCaught")
  LaunchedEffect(offerState.value) {
    when (val value = offerState.value) {
      is SignatureLoadOfferState.Success -> {
        val contact = when (value.offer) {
          is Offer.Draft -> value.contacts.buyers.contacts.single { it.name == "Joe" }
          is Offer.Published -> value.contacts.buyers.contacts.single { it.name == "Joe" }
        }.run { copy(methods = methods.filter { it is ContactMethod.Email && it.email == "joe2@joe.com" }) }

        val onAttempt = onRpcAttempt<StartContactVerificationResult> { a, e ->
          Logger.w(e) { "Error starting contact verification, attempt=$a, will retry" }
        }
        retry(onAttempt = onAttempt) {
          try {
            val result = contactVerificationRpcService.completeVerificationViaOobCode(
              id = UidValue("acc8fabb-c5da-4e62-93f5-bcb55d44d24a".toUid()),
              contact = contact,
              oobCode = "84864",
            )
            setResultState(
              when (result) {
                VerificationOobCodeResult.Failure -> "Verification Failure"
                VerificationOobCodeResult.NotFound -> "Verification Not Found"
                VerificationOobCodeResult.Success -> "Verification Success"
              },
            )
          } catch (e: Exception) {
            Logger.e(e) { "Error sending buyer offer contact verification" }
            setResultState("Error")
          }
        }
      }
      else -> setResultState("Offer load error: ${value::class.simpleName}")
    }
  }
}
