package com.diyoffer.negotiation.model.rpcs

import kotlinx.serialization.Serializable

/**
 * Provides the result of the initial creation of an [Offer.Draft].
 */
@Serializable
data class OfferCreateResult(
  val saveResult: OfferSaveResult,
  val random: String,
  val signature: String,
)
