package style

import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.StyleSheet
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.lineHeight
import org.jetbrains.compose.web.css.listStyle
import org.jetbrains.compose.web.css.margin
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.paddingBottom
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.style
import org.jetbrains.compose.web.css.textDecoration
import org.jetbrains.compose.web.css.width

object WhatTodoStyleSheet : StyleSheet() {
  val container by style {
    padding(24.px)
    border {
      width(1.px)
      style(LineStyle.Solid)
      color(DiyStyleSheet.Colors.lightGrey)
    }
  }

  val titleContainer by style {
    marginBottom(16.px)
    gap(10.px)
  }

  val title by style {
    fontSize(16.px)
    fontWeight(DiyStyleSheet.Weights.darkNormal)
  }

  val checkedTitle by style {
    textDecoration("line-through")
    color(DiyStyleSheet.Colors.grey)
  }

  val checklistItem by style {
    gap(16.px)
    paddingBottom(12.px)
    margin(0.px, 0.px, 12.px, 24.px)
    borderRadius(0.px)
  }

  val subTitle by style {
    marginLeft(24.px)
    paddingBottom(12.px)
    borderRadius(0.px)
    gap(16.px)
  }

  val description by style {
    marginLeft(24.px)
    color(DiyStyleSheet.Colors.darkGrey)
  }

  val marginLeft24 by style {
    marginLeft(24.px)
  }

  val ul by style {
    margin(0.px, 0.px, 0.px, 24.px)
    property("padding-inline-start", "10px")
  }

  val li by style {
    lineHeight(20.px)
    marginBottom(12.px)
    color(DiyStyleSheet.Colors.darkGrey)
  }

  val gap10 by style {
    gap(10.px)
  }

  val gap16 by style {
    gap(16.px)
  }

  val noActivity by style {
    marginTop(32.px)
    fontSize(24.px)
    fontWeight(DiyStyleSheet.Weights.bold)
    color(DiyStyleSheet.Colors.darkBlue)
  }

  val noActivityDescription by style {
    marginTop(16.px)
    color(DiyStyleSheet.Colors.darkGrey)
  }

  init {
    ".checklist-area ul" style {
      listStyle("none")
      fontSize(14.px)
      marginTop(0.px)
      marginBottom(0.px)
    }

    ".checklist-area .material-icons-two-tone" style {
      fontSize(18.px)
    }

    ".checklist-area ul li p" style {
      padding(0.px)
      margin(0.px)
    }
  }
}
