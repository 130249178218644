package common

import androidx.compose.runtime.Composable
import com.diyoffer.negotiation.model.*
import core.Builder
import dev.petuska.kmdc.core.MDCContent
import dev.petuska.kmdc.textfield.MDCTextFieldScope
import model.FormValue

fun percentToPlainText(percent: Percent?): String = percent?.value?.toString() ?: ""

fun percentToFormattedText(percent: Percent?): String = if (percent == null) "" else "${percentToPlainText(percent)}%"

fun plainTextToPercent(percentText: String?): Percent? = percentText
  ?.toDoubleOrNull()
  ?.let { Percent(it) }

@Composable
fun PercentField(
  opts: Builder<ValidatedFormValueInputOptions<Percent>>,
  trailingIcon: MDCContent<MDCTextFieldScope>? = null,
) {
  val options = ValidatedFormValueInputOptions<Percent>().apply { opts(this) }
  TypedTextField<Percent>(opts = {
    label = options.label
    value = options.value
    onBlur = {
      options.onModified(if (it is FormValue.Valid) it.value else null)
      options.onBlur(it)
    }
    toType = ::plainTextToPercent
    toFormatted = ::percentToFormattedText
    toPlain = ::percentToPlainText
  }, trailingIcon = trailingIcon)
}
