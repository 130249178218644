package com.diyoffer.negotiation.repository.user

import com.diyoffer.negotiation.messages.InfoPopup
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.client.*
import com.diyoffer.negotiation.ui.state.LoadingState
import dev.gitlive.firebase.auth.FirebaseUser
import kotlinx.coroutines.CompletableDeferred

object UserRepositoryContract {
  data class State(
    val initialized: Boolean = false,
    val initializedUserAtLoad: Boolean = false,
    val user: SessionUser? = null,
    val createUser: Boolean = false,
    val createUserSettings: UserSettings? = null,
    val authLoadingState: LoadingState = LoadingState.READY,
    val authResult: SuccessOrFailure? = null,
    val linkCredentials: LinkCredentials? = null,
    val queuedPopup: InfoPopup? = null,
  )

  sealed class Inputs {
    data object Initialize : Inputs()
    data class InitializeUserAtLoad(val url: String) : Inputs()
    data class SetSignature(val signature: String, val random: String) : Inputs()
    data class QueuePopup(val popup: InfoPopup?) : Inputs()
    data class AuthStateUser(val firebaseUser: FirebaseUser) : Inputs()
    data class AuthStateUrl(val url: String) : Inputs()
    data object SignInStarted : Inputs()
    data class SignUpStarted(val userSettings: UserSettings) : Inputs()
    data class UserSignedIn(
      val firebaseUser: FirebaseUser,
    ) : Inputs()
    data class UserSignedInNotExists(
      val firebaseUser: FirebaseUser,
    ) : Inputs()
    data class UserSignInFailed(
      val message: String,
    ) : Inputs()
    data class UserSignUpFailed(
      val message: String,
    ) : Inputs()
    data class AuthCredentialsFailed(
      val message: String,
    ) : Inputs()
    data class UserSignInLinkCredentials(
      val linkCredentials: LinkCredentials,
    ) : Inputs()
    data class UserResetPassword(val deferred: CompletableDeferred<SuccessOrFailure>) : Inputs()
    data object UserSignedOut : Inputs()
  }

  sealed class Events
}
