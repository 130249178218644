package forms

import androidx.compose.runtime.Composable
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.ui.condition.ConditionListContract.Inputs
import com.diyoffer.negotiation.ui.condition.ConditionListContract.State
import com.diyoffer.negotiation.ui.condition.getTitle
import com.diyoffer.negotiation.ui.condition.getUpdatedBuilderList
import components.color
import components.icon
import dev.petuska.kmdcx.icons.MDCIcon
import style.DiyStyleSheet

@Composable
fun OfferConditionListNegotiate(state: State, sendVM: (Inputs) -> Unit) {
  ConditionList(sendVM, state) { idx, innerContent ->
    state.negotiationState?.negotiatedConditionBuilderList?.getOrNull(idx)?.let { conditionBuilder ->
      val condition = conditionBuilder.getEffectiveValue()
      NegotiableItemInteract(
        optionKey = condition.optionKey,
        predefinedTitle = state.getTitle(condition),
        builder = conditionBuilder,
        setBuilder = {
          val updatedBuilderList = state.getUpdatedBuilderList(idx, it)
          updatedBuilderList?.let { b -> sendVM(Inputs.NegotiatedBuilderUpdated(b)) }
        },
        content = innerContent
      )
    }
  }
}

fun NegotiatedTerm<Condition>.toUI(title: String? = null, draftMode: Boolean = false) = ConditionUI(
  currentValue.get(),
  if (draftMode) MDCIcon.Gavel else state.icon(),
  if (draftMode) DiyStyleSheet.Colors.green else state.color(),
  state == NegotiatedTerm.State.REMOVED,
  title = title
)
