package com.diyoffer.negotiation.auth

data class TokenResult(
  val claims: Map<String, Any?>,
  val token: String?,
)

@Suppress("UNCHECKED_CAST")
fun TokenResult.diyOfferClaims() = claims["https://diyoffer.com/jwt/claims"] as? Map<String, String>

fun TokenResult.diyOfferUserId() = diyOfferClaims()?.get("x-diyoffer-user-id")

@Suppress("MagicNumber")
fun TokenResult.redact() = copy(token = if (token == null) token else "${token.subSequence(range = 0..10)}…")
