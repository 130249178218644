package environment

import com.diyoffer.negotiation.environment.EnvironmentConfig
import org.kodein.di.DI
import org.kodein.di.bind
import org.kodein.di.singleton

val jsEnvironmentModule = DI.Module("jsEnvironmentModule") {
  bind<EnvironmentConfig> { singleton { JsEnvironmentConfig() } }
}
