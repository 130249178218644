package com.diyoffer.negotiation.ui.condition

import builders.NegotiatedTermBuilder
import co.touchlab.kermit.Logger
import com.diyoffer.negotiation.common.replaceAt
import com.diyoffer.negotiation.common.safeCast
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.ui.state.LoadingState

object ConditionListContract {
  data class State(
    // Which is the party that owns those conditions
    val party: Party? = null,
    // Can user add / remove conditions and modify fields
    // true when creating a listing for instance but false when Accepting or Rejecting
    val draftMode: Boolean = true,
    // The actual conditions defined by the user
    val conditions: List<Condition>? = null,
    // The list of condition options the user needs to interact with to define his conditions
    val conditionOptions: List<ConditionOption.Core>? = null,
    // Which option is selected on the dropdown
    val selectedOption: ConditionOption.Core? = null,
    // Active criteria that affect which are the default conditions, such as property type or jurisdiction
    val filters: ConditionFilters? = null,
    // Allows the user to type a custom condition text that will be added to the list
    val newCondition: NewConditionUI? = null,
    val loadingState: LoadingState = LoadingState.NOT_LOADED,
    val error: String? = null,
    val negotiationState: NegotiationState? = null,
  ) {
    fun unselectedConditionOptions() = conditionOptions?.filterNot { option ->
      conditions?.any { it.optionKey.safeCast<OptionKey.Dynamic>()?._id == option._id } == true
    } ?: emptyList()
  }

  // State engine required for the case there the conditions are NegotiatedTerm<Condition>. They must keep
  // the builder hydrated and so some further housekeeping.
  data class NegotiationState(
    val authoringParty: Party,
    val currency: Currency,
    val negotiationStage: NegotiationStage,
    val negotiatedConditions: List<NegotiatedTerm<Condition>>,
    val negotiatedConditionBuilderList: List<NegotiatedTermBuilder<Condition>> = emptyList(),
  )

  sealed class Inputs {
    data class LoadInitialConditions(
      val party: Party,
      val filters: ConditionFilters,
      val draftMode: Boolean,
      // Standard, non-negotiated conditions
      val conditions: List<Condition>? = null,
      // This is required if the InputHandler must manage NegotiatedTerm<Condition> (offers)
      // instead of Condition (listings)
      val negotiationState: NegotiationState? = null,
    ) : Inputs()
    data class Select(val option: ConditionOption.Core) : Inputs()
    data class NegotiatedBuilderUpdated(val builderList: List<NegotiatedTermBuilder<Condition>>) : Inputs()
    data object AddSelected : Inputs()
    data class Remove(val index: Int) : Inputs()
    data object CustomConditionClicked : Inputs()
    data class CustomConditionTitle(val title: String) : Inputs()
    data class CustomConditionText(val text: String) : Inputs()
    data object CustomConditionSave : Inputs()
    data object CustomConditionCancel : Inputs()
    data class DynamicConditionParamChange(
      val id: Uid<OptionKey.Dynamic>,
      val paramName: String,
      val paramValue: String,
    ) : Inputs()
    data class SetError(val message: String) : Inputs()
    data object ClearError : Inputs()
  }

  sealed class Events {
    data class OnNegotiatedBuilderListUpdated(val builderList: List<NegotiatedTermBuilder<Condition>>) : Events()
  }

  data class NewConditionUI(
    val title: String = "",
    val body: String = "",
  )
}

fun ConditionListContract.State.getOptions(conditionKey: OptionKey) =
  conditionKey.safeCast<OptionKey.Dynamic>()?.let { dynamicKey ->
    conditionOptions?.firstOrNull { it._id == dynamicKey._id }
  }

fun ConditionListContract.State.getTitle(condition: Condition) = when (val key = condition.optionKey) {
  is OptionKey.Custom -> key.title
  is OptionKey.Dynamic -> conditionOptions?.firstOrNull { it._id == key._id }?.title ?: ""
  else -> {
    Logger.e("A condition must have a Custom or Dynamic Option Key")
    ""
  }
}

fun ConditionListContract.State.getPopupRef(conditionKey: OptionKey) = getOptions(conditionKey)?.let { option ->
  when (party) {
    Party.SELLER -> option.popupHelp.seller
    Party.BUYER -> option.popupHelp.buyer
    null -> null
  }
}

fun ConditionListContract.State.getUpdatedBuilderList(idx: Int, updatedBuilder: NegotiatedTermBuilder<Condition>) =
  this.negotiationState?.negotiatedConditionBuilderList?.replaceAt(idx, updatedBuilder)
