package com.diyoffer.negotiation.ui.offer

import com.copperleaf.ballast.EventHandler
import com.copperleaf.ballast.EventHandlerScope
import com.diyoffer.negotiation.ui.offer.SellerOfferTabContract.Events
import com.diyoffer.negotiation.ui.offer.SellerOfferTabContract.Inputs
import com.diyoffer.negotiation.ui.offer.SellerOfferTabContract.State

class SellerOfferTabEventHandler(val onNavigate: (String) -> Unit) : EventHandler<Inputs, Events, State> {
  override suspend fun EventHandlerScope<Inputs, Events, State>.handleEvent(event: Events) = when (event) {
    is Events.OnNavigate -> onNavigate(event.url)
  }
}
