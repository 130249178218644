package com.diyoffer.negotiation.ballast

import com.copperleaf.ballast.BallastInterceptor
import com.copperleaf.ballast.BallastLogger
import com.copperleaf.ballast.BallastViewModelConfiguration
import com.copperleaf.ballast.core.LoggingInterceptor
import com.copperleaf.ballast.plusAssign
import com.diyoffer.negotiation.common.DITypeBinding
import org.kodein.di.DI
import org.kodein.di.bind
import org.kodein.di.bindProvider
import org.kodein.di.bindSet
import org.kodein.di.factory
import org.kodein.di.inBindSet
import org.kodein.di.instance
import org.kodein.di.singleton

val ballastModule = DI.Module("ballastModule") {
  bind<BallastLogger> { factory { tag: String -> KermitBallastLogger(tag) } }

  bindSet<BallastInterceptor<Any, Any, Any>>()

  bindBallastInterceptor { singleton { LoggingInterceptor() } }

  bindBallastInterceptor { singleton { BallastInputDroppedLoggingInterceptor() } }

  // builders are mutable, so we use a provider instead of a singleton
  bindProvider {
    BallastViewModelConfiguration.Builder()
      .apply {
        this += instance<Set<BallastInterceptor<Any, Any, Any>>>()
        logger = factory()
      }
  }
}

fun DI.Builder.bindBallastInterceptor(binding: DITypeBinding<BallastInterceptor<Any, Any, Any>>) =
  inBindSet { add(binding) }
