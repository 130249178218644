package com.diyoffer.negotiation.common.services

import com.diyoffer.negotiation.model.*
import kotlinx.datetime.Instant
import kotlin.time.Duration

object TimeService {
  private const val multiDayHourCutoff = 23
  private const val hourMinutesCutoff = 30
  private const val multiHourMinutesCutoff = 5
  private const val secondsCutoff = 30

  fun InstantLr.humanReadableRelativeTo(
    instant: InstantLr,
    prefix: String = "expires in",
    negatedPrefix: String = "expired",
  ): String = (this - instant).let { "${if (it.isNegative()) negatedPrefix else prefix} ${it.humanReadable()}" }

  fun InstantLr.humanReadableRelativeTo(
    instant: Instant,
    prefix: String = "expires in",
    negatedPrefix: String = "expired",
  ): String = humanReadableRelativeTo(instant.lowRes(), prefix, negatedPrefix)

  @Suppress("ComplexMethod")
  fun Duration.humanReadable(
    approximationWord: String = "about ",
    positiveSuffix: String = "",
    negativeSuffix: String = " ago",
    long: Boolean = true,
  ): String {
    val ds = if (long) " days" else "d"
    val d = if (long) " day" else "d"
    val hs = if (long) " hours" else "h"
    val h = if (long) " hour" else "h"
    val ms = if (long) " minutes" else "m"
    val m = if (long) " minute" else "m"
    val ss = if (long) " seconds" else "s"
    val lt = if (long) "less than " else "< "

    return toComponents { days, hours, minutes, seconds, _ ->
      when {
        isNegative() ->
          "${absoluteValue.humanReadable(approximationWord = approximationWord, long = long)}$negativeSuffix"
        days >= 2 && hours > 1 ->
          "${approximationWord}$days$ds, $hours$hs$positiveSuffix"
        days >= 2 && hours == 1 ->
          "${approximationWord}$days$ds, 1$h$positiveSuffix"
        // 48 - 49 hours
        days >= 2 && hours == 0 && minutes == 0 && seconds == 0 ->
          "$days$ds$positiveSuffix"
        days == 1L && hours == 0 && minutes == 0 && seconds == 0 ->
          "$days$d$positiveSuffix"
        days >= 2 ->
          "${approximationWord}$days$ds$positiveSuffix"
        // 48 - 49 hours
        days >= 1 && hours >= multiDayHourCutoff && minutes >= hourMinutesCutoff ->
          "${approximationWord}2$ds$positiveSuffix"
        // 24 to 47.5 hours
        days >= 1 ->
          "${approximationWord}$inWholeHours$hs$positiveSuffix"
        // now get progressively more accurate
        hours > 1 && minutes >= multiHourMinutesCutoff ->
          "${approximationWord}$hours$hs, $minutes$ms$positiveSuffix"
        hours == 1 && minutes >= multiHourMinutesCutoff ->
          "${approximationWord}1$h, $minutes$ms$positiveSuffix"
        hours > 1 ->
          "${approximationWord}$hours$hs$positiveSuffix"
        hours == 1 ->
          "${approximationWord}1$h$positiveSuffix"
        minutes > 1 ->
          "${approximationWord}$minutes$ms$positiveSuffix"
        minutes == 1 ->
          "${approximationWord}1$m$positiveSuffix"
        seconds >= secondsCutoff ->
          "$seconds$ss$positiveSuffix"
        seconds >= 0 ->
          "$lt$secondsCutoff$ss$positiveSuffix"
        else -> error("Unexpected value for duration: $this")
      }
    }
  }
}
