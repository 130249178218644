package common

import androidx.compose.runtime.Composable
import dev.petuska.kmdc.dialog.Actions
import dev.petuska.kmdc.dialog.MDCDialog
import dev.petuska.kmdc.dialog.onClosed
import dev.petuska.kmdcx.icons.MDCIcon
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import style.DiyStyleSheet

@Composable
fun Popup(
  opened: Boolean,
  title: String? = null,
  titleIcon: MDCIcon? = null,
  onDismiss: () -> Unit,
  content: ContentBuilder<HTMLDivElement>,
) {
  MDCDialog(
    open = opened,
    attrs = {
      classes("mdc-border-top")
      style { property("z-index", DiyStyleSheet.ZIndex.POPUP) }
      onClosed {
        // only if the user closed it via scrim or esc key, otherwise we already did onDismiss
        if (it.detail.action == "close") {
          onDismiss()
        }
      }
    }
  ) {
    DialogBody(title = title, titleIcon = titleIcon) {
      Div({
        style {
          fontSize(14.px)
          color(DiyStyleSheet.Colors.darkGrey)
        }
      }, content = content)
    }

    Actions {
      DarkBlueButton(
        attrs = {
          onClick {
            it.stopPropagation()
            onDismiss()
          }
        }
      ) {
        Text("OK, I understand")
      }
    }
  }
}
