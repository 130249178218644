package com.diyoffer.negotiation.model

import com.diyoffer.negotiation.model.serdes.*
import kotlinx.serialization.Serializable

@Serializable
sealed class Checklist {
  abstract val summary: Asciidoc
  abstract val hint: ChecklistHint?
  abstract val items: List<ChecklistItem>
  abstract val status: ChecklistStatus

  // Specify an action that should be inlined with the item
  abstract val actions: List<ChecklistAction>
}

@Serializable
enum class ChecklistHint {
  NEW_EXCLAIM,
  TIME_SENSITIVE,
}

@Serializable
enum class ChecklistStatus {
  PENDING_SELF,
  PENDING_OTHER,
  COMPLETE,
  NEGATED,
}

/**
 * These are various actions that can be attached to checklist items.
 *
 * These actions are converted into UI buttons at runtime.
 */
@Serializable
sealed class ChecklistAction {
  @Serializable
  sealed class ListingChecklistAction : ChecklistAction() {
    @Serializable(with = UidSerializer::class)
    abstract val listingUid: Uid<Listing>
  }

  @Serializable
  data class ReviewListing(@Serializable(with = UidSerializer::class) override val listingUid: Uid<Listing>) :
    ListingChecklistAction()

  @Serializable
  sealed class OfferChecklistAction : ChecklistAction() {
    @Serializable(with = UidSerializer::class)
    abstract val offerUid: Uid<Offer>
  }

  @Serializable
  data class ViewOffer(@Serializable(with = UidSerializer::class) override val offerUid: Uid<Offer>) :
    OfferChecklistAction()

  @Serializable
  data class ContinueOffer(@Serializable(with = UidSerializer::class) override val offerUid: Uid<Offer>) :
    OfferChecklistAction()

  @Serializable
  data class ReviewOffer(@Serializable(with = UidSerializer::class) override val offerUid: Uid<Offer>) :
    OfferChecklistAction()

  @Serializable
  data class SubmitOffer(@Serializable(with = UidSerializer::class) override val offerUid: Uid<Offer>) :
    OfferChecklistAction()

  @Serializable
  data class LegalContactForm(
    @Serializable(with = UidSerializer::class) override val offerUid: Uid<Offer>,
    val userPrompt: String,
    val forParty: Party,
  ) : OfferChecklistAction()

  @Serializable
  data class CancelOffer(@Serializable(with = UidSerializer::class) override val offerUid: Uid<Offer>) :
    OfferChecklistAction()

  @Serializable
  data class CompleteOffer(@Serializable(with = UidSerializer::class) override val offerUid: Uid<Offer>) :
    OfferChecklistAction()

  @Serializable
  data class TriggerConditionEscapeClause(
    @Serializable(with = UidSerializer::class) override val offerUid: Uid<Offer>,
  ) :
    OfferChecklistAction()
}

@Serializable
sealed class ChecklistItem {
  abstract val status: ChecklistStatus
  abstract val transient: Boolean
  abstract val key: String?

  // Specify an action that should be inlined with the item
  abstract val actions: List<ChecklistAction>
}

@Serializable
data class ChecklistItemMarkup(
  val text: Asciidoc,
  override val status: ChecklistStatus,
  override val transient: Boolean = false,
  override val key: String? = null,
  override val actions: List<ChecklistAction> = emptyList(),
) : ChecklistItem()

@Serializable
data class ChecklistItemCondition(
  val text: Asciidoc,
  val details: List<ChecklistConditionDetails>,
  override val status: ChecklistStatus,
  override val transient: Boolean = false,
  override val key: String? = null,
  override val actions: List<ChecklistAction> = emptyList(),
) : ChecklistItem()

@Serializable
data class ChecklistConditionDetails(
  val title: String,
  val text: Asciidoc,
  val expiry: InstantLr,
)

@Serializable
data class DraftListingCompletionChecklist(
  @Serializable(with = UidSerializer::class) val draftListingId: Uid<Listing>,
  override val summary: Asciidoc = Asciidoc(""),
  override val hint: ChecklistHint? = null,
  override val items: List<ChecklistItem> = emptyList(),
  override val status: ChecklistStatus = ChecklistStatus.PENDING_SELF,
  override val actions: List<ChecklistAction> = emptyList(),
) : Checklist()

@Serializable
data class DraftOfferCompletionChecklist(
  @Serializable(with = UidSerializer::class) val draftOfferId: Uid<Offer>,
  override val summary: Asciidoc = Asciidoc(""),
  override val hint: ChecklistHint? = null,
  override val items: List<ChecklistItem> = emptyList(),
  override val status: ChecklistStatus = ChecklistStatus.PENDING_SELF,
  override val actions: List<ChecklistAction> = emptyList(),
) : Checklist()

@Serializable
data class OfferChecklist(
  @Serializable(with = UidSerializer::class) val offerId: Uid<Offer>,
  override val summary: Asciidoc = Asciidoc(""),
  override val hint: ChecklistHint? = null,
  override val items: List<ChecklistItem> = emptyList(),
  override val status: ChecklistStatus = ChecklistStatus.PENDING_SELF,
  override val actions: List<ChecklistAction> = emptyList(),
) : Checklist()
