//
// GENERATED by KVision
//
package com.diyoffer.negotiation.rpcs

import org.w3c.fetch.RequestInit
import io.kvision.remote.KVRemoteAgent
import kotlinx.serialization.modules.SerializersModule
import com.diyoffer.negotiation.model.Listing
import com.diyoffer.negotiation.model.UidValue
import com.diyoffer.negotiation.model.rpcs.ChecklistResult
import com.diyoffer.negotiation.model.rpcs.ListingCancelResult
import com.diyoffer.negotiation.model.rpcs.ListingListLoadResult
import com.diyoffer.negotiation.model.rpcs.ListingLoadResult
import com.diyoffer.negotiation.model.rpcs.ListingSaveResult
import kotlinx.datetime.TimeZone

actual class ListingAuthRpcService(serializersModules: List<SerializersModule>? = null, requestFilter: (suspend RequestInit.() -> Unit)? = null) : IListingAuthRpcService, KVRemoteAgent<ListingAuthRpcService>(ListingAuthRpcServiceManager, serializersModules, requestFilter) {
    override suspend fun save(listing: Listing) = call(IListingAuthRpcService::save, listing)
    override suspend fun load(id: UidValue<Listing>) = call(IListingAuthRpcService::load, id)
    override suspend fun loadAuthoredBySelf() = call(IListingAuthRpcService::loadAuthoredBySelf)
    override suspend fun publish(listing: Listing) = call(IListingAuthRpcService::publish, listing)
    override suspend fun cancel(listingId: UidValue<Listing>) = call(IListingAuthRpcService::cancel, listingId)
    override suspend fun checklist(listing: Listing, timeZone: TimeZone) = call(IListingAuthRpcService::checklist, listing, timeZone)
}
