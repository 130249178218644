package builders

interface IViewModelBuilder<T, State : Any> {
  fun build(state: State): BuildResult<T>
}

/**
 * Wraps a view model implementing the [IViewModelBuilder] interface and provides the [IBuilder] interface to
 * callers. We accept the state as a factory function so that we can use the result of `observeState.collectAsState()`
 * in the view -- that way the view state is synchronized with the builder state, and we are at no risk of timing
 * issues with respect to different states at view composition time vs [IBuilder.build] time.
 */
class ViewModelBuilder<T, State : Any>(
  val vm: IViewModelBuilder<T, State>,
  val stateFactory: () -> State,
) : IBuilder<T> {
  override fun hydrate(item: T?): IBuilder<T> = this

  override fun build(): BuildResult<T> = vm.build(stateFactory())
}
