package com.diyoffer.negotiation.model.client

import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.ui.offer.NegotiatedSummaryRow
import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime

enum class Trending {
  UP,
  DOWN,
}

data class OfferUI(
  val offer: Offer.Published,
  val listing: Listing.Published,
  val contacts: OfferContacts,
  val hasPen: Boolean = false,
  val hasAcceptedOtherOffer: Boolean = false,
  val active: Boolean = true,
  val listingName: String? = null,
  val withheldUntil: LocalDateTime? = null,
  val withheld: Boolean = false,
  val orderNumber: Int? = null,
  val highest: Boolean? = null,
  val lowest: Boolean? = null,
  val effectivePrice: Money,
  val offerPrice: NegotiatedTerm<Money>? = null,
  val updateTime: LocalDateTime,
  val updateParty: Party? = null,
  val closingDate: LocalDate,
  val expiryTime: LocalDateTime?,
  val expired: Boolean,
  val buyerName: String,
  val buyerFullName: String? = null,
  val trending: Trending?,
  val cardInfoSummary: String?,
  val counterRejectList: List<NegotiatedSummaryRow>,
  val ownAgent: Boolean,
)
