package com.diyoffer.negotiation.model.serdes

import com.diyoffer.negotiation.model.*
import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import kotlinx.serialization.KSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.polymorphic
import kotlinx.serialization.modules.subclass

/**
 * Handle serializing primitives and types that serialize to literals as polymorphic types by wrapping
 * them in surrogates to allow for the type discriminator to be added to the surrogate. This should also include
 * value classes as these are also serialized directly as their underlying type.
 *
 * See https://github.com/Kotlin/kotlinx.serialization/issues/1252 for some background -- the basic problem is
 * that types that serialize to literals don't have any place for a discriminator value.
 */
val PolymorphicLiteralsSerializationModule: SerializersModule by lazy {
  SerializersModule {
    polymorphic(Any::class) {
      subclass(BooleanAsObjectSerializer)
      subclass(IntAsObjectSerializer)
      subclass(LocalDateAsObjectSerializer)
      subclass(LocalDateTimeAsObjectSerializer)
      subclass(DaysAsObjectSerializer)
      subclass(HoursAsObjectSerializer)
      subclass(MoneyAsObjectSerializer)
      subclass(PercentAsObjectSerializer)
      subclass(StringAsObjectSerializer)
    }
  }
}

object BooleanAsObjectSerializer : KSerializer<Boolean> {
  @Serializable
  @SerialName("Boolean")
  data class BooleanSurrogate(val value: Boolean)

  override val descriptor: SerialDescriptor = BooleanSurrogate.serializer().descriptor

  override fun serialize(encoder: Encoder, value: Boolean) {
    BooleanSurrogate.serializer().serialize(encoder, BooleanSurrogate(value))
  }

  override fun deserialize(decoder: Decoder): Boolean {
    return decoder.decodeSerializableValue(BooleanSurrogate.serializer()).value
  }
}

object IntAsObjectSerializer : KSerializer<Int> {
  @Serializable
  @SerialName("Int")
  data class IntSurrogate(val value: Int)

  override val descriptor: SerialDescriptor = IntSurrogate.serializer().descriptor

  override fun serialize(encoder: Encoder, value: Int) {
    IntSurrogate.serializer().serialize(encoder, IntSurrogate(value))
  }

  override fun deserialize(decoder: Decoder): Int {
    return decoder.decodeSerializableValue(IntSurrogate.serializer()).value
  }
}

object LocalDateAsObjectSerializer : KSerializer<LocalDate> {
  @Serializable
  @SerialName("LocalDate")
  data class LocalDateSurrogate(val value: LocalDate)

  override val descriptor: SerialDescriptor = LocalDateSurrogate.serializer().descriptor

  override fun serialize(encoder: Encoder, value: LocalDate) {
    LocalDateSurrogate.serializer().serialize(encoder, LocalDateSurrogate(value))
  }

  override fun deserialize(decoder: Decoder): LocalDate {
    return decoder.decodeSerializableValue(LocalDateSurrogate.serializer()).value
  }
}

object LocalDateTimeAsObjectSerializer : KSerializer<LocalDateTime> {
  @Serializable
  @SerialName("LocalDateTime")
  data class LocalDateTimeSurrogate(val value: LocalDateTime)

  override val descriptor: SerialDescriptor = LocalDateTimeSurrogate.serializer().descriptor

  override fun serialize(encoder: Encoder, value: LocalDateTime) {
    LocalDateTimeSurrogate.serializer().serialize(encoder, LocalDateTimeSurrogate(value))
  }

  override fun deserialize(decoder: Decoder): LocalDateTime {
    return decoder.decodeSerializableValue(LocalDateTimeSurrogate.serializer()).value
  }
}

object DaysAsObjectSerializer : KSerializer<Days> {
  @Serializable
  @SerialName("Days")
  data class DaysSurrogate(val value: Days)

  override val descriptor: SerialDescriptor = DaysSurrogate.serializer().descriptor

  override fun serialize(encoder: Encoder, value: Days) {
    DaysSurrogate.serializer().serialize(encoder, DaysSurrogate(value))
  }

  override fun deserialize(decoder: Decoder): Days {
    return decoder.decodeSerializableValue(DaysSurrogate.serializer()).value
  }
}

object HoursAsObjectSerializer : KSerializer<Hours> {
  @Serializable
  @SerialName("Hours")
  data class HoursSurrogate(val value: Hours)

  override val descriptor: SerialDescriptor = HoursSurrogate.serializer().descriptor

  override fun serialize(encoder: Encoder, value: Hours) {
    HoursSurrogate.serializer().serialize(encoder, HoursSurrogate(value))
  }

  override fun deserialize(decoder: Decoder): Hours {
    return decoder.decodeSerializableValue(HoursSurrogate.serializer()).value
  }
}

object MoneyAsObjectSerializer : KSerializer<Money> {
  @Serializable
  @SerialName("Money")
  data class MoneySurrogate(val value: Money)

  override val descriptor: SerialDescriptor = MoneySurrogate.serializer().descriptor

  override fun serialize(encoder: Encoder, value: Money) {
    MoneySurrogate.serializer().serialize(encoder, MoneySurrogate(value))
  }

  override fun deserialize(decoder: Decoder): Money {
    return decoder.decodeSerializableValue(MoneySurrogate.serializer()).value
  }
}

object PercentAsObjectSerializer : KSerializer<Percent> {
  @Serializable
  @SerialName("Percent")
  data class PercentSurrogate(val value: Percent)

  override val descriptor: SerialDescriptor = PercentSurrogate.serializer().descriptor

  override fun serialize(encoder: Encoder, value: Percent) {
    PercentSurrogate.serializer().serialize(encoder, PercentSurrogate(value))
  }

  override fun deserialize(decoder: Decoder): Percent {
    return decoder.decodeSerializableValue(PercentSurrogate.serializer()).value
  }
}

object StringAsObjectSerializer : KSerializer<String> {
  @Serializable
  @SerialName("String")
  data class StringSurrogate(val value: String)

  override val descriptor: SerialDescriptor = StringSurrogate.serializer().descriptor

  override fun serialize(encoder: Encoder, value: String) {
    StringSurrogate.serializer().serialize(encoder, StringSurrogate(value))
  }

  override fun deserialize(decoder: Decoder): String {
    return decoder.decodeSerializableValue(StringSurrogate.serializer()).value
  }
}
