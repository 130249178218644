package components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.diyoffer.negotiation.model.*
import common.ErrorMessageInline
import common.FlexColumn
import common.FlexRow
import common.InputOptions
import common.Row
import common.TextArea
import common.TextField
import core.Builder
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import style.DiyStyleSheet

/**
 * This allows the user to build a optionKey. He can either pick it from
 * a predefined list or create a custom one if allowed.
 * Dynamic Keys are not yet supported
 */
@Composable
fun WithOptionKeyEdit(
  opts: Builder<WithOptionKeyEditOpts>,
  content: ContentBuilder<HTMLDivElement>,
) {
  val options = WithOptionKeyEditOpts().apply { opts(this) }
  val (customMode, setCustomMode) = remember {
    mutableStateOf(options.predefined.isNullOrEmpty() && options.allowCustom)
  }

  if (customMode) {
    WithCustomOptionKeyDisplay(options, setCustomMode, content)
  } else {
    if (!options.predefined.isNullOrEmpty()) {
      WithPredefinedOptionKeyEdit(options, setCustomMode, content)
    } else {
      Row {
        ErrorMessageInline { Text("If no predefined option is provided, then allowCustom must be set.") }
      }
    }
  }
}

@Composable
private fun WithPredefinedOptionKeyEdit(
  options: WithOptionKeyEditOpts,
  setCustomMode: (Boolean) -> Unit,
  content: ContentBuilder<HTMLDivElement>,
) {
  options.predefined?.let { predefinedMap ->
    FlexRow {
      FlexColumn {
        DiySelect(
          label = "Predefined ${options.label}",
          items = predefinedMap.values.toList(),
          initialSelected = predefinedMap[options.value],
        ) {
          options.onModified(predefinedMap.entries.singleOrNull { e -> e.value == it }?.key)
        }
      }
      if (options.allowCustom) {
        Button({
          classes(DiyStyleSheet.button, DiyStyleSheet.buttonLink)
          onClick { setCustomMode(true) }
        }) {
          Text("Define your own")
        }
      }
    }
  }
  Row {
    content.invoke(this)
  }
}

@Composable
private fun WithCustomOptionKeyDisplay(
  options: WithOptionKeyEditOpts,
  setCustomMode: (Boolean) -> Unit,
  content: ContentBuilder<HTMLDivElement>,
) {
  val customKey = options.value as? OptionKey.Custom ?: OptionKey.Custom("", Asciidoc(""))
  FlexRow {
    FlexColumn {
      TextField(opts = {
        label = "Custom ${options.label}"
        value = customKey.title
        onModified = { options.onModified(customKey.copy(title = it ?: "")) }
      })
    }
    if (!options.predefined.isNullOrEmpty()) {
      if (options.allowCustom) {
        Button({
          classes(DiyStyleSheet.button, DiyStyleSheet.buttonLink)
          onClick { setCustomMode(false) }
        }) {
          Text("Pick predefined")
        }
      }
    }
  }

  Row {
    content.invoke(this)
  }

  Row {
    TextArea(opts = {
      label = "Comments"
      value = customKey.value.text
      onModified = { options.onModified(customKey.copy(value = Asciidoc(it ?: ""))) }
    })
  }
}

class WithOptionKeyEditOpts(
  var allowCustom: Boolean = true,
  var predefined: Map<OptionKey.Predefined, String>? = null,
) : InputOptions<OptionKey>()
