package com.diyoffer.negotiation.ui.home

import com.copperleaf.ballast.repository.cache.Cached
import com.copperleaf.ballast.repository.cache.getCachedOrElse
import com.copperleaf.ballast.repository.cache.isLoading
import com.copperleaf.ballast.repository.cache.map
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.ui.checklist.ChecklistUI
import com.diyoffer.negotiation.ui.state.LoadingState

object SellerHomeScreenContract {
  data class State(
    val initialized: Boolean = false,
    val offerChecklists: Cached<List<ChecklistUI.OfferChecklistUI>> = Cached.NotLoaded(),
    val listingChecklists: Cached<List<ChecklistUI.ListingChecklistUI>> = Cached.NotLoaded(),
    val offerCount: Int? = null,
    val offersLoadingState: LoadingState = LoadingState.NOT_LOADED,
  ) {
    fun checklistCount() = offerChecklists.countOr0() + listingChecklists.countOr0()

    fun checklistLoadingState() = when {
      offerChecklists.isLoading() || listingChecklists.isLoading() -> LoadingState.FETCHING
      else -> LoadingState.READY
    }

    private fun <T : ChecklistUI> Cached<List<T>>.countOr0() = map { it.size }.getCachedOrElse { 0 }
  }

  sealed class Inputs {
    data object Fetch : Inputs()
    data class OfferChecklistsUpdated(val checklists: Cached<List<ChecklistUI.OfferChecklistUI>>) : Inputs()
    data class ListingChecklistsUpdated(val checklists: Cached<List<ChecklistUI.ListingChecklistUI>>) : Inputs()
    data class OffersUpdated(val offers: Cached<List<Pair<Offer, OfferContacts>>>) : Inputs()
  }

  sealed class Events
}
