package com.diyoffer.negotiation.ui.login

import com.copperleaf.ballast.InputHandler
import com.copperleaf.ballast.InputHandlerScope
import com.diyoffer.negotiation.model.client.*
import com.diyoffer.negotiation.repository.user.UserRepository
import com.diyoffer.negotiation.ui.login.ResetPasswordScreenContract.Events
import com.diyoffer.negotiation.ui.login.ResetPasswordScreenContract.Inputs
import com.diyoffer.negotiation.ui.login.ResetPasswordScreenContract.State
import com.diyoffer.negotiation.ui.state.StateValidation
import com.diyoffer.negotiation.ui.state.doValidations

typealias ResetPasswordInputHandlerScope = InputHandlerScope<Inputs, Events, State>

class ResetPasswordScreenInputHandler(
  private val userRepo: UserRepository,
) : InputHandler<Inputs, Events, State> {

  private val validations = listOf<StateValidation<State>>(
    StateValidation(
      { copy(emailError = "You must supply a valid email.") },
      { copy(emailError = null) },
    ) { email.isNullOrBlank() },
  )

  override suspend fun ResetPasswordInputHandlerScope.handleInput(input: Inputs) = when (input) {
    is Inputs.EmailChanged -> {
      updateState { it.copy(email = input.value, emailError = null) }
    }
    is Inputs.ResetWithEmail -> {
      resetWithEmail()
    }
    Inputs.ResetStarted -> {
      updateState { it.copy(emailError = null, error = null) }
    }
  }

  private suspend fun ResetPasswordInputHandlerScope.resetWithEmail() {
    if (!doValidations(this, validations)) return
    val state = getCurrentState()
    handleResetResult(userRepo.resetWithEmail(state.email!!))
  }

  private suspend fun ResetPasswordInputHandlerScope.handleResetResult(result: SuccessOrFailure) {
    when (result) {
      SuccessOrFailure.Success -> {
        updateState {
          it.copy(error = null, emailError = null, success = true)
        }
      }
      is SuccessOrFailure.Failure -> {
        updateState {
          it.copy(error = result.message, emailError = null)
        }
      }
    }
  }
}
