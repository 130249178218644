package forms

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import builders.LocalDateBuilder
import builders.OfferClosingBuilder
import com.diyoffer.negotiation.common.formatDate
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.services.DateService
import common.GridLayout
import components.NegotiatedTermDisplay
import components.NegotiatedTermEdit
import kotlinx.datetime.Clock
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.DateInput
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.kodein.di.compose.rememberInstance
import style.DiyStyleSheet.fieldLabel12
import style.DiyStyleSheet.fieldLabel20
import style.GridStyleSheet

@Suppress("LongMethod")
@Composable
fun OfferClosingDateEdit(
  builder: OfferClosingBuilder,
  setBuilder: (OfferClosingBuilder) -> Unit,
  clock: Clock,
  timeZone: TimeZone,
) {
  val dateService by rememberInstance<DateService>()
  // Work around for suspended validation, same as ListingDetailsEdit
  LaunchedEffect(builder.negotiatedClosingDateBuilder) {
    builder.negotiatedClosingDateBuilder?.let {
      setBuilder(
        builder.copy(
          errorMessage = if (!dateService.isBusinessDay(it.getEffectiveValue())) {
            "Closing Date must be on a week day that is not a statutory holiday."
          } else {
            null
          }
        )
      )
    }
  }

  builder.negotiatedClosingDateBuilder?.let { dateBuilder ->
    GridLayout(
      rightPanelContent = {
        NegotiatedTermEdit(
          dateBuilder,
          { setBuilder(builder.copy(negotiatedClosingDateBuilder = it)) }
        )
      }
    ) {
      val effectiveDate = dateBuilder.getEffectiveValue()
      val counterDate = dateBuilder.getCounteredValue()
      val listingDate = dateBuilder.getBaselineValue()

      Div({
        classes(GridStyleSheet.flex, GridStyleSheet.alignItemsCenter)
        style { gap(32.px) }
      }) {
        Div({ classes(GridStyleSheet.flexColumn) }) {
          Span({ classes(fieldLabel20) }) { Text("Closing Date") }
          if (effectiveDate != listingDate) {
            Span({ classes(fieldLabel12) }) { Text("(Listing Date is ${formatDate(listingDate)})") }
          }
        }
        Div({ classes(GridStyleSheet.flexColumn) }) {
          DateInput {
            if (builder.negotiatedClosingDateBuilder?.state != NegotiatedTerm.State.COUNTERED) disabled()
            value(
              if (builder.negotiatedClosingDateBuilder?.state != NegotiatedTerm.State.COUNTERED) {
                effectiveDate.toString()
              } else {
                counterDate.toString()
              }
            )
            onChange {
              val date = LocalDate.parse(it.value)
              val state = if (date == listingDate) NegotiatedTerm.State.ACCEPTED else dateBuilder.state
              setBuilder(
                builder.copy(
                  negotiatedClosingDateBuilder =
                  dateBuilder.copy(
                    counterValueBuilder = LocalDateBuilder(date, clock, timeZone),
                    state = state
                  )
                )
              )
            }
          }
        }
      }
    }
  }
}

@Composable
fun OfferClosingDateDisplay(item: OfferClosing) {
  NegotiatedTermDisplay("Closing Date: ${formatDate(item.date.currentValue.get())}", item.date.state)
}
