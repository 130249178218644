//
// GENERATED by KVision
//
package com.diyoffer.negotiation.rpcs

import org.w3c.fetch.RequestInit
import io.kvision.remote.KVRemoteAgent
import kotlinx.serialization.modules.SerializersModule

actual class UpdateCheckRpcService(serializersModules: List<SerializersModule>? = null, requestFilter: (suspend RequestInit.() -> Unit)? = null) : IUpdateCheckRpcService, KVRemoteAgent<UpdateCheckRpcService>(UpdateCheckRpcServiceManager, serializersModules, requestFilter) {
    override suspend fun currentVersion() = call(IUpdateCheckRpcService::currentVersion)
}
