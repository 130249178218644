//
// GENERATED by KVision
//
package com.diyoffer.negotiation.rpcs

import org.w3c.fetch.RequestInit
import io.kvision.remote.KVRemoteAgent
import kotlinx.serialization.modules.SerializersModule
import com.diyoffer.negotiation.model.Contact
import com.diyoffer.negotiation.model.Offer
import com.diyoffer.negotiation.model.UidValue
import com.diyoffer.negotiation.model.rpcs.StartContactVerificationResult
import com.diyoffer.negotiation.model.rpcs.VerificationLinkResult
import com.diyoffer.negotiation.model.rpcs.VerificationOobCodeResult

actual class ContactVerificationRpcService(serializersModules: List<SerializersModule>? = null, requestFilter: (suspend RequestInit.() -> Unit)? = null) : IContactVerificationRpcService, KVRemoteAgent<ContactVerificationRpcService>(ContactVerificationRpcServiceManager, serializersModules, requestFilter) {
    override suspend fun startVerification(id: UidValue<Offer>, contact: Contact) = call(IContactVerificationRpcService::startVerification, id, contact)
    override suspend fun completeVerificationViaOobCode(id: UidValue<Offer>, contact: Contact, oobCode: String) = call(IContactVerificationRpcService::completeVerificationViaOobCode, id, contact, oobCode)
    override suspend fun completeVerificationViaLink(id: UidValue<Offer>, oobCode: String, signature: String) = call(IContactVerificationRpcService::completeVerificationViaLink, id, oobCode, signature)
}
