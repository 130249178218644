package forms

import androidx.compose.runtime.Composable
import builders.OfferExpirationBuilder
import com.diyoffer.negotiation.common.formatDateTime
import com.diyoffer.negotiation.common.services.TimeService.humanReadable
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.ui.offer.OfferEditScreenContract.State
import common.FlatList
import common.FlexRow
import common.HelpChip
import common.MessageInline
import common.NumberField
import common.RadioButton
import common.Row
import dev.petuska.kmdcx.icons.MDCIcon
import kotlinx.datetime.Clock
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.TimeZone
import kotlinx.datetime.atTime
import kotlinx.datetime.plus
import kotlinx.datetime.toLocalDateTime
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import style.DiyStyleSheet
import style.DiyStyleSheet.Colors.grey
import style.DiyStyleSheet.Sizes.padding

@Suppress("LongMethod")
@Composable
fun OfferExpirationEdit(
  state: State,
  builder: OfferExpirationBuilder,
  setBuilder: (OfferExpirationBuilder) -> Unit,
) {
  if (state.negotiationStage !== NegotiationStage.BUYER_DRAFTING_OFFER) {
    state.offerExpiryTime?.let {
      Row({ style { marginBottom(padding) } }) {
        RadioButton({
          label =
            "${state.otherPartyStr()} has set the offer expiry at ${formatDateTime(it)} Do you wish to keep it or do " +
            "do you want to extend it by a set number of days from now?"
          value = builder.expirySetAction == ExpirySetAction.KEEP
          popupRef = "offer-listing-extend-expiry"
          onModified = {
            setBuilder(
              builder.copy(
                expirySetAction = when (it) {
                  true, null -> ExpirySetAction.KEEP
                  false -> ExpirySetAction.EXTEND
                }
              )
            )
          }
          yesLabel = "Keep"
          noLabel = "Extend"
          noClass = DiyStyleSheet.onLightGreen
        })
      }
    }
  }

  if (builder.expirySetAction == ExpirySetAction.EXTEND) {
    FlexRow({ style { gap(padding) } }) {
      Div({ style { width(220.px) } }) {
        NumberField(opts = {
          label = "Expiry (Days)"
          value = builder.days?.toInt()
          onModified = { setBuilder(builder.copy(days = it?.toLong())) }
        })
      }
    }

    if (state.offerWithheld) {
      state.relatedListingWithholdingDateTime?.let {
        FlexRow {
          MessageInline(
            icon = MDCIcon.CalendarToday,
            twoToneIconColorFilters = DiyStyleSheet.TwoToneColorFilters.YELLOW
          ) {
            Span({ style { color(grey) } }) {
              Text(
                "The seller is withholding offers until ${formatDateTime(it)} " +
                  "The expiration date of your offer will be " + if ((builder.days ?: 0) > 0) {
                  formatDateTime((it.date.plus(builder.days!!, DateTimeUnit.DAY)).atTime(it.time))
                } else {
                  " after that time"
                }
              )
            }
          }
          HelpChip("offer-withholding-offers-date", 24.px)
        }
      }
    }
  }
}

@Composable
fun OfferExpirationDisplay(state: State, clock: Clock, timeZone: TimeZone) {
  state.offer?.expiry?.let { expiry ->
    val withholdingDateTime = state.relatedListingWithholdingDateTime
    FlatList(
      if (expiry.core.setAction == ExpirySetAction.KEEP && state.offerExpiryTime != null) {
        val prefix = if (state.offerExpiryTime!! > clock.now().toLocalDateTime(timeZone)) "will expire" else "expired"
        "The offer $prefix at ${formatDateTime(state.offerExpiryTime!!)}"
      } else if (withholdingDateTime == null || !state.offerWithheld) {
        "The offer has an expiry of ${expiry.core.duration.humanReadable()}"
      } else {
        "The offer has an expiry of ${expiry.core.duration.humanReadable()} after withholding ending on " +
          "${formatDateTime(withholdingDateTime)} "
      }
    )
  }
}
