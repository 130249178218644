package com.diyoffer.negotiation.repository.checklist

import co.touchlab.kermit.Logger
import com.copperleaf.ballast.InputHandler
import com.copperleaf.ballast.InputHandlerScope
import com.copperleaf.ballast.observeFlows
import com.copperleaf.ballast.repository.bus.EventBus
import com.copperleaf.ballast.repository.bus.observeInputsFromBus
import com.copperleaf.ballast.repository.cache.fetchWithCache
import com.copperleaf.ballast.repository.cache.getCachedOrEmptyList
import com.copperleaf.ballast.repository.cache.getCachedOrNull
import com.copperleaf.ballast.repository.cache.map
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.repository.checklist.ChecklistRepositoryContract.Inputs
import com.diyoffer.negotiation.repository.checklist.ChecklistRepositoryContract.State
import com.diyoffer.negotiation.repository.listing.SellerListingRepository
import com.diyoffer.negotiation.repository.offer.BuyerOfferRepository
import com.diyoffer.negotiation.repository.offer.SellerOfferRepository
import com.diyoffer.negotiation.repository.user.UserRepository
import com.diyoffer.negotiation.rpcs.IListingAuthRpcService
import com.diyoffer.negotiation.rpcs.IOfferAnonRpcService
import com.diyoffer.negotiation.rpcs.IOfferAuthRpcService
import com.diyoffer.negotiation.ui.checklist.ChecklistUI.ListingChecklistUI
import com.diyoffer.negotiation.ui.checklist.ChecklistUI.OfferChecklistUI
import com.diyoffer.negotiation.ui.offer.legalContact
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.mapNotNull
import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime

@Suppress("LongParameterList")
class ChecklistRepositoryInputHandler(
  private val sellerOfferRepo: SellerOfferRepository,
  private val buyerOfferRepo: BuyerOfferRepository,
  private val sellerListingRepo: SellerListingRepository,
  private val offerAuthRpcService: IOfferAuthRpcService,
  private val offerAnonRpcService: IOfferAnonRpcService,
  private val listingAuthRpcService: IListingAuthRpcService,
  private val userRepo: UserRepository,
  private val clock: Clock,
  private val eventBus: EventBus,
) : InputHandler<Inputs, Any, State> {
  @Suppress("LongMethod", "CyclomaticComplexMethod")
  override suspend fun InputHandlerScope<Inputs, Any, State>.handleInput(input: Inputs) {
    return when (input) {
      is Inputs.InitializeForSeller -> {
        val s = getAndUpdateState { it.copy(initialized = true, party = Party.SELLER) }
        if (!s.initialized) {
          observeFlows(
            "ChecklistRepositoryInputHandlerSeller",
            eventBus.observeInputsFromBus(),
            combine(
              sellerListingRepo.userListings(),
              userRepo.getUser(),
            ) { listings, user -> listings to user }
              .mapNotNull { (listings, user) -> if (user == null) null else Inputs.ListingsUpdated(listings, user) },
            combine(
              sellerOfferRepo.listingOffers(),
              sellerListingRepo.userListings(),
              userRepo.getUser()
            ) { offers, listings, user -> Triple(offers, listings, user) }
              .mapNotNull { (offers, listings, user) -> if (user == null) null else Triple(offers, listings, user) }
              .mapNotNull { (offers, listings, user) ->
                // logic to relate a seller offer and its listing should be upstream, not here
                offers.map { offerPairs ->
                  offerPairs.mapNotNull { (offer, contacts) ->
                    listings
                      .getCachedOrEmptyList()
                      .map { it.listing }
                      .filterIsInstance<Listing.Published>()
                      .find { it._id == offer.onListing }
                      ?.let {
                        Triple(offer, contacts, it)
                      }
                  }
                } to user
              }
              .mapNotNull { (offers, user) ->
                Inputs.OffersUpdated(offers, user)
              },
          )
        } else {
          noOp()
        }
      }

      is Inputs.InitializeForBuyer -> {
        val s = getAndUpdateState { it.copy(initialized = true, party = Party.BUYER) }
        if (!s.initialized) {
          observeFlows(
            "ChecklistRepositoryInputHandlerBuyer",
            eventBus.observeInputsFromBus(),
            // logic to relate a buyer offer and its listing should be upstream, not here
            combine(
              buyerOfferRepo.fetchOffer(input.offerId),
              buyerOfferRepo.relatedListing(),
              userRepo.getUser(),
            ) { offerLoad, listingLoad, user ->
              Triple(offerLoad, listingLoad, user)
            }
              .mapNotNull { (offerLoad, listingLoad, user) ->
                val listing = listingLoad.getCachedOrNull()?.listing
                if (user == null || listing !is Listing.Published) null else Triple(offerLoad, listing, user)
              }
              .mapNotNull { (offerLoad, listing, user) ->
                val (offer, offerContacts) = offerLoad.getCachedOrNull() ?: return@mapNotNull null
                Inputs.BuyerOfferUpdated(Triple(offer, offerContacts, listing), user)
              },
          )
        } else {
          noOp()
        }
      }

      is Inputs.ListingsUpdated -> {
        updateState { it.copy(listings = input.listings, user = input.user) }
        val listings = input.listings.getCachedOrEmptyList().map { it.listing }
        fetchWithCache(
          input = input,
          forceRefresh = true,
          getValue = { it.listingChecklists },
          updateState = { Inputs.ListingChecklistsFetched(it) },
          doFetch = { fetchListingChecklists(listings, input.user) }
        )
      }

      is Inputs.OffersUpdated -> {
        val s = updateStateAndGet { it.copy(offers = input.offers, user = input.user) }
        fetchWithCache(
          input = input,
          forceRefresh = true,
          getValue = { it.offerChecklists },
          updateState = { Inputs.OfferChecklistsFetched(it) },
          doFetch = {
            fetchOfferChecklists(
              s.party!!,
              input.offers.getCachedOrEmptyList(),
              input.user,
            )
          }
        )
      }

      is Inputs.BuyerOfferUpdated -> {
        fetchWithCache(
          input = input,
          forceRefresh = true,
          getValue = { it.offerChecklists },
          updateState = { Inputs.OfferChecklistsFetched(it) },
          doFetch = {
            listOfNotNull(fetchOfferChecklist(Party.BUYER, input.offer, input.user))
          }
        )
      }

      is Inputs.ListingChecklistsFetched -> updateState {
        it.copy(listingChecklists = input.listingChecklists)
      }

      is Inputs.OfferChecklistsFetched -> updateState {
        it.copy(offerChecklists = input.offerChecklists)
      }
    }
  }

  private suspend fun fetchOfferChecklists(
    party: Party,
    offers: List<Triple<Offer, OfferContacts, Listing>>,
    user: SessionUser,
  ): List<OfferChecklistUI> = offers
    .filter { it.first.state in Offer.State.statesWithSellerChecklist }
    .mapNotNull { offerDetails ->
      fetchOfferChecklist(party, offerDetails, user)
    }

  private suspend fun fetchOfferChecklist(
    party: Party,
    offerDetails: Triple<Offer, OfferContacts, Listing>,
    user: SessionUser,
  ): OfferChecklistUI? {
    val (offer, contacts, listing) = offerDetails
    val tz = user.timeZone()
    val checklistResult =
      if (user !is SessionUser.AuthUser) {
        offerAnonRpcService.checklist(offer, tz)
      } else {
        offerAuthRpcService.checklist(offer, tz)
      }

    return if (checklistResult is ChecklistResult.Success) {
      createChecklistUIModel(listing, contacts, party, offer, checklistResult.checklist, tz)
    } else {
      Logger.e { "Could not load checklist for offer ${offer._id} with user $user" }
      null
    }
  }

  private fun createChecklistUIModel(
    listing: Listing,
    offerContacts: OfferContacts,
    party: Party,
    offer: Offer,
    checklist: Checklist,
    tz: TimeZone,
  ): OfferChecklistUI {
    val withheldUntil = listing.details?.offerWithholding?.until?.valueOrNull()
    val (legalName, legalEmail) = offerContacts.legalContact(party)
    return OfferChecklistUI(
      offerId = offer._id,
      version = offer.version,
      address = listing.propertyDetails?.address?.standardSummary(),
      checklist = checklist,
      legalName = legalName,
      legalEmail = legalEmail,
      withheldUntil = withheldUntil,
      withheld = (withheldUntil != null) && (clock.now().toLocalDateTime(tz) < withheldUntil)
    )
  }

  private suspend fun fetchListingChecklists(
    listings: List<Listing>,
    user: SessionUser,
  ): List<ListingChecklistUI> = if (user !is SessionUser.AuthUser) {
    emptyList()
  } else {
    listings.filter { it.state == Listing.State.DRAFT }.mapNotNull { listing ->
      val checklistResult = listingAuthRpcService.checklist(
        listing,
        user.timeZone(),
      )
      if (checklistResult is ChecklistResult.Success) {
        ListingChecklistUI(
          listingId = listing._id,
          checklist = checklistResult.checklist,
          address = listing.propertyDetails?.address?.shortSummary()
        )
      } else {
        Logger.e { "Could not load checklist for listing ${listing._id} with user $user" }
        null
      }
    }
  }
}
