package com.diyoffer.negotiation.ui.listing

import com.copperleaf.ballast.repository.cache.Cached
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.client.*
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.ui.state.LoadingState

object ListingEditScreenContract {
  data class State(
    val initialized: Boolean = false,
    val observingUserListings: Boolean = false,
    val listingId: Uid<Listing>? = null,
    val listing: Listing? = null,
    val sessionUser: SessionUser? = null,
    val offerCount: Int? = null,
    val canSubmit: Boolean = false,
    val error: String? = null,
    val loadingState: LoadingState = LoadingState.NOT_LOADED,
  ) {
    fun jurisdiction() = listing.jurisdictionOrDefaultForUser(sessionUser)
  }

  sealed class Inputs {
    data class FetchListing(val listingId: Uid<Listing>) : Inputs()
    data class ListingsUpdated(val listings: Cached<List<ListingLoadResult.Success>>, val user: SessionUser?) : Inputs()
    data class DraftListingUpdated(val draftListing: Listing, val offerCount: Int?) : Inputs()
    data class SaveListing(val listing: Listing) : Inputs()
    data class PublishListing(val listing: Listing.Draft) : Inputs()
    data class SetError(val error: String?) : Inputs()
  }

  sealed class Events {
    data object OnPublishSuccess : Events()
  }
}
