
package com.diyoffer.negotiation.repository.offer

import co.touchlab.kermit.Logger
import com.copperleaf.ballast.InputHandler
import com.copperleaf.ballast.InputHandlerScope
import com.copperleaf.ballast.observeFlows
import com.copperleaf.ballast.postInput
import com.copperleaf.ballast.repository.bus.EventBus
import com.copperleaf.ballast.repository.bus.observeInputsFromBus
import com.copperleaf.ballast.repository.cache.fetchWithCache
import com.copperleaf.ballast.repository.cache.getCachedOrEmptyList
import com.copperleaf.ballast.repository.cache.isLoading
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.repository.listing.SellerListingRepository
import com.diyoffer.negotiation.repository.offer.SellerOfferRepositoryContract.Inputs
import com.diyoffer.negotiation.repository.offer.SellerOfferRepositoryContract.State
import com.diyoffer.negotiation.repository.user.UserRepository
import com.diyoffer.negotiation.rpcs.IOfferAuthRpcService
import com.diyoffer.negotiation.services.tryRpc
import kotlinx.coroutines.flow.combine

class SellerOfferRepositoryInputHandler(
  private val offerAuthRpcService: IOfferAuthRpcService,
  private val sellerListingRepo: SellerListingRepository,
  private val userRepo: UserRepository,
  private val eventBus: EventBus,
) : InputHandler<Inputs, Any, State> {
  @Suppress("LongMethod", "ComplexMethod")
  override suspend fun InputHandlerScope<Inputs, Any, State>.handleInput(input: Inputs) = when (input) {
    is Inputs.Initialize -> {
      val previousState = getCurrentState()
      if (!previousState.initialized) {
        updateState { it.copy(initialized = true) }
        observeFlows(
          "SellerOfferRepositoryInputHandler",
          eventBus.observeInputsFromBus(),
          sellerListingRepo.userListings().combine(userRepo.getUser()) { listings, user ->
            Inputs.UserListingsUpdated(listings, user)
          }
        )
      } else {
        noOp()
      }
    }

    is Inputs.InvalidateCache -> {
      updateState { it.copy(offerCacheInvalidated = true, userOffersCacheInvalidated = true) }
      if (input.refreshOffers) postInput(Inputs.RefreshOffers) else noOp()
    }

    is Inputs.RefreshOffers -> {
      val s = getCurrentState()
      if (s.listings.isLoading() || s.sessionUser !is SessionUser.AuthUser) {
        noOp()
      } else {
        val requestedListingIds = s.listings.getCachedOrEmptyList().map { it.listing._id }
        val cachedListingIds = s.userOffers.getCachedOrEmptyList().map { it.first.onListing }.toSet()
        val cacheNeedsUpdating = requestedListingIds.isNotEmpty() && requestedListingIds.toSet() != cachedListingIds
        fetchWithCache(
          input = input,
          forceRefresh = s.userOffersCacheInvalidated || cacheNeedsUpdating,
          getValue = { it.userOffers },
          updateState = { Inputs.UserOffersFetched(it) },
          doFetch = {
            fetchOffers(requestedListingIds)
          }
        )
      }
    }

    is Inputs.UserListingsUpdated -> {
      val s = updateStateAndGet { it.copy(sessionUser = input.user) }
      if (input.listings.isLoading() || s.sessionUser !is SessionUser.AuthUser) {
        noOp()
      } else {
        updateState { it.copy(listings = input.listings) }
        postInput(Inputs.RefreshOffers)
      }
    }

    is Inputs.OfferFetched -> {
      updateState { it.copy(offer = input.offer, offerCacheInvalidated = false) }
    }

    is Inputs.UserOffersFetched -> {
      updateState { it.copy(userOffers = input.offers, userOffersCacheInvalidated = false) }
    }
  }

  @Suppress("TooGenericExceptionCaught")
  private suspend fun fetchOffers(listingIds: List<Uid<Listing>>): List<Pair<Offer.Published, OfferContacts>> {
    Logger.d { "Fetching for listings $listingIds" }
    @Suppress("SwallowedException")
    return tryRpc(onException = { _, _ -> emptyList() }, onCancellation = { emptyList() }) {
      when (val r = offerAuthRpcService.loadOffersForListings(listingIds.map { UidValue(it) })) {
        is ListingOffersLoadResult.Success -> r.offers
        is ListingOffersLoadResult.NotFound,
        is ListingOffersLoadResult.Unauthorized,
        -> emptyList()
      }
    }
  }
}
