package forms

import androidx.compose.runtime.Composable
import builders.ChattelsIncludedBuilder
import builders.ChattelsIncludedListBuilder
import com.diyoffer.negotiation.model.*
import common.FlatList
import common.Row
import components.OptionList
import org.jetbrains.compose.web.css.paddingBottom
import style.DiyStyleSheet.Sizes.paddingXs

@Composable
fun ListingChattelIncludedListEdit(
  builder: ChattelsIncludedListBuilder,
  setBuilder: (ChattelsIncludedListBuilder) -> Unit,
) {
  OptionList(
    itemBuilder = ChattelsIncludedBuilder(),
    opts = {
      value = builder.chattelIncludedList
      onModified = { setBuilder(builder.copy(chattelIncludedList = it)) }
    },
    displayOpts = {
      subtitleLabel = "Click on Add a Chattel or Inclusion to disclose any"
      addButtonLabel = "Add a Chattel or Inclusion"
      listSectionLabel = "ListSelectionLabel"
    },
    displayContent = { ChattelIncludedDisplay(it) },
    editContent = { s, sb -> ListingChattelIncludedEdit(s as ChattelsIncludedBuilder, sb) }
  )
}

@Composable
fun ListingChattelsIncludedListDisplay(item: ListingChattelsIncluded) {
  val reduced = ChattelsIncludedListBuilder().hydrate(item) as ChattelsIncludedListBuilder
  reduced.chattelIncludedList?.let { chattelsIncluded ->
    if (chattelsIncluded.isEmpty()) {
      FlatList("No Chattel or Inclusion")
    } else {
      chattelsIncluded.mapIndexed { idx, c ->
        Row({ style { if (idx < chattelsIncluded.size - 1) paddingBottom(paddingXs) } }) {
          ChattelIncludedDisplay(c)
        }
      }
    }
  }
}
