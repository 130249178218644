package builders

import com.diyoffer.negotiation.model.FixtureExclusion
import com.diyoffer.negotiation.model.OptionKey

data class FixturesExcludedBuilder(
  val optionKey: OptionKey? = null,
  val location: String? = null,
) : IBuilder<FixtureExclusion> {
  companion object {
    fun create(item: FixtureExclusion?): IBuilder<FixtureExclusion> {
      return item?.let {
        FixturesExcludedBuilder(
          optionKey = it.optionKey,
          location = it.location,
        )
      } ?: FixturesExcludedBuilder()
    }
  }

  override fun hydrate(item: FixtureExclusion?) = create(item)

  override fun build(): BuildResult<FixtureExclusion> = validateAndBuild {
    FixtureExclusion(
      optionKey = optionKey!!,
      location = location!!
    )
  }

  private fun validateAndBuild(onValid: () -> FixtureExclusion): BuildResult<FixtureExclusion> {
    val errors = mutableListOf<String>()

    if (optionKey == null) {
      errors.add("You must specify the chattel or inclusion.")
    }

    if (optionKey is OptionKey.Custom && optionKey.title.isBlank()) {
      errors.add("You must give a title to the chattel or inclusion.")
    }

    if (location.isNullOrBlank()) {
      errors.add("You must provide the location/room of the fixture or exclusion.")
    }

    return buildResult(errors, onValid = onValid)
  }
}
