package com.diyoffer.negotiation.model

import kotlinx.serialization.Serializable

@Serializable
data class InvalidListingField(
  val section: ListingSection,
  val field: String,
  val reasons: List<String>,
)
