package builders

import com.diyoffer.negotiation.common.formatDateTime
import com.diyoffer.negotiation.logic.minimumOfferExpirationDays
import com.diyoffer.negotiation.model.*
import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlin.time.Duration.Companion.days

data class OfferExpirationBuilder(
  val party: Party,
  val clock: Clock,
  val timeZone: TimeZone,
  val setBy: Party? = null,
  val days: Long? = null,
  val instant: InstantLr? = null,
  val expirySetAction: ExpirySetAction? = null,
  val otherActiveOfferExpiry: InstantLr? = null,
) : IBuilder<Expiry> {
  override fun hydrate(item: Expiry?) = copy(
    setBy = item?.core?.setBy,
    days = item?.core?.duration?.inWholeDays,
    instant = item?.instant,
    // If last decision is from other party, the default option will be to maintain it.
    // If we are the last editor, then we'll load our own selected option
    expirySetAction = when {
      // Buyer responding for the first time
      party == Party.BUYER && item == null -> ExpirySetAction.EXTEND
      // Expiry was set by other party so by default, we'll keep
      party != item?.core?.setBy -> ExpirySetAction.KEEP
      // Restore what was set by current party
      else -> item.core.setAction
    }
  )

  /**
   * If we keep the expiry, amend the setBy and setAction but keep original instant,
   * else, send a core with only a duration to backend can enrich
   */
  override fun build(): BuildResult<Expiry> = validateAndBuild {
    val core = Expiry.Core(duration = days!!.days, setBy = party, setAction = expirySetAction!!)
    if (instant != null && expirySetAction == ExpirySetAction.KEEP) {
      Expiry.CoreInstant(core, instant)
    } else {
      core
    }
  }

  private fun validateAndBuild(onValid: () -> Expiry): BuildResult<Expiry> {
    val errors = mutableListOf<String>()
    require(expirySetAction != null) { "The expirySetAction must be defined " }
    if (days == null) {
      errors.add("You must enter a valid duration.")
    } else if (days < 1) {
      errors.add("The expiration must be 1 day or more.")
    } else if (otherActiveOfferExpiry != null) {
      val minimum = minimumOfferExpirationDays(otherActiveOfferExpiry.instant, clock.now())
      if (days < minimum.value) {
        errors.add(
          "The listing is currently locked because the seller has tentatively accepted another offer that will expire at " +
            "${formatDateTime(
              otherActiveOfferExpiry.instant.toLocalDateTime(timeZone)
            )} " +
            "As such, you need to set an expiry of at least ${minimum.value} days to allow the seller to consider your offer."
        )
      }
    }
    return buildResult(errors, onValid = onValid)
  }
}
