package com.diyoffer.negotiation.monitoring.sentry

import org.kodein.di.DI
import org.kodein.di.bind
import org.kodein.di.eagerSingleton
import org.kodein.di.new

val sentryModule = DI.Module("sentryModule") {
  bind { eagerSingleton { new(::SentryInitializer) } }
}
