package services

import androidx.compose.runtime.Composable
import androidx.compose.runtime.State
import androidx.compose.runtime.produceState
import co.touchlab.kermit.Logger
import com.diyoffer.negotiation.common.retry
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.rpcs.IOfferAuthRpcService
import com.diyoffer.negotiation.rpcs.onRpcAttempt
import com.diyoffer.negotiation.services.tryRpc
import kotlinx.serialization.Serializable

@Serializable
sealed class LoadOfferState {
  @Serializable
  data class Success(val offer: Offer, val contacts: OfferContacts) : LoadOfferState()

  @Serializable
  data object Loading : LoadOfferState()

  @Serializable
  data object NotFound : LoadOfferState()

  @Serializable
  data object Error : LoadOfferState()
}

/**
 * Load an offer via an authenticated user.
 */
class OfferAuthLoader(
  private val offerAuthRpcService: IOfferAuthRpcService,
) {
  @Composable
  fun load(id: Uid<Offer>): State<LoadOfferState> {
    @Suppress("TooGenericExceptionCaught")
    return produceState<LoadOfferState>(initialValue = LoadOfferState.Loading) {
      retry(
        onAttempt = onRpcAttempt { a, e -> Logger.w(e) { "Error loading offer via auth, attempt=$a, will retry" } }
      ) {
        tryRpc({ _, _ -> value = LoadOfferState.Error }) {
          val result = offerAuthRpcService.load(id = UidValue(id))
          value = when (result) {
            is OfferLoadResult.Success -> LoadOfferState.Success(result.offer, result.contacts)
            OfferLoadResult.NotFound -> LoadOfferState.NotFound
          }
        }
      }
    }
  }
}
