//
// GENERATED by KVision
//
package com.diyoffer.negotiation.rpcs

import io.kvision.remote.HttpMethod
import io.kvision.remote.KVServiceManager
import io.kvision.remote.registerKVisionServiceExceptions

expect class CmsRpcService : ICmsRpcService

object CmsRpcServiceManager : KVServiceManager<CmsRpcService>(CmsRpcService::class) {
    init {
        registerKVisionServiceExceptions()
        bind(ICmsRpcService::loadPopupHelp, HttpMethod.POST, "cms/load_popup_help")
    }
}
