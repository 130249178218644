package common

import androidx.compose.runtime.Composable
import builders.BuildResult
import dev.petuska.kmdc.core.AttrsBuilder
import dev.petuska.kmdcx.icons.MDCIcon
import dev.petuska.kmdcx.icons.MDCIconSpan
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import style.DiyStyleSheet.Colors.red
import style.DiyStyleSheet.Colors.yellow
import style.DiyStyleSheet.Sizes.paddingMd
import style.DiyStyleSheet.Sizes.paddingSm
import style.DiyStyleSheet.Sizes.paddingXs

@Composable
fun <T> ValidationResult(buildResult: BuildResult<T>, showWarnings: Boolean = true, showErrors: Boolean = true) {
  val hasErrors = showErrors && buildResult is BuildResult.Failure && buildResult.errors.isNotEmpty()
  val hasWarnings = showWarnings && buildResult.warnings.isNotEmpty()
  if (hasErrors || hasWarnings) {
    Row({ style { marginTop(paddingMd) } }) {
      if (showErrors && buildResult is BuildResult.Failure) {
        buildResult.errors.map {
          ValidationMessage(it, ValidationMessageType.ERROR)
        }
      }
      if (showWarnings) {
        buildResult.warnings.map {
          ValidationMessage(it, ValidationMessageType.WARNING)
        }
      }
    }
  }
}

enum class ValidationMessageType {
  ERROR,
  WARNING,
}

@Composable
fun ValidationMessage(
  message: String?,
  type: ValidationMessageType = ValidationMessageType.ERROR,
  attrs: AttrsBuilder<HTMLDivElement>? = null,
) {
  if (!message.isNullOrBlank()) {
    FlexRow({
      style {
        gap(paddingSm)
        marginBottom(paddingXs)
      }
      attrs?.invoke(this)
    }) {
      MDCIconSpan(
        icon = if (type == ValidationMessageType.ERROR) MDCIcon.Error else MDCIcon.Warning,
        attrs = {
          style {
            color(if (type == ValidationMessageType.ERROR) red else yellow)
          }
        }
      )
      Span { Text(message) }
    }
  }
}
