package com.diyoffer.negotiation.ui.login

import co.touchlab.kermit.Logger
import com.diyoffer.negotiation.messages.CommonMessages
import dev.gitlive.firebase.auth.FirebaseAuthEmailException
import dev.gitlive.firebase.auth.FirebaseAuthException
import dev.gitlive.firebase.auth.FirebaseAuthInvalidCredentialsException
import dev.gitlive.firebase.auth.FirebaseAuthInvalidUserException
import dev.gitlive.firebase.auth.FirebaseAuthRecentLoginRequiredException
import dev.gitlive.firebase.auth.FirebaseAuthUserCollisionException

@Suppress("ComplexMethod")
fun firebaseAuthExceptionToMessage(e: FirebaseAuthException) = when (e) {
  // https://firebase.google.com/docs/reference/android/com/google/firebase/auth/FirebaseAuthInvalidUserException
  is FirebaseAuthInvalidUserException -> when (e.code()) {
    "auth/user-disabled" -> "Your user is disabled. If you believe this is an error, please contact support."
    // currently this is not mapped by GitLive, handle it below, but leave it here in case that is fixed in a future version
    "auth/user-not-found" -> "Invalid login."
    "auth/user-token-expired" -> "User not found. If you believe this is an error, please contact support."
    "auth/invalid-user-token" -> "There was an unexpected sign-in error. Please try again or contact support."
    else -> {
      Logger.w { "Unexpected code in FirebaseAuthInvalidUserException: ${e.code()}" }
      "User invalid. If you believe this is an error, please contact support."
    }
  }
  is FirebaseAuthRecentLoginRequiredException -> "Your login was too long ago, please sign in again"
  // https://firebase.google.com/docs/reference/android/com/google/firebase/auth/FirebaseAuthInvalidCredentialsException
  is FirebaseAuthInvalidCredentialsException -> when (e.code()) {
    "auth/missing-verification-id" -> "Your login is invalid, please try logging in again."
    // currently this is not mapped by GitLive, handle it below, but leave it here in case that is fixed in a future version
    "auth/invalid-verification-id" -> "Your login is invalid, please try logging in again."
    // currently this is not mapped by GitLive, handle it below, but leave it here in case that is fixed in a future version
    "auth/weak-password" -> "Your password is too weak, please try a stronger one."
    else -> {
      Logger.w { "Unexpected code in FirebaseAuthInvalidCredentialsException: ${e.code()}" }
      "Your login is invalid, please try logging in again."
    }
  }
  // https://firebase.google.com/docs/reference/android/com/google/firebase/auth/FirebaseAuthUserCollisionException
  is FirebaseAuthUserCollisionException -> when (e.code()) {
    "auth/credential-already-in-use" -> "We already have a registered user with that login mechanism."
    "auth/email-already-in-use" -> "We already have a registered user with the same e-mail address."
    // currently this is not mapped by GitLive, handle it below, but leave it here in case that is fixed in a future version
    "auth/account-exists-with-different-credential" -> "We already have a registered user with the same e-mail address."
    else -> {
      Logger.w { "Unexpected code in FirebaseAuthUserCollisionException: ${e.code()}" }
      "We already have a registered user that matches these inputs."
    }
  }
  is FirebaseAuthEmailException -> "The email address is invalid."
  else -> {
    val code = e.code()
    when {
      !code.isNullOrEmpty() -> {
        when (code) {
          "auth/popup-blocked" -> "An authentication popup was blocked, try allowing the pop-up or contact support."
          "auth/popup-closed-by-user" -> "The sign-in was cancelled."
          "auth/user-not-found", "auth/wrong-password" -> "Invalid login."
          "auth/invalid-verification-id" -> "Your login is invalid, please try logging in again."
          "auth/weak-password" -> "Your password is too weak, please try a stronger one."
          "auth/account-exists-with-different-credential" -> "We already have a registered user with the same e-mail address."
          "auth/too-many-requests" -> "Too many failed login attempts, try again later or select the Forgot Password option."
          else -> {
            Logger.w { "Unexpected code in FirebaseException: $code" }
            "Oops, something went wrong. ${CommonMessages.contactAdministrator}"
          }
        }
      }
      e.message?.contains("Firebase: ") == true -> {
        e.message!!.substringAfter("Firebase: ")
      }
      else -> {
        Logger.w(e) { "Unexpected FirebaseException, no code or useful message information available" }
        "Oops, something went wrong. ${CommonMessages.contactAdministrator}"
      }
    }
  }
}

/**
 * Work around https://github.com/GitLiveApp/firebase-kotlin-sdk/issues/298 by extracting the code from the message.
 */
fun FirebaseAuthException.code(): String? = message?.takeWhile { it != ':' }?.trim()
