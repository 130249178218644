package style

import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.StyleSheet
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.style
import org.jetbrains.compose.web.css.width
import style.DiyStyleSheet.Colors.green

object OfferListStyleSheet : StyleSheet() {
  val badge by style {
    width(20.px)
    height(20.px)
    borderRadius(50.percent)
    backgroundColor(DiyStyleSheet.Colors.lightBlue)
    color(DiyStyleSheet.Colors.blue)
    display(DisplayStyle.Flex)
    justifyContent(JustifyContent.Center)
    alignItems(AlignItems.Center)
    marginLeft(10.px)
    fontSize(10.px)
  }

  val pageTitle by style {
    display(DisplayStyle.Flex)
    alignItems(AlignItems.Center)
    gap(10.px)
  }

  val textTitle by style {
    fontWeight(DiyStyleSheet.Weights.darkNormal)
    fontSize(22.px)
  }

  val showing by style {
    color(DiyStyleSheet.Colors.grey)
    gap(5.px)
  }

  val blackText by style {
    color(DiyStyleSheet.Colors.black)
    fontWeight(DiyStyleSheet.Weights.darkNormal)
  }

  val greyText by style {
    color(DiyStyleSheet.Colors.grey)
    fontWeight(DiyStyleSheet.Weights.darkNormal)
  }

  val yellowText by style {
    color(DiyStyleSheet.Colors.yellow)
    fontWeight(DiyStyleSheet.Weights.darkNormal)
  }

  val offerItemContainer by style {
    marginTop(20.px)
    padding(24.px)
    borderRadius(4.px)
    border {
      style(LineStyle.Solid)
      width(1.px)
      color(DiyStyleSheet.Colors.lightGrey)
    }
    hover(self) style {
      cursor("pointer")
      border {
        style(LineStyle.Solid)
        width(1.px)
        color(green)
      }
    }
  }

  val trending by style {
    color(DiyStyleSheet.Colors.grey)
    marginLeft(5.px)
  }

  val accepted by style {
    border {
      color(green)
      width(2.px)
      style(LineStyle.Solid)
    }
  }

  val waitingForBuyer by style {
    backgroundColor(DiyStyleSheet.Colors.lightGrey)
    borderRadius(4.px)
    padding(11.px, 24.px)
    gap(5.px)
  }

  val darkBlueButton by style {
    fontWeight(DiyStyleSheet.Weights.darkNormal)
    fontSize(12.px)
    padding(11.px, 24.px)
  }
}
