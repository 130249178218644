package com.diyoffer.negotiation.analytics.gtm

import com.diyoffer.negotiation.analytics.AnalyticsClient
import org.kodein.di.DI
import org.kodein.di.bindSingleton
import org.kodein.di.new

val gtmAnalyticsModule = DI.Module("gtmAnalyticsModule") {
  bindSingleton<AnalyticsClient> { new(::GtmAnalyticsClient) }
}
