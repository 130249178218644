package com.diyoffer.negotiation.ui.landing

import co.touchlab.kermit.Logger
import com.copperleaf.ballast.InputHandler
import com.copperleaf.ballast.InputHandlerScope
import com.copperleaf.ballast.observeFlows
import com.copperleaf.ballast.postInput
import com.diyoffer.negotiation.analytics.AnalyticsClient
import com.diyoffer.negotiation.analytics.AnalyticsEvent
import com.diyoffer.negotiation.common.EMAIL_VALIDATION_REGEX
import com.diyoffer.negotiation.messages.CommonMessages
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.repository.user.UserRepository
import com.diyoffer.negotiation.rpcs.IListingAnonRpcService
import com.diyoffer.negotiation.services.tryRpc
import com.diyoffer.negotiation.ui.landing.YourInformationContract.Action.BOOK_A_VIEWING
import com.diyoffer.negotiation.ui.landing.YourInformationContract.Action.WALK_ME_THROUGH_THE_OFFER
import com.diyoffer.negotiation.ui.landing.YourInformationContract.Events
import com.diyoffer.negotiation.ui.landing.YourInformationContract.Fields
import com.diyoffer.negotiation.ui.landing.YourInformationContract.Inputs
import com.diyoffer.negotiation.ui.landing.YourInformationContract.State
import com.diyoffer.negotiation.ui.state.LoadingState
import com.diyoffer.negotiation.workflows.ListingToOfferWorkflow
import kotlinx.coroutines.flow.map
import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone

class YourInformationInputHandler(
  private val listingToOfferWorkflow: ListingToOfferWorkflow,
  private val listingAnonRpcService: IListingAnonRpcService,
  private val userRepo: UserRepository,
  private val analyticsClient: AnalyticsClient,
  private val clock: Clock,
) : InputHandler<Inputs, Events, State> {
  @Suppress("ComplexMethod", "LongMethod")
  override suspend fun InputHandlerScope<Inputs, Events, State>.handleInput(input: Inputs) = when (input) {
    is Inputs.Initialize -> {
      val s = updateStateAndGet { it.copy(id = input.id) }
      if (!s.initialized) {
        observeFlows(
          "YourInformationInputHandler",
          userRepo.getUser().map { Inputs.UserUpdated(it) }
        )
      } else {
        noOp()
      }
    }
    is Inputs.UpdateName -> {
      updateState {
        it.copy(
          name = input.name,
          errors = if (it.errors.isNotEmpty()) validateInfo(it) else mapOf()
        )
      }
    }

    is Inputs.UpdateEmail -> {
      updateState {
        it.copy(
          email = input.email,
          errors = if (it.errors.isNotEmpty()) validateInfo(it) else mapOf()
        )
      }
    }

    is Inputs.UpdateMobile -> {
      updateState {
        it.copy(
          mobile = input.mobile,
          errors = if (it.errors.isNotEmpty()) validateInfo(it) else mapOf()
        )
      }
    }

    is Inputs.UpdateProposedBookingTime -> updateState { it.copy(proposedBookingTimes = input.bookingTime) }
    is Inputs.BookAViewing -> {
      val s = getCurrentState()
      val errors = validateInfo(s)
      if (errors.isNotEmpty()) {
        updateState { it.copy(errors = errors) }
      } else {
        if (s.id != null) analyticsClient.logEvent(AnalyticsEvent.RequestViewingOptions(s.id))
        updateState { it.copy(popupVisible = BOOK_A_VIEWING) }
      }
    }

    is Inputs.InitiateOfferProcess -> {
      val s = getCurrentState()
      val errors = validateInfo(s)
      if (errors.isNotEmpty()) {
        updateState { it.copy(errors = errors, loadingState = LoadingState.READY) }
      } else {
        updateState { it.copy(loadingState = it.loadingState.transitionToFetching()) }
        createOfferAndSendLink(s, s.id!!)
      }
    }

    is Inputs.OkSuccessPopup -> {
      val s = getAndUpdateState { it.copy(popupVisible = null) }
      when (s.popupVisible) {
        WALK_ME_THROUGH_THE_OFFER -> {
          analyticsClient.logEvent(AnalyticsEvent.WalkMeThroughTheOffer(s.id!!))
          s.offerId?.let { postEvent(Events.OnNavigateToOffer(it)) } ?: noOp()
        }
        BOOK_A_VIEWING -> {
          analyticsClient.logEvent(AnalyticsEvent.RequestViewing(s.id!!))
          bookAViewing(s.id, s.sessionUser.timeZone())
        }
        else -> noOp()
      }
    }

    is Inputs.DismissSuccessPopup -> updateState { it.copy(popupVisible = null) }
    is Inputs.SetSuccessMessage -> updateState { it.copy(successMessage = input.message) }
    is Inputs.SetError -> updateState { it.copy(error = input.error, loadingState = LoadingState.READY) }
    is Inputs.ClearErrors -> updateState { it.copy(errors = mapOf(), error = null) }
    is Inputs.UserUpdated -> updateState { it.copy(sessionUser = input.user) }
  }

  /**
   * 1. Takes a permalink and the content of the form
   * 2. Load associated Listing.Published
   * 3. Convert form State to Contact
   * 3. Create offer from loaded listing and preload contacts
   * 4. Send link to buyer
   * 5. Store redirect information for when user clicks on accept prompt
   */
  private suspend fun InputHandlerScope<Inputs, Events, State>.createOfferAndSendLink(
    state: State,
    listingId: Uid<Listing>,
  ) {
    val contact = OfferContacts(
      OfferBuyers(
        listOf(state.toContact())
      ),
      buyerLegal = null,
      sellerLegal = null,
    )
    tryRpc(onException = { message, _ -> postInput(Inputs.SetError(message)) }) {
      val createResult = listingToOfferWorkflow.createOfferDraft(listingId, contact)
      val saveResult = createResult.saveResult
      if (saveResult is OfferSaveResult.Success) {
        userRepo.setSignature(createResult.signature, createResult.random)
        updateState {
          it.copy(
            popupVisible = WALK_ME_THROUGH_THE_OFFER,
            offerId = saveResult.offer._id,
            loadingState = LoadingState.READY
          )
        }
      } else {
        updateState {
          it.copy(
            errors = state.errors + (Fields.FORM_LEVEL to saveResult.message),
            loadingState = LoadingState.READY
          )
        }
      }
    }
  }

  private suspend fun InputHandlerScope<Inputs, Events, State>.bookAViewing(
    id: Uid<Listing>,
    timeZone: TimeZone,
  ) {
    val s = getCurrentState()
    tryRpc(onException = { message, e ->
      Logger.e(e) { "Book a viewing endpoint exception: $message" }
      postInput(
        Inputs.SetError(
          "There has been an error while booking the viewing. ${CommonMessages.contactAdministrator(e)}"
        )
      )
    }) {
      val res = listingAnonRpcService.bookAViewing(
        UidValue(id),
        s.name!!,
        s.email!!,
        s.mobile,
        s.proposedBookingTimes,
        timeZone,
      )
      if (res !is ListingLoadResult.Success) {
        postInput(
          Inputs.SetError(
            "There has been an error while booking the viewing. ${CommonMessages.contactAdministrator}"
          )
        )
      } else {
        updateState {
          it.copy(
            bookViewingEmailSent = true,
            successMessage = "Your booking options have been sent to the seller! " +
              "Expect an email from them to arrange the viewing."
          )
        }
      }
    }
  }

  private fun validateInfo(state: State): Map<Fields, String> {
    val errors = mutableMapOf<Fields, String>()
    if (state.name.isNullOrBlank()) errors[Fields.NAME] = "Full name is required."
    if (state.email.isNullOrBlank()) {
      errors[Fields.EMAIL] = "Email is required."
    } else if (!EMAIL_VALIDATION_REGEX.matches(state.email)) errors[Fields.EMAIL] = "Invalid email format."
    return errors
  }

  private fun State.toContact() = Contact(
    name = name!!,
    methods = listOf(
      ContactMethod.Email(
        email = email!!,
        verified = Auditable.Core(Optional.of(false), clock.now())
      )
    )
  )
}
