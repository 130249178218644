//
// GENERATED by KVision
//
package com.diyoffer.negotiation.rpcs

import io.kvision.remote.HttpMethod
import io.kvision.remote.KVServiceManager
import io.kvision.remote.registerKVisionServiceExceptions

expect class OfferAuthRpcService : IOfferAuthRpcService

object OfferAuthRpcServiceManager : KVServiceManager<OfferAuthRpcService>(OfferAuthRpcService::class) {
    init {
        registerKVisionServiceExceptions()
        bind(IOfferAuthRpcService::create, HttpMethod.POST, "offer/auth/create")
        bind(IOfferAuthRpcService::save, HttpMethod.POST, "offer/auth/save")
        bind(IOfferAuthRpcService::saveContacts, HttpMethod.POST, "offer/auth/save_contacts")
        bind(IOfferAuthRpcService::load, HttpMethod.POST, "offer/auth/load")
        bind(IOfferAuthRpcService::publish, HttpMethod.POST, "offer/auth/publish")
        bind(IOfferAuthRpcService::loadOffersForListings, HttpMethod.POST, "offer/auth/offers-for-listings")
        bind(IOfferAuthRpcService::checklist, HttpMethod.POST, "offer/auth/checklist")
        bind(IOfferAuthRpcService::complete, HttpMethod.POST, "offer/auth/complete")
        bind(IOfferAuthRpcService::accept, HttpMethod.POST, "offer/auth/accept")
        bind(IOfferAuthRpcService::reject, HttpMethod.POST, "offer/auth/reject")
        bind(IOfferAuthRpcService::cancel, HttpMethod.POST, "offer/auth/cancel")
    }
}
