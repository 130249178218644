package com.diyoffer.negotiation.ui.listing

import com.diyoffer.negotiation.model.*

object ListingCreatePageContract {
  data class State(
    val user: SessionUser = SessionUser.NotLoaded,
    val initialized: Boolean = false,
    val error: String? = null,
  )

  sealed class Inputs {
    data object Initialize : Inputs()
    data class UserUpdated(val user: SessionUser) : Inputs()
    data class SetError(val error: String?) : Inputs()
  }

  sealed class Events {
    data class OnSuccess(val draftListingId: Uid<Listing>) : Events()
  }
}
