//
// GENERATED by KVision
//
package com.diyoffer.negotiation.rpcs

import io.kvision.remote.HttpMethod
import io.kvision.remote.KVServiceManager
import io.kvision.remote.registerKVisionServiceExceptions

expect class UserRpcService : IUserRpcService

object UserRpcServiceManager : KVServiceManager<UserRpcService>(UserRpcService::class) {
    init {
        registerKVisionServiceExceptions()
        bind(IUserRpcService::createSelf, HttpMethod.POST, "users/create-self")
        bind(IUserRpcService::loadSelf, HttpMethod.POST, "users/load-self")
    }
}
