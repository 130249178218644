package com.diyoffer.negotiation.ui.login

import com.copperleaf.ballast.EventHandler
import com.copperleaf.ballast.EventHandlerScope
import com.diyoffer.negotiation.ui.login.SignUpScreenContract.Events
import com.diyoffer.negotiation.ui.login.SignUpScreenContract.Inputs
import com.diyoffer.negotiation.ui.login.SignUpScreenContract.State

class SignUpScreenEventHandler(
  private val onSuccess: suspend () -> Unit,
) : EventHandler<Inputs, Events, State> {
  override suspend fun EventHandlerScope<Inputs, Events, State>.handleEvent(event: Events) = when (event) {
    is Events.OnSuccess -> onSuccess()
  }
}
