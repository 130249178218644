//
// GENERATED by KVision
//
package com.diyoffer.negotiation.rpcs

import io.kvision.remote.HttpMethod
import io.kvision.remote.KVServiceManager
import io.kvision.remote.registerKVisionServiceExceptions

expect class UpdateCheckRpcService : IUpdateCheckRpcService

object UpdateCheckRpcServiceManager : KVServiceManager<UpdateCheckRpcService>(UpdateCheckRpcService::class) {
    init {
        registerKVisionServiceExceptions()
        bind(IUpdateCheckRpcService::currentVersion, HttpMethod.POST, "current-version")
    }
}
