package services

import org.kodein.di.DI
import org.kodein.di.bindSingleton
import org.kodein.di.new

val servicesModule = DI.Module("servicesModule") {
  bindSingleton { new(::ListingAuthLoader) }

  bindSingleton { new(::OfferAnonLoader) }

  bindSingleton { new(::OfferAuthLoader) }

  bindSingleton { new(::PopupLoader) }

  bindSingleton { new(::AsciidoctorJs) }

  bindSingleton { new(::AsciidocConverter) }
}
