package common

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import core.Builder
import dev.petuska.kmdc.core.MDCContent
import dev.petuska.kmdc.textfield.MDCTextFieldScope
import model.FormValue
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLInputElement

@Composable
fun <T> TypedTextField(
  opts: Builder<TypedTextInputAttrs<T>>,
  attrs: AttrBuilderContext<HTMLInputElement>? = null,
  trailingIcon: MDCContent<MDCTextFieldScope>? = null,
) {
  val options = TypedTextInputAttrs<T>().apply { opts(this) }
  val (plain, setPlain) = remember { mutableStateOf(options.toPlain(options.value)) }
//  val formatted by derivedStateOf { options.toFormatted(options.toType(plain)) }
  val (formatted, setFormatted) = remember { mutableStateOf(options.toFormatted(options.toType(plain))) }
  val (focused, setFocused) = remember { mutableStateOf(false) }

  LaunchedEffect(options.value) {
    setPlain(options.toPlain(options.value))
    setFormatted(options.toFormatted(options.toType(options.toPlain(options.value))))
  }

  TextField(
    opts = {
      label = options.label
      value = if (focused || formatted.isBlank()) plain else formatted
      disabled = options.disabled
      onModified = { if (it.isNullOrBlank() || options.toType(it) != null) setPlain(it ?: "") }
    },
    attrs = {
      onFocus {
        setFocused(true)
      }
      onBlur {
        setFocused(false)
        if (plain.isBlank()) {
          options.onBlur(null)
        } else {
          options.toType(plain)?.let {
            options.onBlur(FormValue.Valid(it))
          } ?: run {
            options.onBlur(FormValue.Invalid(plain))
          }
        }
      }
      attrs?.invoke(this)
    },
    trailingIcon = trailingIcon
  )
}

class TypedTextInputAttrs<T> : ValidatedFormValueInputOptions<T>() {
  var toType: (String?) -> T? = { null }
  var toFormatted: (T?) -> String = { "" }
  var toPlain: (T?) -> String = { "" }
}
