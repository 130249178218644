package com.diyoffer.negotiation.ui.login

import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.client.*
import com.diyoffer.negotiation.ui.state.LoadingState
import dev.gitlive.firebase.auth.FirebaseAuthException

object SignInScreenContract {
  data class State(
    val initialized: Boolean = false,
    val email: String? = null,
    val emailError: String? = null,
    val password: String? = null,
    val passwordError: String? = null,
    val country: Country = Country.US,
    val error: String? = null,
    val socialError: String? = null,
    val loadingState: LoadingState = LoadingState.READY,
    val linkCredentials: LinkCredentials? = null,
  ) {
    fun clearErrors() = copy(
      emailError = null,
      passwordError = null,
      error = null,
      socialError = null,
    )
  }

  sealed class Inputs {
    data object Initialize : Inputs()
    data object ClearErrors : Inputs()
    data class UsernameChanged(val value: String?) : Inputs()
    data class PasswordChanged(val value: String?) : Inputs()
    data object SignInStarted : Inputs()
    data object SocialSignUpStarted : Inputs()
    data object SignInWithEmailAndPassword : Inputs()
    data object SignInSuccess : Inputs()
    data class SignInFailure(val message: String) : Inputs()
    data class LoadingStateChange(val loadingState: LoadingState) : Inputs()
    data class ObtainSocialAuthCredentialsError(val exception: FirebaseAuthException) : Inputs()
    data class AlternateCredentialsRequiredError(
      val linkCredentials: LinkCredentials,
    ) : Inputs()
  }

  sealed class Events {
    data object OnSuccess : Events()
  }
}
