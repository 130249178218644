//
// GENERATED by KVision
//
package com.diyoffer.negotiation.rpcs

import io.kvision.remote.HttpMethod
import io.kvision.remote.KVServiceManager
import io.kvision.remote.registerKVisionServiceExceptions

expect class DynamicOptionsRpcService : IDynamicOptionsRpcService

object DynamicOptionsRpcServiceManager : KVServiceManager<DynamicOptionsRpcService>(DynamicOptionsRpcService::class) {
    init {
        registerKVisionServiceExceptions()
        bind(IDynamicOptionsRpcService::listDynamicDefaultConditions, HttpMethod.POST, "options/list_dynamic_default_conditions")
        bind(IDynamicOptionsRpcService::listDynamicSelectableConditions, HttpMethod.POST, "options/list_dynamic_selectable_conditions")
        bind(IDynamicOptionsRpcService::hydrateDynamicConditionOptionKey, HttpMethod.POST, "options/hydrate_dynamic_condition_option_key")
        bind(IDynamicOptionsRpcService::hydrateDynamicConditionOptionKeys, HttpMethod.POST, "options/hydrate_dynamic_condition_option_keys")
    }
}
