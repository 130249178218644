package components

import androidx.compose.runtime.Composable
import com.diyoffer.negotiation.model.*
import common.ErrorMessageInline
import common.InlineWithLeadingIcon
import common.Row
import core.Builder
import dev.petuska.kmdc.core.AttrsBuilder
import dev.petuska.kmdcx.icons.MDCIcon
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.textDecoration
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import style.DiyStyleSheet
import style.DiyStyleSheet.Colors.green

/**
 * This allows the user to build a optionKey. He can either pick it from
 * a predefined list or create a custom one if allowed.
 * Dynamic Keys are not yet supported
 */
@Composable
fun WithOptionKeyDisplay(
  opts: Builder<WithOptionKeyDisplayOpts>,
  attrs: AttrsBuilder<HTMLDivElement>? = null,
  content: ContentBuilder<HTMLDivElement>? = null,
) {
  val options = WithOptionKeyDisplayOpts().apply { opts(this) }
  if (options.optionKey == null) {
    ErrorMessageInline { Text("Item must either provide a mandatory OptionKey") }
    return
  }
  if (options.optionKey is OptionKey.Predefined && options.predefinedTitle.isNullOrBlank()) {
    ErrorMessageInline { Text("Item must either use a Custom OptionKey or provide a predefined title") }
    return
  }

  val title = when (val key = options.optionKey) {
    is OptionKey.Custom -> key.title
    is OptionKey.Predefined -> options.predefinedTitle!!
    else -> options.predefinedTitle ?: "Dynamic key not supported yet."
  }

  InlineWithLeadingIcon(
    attrs = attrs,
    icon = options.icon,
    iconColor = options.iconColor
  ) {
    Span({
      classes(DiyStyleSheet.semiBold)
      if (options.lineThrough) style { textDecoration("line-through") }
    }) {
      Text(title)
    }
  }

  content?.let {
    Row({
      style {
        marginTop(0.px)
        marginBottom(0.px)
      }
    }) {
      it.invoke(this)
    }
  }
}

data class WithOptionKeyDisplayOpts(
  var optionKey: OptionKey? = null,
  var predefinedTitle: String? = null,
  var icon: MDCIcon = MDCIcon.Check,
  var iconColor: CSSColorValue = green,
  var lineThrough: Boolean = false,
)
