package com.diyoffer.negotiation.model.rpcs

import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.serdes.*
import kotlinx.serialization.Serializable
import kotlin.jvm.JvmInline

@Serializable
@JvmInline
value class DynamicUid(@Serializable(with = UidSerializer::class) val uid: Uid<OptionKey.Dynamic>)

@Serializable
sealed class HydrateDynamicConditionResult {
  @Serializable
  data class Success(val value: ConditionOption.Core) : HydrateDynamicConditionResult()

  @Serializable
  data class NotFound(val id: DynamicUid) : HydrateDynamicConditionResult()
}
