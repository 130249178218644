package layout

import androidx.compose.runtime.Composable
import app.softwork.routingcompose.Router
import com.diyoffer.negotiation.model.client.*
import common.ActionButton
import common.DarkBlueButton
import common.FlexRow
import common.ValidationMessage
import common.ValidationMessageType
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Text
import style.DiyStyleSheet

@Composable
fun SubmitOrGoBackFooter(
  goBackLabel: String = "Return to Dashboard",
  submitState: SubmitState,
  warningMessage: String,
  goBack: (() -> Unit)? = null,
  onSubmit: () -> Unit,
) {
  val router = Router.current
  if (submitState == SubmitState.DISABLED) {
    FlexRow({ style { justifyContent(JustifyContent.Center) } }) {
      ValidationMessage(warningMessage, ValidationMessageType.WARNING)
    }
  }
  FlexRow({
    style {
      justifyContent(JustifyContent.Center)
      marginBottom(0.px)
    }
  }) {
    SubmitOrGoBackButton(
      goBackLabel,
      submitState = submitState,
      onGoBack = { goBack?.let { it() } ?: router.navigate("/home") },
      onSubmit = onSubmit
    )
  }
}

@Composable
fun SubmitOrGoBackButton(
  goBackLabel: String,
  submitState: SubmitState,
  onGoBack: () -> Unit,
  onSubmit: () -> Unit,
) {
  DarkBlueButton(
    attrs = {
      style {
        padding(8.px, 12.px)
        backgroundColor(DiyStyleSheet.Colors.white)
      }
      onClick { onGoBack() }
    },
    noBackground = true
  ) {
    Text(goBackLabel)
  }

  if (submitState != SubmitState.HIDDEN) {
    ActionButton(
      disabled = submitState == SubmitState.DISABLED,
      attrs = {
        style { padding(8.px, 12.px) }
        onClick { onSubmit() }
      }
    ) {
      Text("Submit")
    }
  }
}
