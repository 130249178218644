package com.diyoffer.negotiation.prototypes

import com.diyoffer.negotiation.model.*
import com.soywiz.kbignum.BigInt
import kotlinx.datetime.Clock
import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import kotlin.time.Duration.Companion.days

@Suppress("MagicNumber")
fun baseTestMinimalDraftListing(uid: Uid<Listing> = newUid()) = Listing.Draft(
  _id = uid,
  version = 1,
  currency = Currency.CAD,
  events = emptyList(),
)

@Suppress("MagicNumber", "LongMethod")
fun baseTestDraftListing(clock: Clock, uid: Uid<Listing> = newUid()) = Listing.Draft(
  _id = uid,
  version = 0,
  propertyOwners = ListingPropertyOwners(
    contacts = listOf(
      Contact(
        name = "John Doe",
        methods = listOf(
          ContactMethod.Email(
            email = "johndoe@example.com",
            verified = Auditable.Core(Optional.of(true), clock.now()),
          ),
        ),
      ),
      Contact(
        name = "Jill Jones",
        methods = listOf(
          ContactMethod.Email(
            email = "jilljones@example.com",
            verified = Auditable.Core(Optional.of(true), clock.now()),
          ),
        ),
      ),
    ),
    userCertifiedLegalAuthority = Auditable.Core(Optional.of(true), clock.now()),
  ),
  externalLinks = ListingLinks(
    listOf(
      ListingLinks.Link(
        ListingLinks.Link.ListingProvider.FACEBOOK,
        "https://facebook.com/real-estate/xyz"
      ),
      ListingLinks.Link(
        ListingLinks.Link.ListingProvider.KIJIJI,
        "https://kijiji.ca/real-estate/xyz"
      ),
    )
  ),
  details = ListingDetails(
    price = Auditable.Core(Optional.of(Money(BigInt(500_000), Currency.CAD)), clock.now()),
    closingDate = Auditable.Core(Optional.of(LocalDate(2022, 1, 12)), clock.now()),
    deposit = Auditable.Core(Optional.of(Money(BigInt(30_000), Currency.CAD)), clock.now()),
    offerWithholding = ListingOfferWithholding(
      until = Auditable.Core(Optional.of(LocalDateTime(2022, 1, 15, 17, 0)), clock.now()),
    ),
  ),
  propertyDetails = ListingPropertyDetails(
    address = Address(null, "3340", "Roma Ave", "Burlington", ProvinceState.ON, "L7M0N7", Country.CA),
    propertyOwnership = PropertyOwnership.Freehold(),
    propertyTaxes = PropertyTaxes(2022, Money(BigInt(5_000), Currency.CAD), "abc123"),
    lotServices = emptyList(),
    activeMortgage = true,
  ),
  assumableContracts = ListingAssumableContracts(
    emptyList()
  ),
  fixturesExcluded = ListingFixturesExcluded(emptyList()),
  chattelsIncluded = ListingChattelsIncluded(emptyList()),
  sellerConditions = SellerConditions(
    listOf(
      Auditable.Core(
        Optional.of(
          Condition(
            OptionKey.Custom("Condition of Sale of property", Asciidoc("Condition of Sale of property")),
            Expiry.Core(2.days),
            emptyMap()
          )
        ),
        Clock.System.now(),
        null
      ),
      Auditable.Core(
        Optional.of(
          Condition(
            OptionKey.Custom("Built-in Condition 1", Asciidoc("Built-in Condition 1")),
            Expiry.Core(3.days),
            emptyMap()
          )
        ),
        Clock.System.now(),
        null
      )
    )
  ),
  bindingAgreementTerms = BindingAgreementTerms(
    Auditable.Core(Optional.of(Days(3)), clock.now()),
  ),
  marketingInformation = MarketingInformation(
    description = "A great description about a great place!",
    details = listOf(
      ListingLandingDetail("Massive fireplace", "2m high fireplace heats the home during winter."),
      ListingLandingDetail("Indoor pool", "Olympic size indoor pool for the swimmers out there."),
      ListingLandingDetail("Elevator", "Multi-generational ready"),
      ListingLandingDetail("Green rooftop", "You can grow your favorite vegetables on the rooftop."),
    ),
    thingsYouNeedToKnow = listOf(
      "21,000 square feet",
      "Helipad",
      "Basketball court"
    )
  ),
  currency = Currency.CAD,
  events = emptyList(),
)

@Suppress("MagicNumber", "LongMethod")
fun baseTestPublishedListing(clock: Clock, uid: Uid<Listing> = newUid()) = Listing.Published(
  _id = uid,
  version = 1,
  state = Listing.State.PUBLISHED,
  currency = Currency.CAD,
  propertyOwners = ListingPropertyOwners(
    contacts = listOf(
      Contact(
        name = "John Doe",
        methods = listOf(
          ContactMethod.Email(
            email = "johndoe@example.com",
            verified = Auditable.Core(Optional.of(true), clock.now()),
          ),
          ContactMethod.Sms(
            telephone = "555-111-2222",
            verified = Auditable.Core(Optional.of(true), clock.now()),
          ),
        ),
      ),
      Contact(
        name = "Jill Jones",
        methods = listOf(
          ContactMethod.Email(
            email = "jilljones@example.com",
            verified = Auditable.Core(Optional.of(true), clock.now()),
          ),
        ),
      ),
    ),
    userCertifiedLegalAuthority = Auditable.Core(Optional.of(true), clock.now()),
  ),
  permalink = "https://diyoffer.ca/test-listing-a7hg37d",
  externalLinks = ListingLinks(
    listOf(
      ListingLinks.Link(
        ListingLinks.Link.ListingProvider.FACEBOOK,
        "https://facebook.com/real-estate/xyz"
      ),
      ListingLinks.Link(
        ListingLinks.Link.ListingProvider.KIJIJI,
        "https://kijiji.ca/real-estate/xyz"
      ),
    )
  ),
  details = ListingDetails(
    price = Auditable.Core(Optional.of(Money(BigInt(500_000), Currency.CAD)), clock.now()),
    closingDate = Auditable.Core(Optional.of(LocalDate(2022, 1, 12)), clock.now()),
    deposit = Auditable.Core(Optional.of(Money(BigInt(30_000), Currency.CAD)), clock.now()),
    offerWithholding = ListingOfferWithholding(
      until = Auditable.Core(Optional.of(LocalDateTime(2022, 1, 15, 17, 0)), clock.now()),
    ),
  ),
  propertyDetails = ListingPropertyDetails(
    address = Address(null, "3340", "Roma Ave", "Burlington", ProvinceState.ON, "L7M0N7", Country.CA),
    propertyOwnership = PropertyOwnership.Freehold(),
    propertyTaxes = PropertyTaxes(2022, Money(BigInt(5_000), Currency.CAD), "abc123"),
    lotServices = emptyList(),
    activeMortgage = true,
  ),
  assumableContracts = ListingAssumableContracts(
    emptyList()
  ),
  fixturesExcluded = ListingFixturesExcluded(emptyList()),
  chattelsIncluded = ListingChattelsIncluded(emptyList()),
  sellerConditions = SellerConditions(
    listOf(
      Auditable.Core(
        Optional.of(
          Condition(
            optionKey = OptionKey.Custom(
              "Test",
              Asciidoc("*Test Condition Title* - Buyer must bla bla bla."),
            ),
            expiry = Expiry.Core(3.days),
          ),
        ),
        clock.now()
      ),
    )
  ),
  bindingAgreementTerms = BindingAgreementTerms(
    Auditable.Core(Optional.of(Days(3)), clock.now()),
  ),
  events = listOf(
    ListingPublishedEvent(timestamp = clock.now().lowRes(), 1, null, null, ListingSection.values().size),
  ),
)
