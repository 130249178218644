package com.diyoffer.negotiation.model

import com.diyoffer.negotiation.model.serdes.*
import com.soywiz.kbignum.BigInt
import com.soywiz.kbignum.BigNum
import kotlinx.datetime.Instant
import kotlinx.serialization.Contextual
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.jvm.JvmInline
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.hours

@Serializable
enum class Party(val label: String) {
  BUYER("Buyer"),
  SELLER("Seller"),
  ;

  fun counterParty() = when (this) {
    SELLER -> BUYER
    BUYER -> SELLER
  }
}

/**
 * This is used as a key to options.
 *
 * This is a sealed class rather than a sealed interface due to
 * https://github.com/Kotlin/kotlinx.serialization/issues/1417.
 *
 * Option keys can be custom (user created), dynamic (created and managed by the system, but
 * dynamically defined e.g. in a database), or predefined in the data model.
 */
@Serializable
sealed class OptionKey {
  /**
   * An id pointing to a dynamic option defined in the database.
   */
  @Serializable
  @SerialName("OptionKey.Dynamic")
  data class Dynamic(@Serializable(with = UidSerializer::class) val _id: Uid<Dynamic>) : OptionKey()

  /**
   * A predefined option key. These option keys are defined in code and have explicit behavior attached to them.
   */
  @Serializable
  sealed class Predefined : OptionKey()

  /**
   * A custom option key entered by the user.
   */
  @Serializable
  @SerialName("OptionKey.Custom")
  data class Custom(val title: String, val value: Asciidoc) : OptionKey()
}

@Serializable
enum class Currency(val places: Int = 2) {
  CAD,
  USD,
  X_TEST4(places = 4),
  ;

  /**
   * Usage example:
   * ```
   * with(Currency.CAD) {
   *   Money(15).toBigNum()
   * }
   * ```
   */
  fun Money.toBigNum() = toBigNum(this@Currency)

  /**
   * Usage example:
   * ```
   * with(Currency.CAD) {
   *   BigNum("0.15").toMoney()
   * }
   * ```
   */
  fun BigNum.toMoney() = Money(this, this@Currency)

  companion object {
    val DEFAULT = CAD
  }
}

/**
 * A typed money value -- the integer value is the number of units of money in the currency's smallest
 * unit e.g. "cents" if US dollars i.e. 100 represents $1.00. This avoids errors due to floating point
 * inaccuracies.
 *
 * Note that we do not store the currency here -- the currency is stored once at the level of a listing
 * or offer, and all money values are consistent against that currency. This eliminates the need to check
 * for currency equivalency every time a money value is used.
 *
 * [Float] and [Double]s are not safe to represent Money due to float-point inaccuracies, so we provide no
 * conversion mechanism to and from Double or Float. Use [BigNum] instead.
 */
@Serializable
@JvmInline
value class Money(val value: Int) : Comparable<Money> {
  companion object {
    val ZERO = Money(0)

    operator fun invoke(value: BigNum, currency: Currency) =
      Money(value.times(BigNum(BigInt(value = 10).pow(currency.places), scale = 0)).toBigInt().toInt())

    operator fun invoke(value: BigInt, currency: Currency) =
      Money(value.times(BigInt(value = 10).pow(currency.places)).toInt())
  }

  fun toBigNum(currency: Currency): BigNum = BigNum(BigInt(value), currency.places)

  operator fun minus(other: Money) = Money(value - other.value)

  operator fun plus(other: Money) = Money(value + other.value)

  override fun compareTo(other: Money): Int = value.compareTo(other.value)

  operator fun times(multiplier: Double) = Money((value * multiplier).toInt())

  operator fun div(denominator: Double) = Money((value / denominator).toInt())
}

/**
 * A typed percent value -- the value is a percent e.g. `20`, not a decimal e.g. `0.2`.
 */
@Serializable
@JvmInline
value class Percent(val value: Double) : Comparable<Percent> {
  companion object {
    val ZERO = Percent(0.0)
    val ONE_HUNDRED = Percent(100.0)
  }

  override fun compareTo(other: Percent): Int = value.compareTo(other.value)
}

@Serializable
@JvmInline
value class Days(val value: Int) : Comparable<Days> {
  override fun compareTo(other: Days): Int = value.compareTo(other.value)
  fun asDuration(): Duration = value.days
}

@Serializable
@JvmInline
value class Hours(val value: Int) : Comparable<Hours> {
  override fun compareTo(other: Hours): Int = value.compareTo(other.value)
  fun asDuration(): Duration = value.hours
}

/**
 * A typed asciidoc value -- the text can be parsed and converted using Asciidoctor.
 */
@Serializable
@JvmInline
value class Asciidoc(val text: String)

/**
 * A "low-resolution" [Instant] value i.e. millisecond resolution rather than micro or nano-second. This ensures
 * that all systems in the stack are able to read and write the same value without a round-trip failure in systems that
 * don't support higher resolution timestamps (e.g.
 * [MongoDB](https://docs.mongodb.com/manual/reference/bson-types/#date) and
 * [the native JavaScript Date type](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/now)).
 */
@Serializable
@JvmInline
value class InstantLr private constructor(@Contextual val instant: Instant) {
  companion object {
    operator fun invoke(instant: Instant) = InstantLr(instant.truncatedToMillis())
  }
  init {
    require(instant.truncatedToMillis() == instant)
  }

  operator fun minus(other: Duration) = InstantLr((instant - other).truncatedToMillis())

  operator fun plus(other: Duration) = InstantLr((instant + other).truncatedToMillis())

  operator fun minus(other: Instant): Duration = instant - other.lowRes().instant

  operator fun minus(other: InstantLr): Duration = instant - other.instant

  operator fun compareTo(other: InstantLr): Int = instant.compareTo(other.instant)

  operator fun compareTo(other: Instant): Int = instant.compareTo(other.lowRes().instant)
}

fun Instant.truncatedToMillis(): Instant =
  Instant.fromEpochMilliseconds(toEpochMilliseconds())

fun Instant.lowRes() = InstantLr(this)
