package services

import androidx.compose.runtime.Composable
import androidx.compose.runtime.State
import androidx.compose.runtime.produceState
import co.touchlab.kermit.Logger

/**
 * Wraps / provides types for [asciidoctor.js](https://docs.asciidoctor.org/asciidoctor.js/latest/).
 */
@JsModule("asciidoctor")
@Suppress("UnusedPrivateMember")
external class AsciidoctorJs {
  // optional convert options not yet defined, define if needed as an external interface
  // see https://docs.asciidoctor.org/asciidoctor.js/latest/processor/convert-options/
  fun convert(content: String): String

  // we could provide (partial) types for the response if we needed this for some reason
  // see http://asciidoctor.github.io/asciidoctor.js/master/#document for the return type
  fun load(content: String): dynamic
}

sealed class AsciidocConversionState
data object Converting : AsciidocConversionState()
data class Converted(val html: String) : AsciidocConversionState()
data object ConversionError : AsciidocConversionState()

class AsciidocConverter(
  private val asciidoctorJs: AsciidoctorJs,
) {
  @Composable
  fun convert(
    input: String,
  ): State<AsciidocConversionState> {
    @Suppress("TooGenericExceptionCaught")
    return produceState<AsciidocConversionState>(initialValue = Converting, input) {
      value = try {
        Converted(asciidoctorJs.convert(input))
      } catch (e: Exception) {
        Logger.e(e) { "Error converting asciidoc to HTML" }
        ConversionError
      }
    }
  }
}
