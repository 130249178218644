//
// GENERATED by KVision
//
package com.diyoffer.negotiation.rpcs

import org.w3c.fetch.RequestInit
import io.kvision.remote.KVRemoteAgent
import kotlinx.serialization.modules.SerializersModule
import com.diyoffer.negotiation.cms.PortableTextBlockList
import com.diyoffer.negotiation.model.CmsReference

actual class CmsRpcService(serializersModules: List<SerializersModule>? = null, requestFilter: (suspend RequestInit.() -> Unit)? = null) : ICmsRpcService, KVRemoteAgent<CmsRpcService>(CmsRpcServiceManager, serializersModules, requestFilter) {
    override suspend fun loadPopupHelp(cmsReference: CmsReference, language: String) = call(ICmsRpcService::loadPopupHelp, cmsReference, language)
}
