package components

import androidx.compose.runtime.Composable
import app.softwork.routingcompose.Router
import com.diyoffer.negotiation.model.client.*
import common.DarkBlueButton
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Text
import style.DiyStyleSheet

@Composable
fun ReturnButton(returnMetadata: NavButtonMetadata?) {
  val router = Router.current
  DarkBlueButton(
    attrs = {
      style {
        padding(8.px, 12.px)
        backgroundColor(DiyStyleSheet.Colors.white)
      }
      onClick {
        router.navigate(returnMetadata?.data?.route ?: "/home")
      }
    },
    noBackground = true
  ) {
    Text(if (returnMetadata?.label != null) returnMetadata.label else "Return to Dashboard")
  }
}
