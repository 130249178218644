package com.diyoffer.negotiation.repository.offer

import com.copperleaf.ballast.repository.cache.Cached
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*
import kotlinx.coroutines.flow.Flow

interface BuyerOfferRepository {
  suspend fun fetchOffer(
    offerId: Uid<Offer>,
  ): Flow<Cached<Pair<Offer, OfferContacts>>>

  suspend fun offer(): Flow<Cached<Pair<Offer, OfferContacts>>>
  suspend fun relatedListing(): Flow<Cached<ListingLoadResult.Success>>
  suspend fun saveOffer(offer: Offer): OfferSaveResult
  suspend fun publishOffer(offer: Offer): OfferSaveResult
  suspend fun publishOfferById(offerId: Uid<Offer>): OfferSaveResult
  suspend fun rejectOffer(offerId: Uid<Offer>): OfferSaveResult
  suspend fun acceptOffer(offerId: Uid<Offer>): OfferSaveResult
  suspend fun saveContacts(
    offerId: Uid<Offer>,
    contacts: OfferContacts,
    refetchOffer: Boolean = true,
  ): OfferContactsSignatureSaveResult

  suspend fun saveLegalContact(
    offerId: Uid<Offer>,
    legalContact: Contact,
  ): OfferContactsSaveResult

  suspend fun invalidateCache(refetchOffer: Boolean = false)
}
