package builders

import com.diyoffer.negotiation.model.ChattelInclusion
import com.diyoffer.negotiation.model.OptionKey

data class ChattelsIncludedBuilder(
  val optionKey: OptionKey? = null,
  val brand: String? = null,
  val year: Int? = null,
  val serialNumber: String? = null,
  val notes: String? = null,
) : IBuilder<ChattelInclusion> {
  companion object {
    fun create(item: ChattelInclusion?): IBuilder<ChattelInclusion> {
      return item?.let {
        ChattelsIncludedBuilder(
          optionKey = it.optionKey,
          brand = it.brand,
          year = it.year,
          serialNumber = it.serialNumber
        )
      } ?: ChattelsIncludedBuilder()
    }
  }

  override fun hydrate(item: ChattelInclusion?) = create(item)

  override fun build(): BuildResult<ChattelInclusion> = validateAndBuild {
    ChattelInclusion(
      optionKey = optionKey!!,
      brand = brand,
      year = year,
      serialNumber = serialNumber
    )
  }

  private fun validateAndBuild(onValid: () -> ChattelInclusion): BuildResult<ChattelInclusion> {
    val errors = mutableListOf<String>()

    if (optionKey == null) {
      errors.add("You must specify the chattel or inclusion.")
    }

    if (optionKey is OptionKey.Custom && optionKey.title.isNullOrBlank()) {
      errors.add("You must give a title to the chattel or inclusion.")
    }

    return buildResult(errors, onValid = onValid)
  }
}
