package layout

import androidx.compose.runtime.Composable
import dev.petuska.kmdcx.icons.MDCIcon
import dev.petuska.kmdcx.icons.MDCIconSpan
import dev.petuska.kmdcx.icons.MDCIconType
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import style.DiyStyleSheet
import style.DiyStyleSheet.Colors.blue

@Composable
fun InformationBar(text: String, icon: MDCIcon = MDCIcon.Info, color: CSSColorValue = blue) {
  InformationBar(icon, color) {
    Text(text)
  }
}

@Composable
fun InformationBar(icon: MDCIcon = MDCIcon.Info, color: CSSColorValue = blue, content: @Composable () -> Unit) {
  Div({ classes(DiyStyleSheet.informationContainer) }) {
    MDCIconSpan(
      icon = icon,
      type = MDCIconType.Outlined,
      attrs = {
        style {
          fontSize(16.px)
          color(color)
        }
      }
    )
    Div({
      style {
        color(Color.darkgray)
        marginLeft(5.px)
      }
    }) {
      content()
    }
  }
}
