package common

import androidx.compose.runtime.Composable
import core.Builder
import dev.petuska.kmdc.core.AttrsBuilder
import model.FormValue
import org.w3c.dom.HTMLInputElement

@Composable
fun NumberField(
  opts: Builder<ValidatedFormValueInputOptions<Int>>,
  attrs: AttrsBuilder<HTMLInputElement>? = null,
) {
  val options = ValidatedFormValueInputOptions<Int>().apply { opts(this) }
  TypedTextField<Int>(opts = {
    label = options.label
    value = options.value
    disabled = options.disabled
    onBlur = {
      options.onModified(if (it is FormValue.Valid) it.value else null)
      options.onBlur(it)
    }
    toType = { it?.toIntOrNull() }
    toFormatted = { it?.toString() ?: "" }
    toPlain = { it?.toString() ?: "" }
  }, attrs = attrs)
}
