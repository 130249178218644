package com.diyoffer.negotiation.common

import com.soywiz.kbignum.BigIntException
import com.soywiz.kbignum.bn

/**
 * Convert a String to a [BigNum], or null if it cannot be converted to a [BigNum].
 *
 * We explicitly test for an empty String because of https://github.com/korlibs/kbignum/issues/13.
 */
@Suppress("SwallowedException")
fun String.bnOrNull() = try {
  if (this.isEmpty()) null else this.bn
} catch (e: BigIntException) {
  null
} catch (e: NumberFormatException) {
  null
}
