package components.checklist

import androidx.compose.runtime.Composable
import com.diyoffer.negotiation.model.*
import dev.petuska.kmdc.core.AttrsBuilder
import dev.petuska.kmdcx.icons.MDCIcon
import dev.petuska.kmdcx.icons.MDCIconSpan
import dev.petuska.kmdcx.icons.MDCIconType
import org.w3c.dom.HTMLSpanElement
import style.DiyStyleSheet
import style.DiyStyleSheet.onGreen
import style.DiyStyleSheet.onLightRed
import style.DiyStyleSheet.onLightYellow

@Composable
fun ChecklistStatus.icon(attr: AttrsBuilder<HTMLSpanElement>? = null) {
  val (icon, style) = when (this) {
    ChecklistStatus.COMPLETE -> MDCIcon.CheckCircle to DiyStyleSheet.TwoToneColorFilters.GREEN
    ChecklistStatus.PENDING_OTHER -> MDCIcon.Announcement to DiyStyleSheet.TwoToneColorFilters.GREY
    ChecklistStatus.PENDING_SELF -> MDCIcon.Announcement to DiyStyleSheet.TwoToneColorFilters.YELLOW
    ChecklistStatus.NEGATED -> MDCIcon.ThumbDown to DiyStyleSheet.TwoToneColorFilters.RED
  }

  MDCIconSpan(icon, MDCIconType.TwoTone) {
    classes(style.styleClass)
    attr?.invoke(this)
  }
}

fun ChecklistAction.toClassName() = when (this) {
  is ChecklistAction.CancelOffer -> onLightRed
  is ChecklistAction.ReviewListing,
  is ChecklistAction.ContinueOffer,
  is ChecklistAction.ReviewOffer,
  is ChecklistAction.ViewOffer,
  -> onLightYellow

  else -> onGreen
}
