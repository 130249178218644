package com.diyoffer.negotiation.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient

@Serializable
data class Contact(
  val name: String,
  val methods: List<ContactMethod>,
)

@Serializable
sealed class ContactMethod {
  abstract val verified: Auditable<Boolean>
  abstract val methodKey: String

  @Serializable
  @SerialName("Email")
  data class Email(
    val email: String,
    override val verified: Auditable<Boolean>,
  ) : ContactMethod() {
    @Transient override val methodKey = email
  }

  @Serializable
  @SerialName("SMS")
  data class Sms(
    val telephone: String,
    override val verified: Auditable<Boolean>,
  ) : ContactMethod() {
    @Transient override val methodKey = telephone
  }
}

fun Contact.emailMethods(onlyVerified: Boolean = false) = methods
  .filterIsInstance<ContactMethod.Email>()
  .filter { if (onlyVerified) it.verified.bool() else true }

fun Contact.anyVerifiedEmailMethods() = emailMethods().any { it.verified.bool() }

fun Contact.emails(onlyVerified: Boolean = false) = emailMethods(onlyVerified).map { it.email }

fun Contact.firstEmailOrNull(onlyVerified: Boolean = false) = emailMethods(onlyVerified).firstOrNull()?.email

fun List<Contact>.findMatchingByNameAndMethod(contact: Contact, method: ContactMethod): Contact? =
  singleOrNull { c ->
    c.name == contact.name && c.methods.any { it.equalsIgnoringVerified(method) }
  }

fun List<Contact>.anyVerified(): Boolean =
  flatMap { it.methods }.any { it.verified.bool() }

fun List<Contact>.anyVerifiedEmail(): Boolean =
  emailMethods().any { it.verified.bool() }

fun List<Contact>.allVerified(): Boolean =
  flatMap { it.methods }.all { it.verified.bool() }

fun List<Contact>.mapByMethod(): Map<ContactMethod, Contact> =
  flatMap { c -> c.methods.map { it to c } }.toMap()

inline fun <reified R : ContactMethod> List<Contact>.filterMethodsOf(): List<R> =
  flatMap { c -> c.methods.filterIsInstance<R>() }

inline fun <reified R : ContactMethod> List<Contact>.contactMethodsOf(): List<Pair<R, Contact>> =
  flatMap { c -> c.methods.filterIsInstance<R>().map { it to c } }

fun List<Contact>.mapByMethodKeys(): Map<String, Pair<Contact, ContactMethod>> =
  mapByMethod().map { (k, v) -> k.methodKey to (v to k) }.toMap()

fun List<Contact>.emailMethods() = filterMethodsOf<ContactMethod.Email>()

fun List<Contact>.smsMethods() = filterMethodsOf<ContactMethod.Sms>()

fun List<Contact>.allEmails(): List<String> = emailMethods().map { it.email }

fun List<Contact>.allSms(): List<String> = smsMethods().map { it.telephone }

fun List<Contact>.contactSummary(): String {
  require(isNotEmpty()) { "Must be at least one contact" }
  return when (size) {
    1 -> single().name
    else -> "${dropLast(1).joinToString(", ") { it.name }}${if (size > 2) "," else ""} and ${last().name}"
  }
}

fun ContactMethod.equalsIgnoringVerified(other: ContactMethod): Boolean = when (this) {
  is ContactMethod.Email -> other is ContactMethod.Email && other.email == email
  is ContactMethod.Sms -> other is ContactMethod.Sms && other.telephone == telephone
}

fun ContactMethod.withVerified(auditable: Auditable<Boolean>): ContactMethod = when (this) {
  is ContactMethod.Email -> copy(verified = auditable)
  is ContactMethod.Sms -> copy(verified = auditable)
}

typealias ContactReader<T> = T.() -> Contact?

typealias ContactWriter<T> = T.(Contact?) -> T

data class ContactLens<T>(
  val reader: ContactReader<T>,
  val writer: ContactWriter<T>,
)

typealias ContactListReader<T> = T.() -> List<Contact>?

typealias ContactListWriter<T> = T.(Int, Contact) -> T

data class ContactListLens<T>(
  val reader: ContactListReader<T>,
  val writer: ContactListWriter<T>,
)
