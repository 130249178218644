package vm.login

import androidx.compose.runtime.NoLiveLiterals
import co.touchlab.kermit.Logger
import com.copperleaf.ballast.BallastViewModelConfiguration
import com.copperleaf.ballast.core.BasicViewModel
import com.diyoffer.negotiation.auth.FirebaseAuth
import com.diyoffer.negotiation.model.auth.*
import com.diyoffer.negotiation.model.client.*
import com.diyoffer.negotiation.ui.login.SignInScreenContract.Events
import com.diyoffer.negotiation.ui.login.SignInScreenContract.Inputs
import com.diyoffer.negotiation.ui.login.SignInScreenContract.State
import com.diyoffer.negotiation.ui.login.SignInScreenEventHandler
import com.diyoffer.negotiation.ui.login.code
import dev.gitlive.firebase.auth.AuthCredential
import dev.gitlive.firebase.auth.FirebaseAuthException
import dev.gitlive.firebase.firebase.FirebaseError
import dev.gitlive.firebase.firebase.auth.AuthProvider
import dev.gitlive.firebase.firebase.auth.FacebookAuthProvider
import dev.gitlive.firebase.firebase.auth.GoogleAuthProvider
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.await
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.encodeToDynamic

typealias SignInViewModelConfiguration = BallastViewModelConfiguration<Inputs, Events, State>

class SignInViewModel(
  viewModelCoroutineScope: CoroutineScope,
  config: SignInViewModelConfiguration,
  eventHandler: SignInScreenEventHandler,
  val firebaseAuth: FirebaseAuth,
) : BasicViewModel<Inputs, Events, State>(
  config = config,
  eventHandler = eventHandler,
  coroutineScope = viewModelCoroutineScope,
) {
  /**
   * Requests auth credentials from a social provider. This logic is not in common main because we don't
   * have access to `signInWithPopup` in common main -- this is a web-specific function.
   */
  suspend fun obtainSocialAuthCredentialsAndTriggerSignIn(
    providerId: ProviderId,
    createUser: Boolean,
    loginHint: String?,
  ) {
    sendAndAwaitCompletion(if (createUser) Inputs.SocialSignUpStarted else Inputs.SignInStarted)
    val provider = providerOf(providerId, if (loginHint != null) mapOf("login_hint" to loginHint) else null)
    doObtainSocialAuthCredentialsAndTriggerSignIn(providerId, provider)
  }

  private suspend fun doObtainSocialAuthCredentialsAndTriggerSignIn(
    providerId: ProviderId,
    provider: AuthProvider,
  ) {
    @Suppress("TooGenericExceptionCaught")
    try {
      // when the sign-in happens, the UserSignedIn will happen asynchronously, set up the state in advance
      firebaseAuth.auth.js.signInWithPopup(provider).await()
    } catch (e: Throwable) {
      // the type of e here is FirebaseError, but FirebaseError is an interface type, not an exception type so we
      //  can't catch it directly
      @Suppress("UNCHECKED_CAST_TO_EXTERNAL_INTERFACE")
      val authException = (e as? FirebaseError)?.let { FirebaseAuthException(it.code, e) } ?: FirebaseAuthException(
        null,
        e
      )
      when (val code = authException.code()) {
        "auth/account-exists-with-different-credential" -> {
          // Google is considered the "owner" of @gmail.com accounts, if the user tries to login to an alternate
          // provider like Facebook with the same email address, Firebase will throw this error -- we attempt to
          // use Google to sign-in and then link the accounts
          // see https://github.com/firebase/firebase-ios-sdk/issues/5344#issuecomment-618518918
          // see https://groups.google.com/g/firebase-talk/c/ms_NVQem_Cw/m/8g7BFk1IAAAJ
          // see https://firebase.google.com/docs/auth/web/facebook-login#handling-account-exists-with-different-credential-errors
          val email = e.asDynamic()?.email
          val credential = e.asDynamic()?.credential

          if (email == null || credential == null) {
            send(Inputs.ObtainSocialAuthCredentialsError(authException))
            return
          }

          val currentProviders = firebaseAuth.auth.fetchSignInMethodsForEmail(email)
            .map { ProviderId.fromProviderKey(it) }.filterNot { it == providerId }

          if (currentProviders.isEmpty()) {
            send(Inputs.ObtainSocialAuthCredentialsError(authException))
          } else {
            val currentProviderId = currentProviders.first()
            val linkCredentials = LinkCredentials(
              providerId = providerId,
              currentProviderId = currentProviderId,
              credential = AuthCredential(credential),
              loginHint = email,
            )
            send(Inputs.AlternateCredentialsRequiredError(linkCredentials))
          }
        }
        else -> {
          Logger.d(e) { "Error obtaining social credentials: $code" }
          send(Inputs.ObtainSocialAuthCredentialsError(authException))
        }
      }
    }
  }

  @NoLiveLiterals
  private fun providerOf(providerId: ProviderId, customParameters: Map<String, String>? = null) = when (providerId) {
    ProviderId.GOOGLE -> GoogleAuthProvider().apply {
      customParameters?.let {
        asDynamic().setCustomParameters(Json.encodeToDynamic(customParameters))
      }
    }
    ProviderId.FACEBOOK -> FacebookAuthProvider().apply {
      asDynamic().addScope("email")
      asDynamic().addScope("public_profile")
    }

    else -> throw IllegalArgumentException("Only Google and Facebook are currently supported")
  }
}
