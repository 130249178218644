package com.diyoffer.negotiation.model.serdes

import com.diyoffer.negotiation.model.*
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.polymorphic
import kotlinx.serialization.modules.subclass

val OptionalSerializationModule: SerializersModule by lazy {
  SerializersModule {
    polymorphic(Any::class) {
      subclass(Condition.serializer())
      subclass(OptionKey.Custom.serializer())
    }
  }
}
