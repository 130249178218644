package com.diyoffer.negotiation.services

import com.diyoffer.negotiation.model.*
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.http.*
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.LocalDate
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json

class DateService {
  val cache = mutableMapOf<String, HolidayProvince>()

  suspend fun isBusinessDay(date: LocalDate, province: String = "ON"): Boolean {
    if (date.dayOfWeek == DayOfWeek.SATURDAY || date.dayOfWeek == DayOfWeek.SUNDAY) return false
    val key = HolidayCacheKey(province, date.year)
    val provinceHolidays = if (cache.containsKey(key.toString())) {
      cache[key.toString()]
    } else {
      val client = HttpClient()
      val response = client.get("https://canada-holidays.ca/api/v1/provinces/$province?year=${date.year}") {
        headers { append(HttpHeaders.ContentType, "application/json") }
      }.body<String>()
      Json.decodeFromString<Holidays>(response).province.also {
        cache[key.toString()] = it
      }
    }
    return provinceHolidays?.holidays?.none { it.date == date } ?: true
  }
}

data class HolidayCacheKey(
  val province: String,
  val year: Int,
) {
  override fun toString() = "$year-$province"
}
