package com.diyoffer.negotiation.ui.checklist

import com.copperleaf.ballast.InputHandler
import com.copperleaf.ballast.InputHandlerScope
import com.copperleaf.ballast.observeFlows
import com.copperleaf.ballast.postInput
import com.copperleaf.ballast.repository.cache.getCachedOrEmptyList
import com.copperleaf.ballast.repository.cache.getCachedOrNull
import com.copperleaf.ballast.repository.cache.map
import com.diyoffer.negotiation.common.EMAIL_VALIDATION_REGEX
import com.diyoffer.negotiation.common.updateWhen
import com.diyoffer.negotiation.messages.CommonMessages
import com.diyoffer.negotiation.messages.InfoPopup
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.repository.checklist.ChecklistRepository
import com.diyoffer.negotiation.repository.offer.BuyerOfferRepository
import com.diyoffer.negotiation.repository.offer.SellerOfferRepository
import com.diyoffer.negotiation.services.runRpc
import com.diyoffer.negotiation.services.tryRpc
import com.diyoffer.negotiation.ui.checklist.ChecklistTabContract.Events
import com.diyoffer.negotiation.ui.checklist.ChecklistTabContract.Inputs
import com.diyoffer.negotiation.ui.checklist.ChecklistTabContract.State
import com.diyoffer.negotiation.ui.checklist.ChecklistUI.OfferChecklistUI
import kotlinx.coroutines.flow.map
import kotlinx.datetime.Clock

typealias ChecklistTabInputHandlerScope = InputHandlerScope<Inputs, Events, State>

class ChecklistTabInputHandler(
  private val clock: Clock,
  private val checklistRepo: ChecklistRepository,
  private val buyerOfferRepo: BuyerOfferRepository,
  private val sellerOfferRepo: SellerOfferRepository,
) : InputHandler<Inputs, Events, State> {
  @Suppress("ComplexMethod", "LongMethod")
  override suspend fun ChecklistTabInputHandlerScope.handleInput(input: Inputs) = when (input) {
    is Inputs.InitializeForSeller -> {
      if (!getCurrentState().initialized) {
        updateState { it.copy(initialized = true, party = Party.SELLER) }
        observeFlows(
          "ChecklistTabInputHandlerSeller",
          checklistRepo.sellerListingChecklists().map { Inputs.ListingChecklistsUpdated(it) },
          checklistRepo.sellerOfferChecklists().map { Inputs.OfferChecklistsUpdated(it) },
          buyerOfferRepo.offer().map { Inputs.OfferUpdated(it) },
        )
      } else {
        noOp()
      }
    }

    is Inputs.InitializeForBuyer -> {
      if (!getCurrentState().initialized) {
        updateState { it.copy(initialized = true, party = Party.BUYER) }
        observeFlows(
          "ChecklistTabInputHandlerBuyer",
          checklistRepo.buyerChecklists(input.offerId).map {
            Inputs.OfferChecklistsUpdated(it)
          }
        )
      } else {
        noOp()
      }
    }

    is Inputs.ListingChecklistsUpdated -> {
      updateState {
        it.copy(listingChecklists = input.checklists)
      }
    }

    is Inputs.OfferChecklistsUpdated -> {
      val s = getCurrentState()
      val completedForBuyer = s.party == Party.BUYER && s.offer.getCachedOrNull()?.first?.state == Offer.State.COMPLETED

      updateState {
        it.copy(
          offerChecklists = input.checklists,
          showSuccessImage = completedForBuyer,
        )
      }
    }

    is Inputs.OfferUpdated -> updateState { state ->
      state.copy(
        offer = input.offer,
      )
    }

    is Inputs.ActionClicked -> when (val action = input.action) {
      is ChecklistAction.ReviewListing -> navigate("/listing/edit/${action.listingUid}")
      is ChecklistAction.ViewOffer -> navigate("/offer/edit/${action.offerUid}")
      is ChecklistAction.ContinueOffer -> navigate("/offer/edit/${action.offerUid}")
      is ChecklistAction.ReviewOffer -> navigate("/offer/edit/${action.offerUid}")
      // for now submit here is really "Review and Submit" -- we don't have proper error handling on the checklist
      //  view for doing submit directly from here
      is ChecklistAction.SubmitOffer -> navigate("/offer/edit/${action.offerUid}")

      is ChecklistAction.CancelOffer -> handleRpc {
        val s = getCurrentState()
        sellerOfferRepo.cancelOffer(action.offerUid, s.reason!!).also {
          if (it is OfferSaveResult.Success) {
            navigate("/home/offers")
          } else {
            postInput(Inputs.SetError(it.message))
          }
        }
      }

      is ChecklistAction.CompleteOffer -> handleRpc {
        sellerOfferRepo.completeOffer(action.offerUid).also {
          if (it is OfferSaveResult.Success) {
            navigate("/home/offers/#popup=${InfoPopup.SELLER_OFFER_COMPLETED}")
          } else {
            postInput(Inputs.SetError(it.message))
          }
        }
      }

      is ChecklistAction.LegalContactForm -> updateLegalContact(action.offerUid)
      is ChecklistAction.TriggerConditionEscapeClause -> TODO(
        "Trigger condition escape clause not yet implemented"
      )
    }

    is Inputs.UpdateLegalContact -> updateState { state ->
      state.copy(
        offerChecklists = state.offerChecklists.map { checklists ->
          checklists.updateWhen({ it.offerId == input.offerId }) {
            it.copy(
              legalEmail = input.email,
              legalName = input.name,
              error = null
            )
          }
        }
      )
    }

    is Inputs.SetReason -> updateState { it.copy(reason = input.reason) }

    is Inputs.SetError -> updateState { state ->
      if (input.offerId == null) {
        state.copy(error = input.error)
      } else {
        state.copy(
          offerChecklists = state.offerChecklists.map { checklists ->
            checklists.updateWhen({ it.offerId == input.offerId }) {
              it.copy(error = input.error)
            }
          }
        )
      }
    }
  }

  private suspend fun ChecklistTabInputHandlerScope.navigate(url: String) = postEvent(Events.OnNavigate(url))

  private suspend fun <T> ChecklistTabInputHandlerScope.handleRpc(block: suspend () -> IRpcResult<T>) =
    runRpc(
      onError = { updateState { state -> state.copy(error = it) } },
      block = block
    )

  private suspend fun ChecklistTabInputHandlerScope.updateLegalContact(offerId: Uid<Offer>) {
    val s = getCurrentState()
    val offerChecklistUI = s.offerChecklists.getCachedOrEmptyList().firstOrNull { it.offerId == offerId } ?: return

    val error = if (offerChecklistUI.legalEmail.isNullOrBlank() ||
      !EMAIL_VALIDATION_REGEX.matches(offerChecklistUI.legalEmail)
    ) {
      "The legal/agent contact email is invalid"
    } else if (offerChecklistUI.legalName.isNullOrBlank()) {
      "You must enter a name for your legal/agent contact"
    } else {
      null
    }
    if (error != null) {
      postInput(Inputs.SetError(error, offerId))
    } else {
      tryRpc(onException = { _, e ->
        postInput(
          Inputs.SetError(
            "There has been a problem saving the contacts. ${CommonMessages.contactAdministrator(e)}"
          )
        )
      }) {
        handleRpc {
          checklistRepo.saveLegalContact(offerId, offerChecklistUI.getLegalContact(), s.party!!)
        }
      }
    }
  }

  private fun OfferChecklistUI.getLegalContact() = Contact(
    name = legalName!!,
    methods = listOf(
      ContactMethod.Email(
        email = legalEmail!!,
        verified = Auditable.Core(Optional.of(false), clock.now())
      )
    )
  )
}
