package com.diyoffer.negotiation.repository.offer

import com.copperleaf.ballast.repository.cache.Cached
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*

object BuyerOfferRepositoryContract {
  data class State(
    val initialized: Boolean = false,
    val cacheInvalidated: Boolean = false,
    val offer: Cached<Pair<Offer, OfferContacts>> = Cached.NotLoaded(),
    val relatedListing: Cached<ListingLoadResult.Success> = Cached.NotLoaded(),
    val user: SessionUser? = null,
  )

  sealed class Inputs {
    data object Initialize : Inputs()
    data class InvalidateCache(val fetchOffer: Boolean = true) : Inputs()
    data class OfferFetchRequest(val offerId: Uid<Offer>) : Inputs()
    data class OfferFetched(val offer: Cached<Pair<Offer, OfferContacts>>) : Inputs()
    data class RelatedListingFetched(val listing: Cached<ListingLoadResult.Success>) : Inputs()
    data class UserUpdated(val user: SessionUser?) : Inputs()
  }
}
