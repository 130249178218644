package components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.SideEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import builders.BuildResult
import builders.IBuilder
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.repository.user.UserRepository
import common.ActionButton
import common.Button
import common.FlexRow
import common.HelpChip
import common.NumberCircled
import common.ValidationResult
import core.Builder
import core.DisplayContent
import core.EditContent
import dev.petuska.kmdcx.icons.MDCIcon
import dev.petuska.kmdcx.icons.MDCIconSpan
import dev.petuska.kmdcx.icons.MDCIconType
import js.scrollTo
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.margin
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H4
import org.jetbrains.compose.web.dom.Text
import org.kodein.di.compose.rememberInstance
import style.DiyStyleSheet
import style.DiyStyleSheet.Sizes.paddingSm
import style.DiyStyleSheet.onLightGrey

enum class DetailStatus {
  NEW,
  COMPLETED,
  RESPONDED_COUNTERED,
  RESPONDED_REJECTED,
  PENDING_COUNTERED,
  PENDING_REJECTED,
  FAILURE,
}

@Suppress("ComplexMethod", "LongMethod")
@Composable
fun <T> DetailContainer(
  itemBuilder: IBuilder<T>,
  opts: Builder<DetailContainerDisplayOps<T>>,
  displayContent: DisplayContent<T>,
  editContent: EditContent<T>,
) {
  val options = DetailContainerDisplayOps<T>().apply { opts.invoke(this) }
  val (builder, setBuilder) = remember(options.value, itemBuilder) {
    mutableStateOf(itemBuilder.hydrate(options.value))
  }
  val (state, setState) = remember { mutableStateOf(DetailContainerState.DISPLAY) }
  val (scrollTo, setScrollTo) = remember { mutableStateOf<String?>(null) }

  val userRepo by rememberInstance<UserRepository>()
  val user by userRepo.getUser().collectAsState()

  val buildResult = builder.build()

  LaunchedEffect(options.expanded, options.canExpand, options.value, user) {
    setState(
      when {
        options.expanded && options.canExpand -> DetailContainerState.EDIT
        options.value != null -> DetailContainerState.DISPLAY
        !options.canExpand -> DetailContainerState.DISPLAY
        else -> DetailContainerState.NEW
      }
    )
  }

  SideEffect {
    // Apply as a SideEffect to ensure we scroll when recomposition is done and after section has collapsed
    if (scrollTo != null && !options.expanded) {
      scrollTo(scrollTo)
      setScrollTo(null)
    }
  }

  Div({
    id("section-${options.sectionNumber}")
    classes(DiyStyleSheet.container, DiyStyleSheet.lightBorder)
    if (options.canExpand && state == DetailContainerState.DISPLAY) {
      onClick {
        setState(DetailContainerState.EDIT)
        options.onExpand?.let { it(true) }
      }
      style { cursor("pointer") }
    }
  }) {
    FlexRow({
      style {
        marginBottom(if (state == DetailContainerState.NEW) 0.px else paddingSm)
        justifyContent(JustifyContent.SpaceBetween)
      }
    }) {
      FlexRow({
        style {
          margin(0.px)
          gap(paddingSm)
        }
      }) {
        NumberCircled(options.sectionNumber)
        H4({ style { margin(0.px) } }) {
          Text(options.title)
        }
        options.popupRef?.let { HelpChip(it) }
      }

      val detailStatus = when {
        options.status != null -> options.status
        options.value == null -> DetailStatus.NEW
        else -> user?.party?.let { party -> buildResult.detailStatus(party) }
      }

      val statusIcon = when (detailStatus) {
        DetailStatus.NEW -> MDCIcon.Pending to DiyStyleSheet.TwoToneColorFilters.GREY
        DetailStatus.COMPLETED -> MDCIcon.CheckCircle to DiyStyleSheet.TwoToneColorFilters.GREEN
        DetailStatus.FAILURE -> MDCIcon.Warning to DiyStyleSheet.TwoToneColorFilters.RED
        DetailStatus.RESPONDED_COUNTERED -> MDCIcon.CheckCircle to DiyStyleSheet.TwoToneColorFilters.YELLOW
        DetailStatus.RESPONDED_REJECTED -> MDCIcon.CheckCircle to DiyStyleSheet.TwoToneColorFilters.RED
        DetailStatus.PENDING_COUNTERED -> MDCIcon.Warning to DiyStyleSheet.TwoToneColorFilters.YELLOW
        DetailStatus.PENDING_REJECTED -> MDCIcon.Warning to DiyStyleSheet.TwoToneColorFilters.RED
        null -> null
      }

      statusIcon?.let {
        MDCIconSpan(
          icon = it.first,
          type = MDCIconType.TwoTone,
        ) {
          classes(statusIcon.second.styleClass)
          style {
            width(20.px)
            height(20.px)
            property("margin-left", "auto")
          }
        }
      }
    }

    Div({
      style { marginTop(24.px) }
    }) {
      when (state) {
        DetailContainerState.NEW, DetailContainerState.EDIT -> {
          DetailContainerEdit(
            builder = builder,
            setBuilder = setBuilder,
            buildResult = buildResult,
            saveLabel = options.saveLabel,
            onSave = {
              setState(DetailContainerState.DISPLAY)
              setBuilder(builder.hydrate(it))
              options.onSave?.invoke(it)
              setScrollTo("section-${options.sectionNumber + 1}")
            },
            onCancel = {
              // Reset state to original value provided
              setState(DetailContainerState.DISPLAY)
              setBuilder(builder.hydrate(options.value))
              options.onCancel?.invoke()
            },
            editContent = editContent
          )
        }

        DetailContainerState.DISPLAY -> options.value?.let { displayContent(this, it) }
      }
    }
  }
}

@Suppress("LongParameterList")
@Composable
fun <T> DetailContainerEdit(
  builder: IBuilder<T>,
  setBuilder: (IBuilder<T>) -> Unit,
  buildResult: BuildResult<T>,
  saveLabel: String,
  showWarnings: Boolean = true,
  onSave: (T?) -> Unit,
  onCancel: () -> Unit,
  editContent: EditContent<T>,
) {
  val (showErrors, setShowErrors) = remember { mutableStateOf(false) }

  Div({ style { marginTop(paddingSm) } }) {
    editContent(this, builder, setBuilder)
  }

  ValidationResult(buildResult, showWarnings = showWarnings, showErrors = showErrors)

  FlexRow({
    style {
      margin(20.px, 0.px, 0.px, 0.px)
      justifyContent(JustifyContent.Center)
    }
  }) {
    ActionButton(
      disabled = showErrors && buildResult is BuildResult.Failure,
      attrs = {
        onClick {
          setShowErrors(true)
          if (buildResult is BuildResult.Success) onSave(buildResult.result)
        }
      }
    ) {
      Text(saveLabel)
    }
    Button(attrs = {
      classes(onLightGrey)
      onClick { onCancel() }
    }) { Text("Cancel") }
  }
}

data class DetailContainerDisplayOps<T>(
  var value: T? = null,
  var title: String = "Not set",
  var sectionNumber: Int = 0,
  var popupRef: String? = null,
  var expanded: Boolean = false,
  var saveLabel: String = "Save",
  var status: DetailStatus? = null,
  // A case where this would be false is a seller reviewing the Name of Buyer section for instance.
  // Seller cannot modify the Buyer contact information so section remain collapsed
  var canExpand: Boolean = true,
  // Bubble up expand event in case upstream component uses the state
  var onExpand: ((Boolean) -> Unit)? = null,
  var onSave: ((T?) -> Unit)? = null,
  var onCancel: (() -> Unit)? = null,
)

enum class DetailContainerState {
  // Expanded, this has not been filled yet
  NEW,

  // Expanded, user can modify all fields
  EDIT,

  // Collapsed state with summary information
  DISPLAY,
}

// / Private

@Suppress("ComplexMethod")
private fun <T> BuildResult<T>.detailStatus(
  currentParty: Party,
): DetailStatus = if (negotiationStates.isNullOrEmpty()) {
  when (this) {
    is BuildResult.Success -> DetailStatus.COMPLETED
    else -> DetailStatus.FAILURE
  }
} else {
  val states = negotiationStates!!
  // We have a history of negotiation. There are two dimensions: 1) each terms has been replied by current party, and
  // 2) 'worst' state of the build result (rejected, countered, agreed). Each have its own DetailStatus
  val hasReject = states.any { it.second == NegotiatedTerm.State.REJECTED }
  val hasCounter = states.any { it.second == NegotiatedTerm.State.COUNTERED }
  val allAgreed = states.all { it.second.isAgreed }
  val isNewToUs = states.any { it.second == NegotiatedTerm.State.NEW && it.first != currentParty }
  val allFilledByCurrentParty = states.all { it.first == currentParty }

  when {
    states.isEmpty() || isNewToUs -> DetailStatus.NEW
    allAgreed -> DetailStatus.COMPLETED
    allFilledByCurrentParty && hasReject -> DetailStatus.RESPONDED_REJECTED
    allFilledByCurrentParty && hasCounter -> DetailStatus.RESPONDED_COUNTERED
    !allFilledByCurrentParty && hasReject -> DetailStatus.PENDING_REJECTED
    !allFilledByCurrentParty && hasCounter -> DetailStatus.PENDING_COUNTERED
    else -> when (this) {
      is BuildResult.Success -> DetailStatus.COMPLETED
      else -> DetailStatus.FAILURE
    }
  }
}
