package com.diyoffer.negotiation.model.rpcs

import com.diyoffer.negotiation.model.*
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
sealed class OfferSaveResult : IRpcResult<Offer> {
  @Serializable
  @SerialName("s")
  data class Success(val offer: Offer) : OfferSaveResult()

  @Serializable
  @SerialName("io")
  data class InvalidOffer(val invalidFields: List<InvalidOfferField>) : OfferSaveResult()

  @Serializable
  @SerialName("isc")
  data class InvalidStateChange(val error: String) : OfferSaveResult()

  @Serializable
  @SerialName("nf")
  data object NotFound : OfferSaveResult()

  @Serializable
  @SerialName("lnf")
  data object ListingNotFound : OfferSaveResult()

  @Serializable
  @SerialName("ls")
  class ListingInvalidState(val state: Listing.State) : OfferSaveResult()

  @Serializable
  @SerialName("nc")
  data object NoChange : OfferSaveResult()

  @Serializable
  @SerialName("cm")
  data object ConcurrentModification : OfferSaveResult()

  @Serializable
  @SerialName("ua")
  data object Unauthenticated : OfferSaveResult()

  override val value: Offer?
    get() = (this as? Success)?.offer

  override val message: String
    get() = when (this) {
      is Success -> "Success"
      is ConcurrentModification -> "Concurrent modification"
      is NoChange -> "No change to offer since last save"
      is NotFound -> "Not found (existing offer version > 0, not in db)"
      is ListingNotFound -> "Listing is not found"
      is ListingInvalidState -> "Changes cannot be made while there are active offers"
      is InvalidOffer -> "Invalid offer, fields: $invalidFields"
      is Unauthenticated -> "User is unauthorized to save this offer"
      is InvalidStateChange -> "State Change Error: $error"
    }
}
