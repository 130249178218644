package common

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.diyoffer.negotiation.model.*
import components.BlockContent
import dev.petuska.kmdcx.icons.MDCIcon
import dev.petuska.kmdcx.icons.MDCIconSpan
import dev.petuska.kmdcx.icons.MDCIconType
import org.jetbrains.compose.web.css.CSSNumeric
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.lineHeight
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.kodein.di.compose.rememberInstance
import org.w3c.dom.HTMLElement
import services.PopupLoader
import services.RichTextState
import style.DiyStyleSheet

@Composable
fun NumberCircled(number: Int) {
  Div({
    style {
      borderRadius(50.percent)
      width(20.px)
      height(20.px)
      color(DiyStyleSheet.Colors.green)
      backgroundColor(DiyStyleSheet.Colors.lightGreen)
      textAlign("center")
      fontSize(10.px)
      fontWeight(value = 900)
      lineHeight(20.px)
    }
  }) {
    Text(number.toString())
  }
}

@Composable
fun HelpChip(
  popupRef: String,
  size: CSSNumeric = 18.px,
  title: String? = null,
  titleIcon: MDCIcon? = null,
) {
  val popupLoader by rememberInstance<PopupLoader>()
  val (popupRequested, setPopupRequested) = remember { mutableStateOf(false) }
  val (loading, setLoading) = remember { mutableStateOf(false) }
  val (popupContent, setPopupContent) = remember { mutableStateOf<(@Composable () -> Unit)?>(null) }

  if (popupRequested) {
    val richTextState by popupLoader.load(CmsReference(CmsFieldType.POPUP_HELP, popupRef))
    when (val value = richTextState) {
      RichTextState.Loading -> setLoading(true)
      is RichTextState.Error -> {
        setLoading(false)
        setPopupRequested(false)
        setPopupContent {
          Span { Text(value.message) }
        }
      }
      is RichTextState.Success -> {
        setLoading(false)
        setPopupRequested(false)
        setPopupContent {
          if (value.blocks.isEmpty()) {
            Span { Text("Sorry, we don't have any additional information for this item.") }
          } else {
            BlockContent(value.blocks)
          }
        }
      }
    }
  }

  MDCIconSpan(
    type = MDCIconType.TwoTone,
    icon = if (!loading) MDCIcon.HelpOutline else MDCIcon.Loop,
    attrs = {
      classes(DiyStyleSheet.TwoToneColorFilters.BLUE.styleClass)
      style {
        fontSize(size)
        cursor("pointer")
      }
      onClick {
        it.stopPropagation()
        setPopupRequested(true)
      }
    },
  )

  Popup(
    title = title,
    titleIcon = titleIcon,
    opened = popupContent != null,
    onDismiss = { setPopupContent(null) }
  ) {
    popupContent?.invoke()
  }
}

@Composable
fun InfoChip(attrs: AttrBuilderContext<out HTMLElement>? = null) {
  MDCIconSpan(
    type = MDCIconType.TwoTone,
    icon = MDCIcon.Info,
  ) {
    classes(DiyStyleSheet.TwoToneColorFilters.BLUE.styleClass)
    style { fontSize(22.px) }
    attrs?.invoke(this)
  }
}
