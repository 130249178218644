@file:Suppress("Filename", "MatchingDeclarationName")

package com.diyoffer.negotiation.monitoring.sentry

internal actual object SentryBridge {
  actual fun captureMessage(message: String): SentryId {
    val jsSentryId = SentryJs.captureMessage(message)
    return SentryId(jsSentryId as String)
  }

  actual fun captureMessage(message: String, scopeCallback: (Scope) -> Unit): SentryId {
    val jsSentryId = SentryJs.captureMessage(message, jsScopeCallback(scopeCallback))
    return SentryId(jsSentryId as String)
  }

  actual fun captureException(throwable: Throwable): SentryId {
    val jsSentryId = SentryJs.captureException(throwable)
    return SentryId(jsSentryId as String)
  }

  actual fun captureException(throwable: Throwable, scopeCallback: (Scope) -> Unit): SentryId {
    val jsSentryId = SentryJs.captureException(throwable, jsScopeCallback(scopeCallback))
    return SentryId(jsSentryId as String)
  }

  actual fun addBreadcrumb(breadcrumb: Breadcrumb) {
    SentryJs.addBreadcrumb(breadcrumb.asJs())
  }

  actual fun configureScope(scopeCallback: (Scope) -> Unit) {
    SentryJs.configureScope(jsScopeCallback(scopeCallback))
  }

  actual fun close() {
    SentryJs.close()
  }

  private fun jsScopeCallback(scopeCallback: (Scope) -> Unit): (dynamic) -> Unit = {
    scopeCallback(Scope(ScopeJsImpl(it)))
  }
}
