package components

import androidx.compose.runtime.Composable
import common.FlexColumn
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import style.DiyStyleSheet

@Composable
fun DescriptionDisplay(label: String, description: String, attrs: AttrBuilderContext<HTMLDivElement>? = null) {
  FlexColumn({
    attrs?.invoke(this)
  }) {
    Span({
      style {
        fontWeight(value = 500)
        marginBottom(16.px)
      }
    }) {
      Text(label)
    }
    Span({
      style {
        color(DiyStyleSheet.Colors.darkGrey)
      }
    }) {
      Text(description)
    }
  }
}
