package components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import dev.petuska.kmdc.select.MDCSelect
import dev.petuska.kmdc.select.MDCSelectType
import dev.petuska.kmdc.select.anchor.Anchor
import dev.petuska.kmdc.select.menu.Menu
import dev.petuska.kmdc.select.menu.SelectItem
import dev.petuska.kmdc.select.onChange
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLDivElement

/**
 * A standard DIY Select component.
 *
 * Supports both lists of items that contain a null option (which are rendered as [nullOption] or an empty string
 * if [nullOption] is not provided) and lists of items that do not contain a null option. Lists that do not contain a
 * null option can start out as empty but once an item is selected, the option cannot be cleared / reset back to null.
 */
@Composable
@Suppress("LongParameterList")
fun <T> DiySelect(
  label: String,
  items: List<T?>,
  attrs: AttrBuilderContext<HTMLDivElement>? = null,
  initialSelected: T? = null,
  nullOption: String? = null,
  disabled: Boolean = false,
  renderer: (T) -> String = { it.toString() },
  onSelected: (T?) -> Unit,
) {
  var selected by remember { mutableStateOf(initialSelected?.let { renderer(it) } ?: nullOption) }

  MDCSelect(
    disabled = disabled,
    type = MDCSelectType.Outlined,
    attrs = {
      onChange { event ->
        val item = items[event.detail.index]
        selected = item?.let(renderer) ?: nullOption
        onSelected(item)
      }
      attrs?.invoke(this)
    },
  ) {
    Anchor(label)
    Menu {
      items.forEach { item ->
        val itemText = item?.let { renderer(it) } ?: nullOption ?: ""
        SelectItem(text = itemText, selected = selected == itemText)
      }
    }
  }
}
