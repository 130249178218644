package common

import model.FormValue
import org.jetbrains.compose.web.css.CSSColorValue

@Suppress("LongParameterList")
open class InputOptions<T>(
  open var label: String? = null,
  open var value: T? = null,
  open var error: String? = null,
  open var popupRef: String? = null,
  open var disabled: Boolean = false,
  open var onModified: (T?) -> Unit = {},
  open var onKeyEnter: (T?) -> Unit = {},
)

class SelectableOptions<T>(
  var items: Map<T, String> = mapOf(),
  var selectedColors: ((T) -> CSSColorValue)? = null,
) : InputOptions<T>()

open class ValidatedFormValueInputOptions<T>(
  open var onBlur: (FormValue<T>?) -> Unit = {},
) : InputOptions<T>()
