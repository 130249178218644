package com.diyoffer.negotiation.ui.offer

import com.diyoffer.negotiation.model.*

object OfferContactsContract {
  data class State(
    val offerId: Uid<Offer>? = null,
    val contactsUI: List<OfferContactUI?> = emptyList(),
    val error: String? = null,
    // Transform to the builder pattern
    val offerContacts: OfferContacts? = null,
    val validationPopupShown: Boolean = false,
  )

  sealed class Inputs {
    data class FetchContacts(val offerId: Uid<Offer>, val party: Party) : Inputs()
    data object SaveContacts : Inputs()
    data object AddContact : Inputs()
    data class RemoveContact(val contextIdx: Int) : Inputs()
    data class OfferUpdated(val offerPair: Pair<Offer, OfferContacts>) : Inputs()
    data class ContactsUpdated(val contacts: OfferContacts?) : Inputs()
    data class UpdateEmail(val contactIdx: Int, val email: String?) : Inputs()
    data class UpdateName(val contactIdx: Int, val name: String?) : Inputs()
    data class UpdateCodeChar(val contactIdx: Int, val code: String?) : Inputs()
    data class VerifyClicked(val contactIdx: Int) : Inputs()
    data class ResendClicked(val contactIdx: Int) : Inputs()
    data class StartVerification(val contactIdx: Int) : Inputs()
    data class CompleteVerification(val contactIdx: Int) : Inputs()
    data class SetError(val error: String?, val contactIdx: Int? = null) : Inputs()
    data object ClearErrors : Inputs()
  }

  sealed class Events

  data class OfferContactUI(
    val name: String? = null,
    val email: String? = null,
    val verifiedState: VerifyingState = VerifyingState.UNVERIFIED,
    val code: String? = null,
    val error: String? = null,
    val offerContactIdx: Int? = null,
  )

  enum class VerifyingState {
    VERIFIED,
    VERIFYING,
    UNVERIFIED,
    DONT_CARE,
  }
}
