package com.diyoffer.negotiation.model.rpcs

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
sealed class SendBuyerOfferLinkResult {
  @Serializable
  @SerialName("s")
  data object Success : SendBuyerOfferLinkResult()

  @Serializable
  @SerialName("nf")
  data object NotFound : SendBuyerOfferLinkResult()

  @Serializable
  @SerialName("nci")
  data object NoContactInfo : SendBuyerOfferLinkResult()

  fun message() = when (this) {
    is Success -> "A link to access your offer was successfully sent to your email."
    is NotFound -> "The offer could not be found."
    is NoContactInfo -> "The offer is missing verified contact information. You'll need to start a new offer."
  }
}
