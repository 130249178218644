package com.diyoffer.negotiation.ui.listing

import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.ui.state.LoadingState
import kotlinx.datetime.LocalDateTime

object ListingLandingScreenContract {
  data class State(
    val id: Uid<Listing>? = null,
    val permalink: String? = null,
    val name: String? = null,
    val address: Address? = null,
    val price: Money? = null,
    val deposit: Money? = null,
    val description: String? = null,
    val withheldUntil: LocalDateTime? = null,
    val links: List<ListingLinks.Link>? = null,
    val details: List<ListingLandingDetail>? = null,
    val thingsYouNeedToKnow: List<String>? = null,
    val currency: Currency? = null,
    val offerCount: Int = 0,
    val isCompleted: Boolean = false,
    val loadingState: LoadingState = LoadingState.NOT_LOADED,
    val error: String? = null,
  )

  sealed class Inputs {
    data class FetchListing(val permalink: String) : Inputs()
    data class ListingUpdated(val listing: Listing.Published, val offerCount: Int) : Inputs()
    data class SetError(val error: String?) : Inputs()
  }

  sealed class Events
}
