package com.diyoffer.negotiation.ui.listing

import com.copperleaf.ballast.repository.cache.Cached
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.client.*
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.ui.state.LoadingState
import io.ktor.http.*

/**
 * This view enumerates the user listings. The user can select which one is expanded,
 * navigate to the details, or copy the link URL. The data is loaded from the listingAuthRepo
 * which is cached for convenience. The data is invalidated when the user is changed.
 */
object SideBarListingsContract {
  data class State(
    val loadingState: LoadingState = LoadingState.NOT_LOADED,
    val sideBarListings: List<SideBarListingUI> = emptyList(),
    val returnRouteData: RouteData? = null,
    val error: String? = null,
    val sessionUser: SessionUser? = null,
  )

  sealed class Inputs {
    data object FetchListing : Inputs()
    data class ListingsUpdated(val listings: Cached<List<ListingLoadResult.Success>>, val user: SessionUser) : Inputs()
    data class ExpandClicked(val listingId: Uid<Listing>, val open: Boolean) : Inputs()
    data class ViewListing(val listingId: Uid<Listing>, val editMode: Boolean = false) : Inputs()
    data class CancellationConfirmed(val listingId: Uid<Listing>) : Inputs()
    data class ReturnRouteData(val returnRouteData: RouteData?) : Inputs()
    data object ClearError : Inputs()
    data class UserUpdated(val user: SessionUser?) : Inputs()
  }

  sealed class Events {
    data class NavigateToListingEditScreen(val listingId: Uid<Listing>, val returnRouteData: RouteData?) : Events()
    data class NavigateToListingDetailsScreen(val permalink: String, val returnRouteData: RouteData?) : Events()
  }

  data class SideBarListingUI(
    val uid: Uid<Listing>? = null,
    val isDraft: Boolean = true,
    val listedDateLabel: String? = null,
    val detailsLabel: String? = null,
    val streetLabel: String? = null,
    val city: String? = null,
    val provinceState: ProvinceState? = null,
    val priceLabel: String? = null,
    val offerCount: Int? = null,
    val publicUrl: Url? = null,
    val expanded: Boolean = false,
    val cancellable: Boolean = true,
  )
}
