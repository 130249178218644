package builders

import com.diyoffer.negotiation.common.EMAIL_VALIDATION_REGEX
import com.diyoffer.negotiation.model.*
import kotlinx.datetime.Clock

data class ListingPropertyOwnersBuilder(
  val contacts: List<Contact>? = null,
  val userCertifiedLegalAuthority: Boolean? = null,
) : IBuilder<ListingPropertyOwners> {

  companion object {
    fun create(item: ListingPropertyOwners?): IBuilder<ListingPropertyOwners> {
      return item?.let { owner ->
        ListingPropertyOwnersBuilder(
          contacts = owner.contacts,
          userCertifiedLegalAuthority = owner.userCertifiedLegalAuthority.valueOrNull()
        )
      } ?: ListingPropertyOwnersBuilder()
    }
  }

  override fun hydrate(item: ListingPropertyOwners?) = create(item)

  override fun build(): BuildResult<ListingPropertyOwners> = validateAndBuild {
    ListingPropertyOwners(
      contacts = contacts ?: listOf(),
      userCertifiedLegalAuthority = Auditable.Core(
        Optional.of(userCertifiedLegalAuthority),
        Clock.System.now().lowRes()
      )
    )
  }

  private fun validateAndBuild(onValid: () -> ListingPropertyOwners): BuildResult<ListingPropertyOwners> {
    val errors = mutableListOf<String>()
    val warnings = mutableListOf<String>()

    if (contacts.isNullOrEmpty()) {
      errors.add("You must specify a least one contact.")
    } else {
      contacts.forEach {
        if (it.name.isBlank()) errors.add("Each contact should have a name.")
        if (it.methods.first().methodKey.isBlank()) {
          errors.add("Each contact should have a valid email.")
        } else if (!EMAIL_VALIDATION_REGEX.matches(it.methods.first().methodKey)) {
          errors.add("The email for ${it.name} is invalid")
        }
      }
    }

    if (userCertifiedLegalAuthority != true) {
      errors.add("You must certify you have the legal authority before proceeding.")
    }

    return buildResult(errors, warnings, onValid = onValid)
  }
}
