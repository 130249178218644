@file:Suppress("Filename")

package com.diyoffer.negotiation.common

import com.diyoffer.negotiation.model.Percent

actual fun formatPercent(percent: Percent, decimal: Int, locale: String): String {
  return jsFormatPercent(
    numberToFormat = percent.value,
    minimumFractionDigits = decimal,
    maximumFractionDigits = if (decimal > DEFAULT_MAXIMUM_FRACTION_DIGITS) decimal else DEFAULT_MAXIMUM_FRACTION_DIGITS,
    locale = locale
  )
}

@Suppress("UNUSED_PARAMETER")
private fun jsFormatPercent(
  numberToFormat: Double,
  minimumFractionDigits: Int,
  maximumFractionDigits: Int,
  locale: String,
): String {
  return "${js(
    "Intl.NumberFormat(" +
      "locale, " +
      "{minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: maximumFractionDigits, useGrouping: false}" +
      ").format(numberToFormat);"
  )}%"
}
