package forms

import androidx.compose.runtime.Composable
import builders.ChattelsIncludedBuilder
import com.diyoffer.negotiation.model.*
import common.FlatList
import common.FlexRow
import common.NumberField
import common.TextField
import components.WithOptionKeyDisplay
import components.WithOptionKeyEdit
import dev.petuska.kmdcx.icons.MDCIcon
import org.jetbrains.compose.web.css.CSSColorValue
import style.DiyStyleSheet

@Composable
fun ListingChattelIncludedEdit(
  builder: ChattelsIncludedBuilder,
  setBuilder: (ChattelsIncludedBuilder) -> Unit,
) {
  WithOptionKeyEdit(opts = {
    label = "Chattel or Inclusion"
    value = builder.optionKey
    onModified = { setBuilder(builder.copy(optionKey = it)) }
  }) {
    FlexRow {
      TextField(opts = {
        label = "Brand"
        value = builder.brand
        onModified = { setBuilder(builder.copy(brand = it)) }
      })
      NumberField(opts = {
        label = "Year"
        value = builder.year
        onModified = { setBuilder(builder.copy(year = it)) }
      })
      TextField(opts = {
        label = "Serial Number"
        value = builder.serialNumber
        onModified = { setBuilder(builder.copy(serialNumber = it)) }
      })
    }
  }
}

@Composable
fun ChattelIncludedDisplay(
  item: ChattelInclusion,
  icon: MDCIcon = MDCIcon.House,
  iconColor: CSSColorValue = DiyStyleSheet.Colors.green,
  lineThrough: Boolean = false,
) {
  WithOptionKeyDisplay(opts = {
    optionKey = item.optionKey
    this.icon = icon
    this.iconColor = iconColor
    this.lineThrough = lineThrough
  }) {
    FlatList(
      item.brand,
      item.year?.toString(),
      item.serialNumber,
    )
  }
}
