package com.diyoffer.negotiation

import com.diyoffer.negotiation.auth.firebaseAuthModule
import com.diyoffer.negotiation.repository.repositoryModule
import com.diyoffer.negotiation.services.uiServiceModule
import com.diyoffer.negotiation.ui.vmConfigModule
import com.diyoffer.negotiation.workflows.workflowModule
import org.kodein.di.DI

val clientModule = DI.Module("clientModule") {
  import(firebaseAuthModule)
  import(vmConfigModule)
  import(repositoryModule)
  import(workflowModule)
  import(uiServiceModule)
}
