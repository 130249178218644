package common

import androidx.compose.runtime.Composable
import com.diyoffer.negotiation.messages.CommonMessages
import dev.petuska.kmdcx.icons.MDCIcon
import dev.petuska.kmdcx.icons.MDCIconSpan
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import style.DiyStyleSheet
import style.DiyStyleSheet.Sizes.padding
import style.GridStyleSheet

@Composable
fun ErrorMessageContainer(content: ContentBuilder<HTMLDivElement>) {
  Div({
    id("diy")
    classes(DiyStyleSheet.listingDetailContainer)
  }) {
    Div({
      classes(GridStyleSheet.flex, GridStyleSheet.flexColumn, GridStyleSheet.flexGrow)
      style {
        alignItems(AlignItems.Center)
        justifyContent(JustifyContent.Center)
      }
    }, content = content)
  }
}

@Composable
fun ErrorMessage(message: String?) {
  FlexRow(
    {
      style {
        color(DiyStyleSheet.Colors.red)
        padding(padding)
        justifyContent(JustifyContent.SpaceAround)
      }
    }
  ) {
    MDCIconSpan(icon = MDCIcon.Error)
    Span { Text((message ?: "An error occurred") + " ${CommonMessages.contactAdministrator}") }
  }
}
