package components.snackbar

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import common.FlexRow
import dev.petuska.kmdc.snackbar.Actions
import dev.petuska.kmdc.snackbar.Dismiss
import dev.petuska.kmdc.snackbar.Label
import dev.petuska.kmdc.snackbar.MDCSnackbar
import dev.petuska.kmdc.snackbar.MDCSnackbarType
import dev.petuska.kmdc.snackbar.onClosed
import dev.petuska.kmdcx.icons.MDCIcon
import dev.petuska.kmdcx.icons.MDCIconSpan
import dev.petuska.kmdcx.icons.mdcIcon
import org.jetbrains.compose.web.css.AlignContent
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.alignContent
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Text
import style.DiyStyleSheet

@Composable
fun Snackbar(
  message: String?,
  icon: MDCIcon = MDCIcon.Error,
  onClosed: () -> Unit,
) {
  if (message == null) return

  val (open, setOpen) = remember { mutableStateOf(false) }

  LaunchedEffect(message) {
    setOpen(true)
  }

  MDCSnackbar(
    open = open,
    type = MDCSnackbarType.Leading,
    timeoutMs = 10_000,
    closeOnEscape = false,
    attrs = {
      onClosed {
        setOpen(false)
        onClosed()
      }
    }
  ) {
    Label {
      FlexRow({
        style {
          gap(DiyStyleSheet.Sizes.paddingXs)
          alignContent(AlignContent.Center)
          marginBottom(0.px)
        }
      }) {
        MDCIconSpan(icon = icon)
        Text(message)
      }
    }
    Actions {
      Dismiss(attrs = {
        mdcIcon()
        style { color(Color.white) }
        title("Dismiss")
      }) {
        Text(MDCIcon.Close.type)
      }
    }
  }
}
