package components.lightbox

import androidx.compose.runtime.Composable
import com.diyoffer.negotiation.messages.YoutubeVideo
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Iframe
import org.jetbrains.compose.web.dom.Text

@Composable
fun YoutubeLink(video: YoutubeVideo, linkText: String) {
  A(href = "#", attrs = {
    attr("data-type", "youtube")
    attr("data-id", video.id)
    attr("data-group", "youtube")
    classes("lightbox")
    ref { el ->
      tobii.add(el)
      onDispose { tobii.remove(el) }
    }
  }) {
    Text(linkText)
  }
}

@Composable
fun YoutubeEmbed(video: YoutubeVideo) = YoutubeEmbed(video.id)

@Composable
fun YoutubeEmbed(id: String) {
  Div(attrs = { classes("videoblock") }) {
    Iframe(
      attrs = {
        val allowOptions =
          "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"

        // rel=0 disables showing non-diy related videos at the end
        attr("src", "https://www.youtube.com/embed/$id?rel=0")
        attr("title", "YouTube video player")
        attr("frameborder", "0")
        attr("allow", allowOptions)
        attr("allowfullscreen", "true")
      }
    )
  }
}
