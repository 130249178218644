package vm.offer

import com.copperleaf.ballast.BallastViewModelConfiguration
import com.copperleaf.ballast.core.BasicViewModel
import com.diyoffer.negotiation.ui.offer.OfferEditScreenContract.Events
import com.diyoffer.negotiation.ui.offer.OfferEditScreenContract.Inputs
import com.diyoffer.negotiation.ui.offer.OfferEditScreenContract.State
import com.diyoffer.negotiation.ui.offer.OfferEditScreenEventHandler
import kotlinx.coroutines.CoroutineScope

typealias OfferEditScreenViewModelConfiguration = BallastViewModelConfiguration<Inputs, Events, State>

class OfferEditScreenViewModel(
  viewModelCoroutineScope: CoroutineScope,
  config: OfferEditScreenViewModelConfiguration,
  eventHandler: OfferEditScreenEventHandler,
) : BasicViewModel<Inputs, Events, State>(
  config = config,
  eventHandler = eventHandler,
  coroutineScope = viewModelCoroutineScope,
)
