package com.diyoffer.negotiation.model

import com.diyoffer.negotiation.model.rpcs.*
import kotlinx.datetime.LocalDate
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient

@Serializable
data class AssumableContract(
  /** The value here should be an instance of [ContractOption], or [CustomOption]. */
  val contract: OptionKey,
  val contractDetails: AssumableContractDetails,
) {
  init {
    // union types for `contract` would be nice here!
    require(contract is ContractOption || contract is OptionKey.Custom) {
      "Currently only ContractOption or OptionKey.Custom values are supported for assumable contracts"
    }
  }
}

/**
 * This is a sealed class rather than a sealed interface due to
 * https://github.com/Kotlin/kotlinx.serialization/issues/1417.
 */
@Serializable
sealed class ContractOption(val title: String) : OptionKey.Predefined() {
  companion object {
    val values: List<ContractOption> = listOf(
      Furnace,
      HotWaterHeater,
      HRV
    )
  }

  @Serializable
  @SerialName("furnace")
  data object Furnace : ContractOption("Furnace")

  @Serializable
  @SerialName("hotWaterHeater")
  data object HotWaterHeater : ContractOption("Hot Water Heater")

  @Serializable
  @SerialName("hrv")
  data object HRV : ContractOption("Heat Recovery Ventilator (HRV)")
}

/**
 * This is a sealed class rather than a sealed interface due to
 * https://github.com/Kotlin/kotlinx.serialization/issues/1417.
 */
@Serializable
sealed class AssumableContractDetails : OptionKey.Predefined() {
  enum class Type(val title: String, val hasBuyout: Boolean) {
    RENTAL("Rental", false),
    RENT_TO_OWN("Rent to Own", true),
    FINANCED("Financed", true),
  }

  abstract val expiry: LocalDate?
  abstract val monthlyFee: Money
  abstract val type: Type

  interface HasBuyout {
    val buyoutFee: Money
  }

  @Serializable
  @SerialName("rental")
  data class Rental(
    override val expiry: LocalDate?,
    override val monthlyFee: Money,
    @Transient
    override val type: Type = Type.RENTAL,
  ) : AssumableContractDetails()

  @Serializable
  @SerialName("rentToOwn")
  data class RentToOwn(
    override val expiry: LocalDate,
    override val monthlyFee: Money,
    override val buyoutFee: Money,
    @Transient
    override val type: Type = Type.RENT_TO_OWN,
  ) : AssumableContractDetails(), HasBuyout

  @Serializable
  @SerialName("financed")
  data class Financed(
    override val expiry: LocalDate,
    override val monthlyFee: Money,
    override val buyoutFee: Money,
    @Transient
    override val type: Type = Type.FINANCED,
  ) : AssumableContractDetails(), HasBuyout
}

@Serializable
data class FixtureExclusion(
  val optionKey: OptionKey,
  val location: String,
)
