package vm.offer

import builders.BuildResult
import builders.IViewModelBuilder
import com.copperleaf.ballast.BallastViewModelConfiguration
import com.copperleaf.ballast.core.BasicViewModel
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.ui.offer.OfferContactsContract
import com.diyoffer.negotiation.ui.offer.OfferContactsContract.Events
import com.diyoffer.negotiation.ui.offer.OfferContactsContract.Inputs
import com.diyoffer.negotiation.ui.offer.OfferContactsContract.State
import com.diyoffer.negotiation.ui.offer.OfferContactsEventHandler
import kotlinx.coroutines.CoroutineScope

typealias OfferContactsViewModelConfiguration = BallastViewModelConfiguration<Inputs, Events, State>

class OfferContactsViewModel(
  viewModelCoroutineScope: CoroutineScope,
  config: OfferContactsViewModelConfiguration,
  eventHandler: OfferContactsEventHandler,
) : BasicViewModel<Inputs, Events, State>(
  config = config,
  eventHandler = eventHandler,
  coroutineScope = viewModelCoroutineScope,
),
  IViewModelBuilder<OfferContacts, State> {

  @Suppress("ComplexCondition")
  override fun build(state: State): BuildResult<OfferContacts> {
    val hasVerifiedContacts = state.contactsUI.any { it?.verifiedState == OfferContactsContract.VerifyingState.VERIFIED }
    return if (state.offerContacts == null || state.error != null || state.contactsUI.any {
      it == null || it.error != null
    }
    ) {
      BuildResult.Failure(emptyList(), emptyList())
    } else if (!hasVerifiedContacts) {
      BuildResult.Failure(
        listOf("You must have at least one verified contact to save that section."),
        emptyList()
      )
    } else if (state.contactsUI.any { it?.name.isNullOrBlank() }) {
      BuildResult.Failure(listOf("Each contact must have a name."), emptyList())
    } else {
      BuildResult.Success(state.offerContacts!!, emptyList())
    }
  }
}
