//
// GENERATED by KVision
//
package com.diyoffer.negotiation.rpcs

import org.w3c.fetch.RequestInit
import io.kvision.remote.KVRemoteAgent
import kotlinx.serialization.modules.SerializersModule
import com.diyoffer.negotiation.model.ConditionDefaultCriteria
import com.diyoffer.negotiation.model.ConditionOption.Core
import com.diyoffer.negotiation.model.Jurisdiction
import com.diyoffer.negotiation.model.Party
import com.diyoffer.negotiation.model.rpcs.DynamicUid
import com.diyoffer.negotiation.model.rpcs.HydrateDynamicConditionResult

actual class DynamicOptionsRpcService(serializersModules: List<SerializersModule>? = null, requestFilter: (suspend RequestInit.() -> Unit)? = null) : IDynamicOptionsRpcService, KVRemoteAgent<DynamicOptionsRpcService>(DynamicOptionsRpcServiceManager, serializersModules, requestFilter) {
    override suspend fun listDynamicDefaultConditions(forParty: Party, criteria: List<ConditionDefaultCriteria>, jurisdiction: Jurisdiction?) = call(IDynamicOptionsRpcService::listDynamicDefaultConditions, forParty, criteria, jurisdiction)
    override suspend fun listDynamicSelectableConditions(forParty: Party, excludeDefaultCriteria: List<ConditionDefaultCriteria>?, jurisdiction: Jurisdiction?) = call(IDynamicOptionsRpcService::listDynamicSelectableConditions, forParty, excludeDefaultCriteria, jurisdiction)
    override suspend fun hydrateDynamicConditionOptionKey(optionKeyId: DynamicUid) = call(IDynamicOptionsRpcService::hydrateDynamicConditionOptionKey, optionKeyId)
    override suspend fun hydrateDynamicConditionOptionKeys(optionKeyIds: List<DynamicUid>) = call(IDynamicOptionsRpcService::hydrateDynamicConditionOptionKeys, optionKeyIds)
}
