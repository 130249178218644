package com.diyoffer.negotiation.ui.checklist

import com.copperleaf.ballast.EventHandler
import com.copperleaf.ballast.EventHandlerScope
import com.diyoffer.negotiation.ui.checklist.ChecklistTabContract.Events
import com.diyoffer.negotiation.ui.checklist.ChecklistTabContract.Inputs
import com.diyoffer.negotiation.ui.checklist.ChecklistTabContract.State

class ChecklistTabEventHandler(
  val onNavigate: (url: String) -> Unit,
) : EventHandler<Inputs, Events, State> {
  override suspend fun EventHandlerScope<Inputs, Events, State>.handleEvent(event: Events) = when (event) {
    is Events.OnNavigate -> onNavigate(event.url)
  }
}
