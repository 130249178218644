package com.diyoffer.negotiation.repository.offer

import com.copperleaf.ballast.repository.cache.Cached
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*

object SellerOfferRepositoryContract {
  data class State(
    val initialized: Boolean = false,
    val offerCacheInvalidated: Boolean = false,
    val userOffersCacheInvalidated: Boolean = false,
    val user: User? = null,
    val sessionUser: SessionUser? = null,
    // Single offer when countering on the OfferEditPage
    val offer: Cached<Pair<Offer, OfferContacts>> = Cached.NotLoaded(),
    // All offers in the seller's scope
    val userOffers: Cached<List<Pair<Offer, OfferContacts>>> = Cached.NotLoaded(),
    // Keep the listing accessible when we need related information
    val listings: Cached<List<ListingLoadResult.Success>> = Cached.NotLoaded(),
  )

  sealed class Inputs {
    data object Initialize : Inputs()
    data class InvalidateCache(val refreshOffers: Boolean) : Inputs()
    data object RefreshOffers : Inputs()
    data class UserListingsUpdated(val listings: Cached<List<ListingLoadResult.Success>>, val user: SessionUser?) : Inputs()
    data class UserOffersFetched(val offers: Cached<List<Pair<Offer, OfferContacts>>>) : Inputs()
    data class OfferFetched(val offer: Cached<Pair<Offer, OfferContacts>>) : Inputs()
  }

  sealed class Events
}
