package vm.listing

import com.copperleaf.ballast.BallastViewModelConfiguration
import com.copperleaf.ballast.core.BasicViewModel
import com.diyoffer.negotiation.ui.listing.ListingCreatePageContract.Events
import com.diyoffer.negotiation.ui.listing.ListingCreatePageContract.Inputs
import com.diyoffer.negotiation.ui.listing.ListingCreatePageContract.State
import com.diyoffer.negotiation.ui.listing.ListingCreatePageEventHandler
import kotlinx.coroutines.CoroutineScope

typealias ListingCreatePageViewModelConfiguration = BallastViewModelConfiguration<Inputs, Events, State>

class ListingCreatePageViewModel(
  viewModelCoroutineScope: CoroutineScope,
  config: ListingCreatePageViewModelConfiguration,
  eventHandler: ListingCreatePageEventHandler,
) : BasicViewModel<Inputs, Events, State>(
  config = config,
  eventHandler = eventHandler,
  coroutineScope = viewModelCoroutineScope,
)
