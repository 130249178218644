package com.diyoffer.negotiation.model.serdes

import com.diyoffer.negotiation.model.*
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.polymorphic
import kotlinx.serialization.modules.subclass

val AuditableSerializationModule: SerializersModule by lazy {
  SerializersModule {
    polymorphic(Any::class) {
      subclass(Address.serializer())
      subclass(AssumableContract.serializer())
      subclass(AgentCommission.serializer())
      subclass(ChattelInclusion.serializer())
      subclass(FixtureExclusion.serializer())
    }
  }
}
