package com.diyoffer.negotiation.repository.listing

import com.copperleaf.ballast.repository.cache.Cached
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*

object SellerListingRepositoryContract {
  data class State(
    val initialized: Boolean = false,
    val cacheInvalidated: Boolean = false,
    val user: SessionUser? = null,
    val listings: Cached<List<ListingLoadResult.Success>> = Cached.NotLoaded(),
  )

  sealed class Inputs {
    data object Initialize : Inputs()
    data object InvalidateCache : Inputs()
    data class UserUpdated(val user: SessionUser?) : Inputs()
    data class FetchListings(val forceRefresh: Boolean = false) : Inputs()
    data class ListingsFetched(val listings: Cached<List<ListingLoadResult.Success>>) : Inputs()
  }

  sealed class Events
}
