package vm.landing

import com.copperleaf.ballast.BallastViewModelConfiguration
import com.copperleaf.ballast.core.BasicViewModel
import com.diyoffer.negotiation.ballast.VmConfigFactoryParams
import com.diyoffer.negotiation.ui.landing.YourInformationContract.Events
import com.diyoffer.negotiation.ui.landing.YourInformationContract.Inputs
import com.diyoffer.negotiation.ui.landing.YourInformationContract.State
import com.diyoffer.negotiation.ui.landing.YourInformationEventHandler
import kotlinx.coroutines.CoroutineScope

typealias YourInformationVmConfigParams = VmConfigFactoryParams<Inputs, Events, State>

typealias YourInformationVmConfig = BallastViewModelConfiguration<Inputs, Events, State>

class YourInformationViewModel(
  viewModelCoroutineScope: CoroutineScope,
  config: YourInformationVmConfig,
  eventHandler: YourInformationEventHandler,
) : BasicViewModel<Inputs, Events, State>(
  config = config,
  eventHandler = eventHandler,
  coroutineScope = viewModelCoroutineScope,
)
