package com.diyoffer.negotiation.rpcs

import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*
import io.kvision.annotations.KVBindingRoute
import io.kvision.annotations.KVService
import kotlinx.datetime.TimeZone

@KVService
interface IOfferAuthRpcService {

  /**
   * Saves offer data by an authenticated and authorized user. Sellers may save counter-offers on their listing
   * via this endpoint. We validate:
   *
   * * The offer is attached to a listing owned by the authenticated seller.
   * * The offer states are valid.
   */
  @KVBindingRoute("offer/auth/create")
  suspend fun create(offer: Offer.Draft, contacts: OfferContacts): OfferSaveResult

  /**
   * Saves offer data by an authenticated and authorized user. Sellers may save counter-offers on their listing
   * via this endpoint. We validate:
   *
   * * The offer is attached to a listing owned by the authenticated seller.
   * * The offer states are valid.
   */
  @KVBindingRoute("offer/auth/save")
  suspend fun save(offer: Offer): OfferSaveResult

  /**
   * Saves the contacts for an offer. If the changes are accepted, it does not change the offer version, and applies
   * immediately to all offer versions, past and present. Any contacts which are new must be unverified.
   */
  @KVBindingRoute("offer/auth/save_contacts")
  suspend fun saveContacts(
    id: UidValue<Offer>,
    contacts: OfferContacts,
  ): OfferContactsSaveResult

  /**
   * Loads offer data for an offer by an authenticated and authorized user. Sellers may load published offers
   * on their listing via this endpoint. We validate:
   *
   * * The offer is attached to a listing owned by the authenticated seller.
   * * The offer is published, or to see a draft, the author of the draft matches the user.
   */
  @KVBindingRoute("offer/auth/load")
  suspend fun load(
    id: UidValue<Offer>,
    versionOption: LoadOfferVersionOption = LoadOfferVersionOption.LATEST,
  ): OfferLoadResult

  /**
   * Publishes a given offer i.e. changes state from [Offer.State.DRAFT] to [Offer.State.PUBLISHED].
   * This method does not accept any changes to the offer -- use [save] for modifying a draft before
   * publishing.
   *
   * This endpoint must only be used by authenticated users.
   */
  @KVBindingRoute("offer/auth/publish")
  suspend fun publish(offer: Offer): OfferSaveResult

  /**
   * Loads the offers that are associated to our listings
   */
  @KVBindingRoute("offer/auth/offers-for-listings")
  suspend fun loadOffersForListings(listingIds: List<UidValue<Listing>>): ListingOffersLoadResult

  /**
   * Loads the checklist for the associated offer. Checks the state to see if it's for the buyer or seller
   */
  @KVBindingRoute("offer/auth/checklist")
  suspend fun checklist(offer: Offer, timeZone: TimeZone): ChecklistResult

  /**
   * Mark offer as completed by seller
   */
  @KVBindingRoute("offer/auth/complete")
  suspend fun complete(offerId: UidValue<Offer>): OfferSaveResult

  /**
   * Mark offer as accepted by seller
   */
  @KVBindingRoute("offer/auth/accept")
  suspend fun accept(offerId: UidValue<Offer>): OfferSaveResult

  /**
   * Mark offer as reject by seller
   */
  @KVBindingRoute("offer/auth/reject")
  suspend fun reject(offerId: UidValue<Offer>): OfferSaveResult

  /**
   * Mark offer as cancelled by seller
   */
  @KVBindingRoute("offer/auth/cancel")
  suspend fun cancel(offerId: UidValue<Offer>, reason: String): OfferSaveResult
}
