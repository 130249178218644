package builders

import com.diyoffer.negotiation.common.services.validation.ValidStateChange
import com.diyoffer.negotiation.model.*
import kotlinx.datetime.Clock

data class NegotiatedTermBuilder<T : Any>(
  val negotiationStage: NegotiationStage,
  // logged-in party
  val party: Party,
  // The current term as available on the Offer or Listing
  val term: NegotiatedTerm<T>,
  // Use to validate the different states that are supported. See com.diyoffer.negotiation.common.services.validation
  val stateValidation: ((NegotiationStage) -> List<ValidStateChange>),
  // Use to build a State.New value or the State.Countered terms based on UI input.
  // Optional as not all negotiated fields support a counter value
  val counterValueBuilder: IBuilder<T>? = null,
  val state: NegotiatedTerm.State? = when {
    // If term has no history, then this must be built a NEW entry
    term.changeHistory.isEmpty() -> NegotiatedTerm.State.NEW
    // If the term is being viewed by the other party (so say Seller is reviewing his offer while the offer's
    // pen belongs to the Buyer who is countering, then the UI should display the terms' state
    negotiationStage.authoringParty != party -> term.state
    // If the current party is the one who made the last entry, then we will also initialize the state to the term's
    term.changeHistory.lastOrNull()?.party == party -> term.state
    // If, for instance, Buyer accept a term that was proposed by Seller, then state is Accepted
    term.state == NegotiatedTerm.State.ACCEPTED -> NegotiatedTerm.State.ACCEPTED
    // If seller has removed a term, then state is Removed
    term.state == NegotiatedTerm.State.REMOVED -> NegotiatedTerm.State.REMOVED
    // In the other cases, the state is initialized to null so Party can select it from an empty radio list
    else -> null
  },
  val currency: Currency,
  // Message added by the user
  val userMessage: String? = null,
  val clock: Clock = Clock.System,
) : IBuilder<NegotiatedTerm<T>> {
  override fun hydrate(item: NegotiatedTerm<T>?) =
    throw UnsupportedOperationException("Cannot hydrate NegotiatedTermBuilder. Please use the constructor instead.")

  override fun build(): BuildResult<NegotiatedTerm<T>> = validateAndBuild {
    val (negotiatedTermValue, effectiveState) = when (state!!) {
      // We accept or reject the previous party's state, so we roll back the term to previous state
      // and return the value.
      NegotiatedTerm.State.ACCEPTED, NegotiatedTerm.State.REJECTED, NegotiatedTerm.State.REMOVED -> term.revert(
        negotiationStage.authoringParty
      )!!.currentValue.get() to state
      // Countered value is built from the countered builder from UI
      NegotiatedTerm.State.COUNTERED -> (counterValueBuilder?.build() as? BuildResult.Success)?.result?.let {
        // In case the countered value is the same as current term
        if (it == term.currentValue.getOrNull()) {
          term.currentValue.get() to NegotiatedTerm.State.ACCEPTED
        } else {
          it to state
        }
      } ?: throw UnsupportedOperationException("Builder did not validate the negotiated term.")
      // For newly created terms that don't yet have history, return current value
      NegotiatedTerm.State.NEW -> term.currentValue.get() to state
      else -> throw UnsupportedOperationException(
        "Negotiated state other than Accepted, Rejected or Countered is not currently supported, " +
          "received $state, $term"
      )
    }

    val updatedTerm = NegotiatedTermValue(
      Auditable.Core(
        Optional.of(negotiatedTermValue),
        clock.now().lowRes(),
        message = userMessage
      ),
      party = negotiationStage.authoringParty,
      state = effectiveState
    )

    val prevParty = term.changeHistory.last().party
    when {
      // The term was agreed and UI doesn't allow change to that, so return as is
      term.isAgreed(negotiationStage.authoringParty) -> term
      // Current party is the author of the last revision as well
      prevParty == negotiationStage.authoringParty ->
        // If value and state haven't changed, return term as-is
        if (term.currentValue.getOrNull() == negotiatedTermValue && term.state == state) {
          term
        } // Amend term so changes modify the tip of the history. Party has the right to change their mind
        // before publishing without a full audit, though publishing memorializes the value
        else {
          term.amendTerm(updatedTerm)
        }
      // The other party is the author of the previous value, so push the update on top
      else -> term.pushTerm(updatedTerm)
    }
  }

  fun getCounteredValue(): T = (counterValueBuilder?.build() as? BuildResult.Success)?.result ?: term.currentValue.get()

  fun getBaselineValue(): T =
    term.revert(negotiationStage.authoringParty)?.currentValue?.getOrNull() ?: term.currentValue.get()

  fun getBaselineState(): NegotiatedTerm.State =
    term.revert(negotiationStage.authoringParty)?.state ?: NegotiatedTerm.State.NEW

  fun getEffectiveValue(): T = if (state == NegotiatedTerm.State.COUNTERED) {
    getCounteredValue()
  } else {
    getBaselineValue()
  }

  private fun validateAndBuild(onValid: () -> NegotiatedTerm<T>): BuildResult<NegotiatedTerm<T>> {
    if (state == NegotiatedTerm.State.COUNTERED) {
      require(counterValueBuilder != null) {
        "NegotiatedTermBuild with support for counter must be constructed with an instance of counterValueBuilder"
      }
    }

    val errors = mutableListOf<String>()

    // If user selected counter, he must have filled the counter UI using valid parameters.
    // The builder is merged at the end and the errors and warnings will be combined into a single build result
    val counterBuilderResult = if (state == NegotiatedTerm.State.COUNTERED) counterValueBuilder?.build() else null

    // Validate the state. If the stateValidation function was provided, ensure that the state changes are accepted
    if (state == null) {
      errors.add("You must Accept, Reject or Counter the current terms.")
    }

    // The full state validation is performed on backend
    return buildResult(errors = errors)
      .mergeAndRun(onValid, counterBuilderResult)
      .withStates(
        listOfNotNull(
          if (state != null) {
            party to state
          } else if (term.changeHistory.isNotEmpty()) {
            term.changeHistory.last().party to term.changeHistory.last().state
          } else {
            null
          }
        )
      )
  }
}
