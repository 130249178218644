package components.lightbox

import org.w3c.dom.HTMLElement
import require

@JsModule("@midzer/tobii")
external object TobiiModule {
  @JsName("default")
  @Suppress("UnusedPrivateMember")
  class Tobii(options: TobiiUserOptions) {
    fun add(el: HTMLElement)
    fun remove(el: HTMLElement)
  }
}

external interface TobiiUserOptions {
  var autoplayVideo: Boolean
}

private val tobiiUserOptions = js("{}").unsafeCast<TobiiUserOptions>().apply {
  autoplayVideo = true
}

val tobii = TobiiModule.Tobii(tobiiUserOptions).also {
  require("@midzer/tobii/dist/tobii.min.css")
  // responsive 100% video
  // see https://www.h3xed.com/web-development/how-to-make-a-responsive-100-width-youtube-iframe-embed
  require("./css/youtube-embed.scss")
}
