package builders

import com.diyoffer.negotiation.common.bnDivAsPercent
import com.diyoffer.negotiation.common.formatPercent
import com.diyoffer.negotiation.common.services.validation.priceStateModel
import com.diyoffer.negotiation.model.*

data class OfferPriceBuilder(
  val party: Party,
  val negotiationStage: NegotiationStage,
  val currency: Currency,
  val price: NegotiatedTermBuilder<Money>? = null,
  val deposit: NegotiatedTermBuilder<Money>? = null,
) : IBuilder<OfferPrice> {
  override fun hydrate(item: OfferPrice?) = copy(
    price = item?.let {
      NegotiatedTermBuilder(
        party = party,
        negotiationStage = negotiationStage,
        term = it.price,
        stateValidation = ::priceStateModel,
        counterValueBuilder = MoneyBuilder(it.price.currentValue.getOrNull()),
        currency = currency
      )
    },
    deposit = item?.let {
      NegotiatedTermBuilder(
        party = party,
        negotiationStage = negotiationStage,
        term = it.deposit,
        stateValidation = ::priceStateModel,
        counterValueBuilder = MoneyBuilder(it.deposit.currentValue.getOrNull()),
        currency = currency
      )
    },
  )

  override fun build(): BuildResult<OfferPrice> = validateAndBuild {
    val priceResult = price!!.build()
    val depositResult = deposit!!.build()

    OfferPrice(
      price = (priceResult as BuildResult.Success).result,
      deposit = (depositResult as BuildResult.Success).result,
    )
  }

  private fun validateAndBuild(onValid: () -> OfferPrice): BuildResult<OfferPrice> {
    require(price != null) { "Price has not been hydrated" }
    require(deposit != null) { "Deposit has not been hydrated" }

    val warnings = mutableListOf<String>()

    if (deposit.getEffectiveValue() != deposit.getBaselineValue()) {
      val pct = bnDivAsPercent(
        deposit.getEffectiveValue().toBigNum(currency),
        price.getEffectiveValue().toBigNum(currency)
      )
      if (pct < ListingPriceAndClosingBuilder.DEPOSIT_PCT_LOWER_THRESHOLD) {
        warnings.add(
          "You are offering a deposit of only ${formatPercent(pct)} of the listing price. " +
            "Are you sure you want the deposit to be that low?"
        )
      } else if (pct >= ListingPriceAndClosingBuilder.DEPOSIT_PCT_UPPER_THRESHOLD) {
        warnings.add(
          "You are offering a buyer deposit of ${formatPercent(pct)} of the listing price. " +
            "Are you sure you want the deposit to be that high?"
        )
      }
    }

    return buildResult(
      warnings = warnings,
      negotiationStates = emptyList() // Signal we do want to merge-in the negotiation data
    ).mergeAndRun(onValid, price.build(), deposit.build())
  }
}

data class MoneyBuilder(
  val amount: Money? = null,
) : IBuilder<Money> {
  override fun hydrate(item: Money?) = MoneyBuilder(amount = item)

  override fun build(): BuildResult<Money> = validateAndBuild {
    Money(amount!!.value)
  }

  private fun validateAndBuild(onValid: () -> Money): BuildResult<Money> {
    val errors = mutableListOf<String>()
    if (amount == null) {
      errors.add("You must specify an amount before proceeding.")
    } else if (amount.value < 0) errors.add("The amount must be greater or equal to zero.")
    return buildResult(errors, onValid = onValid)
  }
}
