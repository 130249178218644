package com.diyoffer.negotiation.repository.user

import co.touchlab.kermit.Logger
import com.copperleaf.ballast.BallastViewModelConfiguration
import com.copperleaf.ballast.build
import com.copperleaf.ballast.core.FifoInputStrategy
import com.copperleaf.ballast.repository.BallastRepository
import com.copperleaf.ballast.repository.bus.EventBus
import com.copperleaf.ballast.repository.withRepository
import com.diyoffer.negotiation.auth.FirebaseAuth
import com.diyoffer.negotiation.common.asEagerStateFlow
import com.diyoffer.negotiation.messages.InfoPopup
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.client.*
import com.diyoffer.negotiation.repository.user.UserRepositoryContract.Inputs
import com.diyoffer.negotiation.repository.user.UserRepositoryContract.State
import com.diyoffer.negotiation.rpcs.IUserRpcService
import com.diyoffer.negotiation.ui.login.firebaseAuthExceptionToMessage
import com.diyoffer.negotiation.ui.state.LoadingState
import dev.gitlive.firebase.FirebaseException
import dev.gitlive.firebase.auth.FirebaseAuthException
import kotlinx.coroutines.CompletableDeferred
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.map
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.toLocalDateTime

/**
 * This class holds the application-level state for the user session, including the Firebase instances
 * and other user-specific data. Many components may need the username or the authentication state so
 * these are shared here.
 */
@Suppress("TooManyFunctions")
class UserRepositoryImpl(
  private val coroutineScope: CoroutineScope,
  private val auth: FirebaseAuth,
  private val userRpcService: IUserRpcService,
  eventBus: EventBus,
  configBuilder: BallastViewModelConfiguration.Builder,
) : UserRepository, BallastRepository<Inputs, State>(
  coroutineScope = coroutineScope,
  eventBus = eventBus,
  config = configBuilder
    .apply {
      initialState = State()
      inputHandler = UserRepositoryInputHandler(auth, userRpcService)
      inputStrategy = FifoInputStrategy()
      name = UserRepository::class.simpleName
    }.withRepository().build()
) {
  private val user = observeStates().map { it.user }
    .asEagerStateFlow(coroutineScope, null)

  override fun getUser(): StateFlow<SessionUser?> = user

  override suspend fun initializeAuth() {
    sendAndAwaitCompletion(Inputs.Initialize)
  }

  override fun getLoadingState(): Flow<LoadingState> =
    observeStates().map { it.authLoadingState }

  override fun getAuthResult(): Flow<SuccessOrFailure?> =
    observeStates().map { it.authResult }

  override suspend fun linkCredentials(linkCredentials: LinkCredentials) {
    sendAndAwaitCompletion(Inputs.UserSignInLinkCredentials(linkCredentials))
  }

  override suspend fun startSignIn() {
    sendAndAwaitCompletion(Inputs.Initialize)
    sendAndAwaitCompletion(Inputs.SignInStarted)
  }

  override suspend fun startSocialSignup(country: Country) {
    sendAndAwaitCompletion(Inputs.Initialize)
    sendAndAwaitCompletion(
      Inputs.SignUpStarted(
        UserSettings.default().copy(
          country = country,
          currency = country.currency
        )
      )
    )
  }

  override suspend fun socialAuthCredentialsError(message: String) {
    sendAndAwaitCompletion(Inputs.AuthCredentialsFailed(message))
  }

  @Suppress("ReturnCount")
  override suspend fun signUpWithEmailAndPassword(
    email: String,
    password: String,
    country: Country,
    acceptMarketingContent: Boolean,
    acceptUserAgreementTerms: Boolean,
  ) {
    val userSettings = UserSettings(
      email = email,
      timeZone = user.value.timeZone(),
      country = country,
      currency = country.currency,
      acceptMarketingContent = acceptMarketingContent,
      acceptUserAgreementTerms = acceptUserAgreementTerms,
    )

    sendAndAwaitCompletion(Inputs.SignUpStarted(userSettings))

    try {
      auth.createUserWithEmailAndPassword(email, password).user
    } catch (e: FirebaseAuthException) {
      val message = firebaseAuthExceptionToMessage(e)
      Logger.d(e) { "Firebase auth exception doing create user, message=$message" }
      send(Inputs.UserSignUpFailed(message))
    } ?: send(Inputs.UserSignUpFailed("Sign-up failed"))
  }

  override suspend fun signInWithEmailAndPassword(
    email: String,
    password: String,
  ) {
    sendAndAwaitCompletion(Inputs.SignInStarted)

    try {
      auth.signInWithEmailAndPassword(email, password).user
    } catch (e: FirebaseAuthException) {
      val message = firebaseAuthExceptionToMessage(e)
      Logger.d(e) { "Firebase auth exception doing signIn, message=$message" }
      send(Inputs.UserSignInFailed(message))
    } ?: run {
      send(Inputs.UserSignInFailed("Sign-in failed"))
      return
    }
  }

  override suspend fun resetWithEmail(email: String): SuccessOrFailure =
    resetPassword(email)

  override suspend fun signOut() {
    try {
      auth.signOut()
      send(Inputs.UserSignedOut)
    } catch (e: FirebaseException) {
      Logger.e(e) { "Failed to sign out with firebase exception: ${e.message}" }
    }
  }

  override fun toLocalDateTime(instant: Instant): LocalDateTime {
    return instant.toLocalDateTime(user.value.timeZone())
  }

  private suspend fun resetPassword(email: String): SuccessOrFailure {
    try {
      auth.resetWithEmail(email)
    } catch (e: FirebaseAuthException) {
      return SuccessOrFailure.Failure(firebaseAuthExceptionToMessage(e))
    }
    val deferred = CompletableDeferred<SuccessOrFailure>()
    send(Inputs.UserResetPassword(deferred))
    return deferred.await()
  }

  override suspend fun setSignature(signature: String, random: String) {
    send(Inputs.SetSignature(signature, random))
  }

  override fun getQueuedPopup(): StateFlow<InfoPopup?> =
    observeStates().map { it.queuedPopup }.asEagerStateFlow(coroutineScope, null)

  override suspend fun queuePopup(popup: InfoPopup?) {
    send(Inputs.QueuePopup(popup))
  }

  override suspend fun initializeUserAtLoad(url: String) {
    send(Inputs.InitializeUserAtLoad(url))
  }
}
