package forms

import androidx.compose.runtime.Composable
import builders.CustomOptionKeyBuilder
import builders.OfferAdditionalRequestBuilder
import com.diyoffer.negotiation.common.replaceAt
import com.diyoffer.negotiation.model.*
import common.FlatList
import common.MultiTextField
import common.Row
import components.NegotiatedTermDisplay
import org.jetbrains.compose.web.dom.Text

@Suppress("ForbiddenComment")
@Composable
fun OfferAdditionalRequestEdit(
  builder: OfferAdditionalRequestBuilder,
  setBuilder: (OfferAdditionalRequestBuilder) -> Unit,
) {
  if (builder.negotiationStage == NegotiationStage.BUYER_DRAFTING_OFFER) {
    Row {
      MultiTextField(opts = {
        label = ""
        value = builder.additionalRequestBuilders?.mapNotNull {
          (it.counterValueBuilder as CustomOptionKeyBuilder).description
        }
        onModified = { setBuilder(builder.hydrate(it)) }
      })
    }
  } else {
    builder.additionalRequestBuilders?.mapIndexed { idx, requestBuild ->
      val key = requestBuild.getEffectiveValue() as OptionKey.Custom
      NegotiableItemInteract(optionKey = key, builder = requestBuild, setBuilder = {
        setBuilder(
          builder.copy(additionalRequestBuilders = builder.additionalRequestBuilders!!.replaceAt(idx, it))
        )
      }) {
        Text(key.value.text)
      }
    }
  }
}

@Composable
fun OfferAdditionalRequestDisplay(item: OfferAdditionalRequest, negotiationStage: NegotiationStage) {
  if (item.additionalRequests.isEmpty()) {
    FlatList("There is no additional request.")
  } else {
    item.additionalRequests.map {
      val msg = (it.currentValue.getOrNull() as? OptionKey.Custom)?.value?.text ?: ""
      if (negotiationStage == NegotiationStage.BUYER_DRAFTING_OFFER) {
        FlatList(msg)
      } else {
        NegotiatedTermDisplay(msg, it.state)
      }
    }
  }
}
