package com.diyoffer.negotiation.ui.login

import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.ui.state.LoadingState

object SignUpScreenContract {
  data class State(
    val initialized: Boolean = false,
    val email: String? = null,
    val emailError: String? = null,
    val country: Country = Country.US,
    val password: String? = null,
    val passwordError: String? = null,
    val error: String? = null,
    val authenticated: Boolean = false,
    val confirmPassword: String? = null,
    val confirmPasswordError: String? = null,
    val acceptMarketingContent: Boolean = false,
    val acceptUserAgreementTerms: Boolean = false,
    val acceptUserAgreementTermsError: String? = null,
    val loadingState: LoadingState = LoadingState.READY,
  ) {
    fun clearErrors() = copy(
      emailError = null,
      passwordError = null,
      confirmPasswordError = null,
      error = null,
      acceptUserAgreementTermsError = null,
    )
  }

  sealed class Inputs {
    data object Initialize : Inputs()
    data object ClearErrors : Inputs()
    data object SignUpWithSocial : Inputs()
    data object SignUpSuccess : Inputs()
    data class SignUpFailure(val message: String) : Inputs()
    data class LoadingStateChange(val loadingState: LoadingState) : Inputs()
    data class CountryChanged(val value: Country) : Inputs()
    data class EmailChanged(val value: String?) : Inputs()
    data class PasswordChanged(val value: String?) : Inputs()
    data class ConfirmPasswordChanged(val value: String?) : Inputs()
    data class AcceptMarketingContent(val value: Boolean) : Inputs()
    data class AcceptUserAgreementTerms(val value: Boolean) : Inputs()

    data object SignUpWithEmailAndPasswordClicked : Inputs()
  }

  sealed class Events {
    data object OnSuccess : Events()
  }
}
