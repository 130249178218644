package com.diyoffer.negotiation.model

import com.diyoffer.negotiation.model.serdes.*
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient

@Serializable
sealed class Auditable<out T : Any> {
  abstract val core: Core<T>

  @Serializable
  data class Core<out T : Any>(
    val value: Optional<T>,
    val timestamp: InstantLr,
    val message: String? = null,
  ) : Auditable<T>() {
    companion object {
      operator fun <T : Any> invoke(
        value: Optional<T>,
        timestamp: Instant,
        message: String? = null,
      ): Core<T> = Core(value, timestamp.lowRes(), message)
    }

    @Transient
    override val core: Core<T> = this

    fun enrich(
      userId: Uid<User>?,
      userIpAddress: String?,
      timestamp: Instant,
    ) = Enriched(this, userId, userIpAddress, timestamp)
  }

  @Serializable
  data class Enriched<out T : Any>(
    override val core: Core<T>,
    @Serializable(with = UidSerializer::class) val userId: Uid<User>?,
    val userIpAddress: String?,
    val timestamp: InstantLr,
  ) : Auditable<T>() {
    companion object {
      operator fun <T : Any> invoke(
        core: Core<T>,
        userId: Uid<User>?,
        userIpAddress: String?,
        timestamp: Instant,
      ): Enriched<T> = Enriched(core, userId, userIpAddress, timestamp.lowRes())
    }
  }
}

fun <T : Any> Auditable<T>.mapValue(transform: (Optional<T>) -> Optional<T>): Auditable<T> = when (this) {
  is Auditable.Core -> copy(value = transform(value))
  is Auditable.Enriched -> copy(core = core.copy(value = transform(core.value)))
}

fun <T : Any, A : Auditable<T>?> typedAuditableTransform(
  transform: (Auditable<*>?, Auditable<*>?) -> Auditable<*>?,
  input: A,
  other: Auditable<T>?,
): A {
  @Suppress("UNCHECKED_CAST")
  val transformed = transform(input, other) as A
  if (input != null) requireNotNull(transformed) { "Violated invariant: transformed non-null input $input to null output" }
  return transformed
}

typealias AuditableReader<T, AT> = T.() -> Auditable<AT>?

typealias AuditableWriter<T, AT> = T.(Auditable<AT>?) -> T

data class AuditableLens<T, AT : Any>(
  val reader: AuditableReader<T, AT>,
  val writer: AuditableWriter<T, AT>,
)

typealias AuditableListReader<T, AT> = T.() -> List<Auditable<AT>>?

typealias AuditableListWriter<T, AT> = T.(Int, Auditable<AT>) -> T

data class AuditableListLens<T, AT : Any>(
  val reader: AuditableListReader<T, AT>,
  val writer: AuditableListWriter<T, AT>,
)

/** A boolean value of an Auditable, true iff the value is true, false if false or null. */
fun Auditable<Boolean>.bool() = valueOrNull() == true

/** The value of an [Auditable] if [Present], otherwise null if [Empty] (present-null) or [Absent] (not specified). */
fun <T : Any> Auditable<T>.valueOrNull(): T? = core.value.getOrNull()
