package com.diyoffer.negotiation.rpcs

import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*
import io.kvision.annotations.KVBindingRoute
import io.kvision.annotations.KVService

@KVService
interface IDynamicOptionsRpcService {
  @KVBindingRoute("options/list_dynamic_default_conditions")
  suspend fun listDynamicDefaultConditions(
    forParty: Party,
    criteria: List<ConditionDefaultCriteria>,
    jurisdiction: Jurisdiction?,
  ): List<ConditionOption.Core>

  /**
   * List selectable conditions. If excludeDefaultCriteria is:
   * * null or empty list: then all selectable defaults are returned, nothing is excluded
   * * non-empty list: then all selectable defaults are returned, excluding the specified ones -- see
   *   [ConditionDefaultCriteria.exceptNever] for a common exclusion pattern i.e.
   *   `listDynamicSelectableConditions(ConditionDefaultCriteria.exceptNever())` would exclude all
   *   conditions that are always or sometimes used as defaults.
   */
  @KVBindingRoute("options/list_dynamic_selectable_conditions")
  suspend fun listDynamicSelectableConditions(
    forParty: Party,
    excludeDefaultCriteria: List<ConditionDefaultCriteria>? = null,
    jurisdiction: Jurisdiction? = null,
  ): List<ConditionOption.Core>

  /**
   * This is used when a condition option key is available e.g. an existing listing or offer, and the details
   * need to be hydrated.
   */
  @KVBindingRoute("options/hydrate_dynamic_condition_option_key")
  suspend fun hydrateDynamicConditionOptionKey(optionKeyId: DynamicUid): HydrateDynamicConditionResult

  @KVBindingRoute("options/hydrate_dynamic_condition_option_keys")
  suspend fun hydrateDynamicConditionOptionKeys(optionKeyIds: List<DynamicUid>): List<HydrateDynamicConditionResult>
}
