package builders

import com.diyoffer.negotiation.model.*

data class CustomOptionKeyBuilder(
  val title: String? = null,
  val description: String? = null,
) : IBuilder<OptionKey> {
  override fun hydrate(item: OptionKey?): CustomOptionKeyBuilder {
    val customKey = item as? OptionKey.Custom
    require(item == null || customKey != null) { "This can only be hydrated with OptionKey.Custom" }
    return copy(
      title = customKey?.title,
      description = customKey?.value?.text
    )
  }

  override fun build(): BuildResult<OptionKey> = validateAndBuild {
    OptionKey.Custom(
      title = title!!,
      value = Asciidoc(description ?: "")
    )
  }

  private fun validateAndBuild(onValid: () -> OptionKey): BuildResult<OptionKey> {
    return buildResult(
      errors = if (title.isNullOrBlank()) listOf("You must provide some content") else emptyList(),
      onValid = onValid
    )
  }
}
