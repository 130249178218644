package com.diyoffer.negotiation.ui.login

import com.copperleaf.ballast.EventHandler
import com.copperleaf.ballast.EventHandlerScope
import com.diyoffer.negotiation.ui.login.SignInScreenContract.Events
import com.diyoffer.negotiation.ui.login.SignInScreenContract.Inputs
import com.diyoffer.negotiation.ui.login.SignInScreenContract.State

class SignInScreenEventHandler(
  private val onSuccess: () -> Unit,
) : EventHandler<Inputs, Events, State> {
  override suspend fun EventHandlerScope<Inputs, Events, State>.handleEvent(event: Events) = when (event) {
    is Events.OnSuccess -> onSuccess()
  }
}
