package com.diyoffer.negotiation.rpcs

import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*
import io.kvision.annotations.KVBindingRoute
import io.kvision.annotations.KVService
import kotlinx.datetime.TimeZone

/**
 * Implements anonymous offer operations. Each operation requires a random value and signature which is part of
 * a signed URL, which are validated by the backend before the operation is executed.
 *
 * An uncomfortable problem with this is the following: an offer link may be valid at the time it is clicked on,
 * which allows the user to load the offer. However, the signature may subsequently expire before the offer can be
 * saved or published, which will lead to an error, and no easy way for the user to "keep" their changes in progress.
 *
 * Some workarounds for this are possible and can be considered post-MVP:
 * - at load time, return "expired signature" a day or so *before* the actual expiry -- this makes it more likely that
 *   the user will have time to make changes
 * - periodically refresh the signature and random values behind the scenes while the browser is active, by using the
 *   existing signature and random values
 */
@KVService
interface IOfferAnonRpcService {
  /**
   * Creates a new offer. This is the only time no authentication in the form of a random value and signature is
   * not required. After this time, the random value and signature are required. So that the user can continue within
   * the same context, these values are provided in the response to this function.
   *
   * At least one unverified [OfferContacts.buyers] contact is required for offer creation.
   */
  @KVBindingRoute("offer/anon/create")
  suspend fun create(
    offer: Offer.Draft,
    contacts: OfferContacts,
  ): OfferCreateResult

  /**
   * Saves an offer. Note that the offer may be an [Offer.Draft] (if the offer is being created by the buyer
   * and has not yet been published), or it may be an [Offer.Published] (if the offer has been published and is now
   * in the process of being negotiated. A save of an [Offer.Published] changes the state of the published offer
   * to [Offer.State.DRAFT] (but the datatype remains [Offer.Published]).
   */
  @KVBindingRoute("offer/anon/save")
  suspend fun save(
    offer: Offer,
    random: String,
    signature: String,
  ): OfferSignatureSaveResult

  /**
   * Saves the contacts for an offer. If the changes are accepted, it does not change the offer version, and applies
   * immediately to all offer versions, past and present. Any contacts which are new must be unverified.
   */
  @KVBindingRoute("offer/anon/save_contacts")
  suspend fun saveContacts(
    id: UidValue<Offer>,
    contacts: OfferContacts,
    random: String,
    signature: String,
  ): OfferContactsSignatureSaveResult

  /**
   * Loads offer data for an offer by an anonymous buyer. Here, the user is anonymous **but**
   * in order to see the offer data, the user must provide a valid offer id, random data, and
   * signature in order to access the offer. This information is sent to validated emails
   * attached to the offer as a link in an email.
   *
   * This endpoint must not be used by authenticated users.
   *
   * We validate:
   *
   * * The user has provided a valid offer id, random data, and signature.
   * * The offer is published, or to see a draft, the author of the draft matches the user.
   */
  @KVBindingRoute("offer/anon/load")
  suspend fun load(
    id: UidValue<Offer>,
    random: String,
    signature: String,
    versionOption: LoadOfferVersionOption = LoadOfferVersionOption.LATEST,
  ): OfferSignatureLoadResult

  /**
   * Publishes a given offer i.e. changes state from [Offer.State.DRAFT] to [Offer.State.PUBLISHED].
   * This method does not accept any changes to the offer -- use [save] for modifying a draft before
   * publishing.
   *
   * This endpoint must not be used by authenticated users.
   */
  @KVBindingRoute("offer/anon/publish")
  suspend fun publish(
    offer: Offer,
    random: String,
    signature: String,
  ): OfferSignatureSaveResult

  @KVBindingRoute("offer/anon/reject")
  suspend fun reject(
    offerId: UidValue<Offer>,
    random: String,
    signature: String,
  ): OfferSignatureSaveResult

  @KVBindingRoute("offer/accept")
  suspend fun accept(
    offerId: UidValue<Offer>,
    random: String,
    signature: String,
  ): OfferSignatureSaveResult

  /**
   * Retrieves the checklist for the offer. If anon, caller is assumed to be [Party.BUYER]
   */
  @KVBindingRoute("offer/anon/checklist")
  suspend fun checklist(offer: Offer, timeZone: TimeZone): ChecklistResult
}
