package com.diyoffer.negotiation.model.rpcs

import com.diyoffer.negotiation.model.rpcs.OfferContactsSignatureSaveResult.*
import kotlinx.serialization.Serializable

/**
 * Wraps [OfferContactsSaveResult], and includes particular types for signature failures.
 *
 * If the signature is a [ValidSignature], then the [OfferContactsSaveResult] may be retrieved.
 */
@Serializable
sealed class OfferContactsSignatureSaveResult {
  @Serializable
  data class ValidSignature(val saveResult: OfferContactsSaveResult) : OfferContactsSignatureSaveResult()

  @Serializable
  data object ExpiredSignature : OfferContactsSignatureSaveResult()

  @Serializable
  data object InvalidSignature : OfferContactsSignatureSaveResult()

  fun message() = when (this) {
    is ValidSignature -> "Valid Signature"
    is ExpiredSignature -> "Expired Signature"
    is InvalidSignature -> "Invalid Signature"
  }
}
