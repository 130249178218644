package services

import androidx.compose.runtime.Composable
import androidx.compose.runtime.State
import androidx.compose.runtime.produceState
import co.touchlab.kermit.Logger
import com.diyoffer.negotiation.common.retry
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.rpcs.IOfferAnonRpcService
import com.diyoffer.negotiation.rpcs.onRpcAttempt
import com.diyoffer.negotiation.services.tryRpc
import kotlinx.serialization.Serializable
import org.w3c.dom.Location
import org.w3c.dom.Window

@Serializable
sealed class SignatureLoadOfferState {
  @Serializable
  data class Success(val offer: Offer, val contacts: OfferContacts) : SignatureLoadOfferState()

  @Serializable
  data object Loading : SignatureLoadOfferState()

  @Serializable
  data object NotFound : SignatureLoadOfferState()

  @Serializable
  data object ExpiredSignature : SignatureLoadOfferState()

  @Serializable
  data object InvalidSignature : SignatureLoadOfferState()

  @Serializable
  data object Error : SignatureLoadOfferState()
}

/**
 * Load an offer anonymously using a signed link. The link is obtained from the window location. The
 * path should have the form:
 *
 * ```
 * /offer/{id}/{random}/signed/{signature}
 * ```
 *
 * Example link: http://localhost:9093/offer/acc8fabb-c5da-4e62-93f5-bcb55d44d24a/HC0_fo8hn317wLs/signed/ZGV2LWRpeW9mZmVyXDE2NDQ2MjEzNzFcdEN0Nnd0WXQtclJvbWF5eGpJVDJYZnBBbk5BV1hZR1VEZVpJRTdjVXhsUVw
 */
class OfferAnonLoader(
  private val offerAnonRpcService: IOfferAnonRpcService,
) {
  companion object {
    data class SignatureComponents(val id: String, val random: String, val signature: String)
  }

  @Composable
  fun load(signatureComponents: SignatureComponents?): State<SignatureLoadOfferState> {
    @Suppress("TooGenericExceptionCaught")
    return produceState<SignatureLoadOfferState>(initialValue = SignatureLoadOfferState.Loading) {
      if (signatureComponents == null) {
        SignatureLoadOfferState.Error
      } else {
        @Suppress("MaxLineLength")
        retry(
          onAttempt = onRpcAttempt { a, e ->
            Logger.w(
              e
            ) { "Error loading buyer offer via secure link, attempt=$a, will retry" }
          }
        ) {
          tryRpc({ _, _ -> value = SignatureLoadOfferState.Error }) {
            val result = offerAnonRpcService.load(
              id = UidValue(signatureComponents.id.toUid()),
              random = signatureComponents.random,
              signature = signatureComponents.signature,
            )
            value = when (result) {
              is OfferSignatureLoadResult.ValidSignature -> when (val l = result.loadResult) {
                is OfferLoadResult.Success -> SignatureLoadOfferState.Success(l.offer, l.contacts)
                OfferLoadResult.NotFound -> SignatureLoadOfferState.NotFound
              }
              OfferSignatureLoadResult.ExpiredSignature -> SignatureLoadOfferState.ExpiredSignature
              OfferSignatureLoadResult.InvalidSignature -> SignatureLoadOfferState.InvalidSignature
            }
          }
        }
      }
    }
  }

  @Composable
  fun load(window: Window): State<SignatureLoadOfferState> {
    val signatureComponents = locationParser(window.location)
    return load(signatureComponents)
  }

  @Suppress("MagicNumber")
  private fun locationParser(location: Location): SignatureComponents? {
    val pathElements = location.pathname.split("/").drop(1)
    if (pathElements.size < 5 || pathElements[0] != "offer" || pathElements[3] != "signed") return null
    return SignatureComponents(
      id = pathElements[1],
      random = pathElements[2],
      signature = pathElements[4],
    )
  }
}
