package com.diyoffer.negotiation.repository.offer

import co.touchlab.kermit.Logger
import com.copperleaf.ballast.BallastViewModelConfiguration
import com.copperleaf.ballast.build
import com.copperleaf.ballast.core.FifoInputStrategy
import com.copperleaf.ballast.repository.BallastRepository
import com.copperleaf.ballast.repository.bus.EventBus
import com.copperleaf.ballast.repository.cache.Cached
import com.copperleaf.ballast.repository.cache.getCachedOrEmptyList
import com.copperleaf.ballast.repository.cache.getCachedOrNull
import com.copperleaf.ballast.repository.cache.map
import com.copperleaf.ballast.repository.withRepository
import com.diyoffer.negotiation.analytics.AnalyticsClient
import com.diyoffer.negotiation.analytics.AnalyticsEvent
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.repository.listing.SellerListingRepository
import com.diyoffer.negotiation.repository.offer.SellerOfferRepositoryContract.Inputs
import com.diyoffer.negotiation.repository.offer.SellerOfferRepositoryContract.State
import com.diyoffer.negotiation.repository.user.UserRepository
import com.diyoffer.negotiation.rpcs.IOfferAuthRpcService
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.distinctUntilChanged
import kotlinx.coroutines.flow.filter
import kotlinx.coroutines.flow.map

/**
 * This class holds the application-level state for offers related to a listing.
 */
@Suppress("LongParameterList")
class SellerOfferRepositoryImpl(
  private val offerAuthRpcService: IOfferAuthRpcService,
  private val sellerListingRepo: SellerListingRepository,
  private val userRepo: UserRepository,
  private val analyticsClient: AnalyticsClient,
  coroutineScope: CoroutineScope,
  private val eventBus: EventBus,
  configBuilder: BallastViewModelConfiguration.Builder,
) : SellerOfferRepository, BallastRepository<Inputs, State>(
  coroutineScope = coroutineScope,
  eventBus = eventBus,
  config = configBuilder.apply {
    initialState = State()
    inputHandler = SellerOfferRepositoryInputHandler(offerAuthRpcService, sellerListingRepo, userRepo, eventBus)
    inputStrategy = FifoInputStrategy()
    name = SellerOfferRepository::class.simpleName
  }.withRepository().build()
) {
  private val states = observeStates()
  private val userOffers = states.map { it.userOffers }.distinctUntilChanged()
  private val listings = states.map { it.listings }.distinctUntilChanged()

  init {
    trySend(Inputs.Initialize)
  }

  override suspend fun invalidateCacheAndRefreshOffers() {
    send(Inputs.InvalidateCache(true))
  }

  override suspend fun listingOffers(): Flow<Cached<List<Pair<Offer, OfferContacts>>>> {
    send(Inputs.RefreshOffers)
    return userOffers
  }

  override fun listings(): Flow<Cached<List<ListingLoadResult.Success>>> = listings

  // For now, all the offers belonging to the listing owned by sellers are available on the scope
  // This is a simple helper function that returns a flow with the offer available in the context.
  // If we start to have customers with a large number of offers, we should replace this by
  // a call to fetch single offer at a time.
  override suspend fun offer(offerId: Uid<Offer>): Flow<Cached<Pair<Offer, OfferContacts>>> {
    return listingOffers()
      .filter {
        // pass through loading states
        it.getCachedOrNull()?.any { it.first._id == offerId } ?: true
      }
      .map { sellerOffers ->
        sellerOffers.map { it.first { it.first._id == offerId } }
      }
  }

  override suspend fun saveOffer(offer: Offer) = offerAuthRpcService.save(offer).also {
    analyticsClient.logEvent(AnalyticsEvent.OfferSave(offer._id, Party.SELLER))
    // Do not refetch offer as we are saving in place on the screen
    if (it is OfferSaveResult.Success) send(Inputs.InvalidateCache(false))
  }

  override suspend fun publishOffer(offer: Offer) = offerAuthRpcService.publish(offer).also {
    analyticsClient.logEvent(AnalyticsEvent.OfferPublish(offer._id, Party.SELLER))
    if (it is OfferSaveResult.Success) invalidateCacheAndRefreshOffers()
  }

  override suspend fun acceptOffer(offerId: Uid<Offer>) = offerAuthRpcService.accept(UidValue(offerId)).also {
    analyticsClient.logEvent(AnalyticsEvent.OfferAccept(offerId, Party.SELLER))
    if (it is OfferSaveResult.Success) {
      invalidateCacheAndRefreshOffers()
    }
  }

  override suspend fun completeOffer(offerId: Uid<Offer>) = offerAuthRpcService.complete(UidValue(offerId)).also {
    analyticsClient.logEvent(AnalyticsEvent.OfferComplete(offerId))
    if (it is OfferSaveResult.Success) {
      // Completing the offer affects the associated listing (ie: mark as COMPLETE), so we need to refresh
      sellerListingRepo.invalidateCache()
      send(Inputs.InvalidateCache(refreshOffers = true))
    }
  }

  override suspend fun rejectOffer(offerId: Uid<Offer>) = offerAuthRpcService.reject(UidValue(offerId)).also {
    analyticsClient.logEvent(AnalyticsEvent.OfferReject(offerId, Party.SELLER))
    if (it is OfferSaveResult.Success) {
      send(Inputs.InvalidateCache(refreshOffers = true))
    }
  }

  override suspend fun cancelOffer(offerId: Uid<Offer>, reason: String) =
    offerAuthRpcService.cancel(UidValue(offerId), reason).also {
      analyticsClient.logEvent(AnalyticsEvent.OfferCancel(offerId))
      if (it is OfferSaveResult.Success) {
        // Cancelling the offer invalidates the accepted offer value provided along the listing
        sellerListingRepo.invalidateCacheAndFetchListings()
        invalidateCacheAndRefreshOffers()
      }
    }

  override suspend fun saveLegalContact(
    offerId: Uid<Offer>,
    legalContact: Contact,
  ): OfferContactsSaveResult {
    val s = states.value
    val offerContacts = s.userOffers.getCachedOrEmptyList().firstOrNull { it.first._id == offerId }?.second
    return if (offerContacts == null) {
      Logger.e("Could not find matching offer $offerId in local sellerOffer repo")
      OfferContactsSaveResult.OfferNotFound
    } else {
      analyticsClient.logEvent(AnalyticsEvent.OfferSaveLegal(offerId, Party.SELLER))
      offerAuthRpcService.saveContacts(
        id = UidValue(offerId),
        contacts = offerContacts.copy(sellerLegal = legalContact),
      ).also {
        if (it is OfferContactsSaveResult.Success) {
          invalidateCacheAndRefreshOffers()
        }
      }
    }
  }
}
