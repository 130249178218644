package com.diyoffer.negotiation.ui.listing

import com.copperleaf.ballast.InputHandler
import com.copperleaf.ballast.InputHandlerScope
import com.copperleaf.ballast.postInput
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.rpcs.IListingAnonRpcService
import com.diyoffer.negotiation.services.tryRpc
import com.diyoffer.negotiation.ui.listing.ListingLandingScreenContract.Events
import com.diyoffer.negotiation.ui.listing.ListingLandingScreenContract.Inputs
import com.diyoffer.negotiation.ui.listing.ListingLandingScreenContract.State
import com.diyoffer.negotiation.ui.state.LoadingState
import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime

class ListingLandingScreenInputHandler(
  private val listingAnonRpcService: IListingAnonRpcService,
  private val clock: Clock,
) : InputHandler<Inputs, Events, State> {
  @Suppress("ComplexMethod", "LongMethod")
  override suspend fun InputHandlerScope<Inputs, Events, State>.handleInput(input: Inputs) = when (input) {
    is Inputs.FetchListing -> {
      updateState { it.copy(loadingState = it.loadingState.transitionToFetching()) }
      tryRpc(onException = { message, _ -> postInput(Inputs.SetError(message)) }) {
        when (val r = listingAnonRpcService.loadListingByPermalink(input.permalink)) {
          is ListingLoadResult.Success -> postInput(
            Inputs.ListingUpdated(r.listing as Listing.Published, r.offerCount ?: 0)
          )

          else -> updateState { it.copy(error = r.message(), loadingState = LoadingState.ERROR) }
        }
      }
    }

    is Inputs.ListingUpdated -> {
      val withheldUntil = input.listing.details.offerWithholding?.until?.valueOrNull()
      val nowAnon = clock.now().toLocalDateTime(TimeZone.currentSystemDefault())
      updateState {
        it.copy(
          id = input.listing._id,
          permalink = input.listing.permalink,
          name = input.listing.propertyDetails.address.shortestSummary(),
          address = input.listing.propertyDetails.address,
          price = input.listing.details.price.core.value.get(),
          deposit = input.listing.details.deposit.core.value.get(),
          withheldUntil = if (withheldUntil != null && withheldUntil > nowAnon) withheldUntil else null,
          description = input.listing.marketingInformation?.description,
          links = input.listing.externalLinks.links,
          details = input.listing.marketingInformation?.details,
          thingsYouNeedToKnow = input.listing.marketingInformation?.thingsYouNeedToKnow,
          currency = input.listing.currency,
          isCompleted = input.listing.state == Listing.State.COMPLETED,
          offerCount = input.offerCount,
          loadingState = LoadingState.READY
        )
      }
    }

    is Inputs.SetError -> updateState { it.copy(error = input.error, loadingState = LoadingState.ERROR) }
  }
}
