package com.diyoffer.negotiation.common

/**
 * There is no perfect email address regex validation, as the email spec is very complicated. See
 * https://www.regular-expressions.info/email.html for a good discussion. The following should match
 * pretty much any e-mail address. The true test is whether we can actually deliver the email, and that
 * is taken care of via the validation process.
 */
val EMAIL_VALIDATION_REGEX = "^[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,}$".toRegex(RegexOption.IGNORE_CASE)

val POSTAL_CODE_VALIDATION_REGEX =
  "^[ABCEGHJ-NPRSTVXY]\\d[ABCEGHJ-NPRSTV-Z][ -]?\\d[ABCEGHJ-NPRSTV-Z]\\d\$".toRegex(RegexOption.IGNORE_CASE)

val ZIP_CODE_VALIDATION_REGEX = "^[0-9]{5}(?:-[0-9]{4})?$".toRegex(RegexOption.IGNORE_CASE)

val LINK_VALIDATION_REGEX =
  "((http|https)://)(www.)?[a-zA-Z0-9@:%._\\d~#?&/=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\d~#?&/=]*)"
    .toRegex(RegexOption.IGNORE_CASE)
