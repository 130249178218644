//
// GENERATED by KVision
//
package com.diyoffer.negotiation.rpcs

import org.w3c.fetch.RequestInit
import io.kvision.remote.KVRemoteAgent
import kotlinx.serialization.modules.SerializersModule
import com.diyoffer.negotiation.model.Offer
import com.diyoffer.negotiation.model.UidValue
import com.diyoffer.negotiation.model.rpcs.SendBuyerOfferLinkResult

actual class LinksRpcService(serializersModules: List<SerializersModule>? = null, requestFilter: (suspend RequestInit.() -> Unit)? = null) : ILinksRpcService, KVRemoteAgent<LinksRpcService>(LinksRpcServiceManager, serializersModules, requestFilter) {
    override suspend fun sendBuyerOfferLink(id: UidValue<Offer>) = call(ILinksRpcService::sendBuyerOfferLink, id)
}
