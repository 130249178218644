package com.diyoffer.negotiation.common

import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlin.time.Duration.Companion.hours

/**
 * Determine whether a particular local date/time is ambiguous because of a "fall back" DST shift.
 *
 * Unfortunately this implementation is not ideal -- it makes an assumption that the overlap period is exactly
 * 1 hour, no more and no less. However, practically speaking it should work reasonably well. See
 * https://github.com/Kotlin/kotlinx-datetime/issues/163#issuecomment-1084086868 for more details.
 */
fun Instant.isAmbiguous(zone: TimeZone) =
  toLocalDateTime(zone) == plus(1.hours).toLocalDateTime(zone)

/**
 * Given an [Instant] returns the same or next unambiguous [Instant] in the given zone. Based on [isAmbiguous].
 */
fun Instant.toUnambiguousInstant(zone: TimeZone): Instant =
  generateSequence(this) { it.plus(1.hours) }
    .dropWhile { it.isAmbiguous(zone) }
    .first()

fun Clock.today(timeZone: TimeZone) = now().toLocalDateTime(timeZone).date
