package layout

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import app.softwork.routingcompose.Router
import common.FlexRow
import components.ChangeLoginStateButton
import components.CreateEditTermsActionButton
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import style.DiyStyleSheet
import vm.login.UserViewModel

@Composable
fun AppContainer(
  userVm: UserViewModel,
  sideBarContent: ContentBuilder<HTMLDivElement>? = null,
  informationBarText: String? = null,
  content: ContentBuilder<HTMLDivElement>,
) {
  val router = Router.current
  val userState by userVm.observeStates().collectAsState()

  Header {
    Button({
      classes(DiyStyleSheet.button, DiyStyleSheet.headerButtonLink)
      onClick { router.navigate("/home") }
    }) {
      Text("Home")
    }

    ChangeLoginStateButton(userVm, userState.user)
    CreateEditTermsActionButton(userState.draftListing, router)
  }

  if (informationBarText != null) {
    InformationBar(informationBarText)
  }

  FlexRow(
    {
      style {
        justifyContent(if (sideBarContent == null) JustifyContent.Center else JustifyContent.FlexStart)
        alignItems(AlignItems.Start)
        gap(109.px)
      }
    }
  ) {
    sideBarContent?.let { Div(content = it) }
    Div({
      id("diy")
      classes(DiyStyleSheet.appContainer)
    }, content = content)
  }
}
