package com.diyoffer.negotiation.model.rpcs

import com.diyoffer.negotiation.model.rpcs.OfferSignatureSaveResult.*
import kotlinx.serialization.Serializable

/**
 * Wraps [OfferSaveResult], and includes particular types for signature failures.
 *
 * If the signature is a [ValidSignature], then the [OfferSaveResult] may be retrieved.
 */
@Serializable
sealed class OfferSignatureSaveResult {
  @Serializable
  data class ValidSignature(val saveResult: OfferSaveResult) : OfferSignatureSaveResult()

  @Serializable
  data object ExpiredSignature : OfferSignatureSaveResult()

  @Serializable
  data object InvalidSignature : OfferSignatureSaveResult()

  fun message() = when (this) {
    is ValidSignature -> "Valid Signature"
    is ExpiredSignature -> "The access to the offer has expired."
    is InvalidSignature -> "Unauthorized to access this offer."
  }
}
