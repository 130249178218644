package components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import style.DiyStyleSheet
import style.GridStyleSheet
import style.WhatTodoStyleSheet

@Composable
fun NoItemDisplay(title: String, description: String) = NoItemDisplay(title) {
  Span({ classes(WhatTodoStyleSheet.noActivityDescription) }) {
    Text(description)
  }
}

@Composable
fun NoItemDisplay(title: String, content: @Composable () -> Unit) {
  Div({
    classes(DiyStyleSheet.mainForm)
  }) {
    Div({
      classes(
        GridStyleSheet.flex,
        GridStyleSheet.flexColumn,
        GridStyleSheet.justifyContentCenter,
        GridStyleSheet.alignItemsCenter
      )
    }) {
      Img("images/no-activity.png")
      Span({ classes(WhatTodoStyleSheet.noActivity) }) {
        Text(title)
      }
      content()
    }
  }
}
