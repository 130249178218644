package com.diyoffer.negotiation.workflows

import com.diyoffer.negotiation.common.services.offers.createOfferDraftFromListing
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.rpcs.IListingAnonRpcService
import com.diyoffer.negotiation.rpcs.IOfferAnonRpcService

class ListingToOfferWorkflow(
  private val offerAnonRpcService: IOfferAnonRpcService,
  private val listingAnonRpcService: IListingAnonRpcService,
) {
  suspend fun createOfferDraft(
    listingId: Uid<Listing>,
    contacts: OfferContacts,
  ): OfferCreateResult {
    return when (val loadResult = listingAnonRpcService.loadListingById(UidValue(listingId))) {
      is ListingLoadResult.Success -> {
        val offerDraft = createOfferDraftFromListing(loadResult.listing as Listing.Published)
        return offerAnonRpcService.create(offerDraft, contacts)
      }
      is ListingLoadResult.NotFound -> OfferCreateResult(OfferSaveResult.ListingNotFound, "", "")
    }
  }
}
