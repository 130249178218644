package com.diyoffer.negotiation.common.services.listings

import com.diyoffer.negotiation.common.services.transformers.derichAuditableTransformerFn
import com.diyoffer.negotiation.common.services.transformers.derichExpiry
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.atStartOfDayIn

fun Listing.derichAll(forValidation: Boolean = false): Listing =
  derichAuditables().derichEvents().derichExpiries(forValidation)

fun Listing.derichEvents(): Listing = when (this) {
  is Listing.Draft -> copy(events = events.map { it.derich() })
  is Listing.Published -> copy(events = events.map { it.derich() })
}

fun <T : Listing> T.derichAuditables(): T =
  transformAuditablesGeneric(null, ::derichAuditableTransformerFn)

fun <T : Listing> T.derichExpiries(forValidation: Boolean): Listing {
  // casts are a workaround for https://youtrack.jetbrains.com/issue/KT-21908
  @Suppress("UNCHECKED_CAST")
  return when (val l = this as Listing) {
    is Listing.Draft -> l.copy(sellerConditions = l.sellerConditions?.derichExpiries(forValidation))
    is Listing.Published -> l.copy(sellerConditions = l.sellerConditions.derichExpiries(forValidation))
  }
}

fun SellerConditions.derichExpiries(forValidation: Boolean): SellerConditions = copy(
  conditions = conditions.derichExpiries(forValidation)
)

fun List<Auditable<Condition>>.derichExpiries(forValidation: Boolean): List<Auditable<Condition>> = map { condition ->
  condition.mapValue { it.mapPresent { c -> c.derichExpiry(forValidation) } }
}

@Suppress("UNCHECKED_CAST")
fun <T : Listing> T.transformAuditablesGeneric(
  comparedWithListing: T?,
  transform: (current: Auditable<*>?, existing: Auditable<*>?) -> Auditable<*>?,
): T {
  // casts are a workaround for https://youtrack.jetbrains.com/issue/KT-21908
  return when (val o = this as Listing) {
    is Listing.Draft -> o.transformAuditables(comparedWithListing as? Listing.Draft, transform)
    is Listing.Published -> o.transformAuditables(comparedWithListing as? Listing.Published, transform)
  } as T
}

/**
 * Returns an ordered list of [Listing]s. Drafts are first, then listings with upcoming closing dates second, and
 * finally ordered by time delta between the closing date and now.
 */
fun List<ListingLoadResult.Success>.sortedByClosingDate(now: Instant, tz: TimeZone): List<ListingLoadResult.Success> = map {
  it to run {
    it.listing.closingDate()
      ?.let { closingDate -> now.epochSeconds - closingDate.atStartOfDayIn(tz).epochSeconds }
      ?: Long.MAX_VALUE
  }
}.sortedWith(
  compareBy({ (listingRes) ->
    // First. put drafts first
    if (listingRes.listing is Listing.Draft) 0 else 1
  }, { (_, diffSeconds) ->
    // Then, list the listing that are closing in the future in preference of previous listings
    if (diffSeconds > 0) 1 else 0
  }, { (_, diffSeconds) ->
    // Finally, order by delta to now
    diffSeconds
  })
).map { (listing) -> listing }

fun Listing.closingDate() = when (this) {
  is Listing.Draft -> details?.closingDate?.core?.value?.getOrNull()
  is Listing.Published -> details.closingDate.core.value.getOrNull()
}

fun Listing.buildConditionFilters(): ConditionFilters {
  val criteria = mutableListOf(ConditionDefaultCriteria.ALWAYS)
  return propertyDetails?.let {
    if (it.propertyOwnership is PropertyOwnership.CondoStrata) {
      criteria.add(ConditionDefaultCriteria.CONDO)
    }
    if ((it.propertyOwnership.monthlyFees?.value ?: 0) > 0) {
      criteria.add(ConditionDefaultCriteria.ASSOCIATION_FEES_NONZERO)
    }
    return ConditionFilters(
      criteria,
      Jurisdiction(it.address.country, it.address.provinceState)
    )
  } ?: ConditionFilters()
}

fun Listing.Published.ownersContactSummary() = propertyOwners.contacts.contactSummary()

fun Listing.Published.addressStandardSummary() = propertyDetails.address.standardSummary()

fun Listing.Published.allSellerEmails() = propertyOwners.contacts.allEmails()
