package builders

import com.diyoffer.negotiation.common.services.validation.additionalRequestStateModel
import com.diyoffer.negotiation.model.*
import kotlinx.datetime.Clock

data class OfferAdditionalRequestBuilder(
  val party: Party,
  val negotiationStage: NegotiationStage,
  val currency: Currency,
  val clock: Clock,
  val additionalRequestBuilders: List<NegotiatedTermBuilder<OptionKey>>? = emptyList(),
) : IBuilder<OfferAdditionalRequest> {
  override fun hydrate(item: OfferAdditionalRequest?) = copy(
    additionalRequestBuilders = item?.additionalRequests?.map {
      newBuilder(party, it)
    } ?: emptyList()
  )

  fun hydrate(requests: List<String>?) = copy(
    additionalRequestBuilders = requests?.mapIndexed { idx, msg ->
      newBuilder(
        party,
        newTerm(
          OptionKey.Custom("Buyer Request ${idx + 1}", Asciidoc(msg)),
          negotiationStage.authoringParty,
          clock
        )
      )
    } ?: emptyList()
  )

  override fun build(): BuildResult<OfferAdditionalRequest> = validateAndBuild {
    OfferAdditionalRequest(
      additionalRequests = additionalRequestBuilders?.map {
        (it.build() as BuildResult.Success).result
      } ?: emptyList()
    )
  }

  @Suppress("SpreadOperator")
  private fun validateAndBuild(onValid: () -> OfferAdditionalRequest): BuildResult<OfferAdditionalRequest> {
    return buildResult().mergeAndRun(
      onValid,
      *(additionalRequestBuilders?.map { it.build() }?.toTypedArray() ?: emptyArray())
    )
  }

  private fun newBuilder(party: Party, term: NegotiatedTerm<OptionKey>) = NegotiatedTermBuilder(
    party = party,
    negotiationStage = negotiationStage,
    term = term,
    stateValidation = ::additionalRequestStateModel,
    counterValueBuilder = CustomOptionKeyBuilder().hydrate(term.currentValue.getOrNull()),
    currency = currency,
    clock = clock
  )
}
