package com.diyoffer.negotiation.model

import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuid4
import com.benasher44.uuid.uuidFrom
import com.diyoffer.negotiation.model.serdes.UidSerializer
import com.diyoffer.negotiation.model.serdes.UuidSerializer
import kotlinx.serialization.Serializable
import kotlin.jvm.JvmInline

/**
 * A typed id class. Typed ids are more type-safe than using a simple UUID or String directly, because
 * functions can specify the type(s) of the ids they can work with.
 */
sealed interface Uid<T>

/**
 * A serializable implementation of [Uid] that is backed by a [Uuid].
 */
@JvmInline
value class UuidUid<T>(@Serializable(with = UuidSerializer::class) val id: Uuid) : Uid<T> {
  override fun toString() = id.toString()
}

/**
 * Generate a new [Uid] using the default implementation, which is currently [UuidUid].
 */
fun <T> newUid(): Uid<T> = uuid4().toUid()

/**
 * Create a new [Uid] from a [Uuid].
 */
fun <T> Uuid.toUid(): Uid<T> = UuidUid(this)

/**
 * Create a new [Uid] from the current [String], which must be a UUID.
 */
fun <T> String.toUid(): Uid<T> = uuidFrom(this).toUid()

/**
 * Due to a limitation in Kotlinx-serialization, we cannot directly send `Uid<T>` as an RPC parameter, because
 * "sealed interface" is not yet recognized as a sealed class hierarchy automatically (see
 * https://github.com/Kotlin/kotlinx.serialization/issues/1576, should be supported with Kotlin 1.6.20).
 *
 * For now, we wrap the Uid in a value class, which solves the problem.
 */
@Serializable
@JvmInline
value class UidValue<T>(
  @Serializable(with = UidSerializer::class) val uid: Uid<T>,
)
