package com.diyoffer.negotiation.model.auth

import kotlinx.serialization.Serializable

@Serializable
data class Identity(
  val uid: String,
  val source: IdentitySource,
)

enum class IdentitySource {
  GCLOUD_IDENTITY_PLATFORM,
}
