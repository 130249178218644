package vm.checklist

import com.copperleaf.ballast.BallastViewModelConfiguration
import com.copperleaf.ballast.core.BasicViewModel
import com.diyoffer.negotiation.ui.checklist.ChecklistTabContract.Events
import com.diyoffer.negotiation.ui.checklist.ChecklistTabContract.Inputs
import com.diyoffer.negotiation.ui.checklist.ChecklistTabContract.State
import com.diyoffer.negotiation.ui.checklist.ChecklistTabEventHandler
import kotlinx.coroutines.CoroutineScope

typealias ChecklistTabViewModelConfiguration = BallastViewModelConfiguration<Inputs, Events, State>

class ChecklistTabViewModel(
  viewModelCoroutineScope: CoroutineScope,
  config: ChecklistTabViewModelConfiguration,
  eventHandler: ChecklistTabEventHandler,
) : BasicViewModel<Inputs, Events, State>(
  config = config,
  eventHandler = eventHandler,
  coroutineScope = viewModelCoroutineScope,
)
