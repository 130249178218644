package style

import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.AlignSelf
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.StyleSheet
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.alignSelf
import org.jetbrains.compose.web.css.boxSizing
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flex
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.flexGrow
import org.jetbrains.compose.web.css.flexWrap
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.margin
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.maxWidth
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.textAlign

object GridStyleSheet : StyleSheet() {
  val flex by style {
    display(DisplayStyle.Flex)
  }

  val flexColumn by style {
    display(DisplayStyle.Flex)
    flexDirection(FlexDirection.Column)
  }

  val flexGrow by style {
    flexGrow(1)
  }

  val row by style {
    display(DisplayStyle.Flex)
    alignItems(AlignItems.Center)
    margin(0.px, (5.unaryMinus()).px, 15.px, (5.unaryMinus()).px)
    flexWrap(FlexWrap.Wrap)
  }

  val col1 by style {
    flex("0 0 8.33%")
    padding(0.px, 5.px)
    maxWidth("8.33%")
  }

  val col2 by style {
    flex("0 0 16.67%")
    padding(0.px, 5.px)
    maxWidth("16.67%")
  }

  val col3 by style {
    flex("0 0 25%")
    padding(0.px, 5.px)
    maxWidth("25%")
  }

  val col4 by style {
    flex("0 0 33.33%")
    padding(0.px, 5.px)
    maxWidth("33.33%")
  }

  val col5 by style {
    flex("0 0 41.67%")
    padding(0.px, 5.px)
    maxWidth("41.67%")
  }

  val col6 by style {
    flex("0 0 50%")
    padding(0.px, 5.px)
    maxWidth("50%")
  }

  val col7 by style {
    flex("0 0 58.33%")
    padding(0.px, 5.px)
    maxWidth("58.33%")
  }

  val col8 by style {
    flex("0 0 66.67%")
    padding(0.px, 5.px)
    maxWidth("66.67%")
  }

  val col9 by style {
    flex("0 0 75%")
    padding(0.px, 5.px)
    maxWidth("75%")
  }

  val col10 by style {
    flex("0 0 83.33%")
    padding(0.px, 5.px)
    maxWidth("83.33%")
  }

  val col11 by style {
    flex("0 0 91.67%")
    padding(0.px, 5.px)
    maxWidth("91.67%")
  }

  val col12 by style {
    flex("0 0 100%")
    padding(0.px, 5.px)
    maxWidth("100%")
  }

  val bold by style {
    fontWeight(DiyStyleSheet.Weights.bold)
  }

  val justifyContentEnd by style {
    justifyContent(JustifyContent.FlexEnd)
  }

  val justifyContentCenter by style {
    justifyContent(JustifyContent.Center)
  }

  val justifyContentBetween by style {
    justifyContent(JustifyContent.SpaceBetween)
  }

  val alignItemsCenter by style {
    alignItems(AlignItems.Center)
  }

  val alignItemsStart by style {
    alignItems(AlignItems.FlexStart)
  }

  val alignItemsEnd by style {
    alignItems(AlignItems.FlexEnd)
  }

  val alignSelfStart by style {
    alignSelf(AlignSelf.FlexStart)
  }

  val alignSelfEnd by style {
    alignSelf(AlignSelf.FlexEnd)
  }

  val mb12 by style {
    marginBottom(12.px)
  }

  val mb16 by style {
    marginBottom(16.px)
  }

  val textRight by style {
    textAlign("right")
  }

  init {
    "*" style {
      boxSizing("border-box")
    }
  }
}
