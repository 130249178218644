package com.diyoffer.negotiation.model.serdes

import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuidFrom
import com.diyoffer.negotiation.model.Uid
import com.diyoffer.negotiation.model.UuidUid
import com.diyoffer.negotiation.model.toUid
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object UuidSerializer : KSerializer<Uuid> {
  override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("UuidSerializer", PrimitiveKind.STRING)

  override fun deserialize(decoder: Decoder): Uuid =
    uuidFrom(decoder.decodeString())

  override fun serialize(encoder: Encoder, value: Uuid) {
    encoder.encodeString(value.toString())
  }
}

class UidSerializer<T : Uid<*>>(@Suppress("UNUSED_PARAMETER") typeSerializer: KSerializer<*>?) : KSerializer<T> {
  override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("UidSerializer", PrimitiveKind.STRING)

  @Suppress("UNCHECKED_CAST")
  override fun deserialize(decoder: Decoder): T =
    decoder.decodeString().toUid<Any>() as T

  override fun serialize(encoder: Encoder, value: T) {
    encoder.encodeString(value.toString())
  }
}

class UuidUidSerializer<T : UuidUid<*>>(@Suppress("UNUSED_PARAMETER") typeSerializer: KSerializer<*>?) : KSerializer<T> {
  override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("UuidUidSerializer", PrimitiveKind.STRING)

  @Suppress("UNCHECKED_CAST")
  override fun deserialize(decoder: Decoder): T =
    decoder.decodeString().toUid<Any>() as T

  override fun serialize(encoder: Encoder, value: T) {
    encoder.encodeString(value.toString())
  }
}
