package forms

import androidx.compose.runtime.Composable
import builders.OfferBindingAgreementTermsBuilder
import com.diyoffer.negotiation.common.toHumanCount
import com.diyoffer.negotiation.model.*
import common.GridLayout
import components.NegotiatedTermDisplay
import components.NegotiatedTermEdit
import style.DiyStyleSheet

@Composable
fun OfferBindingAgreementTermEdit(
  builder: OfferBindingAgreementTermsBuilder,
  setBuilder: (OfferBindingAgreementTermsBuilder) -> Unit,
) {
  if (builder.daysTermBuilder != null) {
    val daysBuilder = builder.daysTermBuilder!!
    GridLayout(
      attrs = { classes(DiyStyleSheet.withGreenLeftBorder) },
      rightPanelContent = {
        NegotiatedTermEdit(
          daysBuilder,
          { setBuilder(builder.copy(daysTermBuilder = it)) }
        )
      }
    ) {
      if (daysBuilder.state == NegotiatedTerm.State.COUNTERED) {
        BindingAgreementTermPicker(daysBuilder.getCounteredValue()) {
          setBuilder(
            builder.copy(
              daysTermBuilder = daysBuilder.copy(
                counterValueBuilder = daysBuilder.counterValueBuilder?.hydrate(it)
              )
            )
          )
        }
      } else {
        ItemDisplay(
          "The number of business days to have both parties sign the binding agreement",
          "business day".toHumanCount(daysBuilder.getBaselineValue().value)
        )
      }
    }
  }
}

@Composable
fun OfferBindingAgreementTermsDisplay(item: OfferBindingAgreementTerms) {
  NegotiatedTermDisplay(
    "The number of business days to have both parties sign the binding agreement: " +
      "business day".toHumanCount(item.days.currentValue.get().value),
    state = item.days.state
  )
}
