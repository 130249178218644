package com.diyoffer.negotiation.ui.offer

import co.touchlab.kermit.Logger
import com.copperleaf.ballast.InputHandler
import com.copperleaf.ballast.InputHandlerScope
import com.copperleaf.ballast.observeFlows
import com.copperleaf.ballast.postInput
import com.copperleaf.ballast.repository.cache.Cached
import com.copperleaf.ballast.repository.cache.getCachedOrEmptyList
import com.diyoffer.negotiation.messages.CommonMessages
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.client.*
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.repository.listing.SellerListingRepository
import com.diyoffer.negotiation.repository.offer.SellerOfferRepository
import com.diyoffer.negotiation.repository.user.UserRepository
import com.diyoffer.negotiation.services.tryRpc
import com.diyoffer.negotiation.ui.offer.SellerOfferTabContract.Events
import com.diyoffer.negotiation.ui.offer.SellerOfferTabContract.Inputs
import com.diyoffer.negotiation.ui.offer.SellerOfferTabContract.State
import com.diyoffer.negotiation.ui.state.LoadingState
import kotlinx.coroutines.flow.map
import kotlinx.datetime.Clock
import kotlinx.datetime.toLocalDateTime

typealias SellerOfferTabInputHandlerScope = InputHandlerScope<Inputs, Events, State>

class SellerOfferTabInputHandler(
  private val sellerOfferRepo: SellerOfferRepository,
  private val sellerListingRepo: SellerListingRepository,
  private val userRepo: UserRepository,
  private val clock: Clock,
) : InputHandler<Inputs, Events, State> {
  @Suppress("ComplexMethod", "LongMethod")
  override suspend fun SellerOfferTabInputHandlerScope.handleInput(input: Inputs) = when (input) {
    is Inputs.Fetch -> {
      val s = getAndUpdateState { it.copy(initialized = true) }
      if (!s.initialized) {
        observeFlows(
          "SellerOfferTabInputHandler",
          sellerListingRepo.userListings().map { Inputs.ListingsUpdated(it) },
          sellerOfferRepo.listingOffers().map { Inputs.OffersUpdated(it) },
          userRepo.getUser().map { Inputs.UserUpdated(it) },
        )
      } else {
        noOp()
      }
    }

    is Inputs.OffersUpdated -> {
      val s = getCurrentState()
      when (input.offers) {
        is Cached.Value -> {
          val vms = input.offers.value
            .groupBy { it.first.onListing }
            .flatMap { group ->
              // Build UI representation sorted by creation date
              val listing = s.listings.getValue(group.key).listing as Listing.Published
              val offerUis = group.value
                .mapNotNull { (it.first as? Offer.Published)?.toOfferViewModel(listing, it.second, s) }

              val (minOffer, maxOffer) = offerUis.filter { it.active }.minMax()

              offerUis.mapIndexed { index, offerUi ->
                val isMin = minOffer != maxOffer && minOffer == offerUi.offer._id
                val isMax = minOffer == maxOffer || maxOffer == offerUi.offer._id
                offerUi.copy(
                  orderNumber = index + 1,
                  lowest = isMin,
                  highest = isMax,
                  trending = when {
                    isMax -> Trending.UP
                    isMin -> Trending.DOWN
                    else -> null
                  }
                )
              }
            }
          updateState { state ->
            state.copy(offers = vms.applyListings(s.listings, state), loadingState = LoadingState.READY)
          }
        }
        else -> Unit
      }
    }

    is Inputs.ListingsUpdated -> {
      val listings = input.listings
        .getCachedOrEmptyList()
        .filter { it.listing is Listing.Published }
        .associateBy { it.listing._id }

      updateState { state ->
        state.copy(
          listings = listings,
          offers = state.offers?.applyListings(listings, state)
        )
      }
    }

    is Inputs.OfferActionClicked -> {
      when (input.action) {
        OfferActionType.ACCEPT -> withRpc(input.action) { sellerOfferRepo.acceptOffer(input.offerId) }
        OfferActionType.REJECT -> withRpc(input.action) { sellerOfferRepo.rejectOffer(input.offerId) }
        OfferActionType.PUBLISH -> postInput(
          Inputs.SetError("Operation not supported. ${CommonMessages.contactAdministrator}")
        )

        OfferActionType.REVIEW -> postEvent(
          Events.OnNavigate("/offer/edit/${input.offerId}")
        )
      }
    }

    is Inputs.SetError -> updateState { it.copy(error = input.error, loadingState = LoadingState.ERROR) }

    is Inputs.UserUpdated -> updateState { it.copy(sessionUser = input.user) }
  }

  // TODO this code is essentially the same as OfferEditScreenInputHandler.saveOffer, consider refactoring
  private suspend fun InputHandlerScope<Inputs, Events, State>.withRpc(
    actionType: OfferActionType,
    saveHandler: suspend () -> OfferSaveResult,
  ) {
    fun userErrorMsg(e: Exception? = null) =
      "An error occurred while performing an action on your offer. ${CommonMessages.contactAdministrator(e)}"
    updateState { it.copy(loadingState = LoadingState.FETCHING) }
    tryRpc(onException = { _, e -> postInput(Inputs.SetError(userErrorMsg(e))) }) {
      val s = getCurrentState()
      when (val r = saveHandler()) {
        is OfferSaveResult.Success -> {
          postInput(
            Inputs.OffersUpdated(
              Cached.Value(
                s.offers!!.map { (if (it.offer._id == r.offer._id) r.offer else it.offer) to it.contacts }
              )
            )
          )
          if (actionType == OfferActionType.ACCEPT) {
            sellerListingRepo.invalidateCacheAndFetchListings()
            sellerOfferRepo.invalidateCacheAndRefreshOffers()
          }
        }
        is OfferSaveResult.NoChange -> {
          updateState { it.copy(loadingState = LoadingState.READY) }
        }
        is OfferSaveResult.ListingInvalidState -> {
          if (actionType == OfferActionType.ACCEPT && r.state == Listing.State.LOCKED) {
            postInput(
              Inputs.SetError("The listing is locked. Another offer may already have been accepted.")
            )
            sellerListingRepo.invalidateCacheAndFetchListings()
            sellerOfferRepo.invalidateCacheAndRefreshOffers()
          }
        }
        else -> {
          Logger.e(r.message)
          postInput(Inputs.SetError(r.message))
        }
      }
    }
  }

  private fun List<OfferUI>.applyListings(
    listings: Map<Uid<Listing>, ListingLoadResult.Success>,
    state: State,
  ) = map { offerUI ->
    listings[offerUI.offer.onListing]?.let {
      val withheldUntil = it.listing.details?.offerWithholding?.until?.core?.valueOrNull()
      offerUI.copy(
        listingName = it.listing.propertyDetails?.address?.shortSummary(),
        withheldUntil = withheldUntil,
        withheld = withheldUntil != null && clock.now().toLocalDateTime(state.sessionUser.timeZone()) < withheldUntil,
        hasAcceptedOtherOffer = it.otherAcceptedOfferExpiryTime?.first != null &&
          it.otherAcceptedOfferExpiryTime?.first != offerUI.offer._id,
      )
    } ?: offerUI
  }

  private fun Offer.Published.toOfferViewModel(listing: Listing.Published, contacts: OfferContacts, s: State): OfferUI {
    val updateTime = userRepo.toLocalDateTime(events.last().timestamp.instant)
    val buyerName = contacts.buyers.contacts[0].name
    val expiryInstant = expiry.instant
    val expired = expiryInstant?.let { expiryInstant < clock.now() } ?: false
    return OfferUI(
      offer = this,
      listing = listing,
      contacts = contacts,
      hasPen = hasPen(Party.SELLER),
      active = ((state == Offer.State.DRAFT || state == Offer.State.PUBLISHED) && !expired) || state.isAccepted,
      offerPrice = price.price,
      effectivePrice = effectivePrice(listing.jurisdictionOrDefaultForUser(s.sessionUser)),
      updateTime = updateTime,
      closingDate = closing.date.currentValue.get(),
      expiryTime = expiryInstant?.let { userRepo.toLocalDateTime(it.instant) },
      expired = expiryInstant?.let { expiryInstant < clock.now() } ?: false,
      buyerName = buyerName,
      trending = Trending.UP, // Need specifications
      cardInfoSummary = statusSummary(Party.SELLER, buyerName, s.sessionUser.timeZone()),
      counterRejectList = counterRejectList(),
      ownAgent = buyerAgent.ownAgent.bool()
    )
  }

  // Extract price information to determine highest and lowers offer on the given listing.
  // Sort by price, then by deposit, then by created date.
  private fun List<OfferUI>.minMax(): Pair<Uid<Offer>?, Uid<Offer>?> = this.sortedWith(
    compareBy<OfferUI> {
      it.effectivePrice
    }.thenBy {
      it.offer.price.deposit.currentValue.getOrNull() ?: Money(0)
    }.thenByDescending {
      it.offer.events.firstOrNull()?.timestamp?.instant ?: clock.now()
    }
  ).let {
    it.firstOrNull()?.offer?._id to it.lastOrNull()?.offer?._id
  }
}
