package com.diyoffer.negotiation.model.rpcs

import com.diyoffer.negotiation.model.*
import kotlinx.serialization.Serializable

@Serializable
sealed class ListingCancelResult {
  @Serializable
  data object Success : ListingCancelResult()

  @Serializable
  data object NotFound : ListingCancelResult()

  @Serializable
  data object ConcurrentModification : ListingCancelResult()

  @Serializable
  data class InvalidState(val previousState: Listing.State) : ListingCancelResult()

  fun message() = when (this) {
    is Success -> "Success"
    is NotFound -> "Listing could not be loaded."
    is ConcurrentModification -> "Concurrent modification"
    is InvalidState -> "Listing cannot be canceled because it's was marked as ${previousState.name}"
  }
}
