package com.diyoffer.negotiation.model.rpcs

import com.diyoffer.negotiation.model.rpcs.OfferSignatureLoadResult.*
import kotlinx.serialization.Serializable

/**
 * Wraps [OfferLoadResult], and includes particular types for signature failures.
 *
 * If the signature is a [ValidSignature], then the [OfferLoadResult] may be retrieved.
 */
@Serializable
sealed class OfferSignatureLoadResult {
  @Serializable
  data class ValidSignature(val loadResult: OfferLoadResult) : OfferSignatureLoadResult()

  @Serializable
  data object ExpiredSignature : OfferSignatureLoadResult()

  @Serializable
  data object InvalidSignature : OfferSignatureLoadResult()

  fun message() = when (this) {
    is ValidSignature -> "Success"
    is ExpiredSignature -> "This signature has expired."
    is InvalidSignature -> "The signature provided is invalid."
  }
}
