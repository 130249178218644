package auth

import com.diyoffer.negotiation.frontend.RequestAuthInterceptor
import environment.jsMetaEnvDiyApiKey
import org.kodein.di.DI
import org.kodein.di.bindSingleton
import org.kodein.di.new

val authInterceptorModule = DI.Module("authInterceptorModule") {
  @Suppress("RemoveExplicitTypeArguments")
  bindSingleton<RequestAuthInterceptor> {
    // default to user-based interceptor unless an API key is defined
    if (jsMetaEnvDiyApiKey().isNotEmpty()) {
      new(::RequestAuthApiKeyInterceptor)
    } else {
      new(::RequestAuthUserInterceptor)
    }
  }
}
