package com.diyoffer.negotiation.auth

import org.kodein.di.DI
import org.kodein.di.bind
import org.kodein.di.new
import org.kodein.di.provider

val firebaseAuthOptionsModule = DI.Module("firebaseAuthOptionsModule") {
  bind { provider { new(::FirebaseAuthOptionsProvider).firebaseOptions } }
}
