package com.diyoffer.negotiation.services

import co.touchlab.kermit.Logger
import com.diyoffer.negotiation.common.retry
import com.diyoffer.negotiation.messages.CommonMessages
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.rpcs.onRpcAttempt
import com.diyoffer.negotiation.ui.state.LoadingState
import io.kvision.remote.ServiceException
import kotlin.coroutines.cancellation.CancellationException

@Suppress("TooGenericExceptionCaught")
suspend fun <T : Any?> tryRpc(
  onException: suspend (String, Exception) -> T = { _, e -> throw e },
  onCancellation: suspend (CancellationException) -> T = { onException("Request cancelled.", it) },
  onServiceException: suspend (BackendServiceException) -> T = { e ->
    when (e) {
      BackendTimeoutServiceException,
      is UnexpectedErrorServiceException,
      ->
        onException(
          e.message
            ?: "Backend is unreachable or an unexpected error occurred. ${CommonMessages.contactAdministrator(e)}",
          e
        )
      UnauthorizedServiceException ->
        onException(
          "You are not authorized to perform this action. Contact support if you believe this is an error.",
          e
        )
    }
  },
  block: suspend () -> T,
): T {
  @Suppress("SwallowedException")
  return try {
    retry(
      onAttempt = onRpcAttempt { a, e ->
        Logger.w(e) { "Error attempting RPC, attempt=$a, will retry" }
      }
    ) {
      block()
    }
  } catch (e: ServiceException) {
    val se = e.toRpcError().toBackendServiceException()
    Logger.w("An unexpected backend service exception occurred running an RPC", se)
    onServiceException(se)
  } catch (e: CancellationException) {
    onCancellation(e)
  } catch (e: Exception) {
    // for some reason, Gateway Timeout arrives as a generic Exception
    when (e.message) {
      "Gateway Timeout" -> {
        Logger.w("Gateway timeout")
        onServiceException(BackendTimeoutServiceException)
      }
      else -> {
        Logger.e("An unexpected exception occurred running an RPC", e)
        onException(e.message ?: "An error occurred", e)
      }
    }
  }
}

suspend fun <T> runRpc(
  onLoading: suspend (LoadingState) -> Unit = { },
  onError: suspend (String) -> Unit,
  block: suspend () -> IRpcResult<T>,
) {
  val result = tryRpc(
    onException = { _, e ->
      onError("Unexpected error. ${CommonMessages.contactAdministrator(e)}")
      null
    },
  ) {
    onLoading(LoadingState.FETCHING)
    block().also {
      onLoading(LoadingState.READY)
    }
  }
  if (result?.value != null) {
    onError(result.message)
  }
}
