package com.diyoffer.negotiation.ui.landing

import com.copperleaf.ballast.InputHandler
import com.copperleaf.ballast.InputHandlerScope
import com.diyoffer.negotiation.ui.landing.GetAlertContract.Events
import com.diyoffer.negotiation.ui.landing.GetAlertContract.Inputs
import com.diyoffer.negotiation.ui.landing.GetAlertContract.State

class GetAlertInputHandler : InputHandler<Inputs, Events, State> {
  override suspend fun InputHandlerScope<Inputs, Events, State>.handleInput(input: Inputs) = when (input) {
    is Inputs.PopupOpen -> updateState { it.copy(popupOpen = true) }
    is Inputs.PopupDismissed -> {
      updateState { it.copy(popupOpen = false, firstName = null, lastName = null, email = null) }
    }
    is Inputs.UpdateFirstName -> updateState { it.copy(firstName = input.firstName) }
    is Inputs.UpdateLastName -> updateState { it.copy(lastName = input.lastName) }
    is Inputs.UpdateEmail -> updateState { it.copy(email = input.email) }
    is Inputs.Submit -> { TODO() }
  }
}
