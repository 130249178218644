@file:Suppress("MissingPackageDeclaration")

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import app.softwork.routingcompose.RouteBuilder
import app.softwork.routingcompose.Router
import co.touchlab.kermit.Logger
import com.diyoffer.negotiation.common.retry
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.rpcs.IDynamicOptionsRpcService
import com.diyoffer.negotiation.rpcs.ILinksRpcService
import com.diyoffer.negotiation.rpcs.onRpcAttempt
import common.Button
import components.AsciidocContent
import components.BlockContent
import components.snackbar.Snackbar
import kotlinx.browser.window
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.kodein.di.compose.rememberInstance
import prototypes.PrototypeCompleteContactVerification
import prototypes.PrototypeCreateCounterOfferDraftBuyer
import prototypes.PrototypeCreateCounterOfferDraftSeller
import prototypes.PrototypeCreateListingDraft
import prototypes.PrototypeCreateListingPublished
import prototypes.PrototypeCreateOfferDraft
import prototypes.PrototypeLoadListingAuth
import prototypes.PrototypeLoadListingChangePriceAndSave
import prototypes.PrototypePublishCounterOfferBuyer
import prototypes.PrototypePublishCounterOfferSeller
import prototypes.PrototypePublishListing
import prototypes.PrototypePublishOffer
import prototypes.PrototypeStartContactVerification
import prototypes.PrototypeUpdateContacts
import prototypes.PrototypeUpdateOfferDraft
import prototypes.testSignatureComponents
import services.OfferAnonLoader
import services.PopupLoader
import services.RichTextState
import services.SignatureLoadOfferState

@Composable
@Suppress("LongMethod")
fun RouteBuilder.TestPage() {
  val router = Router.current

  Div({
    id("diy")
    style {
      display(DisplayStyle.Flex)
      flexDirection(FlexDirection.Column)
      justifyContent(JustifyContent.Left)
      width(100.percent)
    }
  }) {
    route("/") {
      TestPageIndex()
    }
    route("/asciiDoc") {
      TestAsciiDocComponent()
    }
    route("/cms") {
      TestCmsComponent()
    }
    route("/conditionLoading") {
      TestConditionLoadingComponent()
    }
    route("/createListingDraft") {
      PrototypeCreateListingDraft()
    }
    route("/createListingPublished") {
      PrototypeCreateListingPublished()
    }
    route("/loadListingAuthDraft") {
      PrototypeLoadListingAuth()
    }
    route("/loadListingAuthModifyAndSaveDraft") {
      PrototypeLoadListingChangePriceAndSave()
    }
    route("/publishListing") {
      PrototypePublishListing()
    }
    route("/createOfferDraft") {
      PrototypeCreateOfferDraft()
    }
    route("/updateOfferDraft") {
      PrototypeUpdateOfferDraft()
    }
    route("/updateContacts") {
      PrototypeUpdateContacts()
    }
    route("/publishOffer") {
      PrototypePublishOffer()
    }
    route("/createCounterOfferDraftSeller") {
      PrototypeCreateCounterOfferDraftSeller()
    }
    route("/publishCounterOfferSeller") {
      PrototypePublishCounterOfferSeller()
    }
    route("/createCounterOfferDraftBuyer") {
      PrototypeCreateCounterOfferDraftBuyer()
    }
    route("/publishCounterOfferBuyer") {
      PrototypePublishCounterOfferBuyer()
    }
    route("/sendBuyerOfferLink") {
      TestSendBuyerOfferLinkComponent()
    }
    route("/startContactVerification") {
      PrototypeStartContactVerification()
    }
    route("/completeContactVerification") {
      PrototypeCompleteContactVerification()
    }
    route("/errorSnackbar") {
      Snackbar("Test error message this is much longer to see what happens") {
        println("ErrorSnackbar dismissed")
      }
    }
    noMatch {
      LaunchedEffect(Unit) {
        router.navigate("/dev")
      }
    }
  }
}

@Suppress("LongMethod")
@Composable
fun TestPageIndex() {
  val router = Router.current

  Div({
    id("diy")
    style {
      display(DisplayStyle.Flex)
      flexDirection(FlexDirection.Column)
      justifyContent(JustifyContent.Left)
      gap(5.px)
    }
  }) {
    Button(attrs = { onClick { router.navigate("/home") } }) {
      Text("Home")
    }
    Button(attrs = { onClick { router.navigate("/dev/asciiDoc") } }) {
      Text("Test Asciidoc")
    }
    Button(attrs = { onClick { router.navigate("/dev/cms") } }) {
      Text("Test CMS")
    }
    Button(attrs = { onClick { router.navigate("/dev/conditionLoading") } }) {
      Text("Test Condition Loading")
    }
    Button(attrs = { onClick { router.navigate("/dev/createListingDraft") } }) {
      Text("Test Create Listing - DRAFT")
    }
    Button(attrs = { onClick { router.navigate("/dev/createListingPublished") } }) {
      Text("Test Create Listing - PUBLISHED")
    }
    Button(attrs = { onClick { router.navigate("/dev/loadListingAuthDraft") } }) {
      Text("Test Load Listing Auth - DRAFT")
    }
    Button(attrs = { onClick { router.navigate("/dev/loadListingAuthModifyAndSaveDraft") } }) {
      Text("Test Load Listing Auth - Modify and Save - DRAFT")
    }
    Button(attrs = { onClick { router.navigate("/dev/publishListing") } }) {
      Text("Test Publish Listing")
    }
    Button(attrs = { onClick { router.navigate("/dev/createOfferDraft") } }) {
      Text("Test Create Offer - DRAFT")
    }
    Button(attrs = { onClick { router.navigate("/dev/updateOfferDraft") } }) {
      Text("Test Update Offer - DRAFT")
    }
    Button(attrs = { onClick { router.navigate("/dev/updateContacts") } }) {
      Text("Test Update Contacts")
    }
    Button(attrs = { onClick { router.navigate("/dev/publishOffer") } }) {
      Text("Test Publish Offer")
    }
    Button(attrs = { onClick { router.navigate("/dev/createCounterOfferDraftSeller") } }) {
      Text("Test Create Counter-Offer Draft (Seller)")
    }
    Button(attrs = { onClick { router.navigate("/dev/publishCounterOfferSeller") } }) {
      Text("Test Publish Counter-Offer (Seller)")
    }
    Button(attrs = { onClick { router.navigate("/dev/createCounterOfferDraftBuyer") } }) {
      Text("Test Create Counter-Offer Draft (Buyer)")
    }
    Button(attrs = { onClick { router.navigate("/dev/publishCounterOfferBuyer") } }) {
      Text("Test Publish Counter-Offer (Buyer)")
    }
    Button(attrs = { onClick { router.navigate("/dev/sendBuyerOfferLink") } }) {
      Text("Test Send Buyer Offer Link")
    }
    Button(attrs = { onClick { router.navigate("/dev/startContactVerification") } }) {
      Text("Test Start Contact Verification")
    }
    Button(attrs = { onClick { router.navigate("/dev/completeContactVerification") } }) {
      Text("Test Complete Contact Verification")
    }
    Button(attrs = { onClick { router.navigate("/dev/errorSnackbar") } }) {
      Text("Test Error Snackbar")
    }
    Button(attrs = { onClick { router.navigate("/dev/errorSnackbar/nullMessage") } }) {
      Text("Test Error Snackbar null message")
    }
    @Suppress("MaxLineLength")
    Button(attrs = {
      onClick {
        // these signed link components will expire and may need to be updated by doing the generate signed offer link prototype/test
        router.navigate(
          "/offer/${testSignatureComponents.id}/${testSignatureComponents.random}/signed/${testSignatureComponents.signature}"
        )
      }
    }) {
      Text("Test Show Buyer Offer Signed Link")
    }
    Button(attrs = { onClick { router.navigate("/dev/stringformat") } }) {
      Text("Test String Formats")
    }
  }
}

@Composable
fun TestConditionLoadingComponent() {
  val s by rememberInstance<IDynamicOptionsRpcService>()
  LaunchedEffect(Unit) {
    val allSelectable = s.listDynamicSelectableConditions(Party.SELLER)
    println(allSelectable.map { it.title })
    println(
      s.listDynamicSelectableConditions(Party.SELLER, ConditionDefaultCriteria.exceptNever()).map { it.title }
    )
    println(
      s.listDynamicDefaultConditions(
        Party.SELLER,
        listOf(
          ConditionDefaultCriteria.ALWAYS,
          ConditionDefaultCriteria.ASSOCIATION_FEES_NONZERO
        ),
        null
      ).map { it.title }
    )
    when (val response = s.hydrateDynamicConditionOptionKey(DynamicUid(allSelectable.first()._id))) {
      is HydrateDynamicConditionResult.Success -> println(response)
      is HydrateDynamicConditionResult.NotFound -> println("Not found")
    }
  }
}

@Composable
fun TestCmsComponent() {
  val popupLoader by rememberInstance<PopupLoader>()

  when (val value = popupLoader.load(CmsReference(CmsFieldType.POPUP_HELP, "condition-buyer-association-fees")).value) {
    RichTextState.Loading -> P { Text("Loading!") }
    is RichTextState.Error -> P { Text("Error: ${value.message}!") }
    is RichTextState.Success -> BlockContent(value.blocks)
  }
}

@Composable
fun TestAsciiDocComponent() {
  AsciidocContent(
    Asciidoc(
      "Hello, _Asciidoc_, \nOne Break\nTwo Breaks\n\nSuper. [.underline]#test underline#"
    ),
    { Text("Converting!") },
    { Text("Error!") }
  )
}

@Composable
fun TestSendBuyerOfferLinkComponent() {
  val linksRpcService by rememberInstance<ILinksRpcService>()
  val (resultState, setResultState) = remember { mutableStateOf("Loading…") }

  Text(resultState)

  @Suppress("TooGenericExceptionCaught")
  LaunchedEffect(Unit) {
    try {
      val onAttempt = onRpcAttempt<SendBuyerOfferLinkResult> { a, e ->
        Logger.w(e) { "Error sending buyer offer link, attempt=$a, will retry" }
      }
      val result = retry(onAttempt = onAttempt) {
        linksRpcService.sendBuyerOfferLink(UidValue("acc8fabb-c5da-4e62-93f5-bcb55d44d24a".toUid()))
      }
      setResultState(
        when (result) {
          SendBuyerOfferLinkResult.NoContactInfo -> "No Contact Info"
          SendBuyerOfferLinkResult.NotFound -> "Offer Not Found"
          SendBuyerOfferLinkResult.Success -> "Success!"
        },
      )
    } catch (e: Exception) {
      Logger.e(e) { "Error sending buyer offer link" }
      setResultState("Error")
    }
  }
}

@Composable
fun TestShowBuyerOfferSignedLinkComponent() {
  val offerAnonLoader by rememberInstance<OfferAnonLoader>()

  when (val value = offerAnonLoader.load(window).value) {
    SignatureLoadOfferState.Error -> P { Text("Error!") }
    // TODO show option to enter email to resend links
    SignatureLoadOfferState.ExpiredSignature -> P { Text("Expired Signature!") }
    // TODO show option to enter email to resend links
    SignatureLoadOfferState.InvalidSignature -> P { Text("Invalid Signature!") }
    SignatureLoadOfferState.Loading -> P { Text("Loading!") } // TODO show loading icon
    SignatureLoadOfferState.NotFound -> P { Text("Not Found!") } // TODO show error page
    is SignatureLoadOfferState.Success -> P { Text("Got offer: ${value.offer}") } // TODO show offer page
  }
}
