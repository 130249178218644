package builders

import com.diyoffer.negotiation.common.services.validation.closingDateStateModel
import com.diyoffer.negotiation.model.*
import kotlinx.datetime.Clock
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone
import kotlinx.datetime.atStartOfDayIn

data class OfferClosingBuilder(
  val party: Party,
  val negotiationStage: NegotiationStage,
  val currency: Currency,
  val clock: Clock,
  val timeZone: TimeZone,
  val negotiatedClosingDateBuilder: NegotiatedTermBuilder<LocalDate>? = null,
  val errorMessage: String? = null,
) : IBuilder<OfferClosing> {
  override fun hydrate(item: OfferClosing?) = copy(
    negotiatedClosingDateBuilder = item?.let {
      NegotiatedTermBuilder(
        party = party,
        negotiationStage = negotiationStage,
        term = it.date,
        stateValidation = ::closingDateStateModel,
        currency = currency,
        counterValueBuilder = LocalDateBuilder(date = it.date.currentValue.getOrNull(), clock, timeZone)
      )
    }
  )

  override fun build(): BuildResult<OfferClosing> = validateAndBuild {
    OfferClosing(
      date = (negotiatedClosingDateBuilder!!.build() as BuildResult.Success).result
    )
  }

  private fun validateAndBuild(onValid: () -> OfferClosing): BuildResult<OfferClosing> {
    val errors = mutableListOf<String>()
    val br = negotiatedClosingDateBuilder?.build() as? BuildResult.Success
    if (br == null) {
      errors.add("Please provide a valid closing date")
    } else if (errorMessage != null) {
      errors.add(errorMessage)
    }

    return buildResult(errors).mergeAndRun(onValid, negotiatedClosingDateBuilder?.build())
  }
}

data class LocalDateBuilder(
  val date: LocalDate? = null,
  val clock: Clock,
  val timeZone: TimeZone,
) : IBuilder<LocalDate> {

  override fun hydrate(item: LocalDate?) = copy(
    date = item
  )

  override fun build(): BuildResult<LocalDate> = validateAndBuild {
    date!!
  }

  private fun validateAndBuild(onValid: () -> LocalDate): BuildResult<LocalDate> {
    val errors = mutableListOf<String>()
    if (date == null) {
      errors.add("You must counter with a valid date.")
    } else if (date.atStartOfDayIn(timeZone) < clock.now()) {
      errors.add("You must counter with a date that is in the future.")
    }
    return buildResult(errors = errors, onValid = onValid)
  }
}
