package com.diyoffer.negotiation.ui.landing

import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.ui.state.LoadingState
import kotlinx.datetime.LocalDateTime

object YourInformationContract {
  data class State(
    val initialized: Boolean = false,
    val id: Uid<Listing>? = null,
    val name: String? = null,
    val email: String? = null,
    val mobile: String? = null,
    val popupVisible: Action? = null,
    val error: String? = null,
    val errors: Map<Fields, String> = emptyMap(),
    val offerId: Uid<Offer>? = null,
    val proposedBookingTimes: List<LocalDateTime> = emptyList(),
    val bookViewingEmailSent: Boolean = false,
    val successMessage: String? = null,
    val loadingState: LoadingState = LoadingState.READY,
    val sessionUser: SessionUser? = null,
  )

  sealed class Inputs {
    data class Initialize(val id: Uid<Listing>) : Inputs()
    data class UpdateName(val name: String?) : Inputs()
    data class UpdateEmail(val email: String?) : Inputs()
    data class UpdateMobile(val mobile: String?) : Inputs()
    data class UpdateProposedBookingTime(val bookingTime: List<LocalDateTime>) : Inputs()
    data object BookAViewing : Inputs()
    data object InitiateOfferProcess : Inputs()
    data object DismissSuccessPopup : Inputs()
    data object OkSuccessPopup : Inputs()
    data class SetSuccessMessage(val message: String?) : Inputs()
    data class SetError(val error: String) : Inputs()
    data object ClearErrors : Inputs()
    data class UserUpdated(val user: SessionUser?) : Inputs()
  }

  sealed class Events {
    data class OnNavigateToOffer(val offerId: Uid<Offer>) : Events()
  }

  enum class Fields {
    NAME,
    EMAIL,
    MOBILE,
    FORM_LEVEL,
  }

  enum class Action {
    WALK_ME_THROUGH_THE_OFFER,
    BOOK_A_VIEWING,
  }
}
