package com.diyoffer.negotiation.model.rpcs

import com.diyoffer.negotiation.model.*
import kotlinx.serialization.Serializable

@Serializable
sealed class ChecklistResult {
  @Serializable
  data class Success(val checklist: Checklist) : ChecklistResult()

  @Serializable
  data object NotFound : ChecklistResult()

  @Serializable
  data object NothingTodo : ChecklistResult()

  @Serializable
  data class InvalidState(val role: Party, val offerState: Offer.State) : ChecklistResult()

  fun message() = when (this) {
    is Success -> "Success"
    is NotFound -> "Listing not found."
    is NothingTodo -> "There is nothing to do with this listing."
    is InvalidState -> "Cannot determine checklist for $role when offer is $offerState"
  }
}
