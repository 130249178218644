package forms

import androidx.compose.runtime.Composable
import builders.FixturesExcludedBuilder
import com.diyoffer.negotiation.model.*
import common.FlexRow
import common.TextField
import components.WithOptionKeyDisplay
import components.WithOptionKeyEdit
import dev.petuska.kmdcx.icons.MDCIcon
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.flex
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.pt
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import style.DiyStyleSheet

@Composable
fun ListingFixtureExcludedEdit(
  builder: FixturesExcludedBuilder,
  setBuilder: (FixturesExcludedBuilder) -> Unit,
) {
  WithOptionKeyEdit(opts = {
    label = "Fixture or Exclusion"
    value = builder.optionKey
    onModified = { setBuilder(builder.copy(optionKey = it)) }
  }) {
    FlexRow {
      TextField(opts = {
        label = "Location"
        value = builder.location
        onModified = { setBuilder(builder.copy(location = it)) }
      })
    }
  }
}

@Composable
fun FixtureExcludedDisplay(
  item: FixtureExclusion,
  icon: MDCIcon = MDCIcon.Bed,
  iconColor: CSSColorValue = DiyStyleSheet.Colors.green,
  lineThrough: Boolean = false,
) {
  WithOptionKeyDisplay(opts = {
    optionKey = item.optionKey
    this.icon = icon
    this.iconColor = iconColor
    this.lineThrough = lineThrough
  }) {
    FlexRow({
      style {
        flex(0)
        gap(DiyStyleSheet.Sizes.paddingXs)
        marginBottom(0.pt)
      }
    }) {
      Span { Text(item.location) }
    }
  }
}
