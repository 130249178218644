//
// GENERATED by KVision
//
package com.diyoffer.negotiation.rpcs

import io.kvision.remote.HttpMethod
import io.kvision.remote.KVServiceManager
import io.kvision.remote.registerKVisionServiceExceptions

expect class ListingAnonRpcService : IListingAnonRpcService

object ListingAnonRpcServiceManager : KVServiceManager<ListingAnonRpcService>(ListingAnonRpcService::class) {
    init {
        registerKVisionServiceExceptions()
        bind(IListingAnonRpcService::loadListingByPermalink, HttpMethod.POST, "listing/anon/load_listing_permalink")
        bind(IListingAnonRpcService::loadListingById, HttpMethod.POST, "listing/anon/load_listing_id")
        bind(IListingAnonRpcService::bookAViewing, HttpMethod.POST, "listing/anon/book_a_viewing")
    }
}
