package components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import app.softwork.routingcompose.RouteBuilder
import app.softwork.routingcompose.Router
import common.FlexColumn
import common.FlexRow
import common.Row
import org.jetbrains.compose.web.css.flex
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import style.DiyStyleSheet

@Composable
fun RouteBuilder.TabSheet(
  tabs: List<TabContent>,
  initialRoute: String = tabs.first().route,
  tabRowAttrs: AttrBuilderContext<HTMLDivElement>? = null,
  attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  fun initialTab() = tabs.singleOrNull { it.route == initialRoute } ?: tabs.first()

  val (currentTab, setCurrentTab) = remember { mutableStateOf(initialTab()) }
  val router = Router.current

  @Composable
  fun navigateToInitialTab() {
    LaunchedEffect(Unit) {
      router.navigate(initialTab().route)
    }
  }

  Div({
    attrs?.invoke(this)
  }) {
    FlexColumn({
      style {
        flex("none")
        width(DiyStyleSheet.Sizes.appWidth)
      }
    }) {
      TabButtons(tabRowAttrs, tabs, currentTab) {
        router.navigate(it.route)
      }

      route("/") {
        navigateToInitialTab()
      }

      string { routeSegment ->
        val matchedTab = tabs.singleOrNull {
          it.route.endsWith("/$routeSegment") || it.route.contains(
            "/$routeSegment/"
          )
        }
        if (matchedTab == null) {
          navigateToInitialTab()
        } else {
          LaunchedEffect(routeSegment) {
            setCurrentTab(matchedTab)
          }
          Row {
            matchedTab.content.invoke(this@string)
          }
        }
      }

      noMatch {
        navigateToInitialTab()
      }
    }
  }
}

@Composable
fun TabButtons(
  tabRowAttrs: AttrBuilderContext<HTMLDivElement>? = null,
  tabs: List<TabContent>,
  currentTab: TabContent?,
  setTab: (TabContent) -> Unit,
) {
  FlexRow(
    attrs = {
      tabRowAttrs?.invoke(this)
    },
  ) {
    tabs.map { tab ->
      tab.buttonContent(tab == currentTab) { setTab(tab) }
    }
  }
}

data class TabContent(
  val route: String,
  val buttonContent: @Composable (currentTab: Boolean, onClick: () -> Unit) -> Unit,
  val content: @Composable RouteBuilder.() -> Unit,
)
