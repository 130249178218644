package vm.listing

import builders.BuildResult
import builders.IViewModelBuilder
import com.copperleaf.ballast.core.BasicViewModel
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.ui.condition.ConditionListContract.Events
import com.diyoffer.negotiation.ui.condition.ConditionListContract.Inputs
import com.diyoffer.negotiation.ui.condition.ConditionListContract.State
import com.diyoffer.negotiation.ui.condition.ConditionListEventHandler
import kotlinx.coroutines.CoroutineScope

class NegotiatedConditionListViewModel(
  viewModelCoroutineScope: CoroutineScope,
  config: ConditionListViewModelConfiguration,
  eventHandler: ConditionListEventHandler,
) : BasicViewModel<Inputs, Events, State>(
  config = config,
  eventHandler = eventHandler,
  coroutineScope = viewModelCoroutineScope,
),
  IViewModelBuilder<List<NegotiatedTerm<Condition>>, State> {

  override fun build(state: State): BuildResult<List<NegotiatedTerm<Condition>>> = validateAndBuild(state) {
    state.negotiationState?.negotiatedConditionBuilderList?.mapNotNull {
      (it.build() as? BuildResult.Success)?.result
    } ?: emptyList()
  }

  private fun validateAndBuild(
    state: State,
    onValid: () -> List<NegotiatedTerm<Condition>>,
  ): BuildResult<List<NegotiatedTerm<Condition>>> {
    val errors = mutableListOf<String>()
    require(state.negotiationState != null)
    val buildResult = state.negotiationState!!.negotiatedConditionBuilderList.map { it.build() }
    val buildErrors = buildResult.mapNotNull { it as? BuildResult.Failure }
    if (buildErrors.isNotEmpty()) {
      errors.addAll(buildErrors.flatMap { it.errors })
    } else {
      val missingParams = buildResult.flatMap { br ->
        val condition = (br as BuildResult.Success).result.currentValue.get()
        condition.parameters.filter { it.value.isBlank() }.map { it.key }
      }
      if (missingParams.isNotEmpty()) {
        errors.add("Please fill every field before continuing. ${missingParams.joinToString()}")
      }
    }

    return BuildResult(
      errors,
      onValid = onValid,
      negotiationStates = buildResult.flatMap { it.negotiationStates ?: emptyList() }
    )
  }
}
