package prototypes

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.Listing.State.Companion.TEST_DRAFT_LISTING_UID
import com.diyoffer.negotiation.model.Listing.State.Companion.TEST_PUBLISHED_LISTING_UID
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.prototypes.baseTestDraftListing
import com.diyoffer.negotiation.rpcs.IListingAuthRpcService
import com.diyoffer.negotiation.services.tryRpc
import com.soywiz.kbignum.BigInt
import kotlinx.datetime.Clock
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.kodein.di.compose.rememberInstance
import services.ListingAuthLoader
import services.LoadListingState

@Composable
fun PrototypeCreateListingDraft() {
  val clock by rememberInstance<Clock>()
  val listingAuthRpcService by rememberInstance<IListingAuthRpcService>()
  val (result, setResult) = remember { mutableStateOf<String?>(null) }

  val draftListing = baseTestDraftListing(clock, TEST_DRAFT_LISTING_UID)

  if (result != null) {
    Text("Result = $result")
  }

  LaunchedEffect(Unit) {
    saveListing(listingAuthRpcService, draftListing, setResult)
  }
}

@Suppress("TooGenericExceptionCaught")
@Composable
fun PrototypeCreateListingPublished() {
  val clock by rememberInstance<Clock>()
  val listingAuthRpcService by rememberInstance<IListingAuthRpcService>()
  val (result, setResult) = remember { mutableStateOf<String?>(null) }

  val listing = baseTestDraftListing(clock, TEST_PUBLISHED_LISTING_UID)

  if (result != null) {
    Text("Result = $result")
  }

  LaunchedEffect(Unit) {
    var trace = ""
    try {
      val saveResult = listingAuthRpcService.save(listing)
      if (saveResult is ListingSaveResult.Success) {
        trace += "Save Success - "
        val loadResult = listingAuthRpcService.load(UidValue(TEST_PUBLISHED_LISTING_UID))
        if (loadResult is ListingLoadResult.Success) {
          trace += "Load Success - "
          val publishedResult = listingAuthRpcService.publish(loadResult.listing)
          if (publishedResult is ListingSaveResult.Success) {
            setResult(trace + "Success")
          } else {
            setResult(trace + publishedResult.message())
          }
        } else {
          setResult(trace + "Load Error")
        }
      }
    } catch (e: Exception) {
      setResult(trace + e.message)
    }
  }
}

@Composable
fun PrototypeLoadListingAuth() {
  val listingAuthLoader by rememberInstance<ListingAuthLoader>()

  when (val value = listingAuthLoader.load(TEST_DRAFT_LISTING_UID).value) {
    LoadListingState.Error -> P { Text("Error!") }
    LoadListingState.Loading -> P { Text("Loading!") } // TODO show loading icon
    LoadListingState.NotFound -> P { Text("Not Found!") } // TODO show error page
    is LoadListingState.Success -> P { Text("Got listing: ${value.listing}") } // TODO show listing page
  }
}

@Composable
fun PrototypeLoadListingChangePriceAndSave() {
  val listingAuthLoader by rememberInstance<ListingAuthLoader>()
  val listingAuthRpcService by rememberInstance<IListingAuthRpcService>()
  val (result, setResult) = remember { mutableStateOf<String?>(null) }
  val clock by rememberInstance<Clock>()

  if (result != null) {
    Text("Result = $result")
  }

  when (val value = listingAuthLoader.load(TEST_DRAFT_LISTING_UID).value) {
    LoadListingState.Error -> setResult("Error!")
    LoadListingState.Loading -> setResult("Loading!") // TODO show loading icon
    LoadListingState.NotFound -> setResult("Not Found!") // TODO show error page
    is LoadListingState.Success -> {
      LaunchedEffect(value.listing._id) {
        val draftListing = when (value.listing) {
          is Listing.Draft -> value.listing.copy(
            // make it an older version to validate concurrent modification scenario
            // version = value.listing.version - 1,
            details = value.listing.details?.copy(
              price = Auditable.Core(
                Optional.of(Money(value = BigInt(value = 900_000), Currency.CAD)),
                clock.now().lowRes(),
                null,
              ),
            ),
          )

          is Listing.Published -> TODO()
        }
        saveListing(listingAuthRpcService, draftListing, setResult)
      }
    }
  }
}

@Suppress("ComplexMethod")
@Composable
fun PrototypePublishListing() {
  val listingAuthLoader by rememberInstance<ListingAuthLoader>()
  val listingAuthRpcService by rememberInstance<IListingAuthRpcService>()
  val (resultState, setResultState) = remember { mutableStateOf<String?>(null) }

  val listingState = listingAuthLoader.load(TEST_DRAFT_LISTING_UID)

  if (resultState != null) {
    Text("Result = $resultState")
  }

  @Suppress("TooGenericExceptionCaught")
  LaunchedEffect(listingState.value) {
    when (val value = listingState.value) {
      is LoadListingState.Success -> {
        tryRpc(onException = { message, _ -> setResultState(message) }) {
          val result = listingAuthRpcService.publish(value.listing)
          setResultState(result.message())
        }
      }

      else -> setResultState("Listing load error: ${value::class.simpleName}")
    }
  }
}

@Suppress("TooGenericExceptionCaught")
suspend fun saveListing(
  listingAuthRpcService: IListingAuthRpcService,
  listing: Listing,
  setResult: (String?) -> Unit,
) {
  tryRpc(onException = { message, _ -> setResult(message) }) {
    listingAuthRpcService.save(listing).also { setResult(it.message()) }
  }
}
