package com.diyoffer.negotiation.repository.offer

import co.touchlab.kermit.Logger
import com.copperleaf.ballast.BallastViewModelConfiguration
import com.copperleaf.ballast.build
import com.copperleaf.ballast.core.FifoInputStrategy
import com.copperleaf.ballast.repository.BallastRepository
import com.copperleaf.ballast.repository.bus.EventBus
import com.copperleaf.ballast.repository.cache.Cached
import com.copperleaf.ballast.repository.cache.getCachedOrNull
import com.copperleaf.ballast.repository.withRepository
import com.diyoffer.negotiation.analytics.AnalyticsClient
import com.diyoffer.negotiation.analytics.AnalyticsEvent
import com.diyoffer.negotiation.common.asEagerStateFlow
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.repository.offer.BuyerOfferRepositoryContract.Inputs
import com.diyoffer.negotiation.repository.offer.BuyerOfferRepositoryContract.State
import com.diyoffer.negotiation.repository.user.UserRepository
import com.diyoffer.negotiation.rpcs.IListingAnonRpcService
import com.diyoffer.negotiation.rpcs.IOfferAnonRpcService
import com.diyoffer.negotiation.services.tryRpc
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.map

/**
 * This class holds the application-level state for offers related to a listing.
 */
@Suppress("LongParameterList")
class BuyerOfferRepositoryImpl(
  private val offerAnonRpcService: IOfferAnonRpcService,
  private val listingAnonRpcService: IListingAnonRpcService,
  private val userRepo: UserRepository,
  private val analyticsClient: AnalyticsClient,
  coroutineScope: CoroutineScope,
  eventBus: EventBus,
  configBuilder: BallastViewModelConfiguration.Builder,
) : BuyerOfferRepository, BallastRepository<Inputs, State>(
  coroutineScope = coroutineScope,
  eventBus = eventBus,
  config = configBuilder
    .apply {
      initialState = State()
      inputHandler = BuyerOfferRepositoryInputHandler(
        offerAnonRpcService,
        listingAnonRpcService,
        userRepo,
        eventBus
      )
      inputStrategy = FifoInputStrategy()
      name = BuyerOfferRepository::class.simpleName
    }.withRepository().build()
) {

  private val state = observeStates()
  private val user = state.map { it.user }.asEagerStateFlow(coroutineScope, null)
  private val offerState = state.map { it.offer }
    .asEagerStateFlow(coroutineScope, Cached.NotLoaded())
  private val relatedListingState = state.map { it.relatedListing }
    .asEagerStateFlow(coroutineScope, Cached.NotLoaded())

  init {
    // we want to start observing flows immediately, otherwise we have a race condition when doing
    // Initialize and OfferFetchRequest
    trySend(Inputs.Initialize)
  }

  /**
   * Anonymously fetches the specified id with the signature
   */
  override suspend fun fetchOffer(
    offerId: Uid<Offer>,
  ): Flow<Cached<Pair<Offer, OfferContacts>>> {
    send(Inputs.OfferFetchRequest(offerId))
    return offerState
  }

  /**
   * Allow downstream services to observe the offer that has been fetched by an upstream component
   */
  override suspend fun offer(): Flow<Cached<Pair<Offer, OfferContacts>>> = offerState

  override suspend fun relatedListing(): Flow<Cached<ListingLoadResult.Success>> = relatedListingState

  override suspend fun saveOffer(offer: Offer): OfferSaveResult {
    send(Inputs.InvalidateCache(false))
    val (signature, random) = sessionSignature()
    analyticsClient.logEvent(AnalyticsEvent.OfferSave(offer._id, Party.BUYER))
    return when (
      val r = offerAnonRpcService.save(
        offer = offer,
        signature = signature,
        random = random
      )
    ) {
      is OfferSignatureSaveResult.ValidSignature -> r.saveResult
      else -> OfferSaveResult.Unauthenticated
    }
  }

  override suspend fun publishOffer(offer: Offer) =
    offerSaveRpc(refetchOffer = true) { signature, random ->
      analyticsClient.logEvent(AnalyticsEvent.OfferPublish(offer._id, Party.BUYER))
      offerAnonRpcService.publish(
        offer = offer,
        signature = signature,
        random = random
      )
    }

  override suspend fun publishOfferById(offerId: Uid<Offer>): OfferSaveResult {
    val offer = offerState.value.getCachedOrNull()?.first
    return if (offer == null || offer._id != offerId) {
      OfferSaveResult.NotFound
    } else {
      publishOffer(offer)
    }
  }

  override suspend fun rejectOffer(offerId: Uid<Offer>) =
    offerSaveRpc(refetchOffer = true) { signature, random ->
      analyticsClient.logEvent(AnalyticsEvent.OfferReject(offerId, Party.BUYER))
      offerAnonRpcService.reject(UidValue(offerId), random, signature)
    }

  override suspend fun acceptOffer(offerId: Uid<Offer>) =
    offerSaveRpc(refetchOffer = true) { signature, random ->
      analyticsClient.logEvent(AnalyticsEvent.OfferAccept(offerId, Party.BUYER))
      offerAnonRpcService.accept(UidValue(offerId), random, signature)
    }

  override suspend fun saveContacts(
    offerId: Uid<Offer>,
    contacts: OfferContacts,
    refetchOffer: Boolean,
  ): OfferContactsSignatureSaveResult {
    val (signature, random) = sessionSignature()
    return offerAnonRpcService.saveContacts(
      id = UidValue(offerId),
      contacts = contacts,
      signature = signature,
      random = random,
    ).also {
      if (it is OfferContactsSignatureSaveResult.ValidSignature && it.saveResult is OfferContactsSaveResult.Success) {
        send(Inputs.InvalidateCache(refetchOffer))
      }
    }
  }

  override suspend fun saveLegalContact(
    offerId: Uid<Offer>,
    legalContact: Contact,
  ): OfferContactsSaveResult {
    val s = state.value
    val offerContacts = s.offer.getCachedOrNull()?.second
    return if (offerContacts == null) {
      Logger.e("Could not find matching offer $offerId in local buyerOfferRepo")
      OfferContactsSaveResult.OfferNotFound
    } else {
      val (signature, random) = sessionSignature()
      analyticsClient.logEvent(AnalyticsEvent.OfferSaveLegal(offerId, Party.BUYER))
      offerAnonRpcService.saveContacts(
        id = UidValue(offerId),
        contacts = offerContacts.copy(buyerLegal = legalContact),
        signature = signature,
        random = random
      ).run {
        if (this is OfferContactsSignatureSaveResult.ValidSignature) {
          send(Inputs.InvalidateCache(true))
          saveResult
        } else {
          OfferContactsSaveResult.Unauthorized
        }
      }
    }
  }

  override suspend fun invalidateCache(refetchOffer: Boolean) {
    send(Inputs.InvalidateCache(refetchOffer))
  }

  private suspend fun offerSaveRpc(
    refetchOffer: Boolean,
    block: suspend (String, String) -> OfferSignatureSaveResult,
  ): OfferSaveResult {
    val (signature, random) = sessionSignature()
    // todo tryRpc here should include an onException handler e.g.
    //   onException = { _, _ ->
    //     postInput(
    //       Inputs.SetError("There has been a problem saving the offer. ${CommonMessages.contactAdministrator}")
    //     )
    //   }
    val result = tryRpc {
      block(signature, random)
    }
    // Ideally, we would take the save result and use it as the new offer. However, given the offer.load() returns
    // a pair of <Offer, OfferContacts>, it would require some refactoring so for the time being, we'll simply
    // refetch the offer from the backend with a get if requested (state changes mostly)
    return when (result) {
      is OfferSignatureSaveResult.ValidSignature -> {
        send(Inputs.InvalidateCache(refetchOffer))
        result.saveResult
      }
      else -> OfferSaveResult.Unauthenticated
    }
  }

  private fun sessionSignature() = (user.value as? SessionUser.SignedUser)?.let {
    it.signature to it.random
  } ?: ("" to "")
}
