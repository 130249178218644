package forms

import androidx.compose.runtime.Composable
import builders.OfferChattelsIncludedListBuilder
import com.diyoffer.negotiation.common.replaceAt
import com.diyoffer.negotiation.model.*
import common.FlatList
import common.Row
import components.color
import components.icon

@Composable
fun OfferChattelsIncludedListEdit(
  builder: OfferChattelsIncludedListBuilder,
  setBuilder: (OfferChattelsIncludedListBuilder) -> Unit,
) {
  builder.chattelsIncluded?.mapIndexed { idx, b ->
    val chattel = b.term.currentValue.get()
    NegotiableItemInteract(
      optionKey = chattel.optionKey,
      builder = b,
      setBuilder = {
        setBuilder(
          builder.copy(
            chattelsIncluded = builder.chattelsIncluded!!.replaceAt(idx, it)
          )
        )
      }
    ) {
      chattel.brand?.let { ItemDisplay("Brand (optional)", it) }
      chattel.year?.let { ItemDisplay("Year (optional)", it.toString()) }
      chattel.serialNumber?.let { ItemDisplay("Serial Number (optional)", it) }
    }
  }
}

@Composable
fun ChattelsIncludedListDisplay(items: OfferChattelsIncluded) {
  if (items.chattelsIncluded.isEmpty()) {
    FlatList("No Chattel or Inclusion")
  } else {
    items.chattelsIncluded.map {
      Row {
        ChattelIncludedDisplay(
          item = it.currentValue.get(),
          icon = it.state.icon(),
          iconColor = it.state.color(),
          lineThrough = it.state == NegotiatedTerm.State.REMOVED
        )
      }
    }
  }
}
