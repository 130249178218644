package com.diyoffer.negotiation.model

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlin.time.Duration

/**
 * Expiry, reflecting the user input which is in the form of a Duration (e.g. in 24 hours), and then when
 * published the value is enriched with an actual expiry.
 */
@Serializable
sealed class Expiry {
  abstract val core: Core

  @Serializable
  data class Core(
    val duration: Duration,
    val setBy: Party = Party.BUYER,
    val setAction: ExpirySetAction = ExpirySetAction.KEEP,
  ) : Expiry() {
    @Transient
    override val core = this
  }

  @Serializable
  data class CoreInstant(
    override val core: Core,
    val expiry: InstantLr,
  ) : Expiry()

  @Serializable
  data class Enriched(
    override val core: Core,
    val expiry: InstantLr,
  ) : Expiry() {
    companion object {
      operator fun invoke(
        core: Core,
        expiry: Instant,
      ) = Enriched(core, expiry.lowRes())
    }
  }
}

enum class ExpirySetAction {
  KEEP,
  EXTEND,
}

val Expiry.instant get() = when (this) {
  is Expiry.Core -> null
  is Expiry.CoreInstant -> expiry
  is Expiry.Enriched -> expiry
}

fun Expiry.derich(forValidation: Boolean) = when (this) {
  is Expiry.Enriched -> if (forValidation) core else Expiry.CoreInstant(core, expiry)
  else -> this
}
