package components

import androidx.compose.runtime.Composable
import com.diyoffer.negotiation.cms.BlockChildSpan
import com.diyoffer.negotiation.cms.BlockStyle
import com.diyoffer.negotiation.cms.MarkDef
import com.diyoffer.negotiation.cms.MarkDefLink
import com.diyoffer.negotiation.cms.PortableTextBlock
import com.diyoffer.negotiation.cms.RichTextBlock
import com.diyoffer.negotiation.cms.SpanMark
import com.diyoffer.negotiation.cms.SpanMarkDefMark
import com.diyoffer.negotiation.cms.SpanStyleMark
import com.diyoffer.negotiation.cms.StyleMark
import com.diyoffer.negotiation.cms.YoutubeEmbedBlock
import components.lightbox.YoutubeEmbed
import org.jetbrains.compose.web.css.margin
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.B
import org.jetbrains.compose.web.dom.ElementScope
import org.jetbrains.compose.web.dom.Em
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLElement

/**
 * Renders block content in the portable text format. See https://www.sanity.io/docs/presenting-block-text.
 *
 * The supported marks as of now are emphasis, strong, and links. The supported styles are normal and h1.
 */
@Composable
fun BlockContent(blocks: List<PortableTextBlock>) {
  blocks.map { block ->
    when (block) {
      is RichTextBlock -> {
        when (block.style) {
          BlockStyle.NORMAL -> P {
            BlockChildrenContent(block)
          }
          BlockStyle.H1 -> H1({ style { margin(0.px) } }) {
            BlockChildrenContent(block)
          }
        }
      }
      is YoutubeEmbedBlock -> YoutubeEmbedContent(block)
    }
  }
}

@Composable
fun ElementScope<HTMLElement>.BlockChildrenContent(block: RichTextBlock) {
  block.children.map { child ->
    when (child) {
      is BlockChildSpan -> BlockChildSpanContent(child, block.markDefs)
    }
  }
}

@Composable
fun ElementScope<HTMLElement>.BlockChildSpanContent(span: BlockChildSpan, markDefs: List<MarkDef>) {
  BlockChildLink(span, markDefs) { BlockChildEmphasis(span) { BlockChildStrong(span) { Text(span.text) } } }
}

@Composable
fun ElementScope<HTMLElement>.BlockChildStrong(
  span: BlockChildSpan,
  content: @Composable ElementScope<HTMLElement>.() -> Unit,
) {
  if (span.marks.isStrong()) {
    B {
      content()
    }
  } else {
    content()
  }
}

@Composable
fun ElementScope<HTMLElement>.BlockChildEmphasis(
  span: BlockChildSpan,
  content: @Composable ElementScope<HTMLElement>.() -> Unit,
) {
  if (span.marks.isEm()) {
    Em {
      content()
    }
  } else {
    content()
  }
}

@Composable
fun ElementScope<HTMLElement>.BlockChildLink(
  span: BlockChildSpan,
  markDefs: List<MarkDef>,
  content: @Composable ElementScope<HTMLElement>.() -> Unit,
) {
  val link = span.marks.linkOrNull(markDefs)
  if (link != null) {
    A(href = link.href) {
      content()
    }
  } else {
    content()
  }
}

fun List<SpanMark>.isEm(): Boolean =
  any { it is SpanStyleMark && it.style == StyleMark.EM }

fun List<SpanMark>.isStrong(): Boolean =
  any { it is SpanStyleMark && it.style == StyleMark.STRONG }

fun List<SpanMark>.linkOrNull(markDefs: List<MarkDef>): MarkDefLink? = filterIsInstance<SpanMarkDefMark>()
  .mapNotNull { mark -> markDefs.singleOrNull { it is MarkDefLink && it.key == mark.key } }
  .filterIsInstance<MarkDefLink>()
  .firstOrNull()

@Composable
fun YoutubeEmbedContent(block: YoutubeEmbedBlock) {
  YoutubeEmbed(block.id)
}
