package com.diyoffer.negotiation.ui.checklist

import com.diyoffer.negotiation.model.*
import kotlinx.datetime.LocalDateTime

sealed class ChecklistUI {
  abstract val address: String?
  abstract val checklist: Checklist
  abstract val error: String?
  abstract val version: Int?

  data class OfferChecklistUI(
    val offerId: Uid<Offer>,
    val legalName: String?,
    val legalEmail: String?,
    val withheld: Boolean = false,
    val withheldUntil: LocalDateTime? = null,
    override val address: String?,
    override val checklist: Checklist,
    override val version: Int? = null,
    override val error: String? = null,
  ) : ChecklistUI()

  data class ListingChecklistUI(
    val listingId: Uid<Listing>,
    override val address: String?,
    override val checklist: Checklist,
    override val version: Int? = null,
    override val error: String? = null,
  ) : ChecklistUI()

  val listingOrOfferId
    get() = when (this) {
      is OfferChecklistUI -> offerId.toString()
      is ListingChecklistUI -> listingId.toString()
    }
}
