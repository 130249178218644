package environment

import com.diyoffer.negotiation.environment.mapToEnv
import kotlinx.browser.window

/**
 * A wrapper for a "fake" `meta.env` (which isn't a browser API, but rather modified via a Webpack plugin to
 * values present in a .env file at build time). See https://github.com/mrsteele/dotenv-webpack.
 *
 * The plugin looks for this literal value, so we need one function for each lookup value.
 *
 * Webpack supports only `development` and `production` modes, so in production mode, we need to differentiate
 * between `prod` and `test` environments. We do that via the hostname and [mapToEnv].
 */
fun jsMetaEnvEnvironment(): String = when (val e = js("meta.env.ENVIRONMENT") as String) {
  "prod" -> window.location.mapToEnv().name.lowercase()
  else -> e
}

fun jsMetaEnvDiyApiKey(): String = js("meta.env.DIY_API_KEY")

fun jsMetaEnvDiyUserId(): String = js("meta.env.DIY_USER_ID")
