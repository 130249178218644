package com.diyoffer.negotiation.frontend

import com.diyoffer.negotiation.common.clock.commonClockModule
import com.diyoffer.negotiation.model.serdes.*
import com.diyoffer.negotiation.monitoring.sentry.sentryModule
import org.kodein.di.DI

val frontendSharedModule = DI.Module("frontendSharedModule") {
  import(commonClockModule)
  import(serdesModule)
  import(frontendRpcsModule)
  import(sentryModule)
}
