package com.diyoffer.negotiation.ui.login

import com.copperleaf.ballast.EventHandler
import com.copperleaf.ballast.EventHandlerScope
import com.diyoffer.negotiation.common.Ignore
import com.diyoffer.negotiation.ui.login.ResetPasswordScreenContract.Events
import com.diyoffer.negotiation.ui.login.ResetPasswordScreenContract.Inputs
import com.diyoffer.negotiation.ui.login.ResetPasswordScreenContract.State

class ResetPasswordScreenEventHandler : EventHandler<Inputs, Events, State> {
  override suspend fun EventHandlerScope<Inputs, Events, State>.handleEvent(event: Events) = Ignore
}
