package components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.attributes.ARel
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.ref
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import style.DiyStyleSheet.buttonLink

@Composable
fun TermsOfService(signingMode: SigningMode, implicit: Boolean) {
  Span {
    if (implicit) {
      Text("By signing ${signingMode.label}, you agree to our ")
    } else {
      Text("I agree to the ")
    }
    A(
      href = "https://diyoffer.ca/terms",
      attrs = {
        target(ATarget.Blank)
        ref(ARel.NoOpener)
        classes(buttonLink)
        onClick { it.stopPropagation() }
      }
    ) {
      Text("Terms of Service")
    }
  }
}

enum class SigningMode(val label: String) {
  SIGN_UP("up"),
  SIGN_IN("in"),
}
