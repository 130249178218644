//
// GENERATED by KVision
//
package com.diyoffer.negotiation.rpcs

import org.w3c.fetch.RequestInit
import io.kvision.remote.KVRemoteAgent
import kotlinx.serialization.modules.SerializersModule
import com.diyoffer.negotiation.model.UserSettings
import com.diyoffer.negotiation.model.rpcs.UserCreateResult
import com.diyoffer.negotiation.model.rpcs.UserLoadResult

actual class UserRpcService(serializersModules: List<SerializersModule>? = null, requestFilter: (suspend RequestInit.() -> Unit)? = null) : IUserRpcService, KVRemoteAgent<UserRpcService>(UserRpcServiceManager, serializersModules, requestFilter) {
    override suspend fun createSelf(settings: UserSettings) = call(IUserRpcService::createSelf, settings)
    override suspend fun loadSelf() = call(IUserRpcService::loadSelf)
}
