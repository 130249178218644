package com.diyoffer.negotiation.ui.listing

import com.copperleaf.ballast.EventHandler
import com.copperleaf.ballast.EventHandlerScope
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.client.*
import com.diyoffer.negotiation.ui.listing.SideBarListingsContract.Events
import com.diyoffer.negotiation.ui.listing.SideBarListingsContract.Inputs
import com.diyoffer.negotiation.ui.listing.SideBarListingsContract.State

@Suppress("EmptyFunctionBlock")
class SideBarListingsEventHandler(
  private val onNavigateToListingEditScreen: (Uid<Listing>, RouteData?) -> Unit,
  private val onNavigateToListingDetailsScreen: (String, RouteData?) -> Unit,
) : EventHandler<Inputs, Events, State> {
  override suspend fun EventHandlerScope<Inputs, Events, State>.handleEvent(event: Events) = when (event) {
    is Events.NavigateToListingEditScreen -> onNavigateToListingEditScreen(event.listingId, event.returnRouteData)
    is Events.NavigateToListingDetailsScreen -> onNavigateToListingDetailsScreen(
      event.permalink,
      event.returnRouteData
    )
  }
}
