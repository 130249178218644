package builders

import com.diyoffer.negotiation.common.services.validation.assumableContractStateModel
import com.diyoffer.negotiation.model.*

data class OfferAssumableContractsBuilder(
  val party: Party,
  val negotiationStage: NegotiationStage,
  val currency: Currency,
  val assumableContractBuilders: List<NegotiatedTermBuilder<AssumableContract>>? = null,
) : IBuilder<OfferAssumableContracts> {

  override fun hydrate(item: OfferAssumableContracts?) = copy(
    assumableContractBuilders = item?.contracts?.map { contractTerm ->
      NegotiatedTermBuilder(
        party = party,
        negotiationStage = negotiationStage,
        term = contractTerm,
        stateValidation = ::assumableContractStateModel,
        currency = currency
      )
    }
  )

  override fun build(): BuildResult<OfferAssumableContracts> = validateAndBuild {
    OfferAssumableContracts(
      contracts = assumableContractBuilders!!.mapNotNull {
        (it.build() as? BuildResult.Success)?.result
      }
    )
  }

  @Suppress("SpreadOperator")
  private fun validateAndBuild(onValid: () -> OfferAssumableContracts): BuildResult<OfferAssumableContracts> {
    require(assumableContractBuilders != null) { "OfferAssumableContractsBuilder has not been hydrated" }
    return buildResult().mergeAndRun(
      onValid,
      *(assumableContractBuilders.map { it.build() }.toTypedArray())
    )
  }
}
