package components

import androidx.compose.runtime.Composable
import app.softwork.routingcompose.Router
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.ui.user.UserContract
import org.jetbrains.compose.web.css.marginRight
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Text
import style.DiyStyleSheet
import vm.login.UserViewModel

@Composable
fun ChangeLoginStateButton(vm: UserViewModel, user: SessionUser) {
  // Button is not visible for Signed users. It would be confusing for them to see a Signin while
  // they clicked on secured links
  if (user !is SessionUser.SignedUser && user !is SessionUser.NotLoaded) {
    val router = Router.current
    Button({
      classes(DiyStyleSheet.button, DiyStyleSheet.headerButtonLink)
      style { marginRight(16.px) }
      onClick {
        when (user) {
          is SessionUser.AnonUser -> router.navigate("/auth/sign-in")
          else -> vm.trySend(UserContract.Inputs.SignOut)
        }
      }
    }) {
      Text(if (user is SessionUser.AnonUser) "Sign in" else "Sign out")
    }
  }
}
