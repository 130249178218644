package auth

import androidx.compose.runtime.NoLiveLiterals
import com.diyoffer.negotiation.frontend.RequestAuthInterceptor
import environment.jsMetaEnvDiyApiKey
import environment.jsMetaEnvDiyUserId
import org.w3c.fetch.RequestInit

class RequestAuthApiKeyInterceptor : RequestAuthInterceptor {
  @NoLiveLiterals
  override fun withRequestInit(): suspend RequestInit.() -> Unit = {
    val apiKey = jsMetaEnvDiyApiKey()
    val userId = jsMetaEnvDiyUserId()

    if (apiKey.isNotEmpty() && userId.isNotEmpty()) {
      headers["x-diyoffer-api-key"] = apiKey
      headers["x-diyoffer-user-id"] = userId
    }
  }
}
