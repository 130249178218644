package com.diyoffer.negotiation.auth

import com.diyoffer.negotiation.model.auth.*
import dev.gitlive.firebase.auth.AuthTokenResult
import dev.gitlive.firebase.auth.FirebaseUser
import dev.gitlive.firebase.auth.UserInfo

fun UserInfo.toProviderUserInfo() = ProviderIdentityInfo(
  uid = uid,
  displayName = displayName,
  photoURL = photoURL,
  email = email,
  phoneNumber = phoneNumber,
  providerId = ProviderId.values().single { p -> p.providerKey == providerId }
)

fun FirebaseUser.toIdentityMetadata() = IdentityMetadata(
  uid = uid,
  displayName = displayName,
  photoURL = photoURL,
  email = email,
  phoneNumber = phoneNumber,
  providerId = ProviderId.fromProviderKey(providerId),
  emailVerified = isEmailVerified,
  providerData = providerData.map { it.toProviderUserInfo() }
)

fun AuthTokenResult.toTokenResult() = TokenResult(claims, token)
