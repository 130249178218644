package builders

import com.diyoffer.negotiation.common.formatCurrency
import com.diyoffer.negotiation.common.safeCast
import com.diyoffer.negotiation.model.*
import com.soywiz.kbignum.BigInt
import kotlinx.datetime.Clock
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone
import kotlinx.datetime.atTime
import kotlinx.datetime.toInstant

data class AssumableContractBuilder(
  val timeZone: TimeZone,
  val currency: Currency,
  val option: OptionKey? = null,
  val type: AssumableContractDetails.Type? = null,
  val expiry: LocalDate? = null,
  val monthlyFee: Money? = null,
  val buyoutFee: Money? = null,
) : IBuilder<AssumableContract> {

  companion object {
    val LARGE_CONTRACT_THRESHOLD = BigInt(1_500)
    val LARGE_BUYOUT_THRESHOLD = BigInt(10_000)
  }

  override fun hydrate(item: AssumableContract?) = copy(
    option = item?.contract,
    type = item?.contractDetails?.type,
    expiry = item?.contractDetails?.expiry,
    monthlyFee = item?.contractDetails?.monthlyFee,
    buyoutFee = item?.contractDetails?.safeCast<AssumableContractDetails.HasBuyout>()?.buyoutFee
  )

  override fun build(): BuildResult<AssumableContract> = validateAndBuild {
    AssumableContract(
      contract = option!!,
      contractDetails = when (type!!) {
        AssumableContractDetails.Type.RENTAL -> AssumableContractDetails.Rental(
          expiry = expiry,
          monthlyFee = monthlyFee!!,
        )
        AssumableContractDetails.Type.RENT_TO_OWN -> {
          AssumableContractDetails.RentToOwn(
            expiry = expiry!!,
            monthlyFee = monthlyFee!!,
            buyoutFee = buyoutFee!!,
          )
        }
        AssumableContractDetails.Type.FINANCED -> {
          AssumableContractDetails.Financed(
            expiry = expiry!!,
            monthlyFee = monthlyFee!!,
            buyoutFee = buyoutFee!!,
          )
        }
      }
    )
  }

  @Suppress("MaxLineLength", "NestedBlockDepth")
  private fun validateAndBuild(onValid: () -> AssumableContract): BuildResult<AssumableContract> {
    val errors = mutableListOf<String>()
    val warnings = mutableListOf<String>()

    if (option == null) errors.add("You must specify a contract type.")

    if (type == null) {
      errors.add("The contract Financing type must be specified.")
    } else {
      if (monthlyFee == null) {
        errors.add("The contract Monthly Fee must be specified.")
      } else {
        if (monthlyFee <= Money.ZERO) {
          errors.add(
            "Contract must have a value greater than ${
            formatCurrency(Money.ZERO, currency)
            }."
          )
        }
        val largeContractThreshold = Money(LARGE_CONTRACT_THRESHOLD, currency)
        if (monthlyFee > largeContractThreshold) {
          warnings.add(
            "Are you sure the Monthly Fee for this contract is above ${
            formatCurrency(largeContractThreshold, currency)
            }?"
          )
        }
      }
      if (type.hasBuyout) {
        if (buyoutFee == null) errors.add("The Buyout Fee must be set.")
        val largeBuyoutThreshold = Money(LARGE_BUYOUT_THRESHOLD, currency)
        buyoutFee?.let { bf ->
          if (bf > largeBuyoutThreshold) {
            warnings.add(
              "Are you sure the Buyout Fee for this contract is greater than ${
              formatCurrency(largeBuyoutThreshold, currency)
              }?"
            )
          }
        }
      }

      if (type != AssumableContractDetails.Type.RENTAL) {
        if (expiry == null) {
          errors.add("Expiry must be specified for ${type.title}.")
        }

        expiry?.let {
          if (it.atTime(0, 0, 0).toInstant(timeZone) < Clock.System.now()) {
            errors.add("The expiry must be later than today.")
          }
        }
      }
    }

    return buildResult(errors, warnings, onValid = onValid)
  }
}
