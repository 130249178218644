package com.diyoffer.negotiation.ui.listing

import com.copperleaf.ballast.EventHandler
import com.copperleaf.ballast.EventHandlerScope
import com.diyoffer.negotiation.common.Ignore
import com.diyoffer.negotiation.ui.listing.ListingLandingScreenContract.Events
import com.diyoffer.negotiation.ui.listing.ListingLandingScreenContract.Inputs
import com.diyoffer.negotiation.ui.listing.ListingLandingScreenContract.State

class ListingLandingScreenEventHandler : EventHandler<Inputs, Events, State> {
  override suspend fun EventHandlerScope<Inputs, Events, State>.handleEvent(
    event: Events,
  ) = Ignore
}
