package components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.rememberCoroutineScope
import com.diyoffer.negotiation.model.*
import common.FlexRow
import kotlinx.browser.window
import kotlinx.coroutines.await
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import style.DiyStyleSheet
import web.window.WindowTarget
import web.window.window as webWindow

/**
 * Displays a link to a url using a label.
 * If clip, link will be copied to clipboard, otherwise user will navigate
 */
@Composable
fun LinkDisplay(
  label: String,
  url: String,
  clip: Boolean = true,
  provider: ListingLinks.Link.ListingProvider = ListingLinks.Link.ListingProvider.OTHER,
  attrs: AttrBuilderContext<HTMLDivElement>? = null,
) {
  val scope = rememberCoroutineScope()
  FlexRow({
    style {
      alignItems(AlignItems.Center)
      gap(10.px)
    }
    attrs?.invoke(this)
  }) {
    ListingIcon(provider)
    Button({
      classes(DiyStyleSheet.button, DiyStyleSheet.buttonLink)
      style {
        padding(0.px)
        textAlign("start")
      }
      onClick {
        it.stopPropagation()
        if (clip) {
          scope.launch {
            window.navigator.clipboard.writeText(url).await()
            window.alert("The public facing link to this listing was copied to your clipboard.")
          }
        } else {
          webWindow.open(url, WindowTarget._blank)
        }
      }
    }) {
      Span({
        style { color(DiyStyleSheet.Colors.blue) }
      }) { Text(label) }
    }
  }
}

@Composable
fun ListingIcon(provider: ListingLinks.Link.ListingProvider) = Img(
  when (provider) {
    ListingLinks.Link.ListingProvider.FACEBOOK -> "images/facebook-32x32.png"
    ListingLinks.Link.ListingProvider.KIJIJI -> "images/kijiji-32x32.png"
    ListingLinks.Link.ListingProvider.CRAIGSLIST -> "images/craigslist-32x32.png"
    else -> "images/link.svg"
  }
) {
  style {
    width(16.px)
    height(16.px)
  }
}
