package components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import builders.BuildResult
import builders.IBuilder
import com.diyoffer.negotiation.common.removeAt
import com.diyoffer.negotiation.common.replaceAt
import common.FlexColumn
import common.FlexRow
import common.InputOptions
import common.LightBlueButton
import common.Row
import common.ValidationResult
import core.Builder
import core.DisplayContent
import core.EditContent
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.flexGrow
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import style.DiyStyleSheet
import style.DiyStyleSheet.Sizes.paddingSm
import style.DiyStyleSheet.withGreenLeftBorder

/**
 * Renders a list of editable items. The [OptionList] frame allows to add new items or remove existing items. It delegates
 * the Display and Edit item views to the caller and forwards it an [IBuilder]. The opts are used to hold the data and
 * onModify while the displayOpts contain the labels of buttons and help messages.
 */
@Composable
fun <T> OptionList(
  itemBuilder: IBuilder<T>,
  opts: Builder<InputOptions<List<T>>>,
  displayOpts: Builder<OptionListDisplayOpts<T>>,
  displayContent: DisplayContent<T>,
  editContent: EditContent<T>,
) {
  val options = InputOptions<List<T>>().apply { opts(this) }
  val displayOptions = OptionListDisplayOpts<T>().apply { displayOpts(this) }
  val (items, setItems) = remember { mutableStateOf<List<T?>>(options.value ?: listOf()) }

  Row({ classes(DiyStyleSheet.greyText) }) {
    Text(displayOptions.subtitleLabel)
  }

  items.mapIndexed { idx, item ->
    OptionInputItem(
      opts = {
        value = item
        onModified = {
          val updated = items.let { v ->
            if (it == null) {
              v.removeAt(idx)
            } else {
              v.replaceAt(idx, it)
            }
          }
          setItems(updated)
          options.onModified(updated.filterNotNull())
        }
      },
      itemBuilder = itemBuilder,
      displayContent = displayContent,
      editContent = editContent
    )
  }

  LightBlueButton({
    onClick {
      // Add the null Edit entry at the end
      setItems(items + null)
    }
  }) {
    Text(displayOptions.addButtonLabel)
  }
}

@Composable
private fun <T> OptionInputItem(
  itemBuilder: IBuilder<T>,
  opts: Builder<InputOptions<T>>,
  displayContent: DisplayContent<T>,
  editContent: EditContent<T>,
) {
  val options = InputOptions<T>().apply { opts(this) }
  val (editing, setEditing) = remember { mutableStateOf(options.value == null) }
  if (editing || options.value == null) {
    EditListItem(
      itemBuilder = itemBuilder,
      opts = {
        value = options.value
        onModified = {
          options.onModified(it)
          setEditing(false)
        }
      },
      editContent = editContent
    )
  } else {
    DisplayListItem(
      item = options.value!!,
      displayContent = displayContent,
      onEdit = { setEditing(true) },
      onRemove = { options.onModified(null) }
    )
  }
}

@Composable
private fun <T> DisplayListItem(
  item: T,
  displayContent: DisplayContent<T>,
  onEdit: () -> Unit,
  onRemove: () -> Unit,
) {
  FlexRow({
    classes(withGreenLeftBorder)
    style { justifyContent(JustifyContent.SpaceBetween) }
  }) {
    FlexColumn({ style { flexGrow(2) } }) {
      displayContent(this, item)
    }
    FlexColumn({ style { alignItems(AlignItems.FlexEnd) } }) {
      FlexRow({ style { gap(paddingSm) } }) {
        Button({
          classes(DiyStyleSheet.button, DiyStyleSheet.buttonLink)
          onClick { onEdit() }
        }) {
          Text("Edit details")
        }
        Button({
          classes(DiyStyleSheet.button, DiyStyleSheet.buttonLink)
          style { color(Color.red) }
          onClick { onRemove() }
        }) {
          Text("Remove")
        }
      }
    }
  }
}

@Composable
fun <T> EditListItem(
  itemBuilder: IBuilder<T>,
  opts: Builder<InputOptions<T>>,
  editContent: EditContent<T>,
) {
  val options = InputOptions<T>().apply { opts(this) }
  val (builder, setBuilder) = remember { mutableStateOf(itemBuilder.hydrate(options.value)) }
  val (showErrors, setShowErrors) = remember { mutableStateOf(false) }

  // Build the object and either retrieve the materialized full object of a list of warnings & errors
  val item = builder.build()

  fun submit() {
    setShowErrors(true)
    if (item is BuildResult.Success) {
      options.onModified(item.result)
    }
  }

  fun cancel() {
    // If we cancel, simply return whatever was provided to us
    options.onModified(options.value)
  }

  Div {
    editContent(this, builder, setBuilder)
  }

  // If we have not yet submitted, only display the warnings
  ValidationResult(item, showWarnings = true, showErrors = showErrors)

  FlexRow({ style { justifyContent(JustifyContent.FlexEnd) } }) {
    Button(attrs = {
      classes(DiyStyleSheet.button, DiyStyleSheet.onDarkBlue)
      onClick { submit() }
    }) { Text(if (options.value == null) "Add" else "Save Changes") }
    Button(attrs = {
      classes(DiyStyleSheet.button)
      style {
        backgroundColor(Color.transparent)
      }
      onClick { cancel() }
    }) { Text("Cancel") }
  }
}

data class OptionListDisplayOpts<T>(
  var subtitleLabel: String = "",
  var addButtonLabel: String = "",
  var addHelperLabel: String = "",
  var listSectionLabel: String = "",
)
