package style

import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.StyleSheet
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.borderWidth
import org.jetbrains.compose.web.css.bottom
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.deg
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.filter
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.fontFamily
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.fontStyle
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.media
import org.jetbrains.compose.web.css.mediaMinWidth
import org.jetbrains.compose.web.css.outline
import org.jetbrains.compose.web.css.overflow
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.paddingTop
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.rgba
import org.jetbrains.compose.web.css.style
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.css.textDecoration
import org.jetbrains.compose.web.css.top
import org.jetbrains.compose.web.css.width
import style.DiyStyleSheet.Sizes.paddingSm

@OptIn(ExperimentalComposeWebApi::class)
object DiyStyleSheet : StyleSheet() {
  object Colors {
    private const val overlayAlpha = 0.4
    private const val lightOverlayAlpha = 0.8
    val black = Color.black
    val white = Color.white
    val grey = Color("#AFAFAF")
    val lightGrey = Color("#ECECEC")
    val lightestGrey = Color("#F6F6F6")
    val darkGrey = Color("#646464")
    var midDarkGrey = Color("#969696")
    var blue = Color("#5C7ED8")
    val lightBlue = Color("#E6EBF9")
    val darkBlue = Color("#15385F")
    val green = Color("#00A664")
    val lightGreen = Color("#E8F6E8")
    val red = Color("#F25F5C")
    val lightRed = Color("#FDEEEE")
    val yellow = Color("#E7B845")
    val lightYellow = Color("#F8EAC8")
    val materialGrey = Color("#9E9E9E")
    val lightMaterialGrey = Color("#F1F4F7")
    val lightDark = Color("#F1F4F7")
    val overlay = rgba(0, 0, 0, overlayAlpha)

    @Suppress("MagicNumber")
    val lightOverlay = rgba(255, 255, 255, lightOverlayAlpha)
  }

  object ZIndex {
    const val CONTENT = 1
    const val OVERLAY = 2
    const val FORM = 5
    const val POPUP = 7 // mdc-dialog
  }

  enum class TwoToneColorFilters(val styleClass: String) {
    YELLOW("onLightYellow"),
    RED("onRed"),
    BLUE("onLightBlue"),
    GREEN("onGreen"),
    GREY("onGrey"),
  }

  object Sizes {
    val appWidth = 720.px
    val padding = 24.px
    val paddingXs = 6.px
    val paddingSm = 12.px
    val paddingMd = 16.px
  }

  object Weights {
    const val normal = 400
    const val darkNormal = 500
    const val semiBold = 600
    const val bold = 700
    const val dark = 900
  }

  val lightBorder by style {
    border {
      style(LineStyle.Solid)
      width(1.px)
      color(Colors.lightGrey)
    }
  }

  val container by style {
    padding(Sizes.padding)
    marginBottom(Sizes.padding)
    color(Colors.darkGrey)
  }

  val appContainer by style {
    display(DisplayStyle.Flex)
    justifyContent(JustifyContent.Center)
    paddingTop(48.px)
  }

  val fullWidth by style {
    width(100.percent)
  }

  val loginContainer by style {
    display(DisplayStyle.Flex)
    flexDirection(FlexDirection.Row)
    property("height", "100vh")
    property("width", "100vw")
  }

  val listingDetailContainer by style {
    display(DisplayStyle.Flex)
    gap(109.px)
    marginTop(Sizes.padding)
  }

  val mainForm by style {
    flexDirection(FlexDirection.Row)
    width(Sizes.appWidth)
  }

  val loginForm by style {
    display(DisplayStyle.Flex)
    justifyContent(JustifyContent.Center)
    borderRadius(0.px)
    property("width", "100%")
  }

  val semiBold by style {
    fontWeight(Weights.semiBold)
  }

  val button by style {
    fontWeight(value = 700)
    textAlign("center")
    justifyContent(JustifyContent.Center)
    padding(8.px, Sizes.padding)
    color(Colors.darkGrey)
    borderWidth(0.px)
    cursor("pointer")
  }

  val buttonLink by style {
    backgroundColor(Color.transparent)
    color(Colors.blue)
    cursor("pointer")
    padding(0.px)
  }

  val headerButtonLink by style {
    backgroundColor(Color.transparent)
    color(Colors.darkBlue)
    fontWeight(Weights.normal)
    cursor("pointer")
  }

  val fieldLabel12 by style {
    fontSize(12.px)
    color(Colors.darkGrey)
  }

  val fieldLabel20 by style {
    fontSize(20.px)
    color(Colors.black)
  }

  val onLightBlue by style {
    backgroundColor(Colors.lightBlue)
    color(Colors.blue)
    borderWidth(0.px)
  }

  val onDarkBlue by style {
    fontWeight(Weights.semiBold)
    color(Colors.white)
    backgroundColor(Colors.darkBlue)
  }

  val onDarkBlueNoBackground by style {
    fontWeight(Weights.semiBold)
    color(Colors.darkBlue)
    backgroundColor(Color.transparent)
    border {
      style(LineStyle.Solid)
      width(1.px)
      color(Colors.darkBlue)
    }
  }

  val boxWithGreenBorder by style {
    border {
      style(LineStyle.Solid)
      width(1.25.px)
      backgroundColor(Color.white)
      color(Colors.green)
      padding(Sizes.paddingSm)
    }
  }

  val withBlueBorder by style {
    border {
      style(LineStyle.Solid)
      width(1.25.px)
      color(Colors.blue)
      cursor("pointer")
    }
  }

  val onRed by style {
    fontWeight(Weights.darkNormal)
    color(Colors.red)
    backgroundColor(Colors.lightRed)
    border {
      width(1.px)
      style(LineStyle.Solid)
      color(Colors.red)
    }
  }

  val onLightGreen by style {
    backgroundColor(Colors.lightGreen)
    color(Colors.green)
    border {
      style(LineStyle.Solid)
      width(1.25.px)
      color(Colors.green)
    }
  }

  val withGreenLeftBorder by style {
    borderRadius(0.px)
    property("border-left", "2px solid ${Colors.green}")
    paddingLeft(paddingSm)
  }

  val onGreen by style {
    backgroundColor(Colors.green)
    color(Colors.white)
    // Adding a border so it has the same size as the other button
    border {
      style(LineStyle.Solid)
      width(1.25.px)
      color(Colors.green)
    }
  }

  val onLightGrey by style {
    color(Colors.midDarkGrey)
    backgroundColor(Colors.lightGrey)
    border {
      style(LineStyle.Solid)
      width(1.25.px)
      color(Colors.midDarkGrey)
    }
  }

  val onLightRed by style {
    color(Colors.red)
    backgroundColor(Colors.lightRed)
    border {
      style(LineStyle.Solid)
      width(1.px)
      color(Colors.red)
    }
  }

  val onLightYellow by style {
    color(Colors.darkGrey)
    backgroundColor(Colors.lightYellow)
    border {
      style(LineStyle.Solid)
      width(1.px)
      color(Colors.yellow)
    }
  }

  val greyText by style {
    color(Colors.grey)
    marginBottom(paddingSm)
  }

  val italicGreyText by style {
    color(Colors.grey)
    fontStyle("italic")
  }

  val darkGreyText by style {
    color(Colors.darkGrey)
    marginBottom(paddingSm)
  }

  val errorText by style {
    color(Color.red)
    fontWeight(Weights.semiBold)
  }

  val hrDivider by style {
    padding(0.px)
    property("margin", "6px 0")
  }

  val headerContainer by style {
    display(DisplayStyle.Flex)
    flexDirection(FlexDirection.Row)
    alignItems(AlignItems.Center)
    height(80.px)
    backgroundColor(Colors.lightMaterialGrey)
  }

  val headerMenu by style {
    width(100.percent)
    display(DisplayStyle.Flex)
    flexDirection(FlexDirection.Row)
    justifyContent(JustifyContent.SpaceBetween)
    alignItems(AlignItems.Center)
    media(mediaMinWidth(400.px)) {
      self style {
        paddingLeft(10.px)
        paddingRight(10.px)
      }
    }
    media(mediaMinWidth(600.px)) {
      self style {
        paddingLeft(66.px)
        paddingRight(66.px)
      }
    }
    media(mediaMinWidth(900.px)) {
      self style {
        paddingLeft(196.px)
        paddingRight(196.px)
      }
    }
    overflow("hidden")
  }

  val overlay by style {
    position(Position.Fixed)
    display(DisplayStyle.None)
    width(100.percent)
    height(100.percent)
    top(0.px)
    bottom(0.px)
    backgroundColor(Colors.overlay)
    cursor("pointer")
    property("z-index", ZIndex.OVERLAY)
  }

  val fillAbsolute by style {
    position(Position.Absolute)
    width(100.percent)
    height(100.percent)
    top(0.px)
    bottom(0.px)
  }

  val informationContainer by style {
    display(DisplayStyle.Flex)
    flexDirection(FlexDirection.Row)
    justifyContent(JustifyContent.Center)
    alignItems(AlignItems.Center)
    height(56.px)
    property("border-bottom", "2px solid ${Colors.lightGrey}")
  }

  val largeGreenText by style {
    fontSize(24.px)
    fontWeight(Weights.bold)
    color(Colors.green)
    textAlign("center")
  }

  val fontSize12 by style {
    fontSize(12.px)
  }

  val fontSize14 by style {
    fontSize(14.px)
  }

  val fontSize16 by style {
    fontSize(16.px)
  }

  val fontSize18 by style {
    fontSize(18.px)
  }

  val fontSize20 by style {
    fontSize(20.px)
  }

  val fontWeightDarkNormal by style {
    fontWeight(Weights.darkNormal)
  }

  val fontWeightSemiBold by style {
    fontWeight(Weights.semiBold)
  }

  val fontWeightBold by style {
    fontWeight(Weights.bold)
  }

  init {
    "#root" style {
      fontFamily("Roboto", "Arial", "Helvetica", "sans-serif")
      fontSize(14.px)
      fontWeight(Weights.normal)
    }

    "div, button, input" style {
      borderRadius(4.px)
    }

    "h2" style {
      fontSize(22.px)
      fontWeight(Weights.bold)
      color(Colors.darkBlue)
    }

    "h4" style {
      fontSize(16.px)
      fontWeight(Weights.semiBold)
    }

    "h5" style {
      fontSize(14.px)
      fontWeight(Weights.semiBold)
      color(Colors.darkBlue)
      marginTop(Sizes.paddingSm)
      marginBottom(Sizes.paddingXs)
    }

    "hr" style {
      marginTop(Sizes.paddingMd)
      marginBottom(Sizes.paddingMd)
      property("border", "none")
      property("border-bottom", "1px solid ${Colors.lightGrey}")
    }

    "input[type='checkbox']" style {
      property("accent-color", Colors.green)
    }

    "input[type='radio']" style {
      property("accent-color", Colors.green)
    }

    "input[type='date']" style {
      padding(Sizes.paddingSm, Sizes.paddingMd)
      width(100.percent)
      color(Colors.darkGrey)
      border {
        style(LineStyle.Solid)
        width(1.px)
        color(Colors.materialGrey)
      }
    }

    "input[type='date']:focus" style {
      outline("none")
      border {
        style(LineStyle.Solid)
        width(2.px)
        color(Colors.green)
      }
    }

    "a" style {
      color(Colors.blue)
      cursor("pointer")
    }

    ".underline" style {
      textDecoration("underline")
    }

    // The two-tone doesn't honor color and backgroundColor so we need a filter
    // https://github.com/material-components/material-components-web-react/issues/730
    // https://codepen.io/sosuke/pen/Pjoqqp
    ".material-icons-two-tone.${TwoToneColorFilters.YELLOW.styleClass}" style {
      filter {
        invert(68.percent)
        sepia(10.percent)
        saturate(4119.percent)
        hueRotate(9.deg)
        brightness(100.percent)
        contrast(106.percent)
      }
    }
    ".material-icons-two-tone.${TwoToneColorFilters.RED.styleClass}" style {
      filter {
        invert(43.percent)
        sepia(97.percent)
        saturate(2292.percent)
        hueRotate(331.deg)
        brightness(113.percent)
        contrast(89.percent)
      }
    }
    ".material-icons-two-tone.${TwoToneColorFilters.BLUE.styleClass}" style {
      filter {
        invert(44.percent)
        sepia(68.percent)
        saturate(587.percent)
        hueRotate(187.deg)
        brightness(96.percent)
        contrast(75.percent)
      }
      ".material-icons-two-tone.${TwoToneColorFilters.GREEN.styleClass}" style {
        filter {
          invert(53.percent)
          sepia(58.percent)
          saturate(5934.percent)
          hueRotate(133.deg)
          brightness(94.percent)
          contrast(101.percent)
        }
      }
      ".material-icons-two-tone.${TwoToneColorFilters.GREY.styleClass}" style {
        filter {
          invert(63.percent)
          sepia(0.percent)
          saturate(464.percent)
          hueRotate(178.deg)
          brightness(94.percent)
          contrast(94.percent)
        }
      }
    }
  }
}
