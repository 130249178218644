//
// GENERATED by KVision
//
package com.diyoffer.negotiation.rpcs

import io.kvision.remote.HttpMethod
import io.kvision.remote.KVServiceManager
import io.kvision.remote.registerKVisionServiceExceptions

expect class ContactVerificationRpcService : IContactVerificationRpcService

object ContactVerificationRpcServiceManager : KVServiceManager<ContactVerificationRpcService>(ContactVerificationRpcService::class) {
    init {
        registerKVisionServiceExceptions()
        bind(IContactVerificationRpcService::startVerification, HttpMethod.POST, "contact/start_verification")
        bind(IContactVerificationRpcService::completeVerificationViaOobCode, HttpMethod.POST, "contact/complete_verification_via_oob_code")
        bind(IContactVerificationRpcService::completeVerificationViaLink, HttpMethod.POST, "contact/complete_verification_via_link")
    }
}
