package builders

import com.diyoffer.negotiation.common.today
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.PropertyOwnership.Type.Companion.taxRollOptionality
import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone

data class ListingPropertyDetailsBuilder(
  val jurisdiction: Jurisdiction,
  val clock: Clock,
  val timeZone: TimeZone,
  val address: AddressBuilder = AddressBuilder(
    country = jurisdiction.country,
    provinceState = jurisdiction.provinceState
  ),
  val propertyOwnershipType: PropertyOwnership.Type? = null,
  val taxYear: Int? = null,
  val taxAmount: Money? = null,
  val rollNumber: String? = null,
  val monthlyFees: Money? = null,
  val parkingSpace: String? = null, // UI NOT IMPLEMENTED
  val lockers: String? = null, // UI NOT IMPLEMENTED
  val lotServices: List<OptionKey> = listOf(), // UI NOT IMPLEMENTED
  val activeMortgage: Boolean? = null,
) : IBuilder<ListingPropertyDetails> {
  companion object {
    // How far in the past can the reported taxable year be on
    const val TAX_YEAR_PAST_LIMIT_YEARS = 3
  }
  override fun hydrate(item: ListingPropertyDetails?) = item?.let {
    copy(
      jurisdiction = it.address.jurisdiction(),
      address = AddressBuilder().hydrate(it.address) as AddressBuilder,
      propertyOwnershipType = it.propertyOwnership.type,
      taxYear = it.propertyTaxes.year,
      taxAmount = it.propertyTaxes.amount,
      rollNumber = it.propertyTaxes.rollNumber,
      parkingSpace = (it.propertyOwnership as? PropertyOwnership.ApartmentStyle)?.parkingSpaces,
      lockers = (it.propertyOwnership as? PropertyOwnership.ApartmentStyle)?.lockers,
      monthlyFees = it.propertyOwnership.monthlyFees,
      lotServices = listOf(), // TODO
      activeMortgage = it.activeMortgage
    )
  } ?: ListingPropertyDetailsBuilder(jurisdiction, clock, timeZone)

  override fun build(): BuildResult<ListingPropertyDetails> = validateAndBuild {
    ListingPropertyDetails(
      address = (address.build() as BuildResult.Success).result,
      propertyOwnership = when (propertyOwnershipType) {
        PropertyOwnership.Type.FREEHOLD -> PropertyOwnership.Freehold(monthlyFees)
        PropertyOwnership.Type.CONDO_STRATA -> PropertyOwnership.CondoStrata(
          monthlyFees!!,
          parkingSpace,
          lockers
        )
        PropertyOwnership.Type.TIMESHARE_FRACTIONAL ->
          PropertyOwnership.TimeshareFractional(monthlyFees!!, parkingSpace, lockers)
        PropertyOwnership.Type.LEASEHOLD -> PropertyOwnership.Leasehold(monthlyFees!!, parkingSpace, lockers)
        else -> throw IllegalArgumentException("System error: invalid PropertyOwnership.Type provided")
      },
      propertyTaxes = PropertyTaxes(
        taxYear!!,
        taxAmount!!,
        if (propertyOwnershipType.taxRollOptionality() == FieldOptionality.UNAVAILABLE) {
          null
        } else {
          rollNumber?.ifBlank { null }
        },
      ),
      lotServices = lotServices,
      activeMortgage = activeMortgage!!
    )
  }

  private fun validateAndBuild(onValid: () -> ListingPropertyDetails): BuildResult<ListingPropertyDetails> {
    val errors = mutableListOf<String>()
    val warnings = mutableListOf<String>()

    val addressResult = address.build()
    warnings += addressResult.warnings
    if (addressResult is BuildResult.Failure) errors.addAll(addressResult.errors)

    if (taxAmount == null) errors.add("Missing yearly tax amount.")
    if (taxYear == null) {
      errors.add("Missing year of the tax amount.")
    } else if (taxYear > clock.today(timeZone).year) {
      errors.add("Tax year cannot be in the future.")
    } else if (taxYear < clock.today(timeZone).year - TAX_YEAR_PAST_LIMIT_YEARS) {
      warnings.add("Are you sure the last available tax year is over $TAX_YEAR_PAST_LIMIT_YEARS years ago?")
    }
    if (propertyOwnershipType?.taxRollOptionality() == FieldOptionality.REQUIRED && rollNumber.isNullOrBlank()) {
      errors.add("Missing property tax roll number.")
    }
    if (activeMortgage == null) errors.add("Please specify if there is an active mortgage.")
    if (propertyOwnershipType != PropertyOwnership.Type.FREEHOLD && monthlyFees == null) {
      errors.add("Missing monthly fees on the property.")
    }

    return buildResult(errors, warnings, onValid = onValid)
  }
}
