package com.diyoffer.negotiation.frontend

import com.diyoffer.negotiation.rpcs.CmsRpcService
import com.diyoffer.negotiation.rpcs.ContactVerificationRpcService
import com.diyoffer.negotiation.rpcs.DynamicOptionsRpcService
import com.diyoffer.negotiation.rpcs.ICmsRpcService
import com.diyoffer.negotiation.rpcs.IContactVerificationRpcService
import com.diyoffer.negotiation.rpcs.IDynamicOptionsRpcService
import com.diyoffer.negotiation.rpcs.ILinksRpcService
import com.diyoffer.negotiation.rpcs.IListingAnonRpcService
import com.diyoffer.negotiation.rpcs.IListingAuthRpcService
import com.diyoffer.negotiation.rpcs.IOfferAnonRpcService
import com.diyoffer.negotiation.rpcs.IOfferAuthRpcService
import com.diyoffer.negotiation.rpcs.IUpdateCheckRpcService
import com.diyoffer.negotiation.rpcs.IUserRpcService
import com.diyoffer.negotiation.rpcs.LinksRpcService
import com.diyoffer.negotiation.rpcs.ListingAnonRpcService
import com.diyoffer.negotiation.rpcs.ListingAuthRpcService
import com.diyoffer.negotiation.rpcs.OfferAnonRpcService
import com.diyoffer.negotiation.rpcs.OfferAuthRpcService
import com.diyoffer.negotiation.rpcs.UpdateCheckRpcService
import com.diyoffer.negotiation.rpcs.UserRpcService
import org.kodein.di.DI
import org.kodein.di.DirectDIAware
import org.kodein.di.bindSingleton
import org.kodein.di.instance
import org.w3c.fetch.RequestInit

val frontendRpcsModule = DI.Module("frontendRpcsModule") {
  // even though not all these services require auth, provide it anyway if available for better logging on the server
  bindSingleton<ICmsRpcService> { newRpcService(::CmsRpcService) }

  bindSingleton<IContactVerificationRpcService> { newRpcService(::ContactVerificationRpcService) }

  bindSingleton<IDynamicOptionsRpcService> { newRpcService(::DynamicOptionsRpcService) }

  bindSingleton<ILinksRpcService> { newRpcService(::LinksRpcService) }

  bindSingleton<IListingAnonRpcService> { newRpcService(::ListingAnonRpcService) }

  bindSingleton<IListingAuthRpcService> { newRpcService(::ListingAuthRpcService) }

  bindSingleton<IOfferAnonRpcService> { newRpcService(::OfferAnonRpcService) }

  bindSingleton<IOfferAuthRpcService> { newRpcService(::OfferAuthRpcService) }

  bindSingleton<IUpdateCheckRpcService> { newRpcService(::UpdateCheckRpcService) }

  bindSingleton<IUserRpcService> { newRpcService(::UserRpcService) }
}

interface RequestAuthInterceptor {
  fun withRequestInit(): suspend RequestInit.() -> Unit
}

inline fun <reified R, reified T1> DirectDIAware.newRpcService(
  constructor: (T1, (suspend RequestInit.() -> Unit)?) -> R,
): R = constructor(instance(), instance<RequestAuthInterceptor>().withRequestInit())
