package layout

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.overflowY
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement
import style.DiyStyleSheet
import style.GridStyleSheet

@Composable
fun FormContainer(content: ContentBuilder<HTMLDivElement>) {
  Div({
    id("diy")
    classes(DiyStyleSheet.listingDetailContainer)
  }) {
    Div({
      classes(GridStyleSheet.flex, GridStyleSheet.flexColumn, GridStyleSheet.flexGrow)
      style {
        padding(48.px, 0.px)
        overflowY("auto")
        alignItems(AlignItems.Center)
        justifyContent(JustifyContent.Center)
      }
    }, content = content)
  }
}
