package builders

import com.diyoffer.negotiation.model.*
import kotlinx.datetime.Clock

data class FixturesExcludedListBuilder(
  val fixturesExcludedList: List<FixtureExclusion>? = null,
) : IBuilder<ListingFixturesExcluded> {

  companion object {
    fun create(item: ListingFixturesExcluded?): IBuilder<ListingFixturesExcluded> {
      return item?.let { fixtureExcluded ->
        FixturesExcludedListBuilder(
          fixturesExcludedList = fixtureExcluded.fixturesExcluded.mapNotNull {
            it.core.value.getOrNull()
          }
        )
      } ?: FixturesExcludedListBuilder()
    }
  }

  override fun hydrate(item: ListingFixturesExcluded?) = create(item)

  override fun build(): BuildResult<ListingFixturesExcluded> = validateAndBuild {
    ListingFixturesExcluded(
      fixturesExcluded = fixturesExcludedList?.map {
        Auditable.Core(
          Optional.of(it),
          Clock.System.now().lowRes()
        )
      } ?: listOf()
    )
  }

  private fun validateAndBuild(onValid: () -> ListingFixturesExcluded): BuildResult<ListingFixturesExcluded> {
    val warnings = mutableListOf<String>()

    // Each item have run their own builder, so we are just validating at the list level
    if (fixturesExcludedList.isNullOrEmpty()) {
      warnings.add("You are not disclosing fixture or exclusion.")
    }

    return buildResult(warnings = warnings, onValid = onValid)
  }
}
