package com.diyoffer.negotiation.model

import kotlinx.serialization.Serializable

interface PartySpecific<out T> {
  val buyer: T
  val seller: T
}

@Serializable
data class GuideAnchor(
  override val buyer: String,
  override val seller: String,
) : PartySpecific<String>

@Serializable
data class PopupHelp(
  override val buyer: CmsReference? = null,
  override val seller: CmsReference? = null,
) : PartySpecific<CmsReference?> {
  init {
    require(buyer?.fieldType == null || buyer.fieldType == CmsFieldType.POPUP_HELP)
    require(seller?.fieldType == null || seller.fieldType == CmsFieldType.POPUP_HELP)
  }
}

fun <T> PartySpecific<T>.forParty(party: Party) = when (party) {
  Party.BUYER -> buyer
  Party.SELLER -> seller
}
