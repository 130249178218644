package forms

import androidx.compose.runtime.Composable
import builders.FixturesExcludedBuilder
import builders.FixturesExcludedListBuilder
import com.diyoffer.negotiation.model.*
import common.FlatList
import common.Row
import components.OptionList
import org.jetbrains.compose.web.css.paddingBottom
import style.DiyStyleSheet.Sizes.paddingXs

@Composable
fun ListingFixtureExcludedListEdit(
  builder: FixturesExcludedListBuilder,
  setBuilder: (FixturesExcludedListBuilder) -> Unit,
) {
  OptionList(
    itemBuilder = FixturesExcludedBuilder(),
    opts = {
      value = builder.fixturesExcludedList
      onModified = { setBuilder(builder.copy(fixturesExcludedList = it)) }
    },
    displayOpts = {
      subtitleLabel = "Click on Add a Fixture or Exclusion to disclose any"
      addButtonLabel = "Add a Fixture or Exclusion"
      listSectionLabel = "ListSelectionLabel"
    },
    displayContent = { FixtureExcludedDisplay(it) },
    editContent = { b, sb -> ListingFixtureExcludedEdit(b as FixturesExcludedBuilder, sb) }
  )
}

@Composable
fun ListingFixturesExcludedListDisplay(item: ListingFixturesExcluded) {
  val reduced = FixturesExcludedListBuilder().hydrate(item) as FixturesExcludedListBuilder
  reduced.fixturesExcludedList?.let { fixturesExcluded ->
    if (fixturesExcluded.isEmpty()) {
      FlatList("No Fixture or Exclusion")
    } else {
      fixturesExcluded.mapIndexed { idx, f ->
        Row({ style { if (idx < fixturesExcluded.size - 1) paddingBottom(paddingXs) } }) {
          FixtureExcludedDisplay(f)
        }
      }
    }
  }
}
