package components

import androidx.compose.runtime.Composable
import builders.AddressBuilder
import com.diyoffer.negotiation.model.*
import common.FlexColumn
import common.FlexRow
import common.TextField
import org.jetbrains.compose.web.css.flexGrow
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div

@Composable
@Suppress("LongMethod")
fun AddressInput(disabled: Boolean = false, builder: AddressBuilder, setBuilder: (AddressBuilder) -> Unit) {
  FlexRow {
    Div({ style { width(120.px) } }) {
      TextField(
        opts = {
          label = "#"
          value = builder.streetNumber
          this.disabled = disabled
          onModified = { setBuilder(builder.copy(streetNumber = it)) }
        }
      )
    }
    TextField(
      opts = {
        label = "Street"
        value = builder.street
        this.disabled = disabled
        onModified = { setBuilder(builder.copy(street = it)) }
      }
    )
    Div({ style { width(100.px) } }) {
      TextField(
        opts = {
          label = "Apt. or Lot"
          value = builder.suiteNumber
          this.disabled = disabled
          onModified = { setBuilder(builder.copy(suiteNumber = it)) }
        }
      )
    }
  }
  FlexRow {
    FlexColumn {
      TextField(
        opts = {
          label = "City"
          value = builder.city
          this.disabled = disabled
          onModified = { setBuilder(builder.copy(city = it)) }
        },
        attrs = {
          style { flexGrow(1) }
        }
      )
    }
    FlexColumn {
      DiySelect(
        label = builder.country.provinceStateLabel,
        items = ProvinceState.values().filter { it.country == builder.country }.toList(),
        initialSelected = builder.provinceState,
        renderer = { it.provinceState },
        disabled = disabled
      ) {
        setBuilder(builder.copy(provinceState = it))
      }
    }
    FlexColumn {
      TextField(
        opts = {
          label = builder.country.postalZipLabel
          value = builder.postalZipCode
          this.disabled = disabled
          onModified = { setBuilder(builder.copy(postalZipCode = it)) }
        }
      )
    }
  }
}
