package com.diyoffer.negotiation.ui.login

import com.copperleaf.ballast.InputHandler
import com.copperleaf.ballast.InputHandlerScope
import com.copperleaf.ballast.observeFlows
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.auth.ProviderId.Companion.displayName
import com.diyoffer.negotiation.model.client.*
import com.diyoffer.negotiation.repository.user.UserRepository
import com.diyoffer.negotiation.ui.login.SignInScreenContract.Events
import com.diyoffer.negotiation.ui.login.SignInScreenContract.Inputs
import com.diyoffer.negotiation.ui.login.SignInScreenContract.State
import com.diyoffer.negotiation.ui.state.StateValidation
import com.diyoffer.negotiation.ui.state.doValidations
import kotlinx.coroutines.flow.map

typealias SignInInputHandlerScope = InputHandlerScope<Inputs, Events, State>

class SignInScreenInputHandler(
  private val userRepo: UserRepository,
) : InputHandler<Inputs, Events, State> {

  private val validations = listOf<StateValidation<State>>(
    StateValidation(
      { copy(emailError = "You must supply a valid email.") },
      { copy(emailError = null) },
    ) { email.isNullOrBlank() },
    StateValidation(
      { copy(passwordError = "You must input your password.") },
      { copy(passwordError = null) },
    ) { password.isNullOrBlank() },
  )

  @Suppress("LongMethod", "CyclomaticComplexMethod")
  override suspend fun SignInInputHandlerScope.handleInput(input: Inputs) = when (input) {
    Inputs.Initialize -> {
      userRepo.initializeAuth()
      updateState {
        it.clearErrors()
      }
      observeFlows(
        "SignInScreenInputHandler.observeFlows",
        userRepo
          .getAuthResult()
          .map {
            when (it) {
              is SuccessOrFailure.Failure -> Inputs.SignInFailure(it.message)
              SuccessOrFailure.Success -> Inputs.SignInSuccess
              // received on Initialize auth
              null -> Inputs.ClearErrors
            }
          },
        userRepo
          .getLoadingState()
          .map { Inputs.LoadingStateChange(it) },
      )
    }
    Inputs.ClearErrors -> {
      updateState { it.clearErrors() }
    }
    is Inputs.UsernameChanged -> {
      updateState { it.copy(email = input.value, emailError = null) }
    }
    is Inputs.PasswordChanged -> {
      updateState { it.copy(password = input.value, passwordError = null) }
    }
    Inputs.SignInStarted -> {
      updateState {
        it.clearErrors()
      }
      userRepo.startSignIn()
    }
    Inputs.SocialSignUpStarted -> {
      updateState {
        it.clearErrors()
      }
      // We don't have a country here so for now, we'll force US as the primary.
      // The UI should be updated so country is asked to user OR country should be inferred (complex) OR
      // we should remove social signup on the sign-in screen and forward correctly
      userRepo.startSocialSignup(Country.US)
    }
    is Inputs.SignInWithEmailAndPassword -> {
      updateState {
        it.clearErrors()
      }
      signInWithEmailAndPassword()
    }
    Inputs.SignInSuccess -> {
      updateState { it.clearErrors() }
      postEvent(Events.OnSuccess)
    }
    is Inputs.SignInFailure -> {
      updateState { it.copy(error = input.message) }
    }
    is Inputs.LoadingStateChange -> {
      updateState { it.copy(loadingState = input.loadingState) }
    }
    is Inputs.ObtainSocialAuthCredentialsError -> {
      val error = firebaseAuthExceptionToMessage(input.exception)
      userRepo.socialAuthCredentialsError(error)
      noOp()
    }
    is Inputs.AlternateCredentialsRequiredError -> {
      userRepo.linkCredentials(input.linkCredentials)
      updateState {
        val linkCredentials = input.linkCredentials
        val current = linkCredentials.currentProviderId.displayName()
        val new = linkCredentials.providerId.displayName()
        it.clearErrors().copy(
          socialError = "Success! However, we see you previously signed in with $current — " +
            "we need you to sign in with $current again so we can link your $new account.",
          linkCredentials = linkCredentials,
        )
      }
    }
  }

  private suspend fun SignInInputHandlerScope.signInWithEmailAndPassword() {
    if (!doValidations(this, validations)) return
    val state = getCurrentState()
    userRepo.signInWithEmailAndPassword(state.email!!, state.password!!)
  }
}
