package builders

import com.diyoffer.negotiation.model.*
import kotlinx.datetime.Clock

data class ListingBindingAgreementTermsBuilder(
  val days: Days? = null,
) : IBuilder<BindingAgreementTerms> {
  override fun hydrate(item: BindingAgreementTerms?) =
    ListingBindingAgreementTermsBuilder(
      days = item?.days?.core?.value?.getOrNull(),
    )

  override fun build(): BuildResult<BindingAgreementTerms> = validateAndBuild {
    BindingAgreementTerms(
      days = Auditable.Core(
        Optional.of(days!!),
        Clock.System.now().lowRes()
      )
    )
  }

  private fun validateAndBuild(onValid: () -> BindingAgreementTerms): BuildResult<BindingAgreementTerms> {
    val errors = mutableListOf<String>()

    if (days == null) { errors.add("You must select days.") }

    return buildResult(errors, onValid = onValid)
  }
}
