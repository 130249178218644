package com.diyoffer.negotiation.cms.serdes

import com.diyoffer.negotiation.cms.SpanMark
import com.diyoffer.negotiation.cms.SpanMarkDefMark
import com.diyoffer.negotiation.cms.SpanStyleMark
import com.diyoffer.negotiation.cms.StyleMark
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object SpanMarkSerializer : KSerializer<SpanMark> {
  override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("mark", PrimitiveKind.STRING)

  override fun serialize(encoder: Encoder, value: SpanMark) {
    encoder.encodeString(
      when (value) {
        is SpanMarkDefMark -> value.key
        is SpanStyleMark -> value.style.name.lowercase()
      }
    )
  }

  override fun deserialize(decoder: Decoder): SpanMark {
    return when (val string = decoder.decodeString()) {
      "em" -> SpanStyleMark(StyleMark.EM)
      "strong" -> SpanStyleMark(StyleMark.STRONG)
      else -> SpanMarkDefMark(string)
    }
  }
}
