package components

import androidx.compose.runtime.Composable
import common.SpacerFlexGrow
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import style.WelcomeCardStyleSheet

@Composable
fun WelcomeCard(
  title: String,
  message: @Composable () -> Unit,
  highlightLabel: String? = null,
  content: ContentBuilder<HTMLDivElement>,
) {
  Style(WelcomeCardStyleSheet)
  Div({
    classes(
      WelcomeCardStyleSheet.container,
      if (highlightLabel != null) WelcomeCardStyleSheet.highlightBorder else WelcomeCardStyleSheet.border
    )
  }) {
    Div({
      classes(WelcomeCardStyleSheet.title)
    }) {
      Text(title)
    }
    Div({
      classes(WelcomeCardStyleSheet.message)
    }) {
      message()
    }
    SpacerFlexGrow()
    Div(content = content)

    if (highlightLabel != null) {
      Div({
        classes(WelcomeCardStyleSheet.highlight)
      }) {
        Text(highlightLabel)
      }
    }
  }
}
