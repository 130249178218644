package services

import androidx.compose.runtime.Composable
import androidx.compose.runtime.State
import androidx.compose.runtime.produceState
import com.diyoffer.negotiation.cms.PortableTextBlock
import com.diyoffer.negotiation.messages.CommonMessages
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.rpcs.ICmsRpcService
import com.diyoffer.negotiation.services.tryRpc

sealed class RichTextState {
  data object Loading : RichTextState()
  data class Success(val blocks: List<PortableTextBlock>) : RichTextState()
  data class Error(val message: String) : RichTextState()
}

class PopupLoader(
  private val cmsRpcService: ICmsRpcService,
) {
  @Composable
  fun load(
    cmsReference: CmsReference,
  ): State<RichTextState> {
    @Suppress("TooGenericExceptionCaught")
    return produceState<RichTextState>(initialValue = RichTextState.Loading, cmsReference) {
      value = tryRpc(
        onException = { _, e ->
          RichTextState.Error("Unexpected error loading popup. ${CommonMessages.contactAdministrator(e)}")
        },
      ) {
        val blocks = cmsRpcService.loadPopupHelp(cmsReference).blocks
        RichTextState.Success(blocks)
      }
    }
  }
}
