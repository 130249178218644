package com.diyoffer.negotiation.model.rpcs

import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.serdes.*
import kotlinx.serialization.Serializable

@Serializable
sealed class ListingLoadResult {
  @Serializable
  data class Success(
    val listing: Listing,
    val offerCount: Int? = null,
    val otherAcceptedOfferExpiryTime: Pair<@Serializable(with = UidSerializer::class) Uid<Offer>, InstantLr?>? = null,
  ) : ListingLoadResult()

  @Serializable
  data object NotFound : ListingLoadResult()

  fun message() = when (this) {
    is Success -> "Success"
    is NotFound -> "Listing could not be loaded."
  }
}

@Serializable
sealed class ListingListLoadResult {
  @Serializable
  data class Success(val listings: List<ListingLoadResult.Success>) : ListingListLoadResult()
}
