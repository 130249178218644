package common

import androidx.compose.runtime.Composable
import com.diyoffer.negotiation.common.bnOrNull
import com.diyoffer.negotiation.common.formatCurrency
import com.diyoffer.negotiation.model.*
import com.soywiz.kbignum.BigNum
import core.Builder
import model.FormValue
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.w3c.dom.HTMLInputElement

/**
 * A TextField that displays money with commas and dots. The currency is displayed in
 * a currency number format e.g. `$12,345.00`, but when you focus, it turns to a number to it's easier to edit.
 */
@Composable
fun MoneyField(
  currency: Currency,
  opts: Builder<ValidatedFormValueInputOptions<Money>>,
  attrs: AttrBuilderContext<HTMLInputElement>? = null,
) {
  val options = ValidatedFormValueInputOptions<Money>().apply { opts(this) }
  TypedTextField<Money>(
    opts = {
      label = options.label
      value = options.value
      disabled = options.disabled
      onBlur = {
        options.onModified(if (it is FormValue.Valid) it.value else null)
        options.onBlur(it)
      }
      toType = { input ->
        if (input == "$") {
          Money(BigNum.ZERO, currency)
        } else {
          input?.replace(",", "")?.replace("$", "")?.bnOrNull()?.let { Money(it, currency) }
        }
      }
      toFormatted = { formatCurrency(it, currency) }
      toPlain = { it?.toBigNum(currency)?.toString() ?: "" }
    },
    attrs = attrs
  )
}
