package com.diyoffer.negotiation.analytics

import com.diyoffer.negotiation.model.*

interface AnalyticsClient {
  fun logEvent(event: AnalyticsEvent)
}

sealed class AnalyticsEvent {
  data object BuyerGuideClicked : AnalyticsEvent()
  data object SellerGuideClicked : AnalyticsEvent()
  data class RequestViewingOptions(val id: Uid<Listing>) : AnalyticsEvent()
  data class RequestViewing(val id: Uid<Listing>) : AnalyticsEvent()
  data class ListingPublished(val id: Uid<Listing>) : AnalyticsEvent()
  data class WalkMeThroughTheOffer(val id: Uid<Listing>) : AnalyticsEvent()
  data class EmailVerified(val offerId: Uid<Offer>) : AnalyticsEvent()
  data class OfferAccept(val offerId: Uid<Offer>, val party: Party) : AnalyticsEvent()
  data class OfferPublish(val offerId: Uid<Offer>, val party: Party) : AnalyticsEvent()
  data class OfferReject(val offerId: Uid<Offer>, val party: Party) : AnalyticsEvent()
  data class OfferSave(val offerId: Uid<Offer>, val party: Party) : AnalyticsEvent()
  data class OfferCancel(val offerId: Uid<Offer>) : AnalyticsEvent()
  data class OfferComplete(val offerId: Uid<Offer>) : AnalyticsEvent()
  data class OfferSaveLegal(val offerId: Uid<Offer>, val party: Party) : AnalyticsEvent()
}
