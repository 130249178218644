package com.diyoffer.negotiation.model

import com.diyoffer.negotiation.model.NegotiatedTerm.*
import kotlinx.datetime.Clock
import kotlinx.serialization.Serializable

/**
 * Allows to audit a trail of changes, and to keep track of the current state of a negotiation.
 *
 * The first auditable in the [changeHistory] is the starting point i.e. usually the listing value if the term
 * is something included in the listing, but could be the first offer value if the initial value of the term is
 * not in the listing.
 *
 * For simplicity, every back-and-forth must contain an entry in the [changeHistory], even if no change in the
 * value has occurred i.e. in the case of the term being accepted or rejected. This keeps logic that reads the change
 * history much simpler, at the minor cost of some extra data duplication. It also allows the [Auditable] in the
 * change history's [NegotiatedTermValue] to contain the timestamp at which the state change occurred.
 *
 * If the buyer accepts the listing value, the state changes to [State.ACCEPTED] and the last entry in the
 * [changeHistory] is created as the current value (which will be the same as the previous except for the timestamp).
 * Same logic for rejected values. If the buyer counters, the [changeHistory] then includes the buyer's counter-value,
 * the state changes to [State.COUNTERED], and the [currentValue] changes to the buyer's counter-value.
 */
@Serializable
data class NegotiatedTerm<out T : Any>(
  val currentValue: Optional<T>,
  val changeHistory: List<NegotiatedTermValue<T>>,
  val state: State,
) {
  enum class State {
    NEW,
    ACCEPTED,
    REJECTED,
    COUNTERED,
    REMOVED,
    ;
    val isAgreed get() = this == ACCEPTED || this == REMOVED
  }

  init {
    require(currentValue == changeHistory.last().value.core.value) {
      "NegotiatedTerm currentValue $currentValue must match the last change history value " +
        "${changeHistory.last().value.core.value}"
    }
  }
}

fun <T : Any> newTerm(value: T, party: Party, clock: Clock): NegotiatedTerm<T> {
  return NegotiatedTerm(
    currentValue = Optional.of(value),
    state = State.NEW,
    changeHistory = listOf(
      NegotiatedTermValue(
        value = Auditable.Core(
          Optional.of(value),
          clock.now().lowRes()
        ),
        party = party,
        state = State.NEW
      )
    )
  )
}

fun <T : Any> NegotiatedTerm<T>.pushTerm(termValue: NegotiatedTermValue<T>): NegotiatedTerm<T> =
  copy(
    currentValue = termValue.value.core.value,
    changeHistory = changeHistory + termValue,
    state = termValue.state
  )

fun <T : Any> NegotiatedTerm<T>.amendTerm(termValue: NegotiatedTermValue<T>) =
  copy(
    currentValue = termValue.value.core.value,
    changeHistory = changeHistory.dropLast(1) + termValue,
    state = termValue.state
  )

fun <T : Any> NegotiatedTerm<T>.mapCurrentTerm(transform: (NegotiatedTermValue<T>) -> NegotiatedTermValue<T>): NegotiatedTerm<T> {
  val mappedTerm = transform(changeHistory.last())
  return copy(
    currentValue = mappedTerm.value.core.value,
    changeHistory = changeHistory.dropLast(1).map { transform(it) } + mappedTerm
  )
}

fun <T : Any> NegotiatedTerm<T>.mapCurrentAuditable(transform: (Auditable<T>) -> Auditable<T>): NegotiatedTerm<T> {
  return mapCurrentTerm { termValue ->
    val mappedAuditable = transform(termValue.value)
    termValue.copy(value = mappedAuditable)
  }
}

fun <T : Any> NegotiatedTerm<T>.mapCurrentAuditableValue(transform: (Optional<T>) -> Optional<T>): NegotiatedTerm<T> {
  return mapCurrentAuditable { auditable ->
    auditable.mapValue(transform)
  }
}

fun <T : Any> NegotiatedTerm<T>.revert(forParty: Party): NegotiatedTerm<T>? {
  val revertedHistory = changeHistory.dropLastWhile { it.party == forParty }
  // This means all entries are for current party, so we cannot revert to other's
  // party baseline value.
  if (revertedHistory.isEmpty()) return null
  return NegotiatedTerm(
    currentValue = revertedHistory.last().value.core.value,
    state = revertedHistory.last().state,
    changeHistory = revertedHistory
  )
}

fun <T : Any> NegotiatedTerm<T>.canSubmit(forParty: Party, negotiationStage: NegotiationStage): Boolean {
  return when {
    // We can only submit if the current party has the pen
    forParty != negotiationStage.authoringParty -> false
    // Party must provide a negotiation unless previous party has approved or removed the clause
    changeHistory.last().party != forParty && state != State.ACCEPTED && state != State.REMOVED -> false
    // NEW state only allowed when drafting
    state == State.NEW &&
      negotiationStage != NegotiationStage.BUYER_DRAFTING_OFFER &&
      negotiationStage != NegotiationStage.SELLER_DRAFTING_LISTING
    -> false
    // Valid
    else -> true
  }
}

// When a negotiated term reaches final state, either ACCEPTED or REMOVED, the history ends with the party
// who closed the discussion.
fun <T : Any> NegotiatedTerm<T>.isAgreed(forParty: Party) =
  state.isAgreed && changeHistory.last().party != forParty

@Serializable
data class NegotiatedTermValue<out T : Any>(
  val value: Auditable<T>,
  val party: Party,
  val state: State = State.NEW,
)

typealias NegotiatedTermReader<T, NT> = T.() -> NegotiatedTerm<NT>?

typealias NegotiatedTermWriter<T, NT> = T.(NegotiatedTerm<NT>?) -> T

data class NegotiatedTermLens<T, NT : Any>(
  val reader: NegotiatedTermReader<T, NT>,
  val writer: NegotiatedTermWriter<T, NT>,
)

typealias NegotiatedTermListReader<T, NT> = T.() -> List<NegotiatedTerm<NT>>?

typealias NegotiatedTermListWriter<T, NT> = T.(Int, NegotiatedTerm<NT>) -> T

data class NegotiatedTermListLens<T, NT : Any>(
  val reader: NegotiatedTermListReader<T, NT>,
  val writer: NegotiatedTermListWriter<T, NT>,
)
