package builders

import com.diyoffer.negotiation.model.*
import kotlinx.datetime.Clock

data class ChattelsIncludedListBuilder(
  val chattelIncludedList: List<ChattelInclusion>? = null,
) : IBuilder<ListingChattelsIncluded> {

  companion object {
    fun create(item: ListingChattelsIncluded?): IBuilder<ListingChattelsIncluded> {
      return item?.let { chattelIncluded ->
        ChattelsIncludedListBuilder(
          chattelIncludedList = chattelIncluded.chattelsIncluded.mapNotNull {
            it.core.value.getOrNull()
          }
        )
      } ?: ChattelsIncludedListBuilder()
    }
  }

  override fun hydrate(item: ListingChattelsIncluded?) = create(item)

  override fun build(): BuildResult<ListingChattelsIncluded> = validateAndBuild {
    ListingChattelsIncluded(
      chattelsIncluded = chattelIncludedList?.map {
        Auditable.Core(
          Optional.of(it),
          Clock.System.now().lowRes(),
          message = "Created by UI V1. Support for auditable coming."
        )
      } ?: listOf()
    )
  }

  private fun validateAndBuild(onValid: () -> ListingChattelsIncluded): BuildResult<ListingChattelsIncluded> {
    val warnings = mutableListOf<String>()

    if (chattelIncludedList.isNullOrEmpty()) {
      warnings.add("You are not disclosing any chattel or inclusion.")
    }

    return buildResult(warnings = warnings, onValid = onValid)
  }
}
