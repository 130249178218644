package com.diyoffer.negotiation.repository.offer

import co.touchlab.kermit.Logger
import com.copperleaf.ballast.InputHandler
import com.copperleaf.ballast.InputHandlerScope
import com.copperleaf.ballast.observeFlows
import com.copperleaf.ballast.postInput
import com.copperleaf.ballast.repository.bus.EventBus
import com.copperleaf.ballast.repository.bus.observeInputsFromBus
import com.copperleaf.ballast.repository.cache.Cached
import com.copperleaf.ballast.repository.cache.fetchWithCache
import com.copperleaf.ballast.repository.cache.getCachedOrNull
import com.copperleaf.ballast.repository.cache.isLoading
import com.diyoffer.negotiation.common.retry
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.repository.offer.BuyerOfferRepositoryContract.Inputs
import com.diyoffer.negotiation.repository.offer.BuyerOfferRepositoryContract.State
import com.diyoffer.negotiation.repository.user.UserRepository
import com.diyoffer.negotiation.rpcs.IListingAnonRpcService
import com.diyoffer.negotiation.rpcs.IOfferAnonRpcService
import com.diyoffer.negotiation.rpcs.onRpcAttempt
import kotlinx.coroutines.flow.map

class BuyerOfferRepositoryInputHandler(
  private val offerAnonRpcService: IOfferAnonRpcService,
  private val listingAnonRpcService: IListingAnonRpcService,
  private val userRepo: UserRepository,
  private val eventBus: EventBus,
) : InputHandler<Inputs, Any, State> {
  @Suppress("ComplexMethod")
  override suspend fun InputHandlerScope<Inputs, Any, State>.handleInput(input: Inputs) = when (input) {
    is Inputs.Initialize -> {
      val s = getAndUpdateState { it.copy(initialized = true) }
      if (!s.initialized) {
        observeFlows(
          "BuyerOfferRepositoryInputHandler",
          eventBus.observeInputsFromBus(),
          userRepo.getUser().map { Inputs.UserUpdated(it) },
        )
      } else {
        noOp()
      }
    }

    is Inputs.InvalidateCache -> {
      val s = getAndUpdateState { it.copy(cacheInvalidated = true) }
      if (input.fetchOffer) {
        s.offer.getCachedOrNull()?.first?._id?.let { postInput(Inputs.OfferFetchRequest(it)) } ?: Unit
      } else {
        Unit
      }
    }

    is Inputs.OfferFetchRequest -> {
      val s = getCurrentState()
      (s.user as? SessionUser.SignedUser)?.let { user ->
        fetchWithCache(
          input = input,
          forceRefresh = s.cacheInvalidated || s.offer.getCachedOrNull()?.first?._id != input.offerId,
          getValue = { it.offer },
          updateState = { Inputs.OfferFetched(it) },
          doFetch = {
            fetchOffer(input.offerId, user.signature, user.random)
          }
        )
      } ?: postInput(
        Inputs.OfferFetched(Cached.FetchingFailed(UnauthorizedServiceException, null))
      )
    }

    is Inputs.OfferFetched -> {
      val us = updateStateAndGet { it.copy(offer = input.offer, cacheInvalidated = false) }
      val offer = us.offer.getCachedOrNull()
      if (offer == null || us.offer.isLoading()) {
        noOp()
      } else {
        val listingRes = us.relatedListing.getCachedOrNull()
        fetchWithCache(
          input = input,
          forceRefresh = (listingRes == null || listingRes.listing._id != offer.first.onListing) || us.cacheInvalidated,
          getValue = { it.relatedListing },
          updateState = { Inputs.RelatedListingFetched(it) },
          doFetch = { fetchRelatedListing(offer.first.onListing) }
        )
      }
    }

    is Inputs.RelatedListingFetched -> updateState { it.copy(relatedListing = input.listing) }

    is Inputs.UserUpdated -> updateState { it.copy(user = input.user, cacheInvalidated = true) }
  }

  @Suppress("ThrowsCount")
  private suspend fun fetchOffer(
    offerId: Uid<Offer>,
    signature: String,
    random: String,
  ): Pair<Offer, OfferContacts> {
    require(signature.isNotBlank() && random.isNotBlank())
    return retry(
      onAttempt = onRpcAttempt { a, e ->
        Logger.w(e) { "Error loading buyer offer via secure link, attempt=$a, will retry" }
      }
    ) {
      when (
        val r = offerAnonRpcService.load(
          id = UidValue(offerId),
          signature = signature,
          random = random,
          versionOption = LoadOfferVersionOption.LATEST
        )
      ) {
        is OfferSignatureLoadResult.ValidSignature -> when (val loadResult = r.loadResult) {
          is OfferLoadResult.Success -> loadResult.offer to loadResult.contacts
          is OfferLoadResult.NotFound -> throw NoSuchElementException("Offer $offerId not found")
        }

        is OfferSignatureLoadResult.ExpiredSignature -> {
          throw IllegalArgumentException("OfferSignatureLoadResult - ${r.message()}")
        }

        is OfferSignatureLoadResult.InvalidSignature -> {
          throw IllegalArgumentException("OfferSignatureLoadResult - ${r.message()}")
        }
      }
    }
  }

  private suspend fun fetchRelatedListing(listingId: Uid<Listing>): ListingLoadResult.Success {
    return retry(
      onAttempt = onRpcAttempt { a, e ->
        Logger.w(e) { "Error loading related listing (anon), attempt=$a, will retry" }
      }
    ) {
      when (val r = listingAnonRpcService.loadListingById(UidValue(listingId))) {
        is ListingLoadResult.Success -> r
        is ListingLoadResult.NotFound ->
          throw NoSuchElementException(
            "fetchRelatedListing - Listing $listingId could not be found. ${r.message()}"
          )
      }
    }
  }
}
