package com.diyoffer.negotiation.common

import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime

actual fun formatDate(date: LocalDate, locale: String): String =
  jsFormatDate(date.year, date.monthNumber - 1, date.dayOfMonth, locale)

@Suppress("UNUSED_PARAMETER")
private fun jsFormatDate(yearArg: Int, monthArg: Int, dayArg: Int, locale: String): String =
  js(
    "(new Date(yearArg, monthArg, dayArg))" +
      ".toLocaleDateString(locale, {year: 'numeric', month: 'short', day: '2-digit'});"
  ) as String

actual fun formatDateTime(date: LocalDateTime, locale: String): String =
  jsFormatDateTime(date.year, date.monthNumber - 1, date.dayOfMonth, date.hour, date.minute, locale)

@Suppress("UNUSED_PARAMETER", "LongParameterList")
private fun jsFormatDateTime(yearArg: Int, monthArg: Int, dayArg: Int, hourArg: Int, minuteArg: Int, locale: String): String =
  js(
    "(new Date(yearArg, monthArg, dayArg, hourArg, minuteArg)).toLocaleTimeString" +
      "(locale, {year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit'});"
  ) as String
