//
// GENERATED by KVision
//
package com.diyoffer.negotiation.rpcs

import io.kvision.remote.HttpMethod
import io.kvision.remote.KVServiceManager
import io.kvision.remote.registerKVisionServiceExceptions

expect class LinksRpcService : ILinksRpcService

object LinksRpcServiceManager : KVServiceManager<LinksRpcService>(LinksRpcService::class) {
    init {
        registerKVisionServiceExceptions()
        bind(ILinksRpcService::sendBuyerOfferLink, HttpMethod.POST, "links/send_buyer_offer_link")
    }
}
