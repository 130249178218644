package com.diyoffer.negotiation.prototypes

import com.diyoffer.negotiation.model.*
import com.soywiz.kbignum.BigInt
import kotlinx.datetime.Clock
import kotlinx.datetime.LocalDate
import kotlin.time.Duration.Companion.days

fun baseTestContacts(clock: Clock, verified: Boolean = false): OfferContacts = OfferContacts(
  buyers = OfferBuyers(
    listOf(
      Contact(
        name = "Raman Gupta",
        methods = listOf(
          ContactMethod.Email("rocketraman@gmail.com", Auditable.Core(Optional.of(verified), clock.now())),
        ),
      ),
      Contact(
        name = "Joe",
        methods = listOf(
          ContactMethod.Email("joe1@joe.com", Auditable.Core(Optional.of(verified), clock.now())),
        ),
      ),
    )
  ),
  buyerLegal = baseTestLegalContact(clock, verified),
  sellerLegal = null,
)

fun baseTestLegalContact(clock: Clock, verified: Boolean = false): Contact =
  Contact(
    name = "Me Judith Lawmaker",
    methods = listOf(
      ContactMethod.Email("judith@lawmaker.com", Auditable.Core(Optional.of(verified), clock.now())),
    )
  )

fun baseTestBuyersAgent(clock: Clock, estimate: Boolean = true): OfferBuyerAgent =
  OfferBuyerAgent(
    ownAgent = Auditable.Core(Optional.of(true), clock.now()),
    commission = Auditable.Core(
      Optional.of(AgentCommission(commission = Percent(value = 2.5), estimate = estimate)),
      clock.now(),
      null,
    ),
    userCertifiedCommissionOverflowResponsibility = Auditable.Core(Optional.of(true), clock.now()),
  )

@Suppress("MagicNumber")
fun baseTestMinimalDraftOffer(uid: Uid<Offer> = newUid(), listingUid: Uid<Listing> = newUid()) = Offer.Draft(
  _id = uid,
  version = 1,
  currency = Currency.CAD,
  onListing = listingUid,
  events = emptyList(),
)

@Suppress("MagicNumber", "LongMethod")
fun baseTestDraftOffer(clock: Clock, uid: Uid<Offer> = newUid(), listingUid: Uid<Listing> = newUid()) = Offer.Draft(
  _id = uid,
  version = 1,
  currency = Currency.CAD,
  onListing = listingUid,
  buyerConfirmation = OfferBuyerConfirmation(
    over18 = Auditable.Core(Optional.of(true), clock.now(), null),
    userCertifiedLegalAuthority = Auditable.Core(Optional.of(true), clock.now(), null),
  ),
  buyerAgent = baseTestBuyersAgent(clock),
  price = OfferPrice(
    deposit = NegotiatedTerm(
      currentValue = Optional.of(Money(BigInt(20_000), Currency.CAD)),
      changeHistory = listOf(
        NegotiatedTermValue(
          Auditable.Core(Optional.of(Money(BigInt(20_000), Currency.CAD)), clock.now()),
          Party.BUYER
        ),
        NegotiatedTermValue(
          Auditable.Core(
            value = Optional.of(Money(BigInt(20_000), Currency.CAD)),
            timestamp = clock.now(),
            message = null,
          ),
          Party.SELLER,
        ),
      ),
      state = NegotiatedTerm.State.NEW,
    ),
    price = NegotiatedTerm(
      currentValue = Optional.of(Money(BigInt(500_000), Currency.CAD)),
      changeHistory = listOf(
        NegotiatedTermValue(
          Auditable.Core(Optional.of(Money(BigInt(500_000), Currency.CAD)), clock.now()),
          Party.SELLER
        ),
      ),
      state = NegotiatedTerm.State.NEW,
    ),
  ),
  closing = OfferClosing(
    date = NegotiatedTerm(
      currentValue = Optional.of(LocalDate(2022, 1, 12)),
      changeHistory = listOf(
        NegotiatedTermValue(
          Auditable.Core(Optional.of(LocalDate(2022, 1, 12)), clock.now()),
          Party.SELLER,
        ),
        NegotiatedTermValue(
          Auditable.Core(Optional.of(LocalDate(2022, 1, 12)), clock.now()),
          Party.BUYER,
        ),
      ),
      state = NegotiatedTerm.State.ACCEPTED,
    ),
  ),
  assumableContracts = OfferAssumableContracts(
    listOf(
      NegotiatedTerm(
        currentValue = Optional.of(
          AssumableContract(
            ContractOption.HotWaterHeater,
            AssumableContractDetails.RentToOwn(LocalDate(2022, 2, 15), Money(300), Money(500)),
          )
        ),
        changeHistory = listOf(
          NegotiatedTermValue(
            Auditable.Core(
              Optional.of(
                AssumableContract(
                  ContractOption.HotWaterHeater,
                  AssumableContractDetails.RentToOwn(LocalDate(2022, 2, 15), Money(300), Money(500)),
                )
              ),
              clock.now()
            ),
            Party.SELLER
          ),
        ),
        state = NegotiatedTerm.State.NEW,
      )
    ),
  ),
  fixturesExcluded = OfferFixturesExcluded(emptyList()),
  chattelsIncluded = OfferChattelsIncluded(emptyList()),
  sellerConditions = OfferSellerConditions(emptyList()),
  buyerConditions = OfferBuyerConditions(
    listOf(
      Optional.of(
        Condition(
          optionKey = OptionKey.Custom("title1", Asciidoc("condition1")),
          expiry = Expiry.Core(1.days),
        )
      ).let {
        NegotiatedTerm(
          currentValue = it,
          changeHistory = listOf(
            NegotiatedTermValue(Auditable.Core(it, clock.now()), Party.BUYER)
          ),
          state = NegotiatedTerm.State.NEW,
        )
      },
      Optional.of(
        Condition(
          optionKey = OptionKey.Custom("title2", Asciidoc("condition2")),
          expiry = Expiry.Core(2.days),
        ),
      ).let {
        NegotiatedTerm(
          currentValue = it,
          changeHistory = listOf(
            NegotiatedTermValue(Auditable.Core(it, clock.now()), Party.BUYER),
          ),
          state = NegotiatedTerm.State.NEW,
        )
      },
    ),
  ),
  bindingAgreementTerms = OfferBindingAgreementTerms(
    days = NegotiatedTerm(
      currentValue = Optional.of(Days(3)),
      changeHistory = listOf(
        NegotiatedTermValue(Auditable.Core(Optional.of(Days(3)), clock.now()), Party.SELLER),
        NegotiatedTermValue(Auditable.Core(Optional.of(Days(3)), clock.now()), Party.BUYER),
      ),
      state = NegotiatedTerm.State.ACCEPTED,
    ),
  ),
  buyerInformation = OfferBuyerInformation(
    Auditable.Core(Optional.of("Love your house!"), clock.now())
  ),
  additionalRequest = OfferAdditionalRequest(emptyList()),
  expiry = Expiry.Core(2.days),
  events = listOf(
    OfferDraftSavedEvent(timestamp = clock.now().lowRes(), 1, null, null, emptyList()),
  ),
)

@Suppress("MagicNumber", "LongMethod")
fun baseTestPublishedOffer(
  clock: Clock,
  uid: Uid<Offer> = newUid(),
  listingUid: Uid<Listing> = newUid(),
  state: Offer.State = Offer.State.PUBLISHED,
): Offer.Published {
  // When run in browser, all historical clock must match so we need to sample it once and keep same value.
  val now = clock.now()
  return Offer.Published(
    _id = uid,
    version = 1,
    currency = Currency.CAD,
    onListing = listingUid,
    number = 1,
    state = state,
    authoredBy = Party.BUYER,
    reviewCount = 0,
    buyerConfirmation = OfferBuyerConfirmation(
      over18 = Auditable.Core(Optional.of(true), now),
      userCertifiedLegalAuthority = Auditable.Core(Optional.of(true), now),
    ),
    buyerAgent = OfferBuyerAgent(
      ownAgent = Auditable.Core(Optional.absent(), now),
      commission = Auditable.Core(Optional.absent(), now),
      userCertifiedCommissionOverflowResponsibility = Auditable.Core(Optional.absent(), now)
    ),
    price = OfferPrice(
      deposit = NegotiatedTerm(
        currentValue = Optional.of(Money(BigInt(20_000), Currency.CAD)),
        changeHistory = listOf(
          NegotiatedTermValue(
            Auditable.Core(Optional.of(Money(BigInt(20_000), Currency.CAD)), now),
            Party.SELLER
          ),
          NegotiatedTermValue(
            Auditable.Core(Optional.of(Money(BigInt(20_000), Currency.CAD)), now),
            Party.SELLER
          ),
        ),
        state = NegotiatedTerm.State.ACCEPTED,
      ),
      price = NegotiatedTerm(
        currentValue = Optional.of(Money(BigInt(600_000), Currency.CAD)),
        changeHistory = listOf(
          NegotiatedTermValue(
            Auditable.Core(Optional.of(Money(BigInt(500_000), Currency.CAD)), now),
            Party.SELLER
          ),
          NegotiatedTermValue(
            Auditable.Core(Optional.of(Money(BigInt(600_000), Currency.CAD)), now),
            Party.BUYER
          ),
        ),
        state = NegotiatedTerm.State.ACCEPTED,
      ),
    ),
    closing = OfferClosing(
      date = NegotiatedTerm(
        currentValue = Optional.of(LocalDate(2021, 12, 17)),
        changeHistory = listOf(
          NegotiatedTermValue(Auditable.Core(Optional.of(LocalDate(2021, 12, 17)), now), Party.SELLER),
          NegotiatedTermValue(Auditable.Core(Optional.of(LocalDate(2021, 12, 17)), now), Party.BUYER),
        ),
        state = NegotiatedTerm.State.ACCEPTED,
      ),
    ),
    assumableContracts = OfferAssumableContracts(emptyList()),
    fixturesExcluded = OfferFixturesExcluded(emptyList()),
    chattelsIncluded = OfferChattelsIncluded(emptyList()),
    sellerConditions = OfferSellerConditions(emptyList()),
    buyerConditions = OfferBuyerConditions(
      listOf(
        NegotiatedTerm(
          Optional.of(
            Condition(
              optionKey = OptionKey.Custom("title1", Asciidoc("condition1")),
              expiry = Expiry.Enriched(Expiry.Core(1.days), now + 1.days),
            )
          ),
          changeHistory = listOf(
            NegotiatedTermValue(
              Auditable.Core(
                Optional.of(
                  Condition(
                    optionKey = OptionKey.Custom("title1", Asciidoc("condition1")),
                    expiry = Expiry.Enriched(Expiry.Core(1.days), now + 1.days),
                  )
                ),
                now
              ),
              Party.SELLER
            ),
            NegotiatedTermValue(
              Auditable.Core(
                Optional.of(
                  Condition(
                    optionKey = OptionKey.Custom("title1", Asciidoc("condition1")),
                    expiry = Expiry.Enriched(Expiry.Core(1.days), now + 1.days),
                  )
                ),
                now
              ),
              Party.BUYER
            ),
          ),
          state = NegotiatedTerm.State.ACCEPTED,
        ),
        NegotiatedTerm(
          Optional.of(
            Condition(
              optionKey = OptionKey.Custom("title2", Asciidoc("condition2")),
              expiry = Expiry.Enriched(Expiry.Core(2.days), now + 2.days),
            ),
          ),
          changeHistory = listOf(
            NegotiatedTermValue(
              Auditable.Core(
                Optional.of(
                  Condition(
                    optionKey = OptionKey.Custom("title2", Asciidoc("condition2")),
                    expiry = Expiry.Enriched(Expiry.Core(2.days), now + 2.days),
                  )
                ),
                now
              ),
              Party.SELLER
            ),
            NegotiatedTermValue(
              Auditable.Core(
                Optional.of(
                  Condition(
                    optionKey = OptionKey.Custom("title2", Asciidoc("condition2")),
                    expiry = Expiry.Enriched(Expiry.Core(2.days), now + 2.days),
                  )
                ),
                now
              ),
              Party.BUYER
            ),
          ),
          state = NegotiatedTerm.State.ACCEPTED,
        ),
      ),
    ),
    bindingAgreementTerms = OfferBindingAgreementTerms(
      days = NegotiatedTerm(
        currentValue = Optional.of(Days(3)),
        changeHistory = listOf(
          NegotiatedTermValue(Auditable.Core(Optional.of(Days(3)), now), Party.SELLER),
          NegotiatedTermValue(Auditable.Core(Optional.of(Days(3)), now), Party.BUYER),
        ),
        state = NegotiatedTerm.State.ACCEPTED,
      ),
    ),
    buyerInformation = OfferBuyerInformation(
      Auditable.Core(Optional.of("Love your house!"), now)
    ),
    additionalRequest = OfferAdditionalRequest(emptyList()),
    expiry = 2.days.let { Expiry.Enriched(Expiry.Core(it), now + it) },
    events = listOf(
      OfferPublishedEvent(timestamp = now.lowRes(), 1, null, null, Party.BUYER, OfferSection.values().size),
    ),
    listingDetailsAcknowledged = true
  )
}
