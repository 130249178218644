package common

import androidx.compose.runtime.Composable
import core.Builder
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginRight
import org.jetbrains.compose.web.css.paddingBottom
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement
import style.DiyStyleSheet
import style.DiyStyleSheet.Sizes.padding
import style.DiyStyleSheet.Sizes.paddingSm
import style.DiyStyleSheet.Sizes.paddingXs
import style.DiyStyleSheet.onLightGreen
import style.DiyStyleSheet.onLightGrey
import style.DiyStyleSheet.onLightRed

@Composable
fun RadioButton(opts: Builder<RadioButtonOptions>, labelContent: ContentBuilder<HTMLDivElement>? = null) {
  val options = RadioButtonOptions().apply { opts(this) }
  FlexColumn({ style { marginBottom(0.px) } }) {
    FlexRow({
      style {
        marginBottom(paddingXs)
        alignItems(AlignItems.Normal)
      }
    }) {
      if (labelContent != null) {
        Div(attrs = { style { paddingBottom(paddingSm) } }, content = labelContent)
      } else {
        TextBold(options.label ?: "")
      }
      options.popupRef?.let { HelpChip(it) }
    }
    FlexRow({ style { marginBottom(0.px) } }) {
      Button({
        classes(DiyStyleSheet.button, if (options.value == true) onLightGreen else onLightGrey)
        style {
          marginRight(padding)
          width(96.px)
          if (options.disabled) cursor("not-allowed")
        }
        if (!options.disabled) onClick { options.onModified(true) }
      }) {
        Text(options.yesLabel)
      }
      Button({
        classes(
          DiyStyleSheet.button,
          if (options.value == false) {
            options.noClass
          } else {
            onLightGrey
          }
        )
        style {
          width(96.px)
          if (options.disabled) cursor("not-allowed")
        }
        if (!options.disabled) onClick { options.onModified(false) }
      }) {
        Text(options.noLabel)
      }
    }
  }
}

class RadioButtonOptions(
  var yesLabel: String = "Yes",
  var noLabel: String = "No",
  var noClass: String = onLightRed,
) : InputOptions<Boolean>()
