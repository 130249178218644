package com.diyoffer.negotiation.ui.checklist

import com.copperleaf.ballast.repository.cache.Cached
import com.diyoffer.negotiation.messages.CommonMessages
import com.diyoffer.negotiation.model.*

object ChecklistTabContract {
  data class State(
    val initialized: Boolean = false,
    val party: Party? = null,
    val listingChecklists: Cached<List<ChecklistUI.ListingChecklistUI>> = Cached.NotLoaded(),
    val offerChecklists: Cached<List<ChecklistUI.OfferChecklistUI>> = Cached.NotLoaded(),
    val offer: Cached<Pair<Offer, OfferContacts>> = Cached.NotLoaded(),
    val showSuccessImage: Boolean = false,
    val reason: String? = null,
    val error: String? = null,
  )

  sealed class Inputs {
    data object InitializeForSeller : Inputs()
    data class InitializeForBuyer(val offerId: Uid<Offer>) : Inputs()
    data class OfferChecklistsUpdated(val checklists: Cached<List<ChecklistUI.OfferChecklistUI>>) : Inputs()
    data class OfferUpdated(val offer: Cached<Pair<Offer, OfferContacts>>) : Inputs()
    data class ListingChecklistsUpdated(val checklists: Cached<List<ChecklistUI.ListingChecklistUI>>) : Inputs()
    data class ActionClicked(val action: ChecklistAction) : Inputs()
    data class UpdateLegalContact(val offerId: Uid<Offer>, val name: String?, val email: String?) : Inputs()
    data class SetReason(val reason: String?) : Inputs()
    data class SetError(val error: String?, val offerId: Uid<Offer>? = null) : Inputs()
  }

  sealed class Events {
    data class OnNavigate(val url: String) : Events()
  }

  val ChecklistAction.options: ChecklistActionOptions
    get() = when (this) {
      is ChecklistAction.ReviewListing -> ChecklistActionOptions("Review Terms")
      is ChecklistAction.ViewOffer -> ChecklistActionOptions("View Offer")
      is ChecklistAction.ContinueOffer -> ChecklistActionOptions("Continue Offer")
      is ChecklistAction.ReviewOffer -> ChecklistActionOptions("Review Offer")
      is ChecklistAction.SubmitOffer -> ChecklistActionOptions("Review and Submit Offer")
      is ChecklistAction.CancelOffer -> ChecklistActionOptions(
        "Offer fell through",
        validationMessage = CommonMessages.offerCancelExplanation,
        provideReason = true,
        popupRef = "checklist-button-offer-fell-through"
      )
      is ChecklistAction.CompleteOffer -> ChecklistActionOptions(
        "Complete Offer",
        validationMessage = CommonMessages.offerCompleteExplanation,
        popupRef = "checklist-button-complete-offer"
      )
      is ChecklistAction.LegalContactForm -> ChecklistActionOptions(
        userPrompt,
        ChecklistActionType.FORM
      )
      is ChecklistAction.TriggerConditionEscapeClause -> ChecklistActionOptions(
        "Trigger Condition Escape Clause",
        validationMessage = Asciidoc("Are you sure you want to trigger the escape clause?")
      )
    }

  enum class ChecklistActionType {
    BUTTON,
    FORM,
  }

  data class ChecklistActionOptions(
    val label: String,
    val type: ChecklistActionType = ChecklistActionType.BUTTON,
    val validationMessage: Asciidoc? = null,
    val provideReason: Boolean = false,
    val popupRef: String? = null,
  )
}
