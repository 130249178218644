package com.diyoffer.negotiation.monitoring

object RouteMapping {
  private val uuidRegex = "/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}".toRegex()

  @Suppress("MagicNumber")
  private val transformations = listOf<(String) -> String>(
    {
      if (it.contains(uuidRegex)) { it.replace(uuidRegex, "/:uuid") } else it
    },
    {
      it.split("/").drop(1).let { segments ->
        if (segments.size == 2 && segments[0] == "listing") {
          segments.dropLast(1).joinToString("/", prefix = "/", postfix = "/:permalink")
        } else {
          it
        }
      }
    },
    {
      it.split("/").drop(1).let { segments ->
        if (segments[0] == "offer" && segments[1] == ":uuid" && segments[3] == "signed") {
          segments.dropLast(3).joinToString("/", prefix = "/", postfix = "/:signature")
        } else {
          it
        }
      }
    }
  )

  fun mapLocationToRoute(location: String): String {
    return transformations.fold(location) { l, transform -> transform(l) }
  }
}
