package forms.auth

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.diyoffer.negotiation.model.auth.*
import com.diyoffer.negotiation.ui.user.UserContract
import vm.login.UserViewModel

/**
 * This is mainly for debugging purposes, to initiate a manual sign-out without having the sign-in screen.
 */
@Suppress("LongMethod")
@Composable
fun SignOut(userVm: UserViewModel) {
  LaunchedEffect(Unit) {
    userVm.send(UserContract.Inputs.SignOut)
  }
}
