package builders

import com.diyoffer.negotiation.common.services.validation.chattelIncludedStateModel
import com.diyoffer.negotiation.model.*

data class OfferChattelsIncludedListBuilder(
  val party: Party,
  val negotiationStage: NegotiationStage,
  val currency: Currency,
  val chattelsIncluded: List<NegotiatedTermBuilder<ChattelInclusion>>? = null,
) : IBuilder<OfferChattelsIncluded> {

  override fun hydrate(item: OfferChattelsIncluded?) = copy(
    chattelsIncluded = item?.chattelsIncluded?.map { fixture ->
      NegotiatedTermBuilder(
        party = party,
        negotiationStage = negotiationStage,
        term = fixture,
        currency = currency,
        stateValidation = ::chattelIncludedStateModel,
      )
    }
  )

  override fun build(): BuildResult<OfferChattelsIncluded> = validateAndBuild {
    OfferChattelsIncluded(
      chattelsIncluded = chattelsIncluded!!.mapNotNull {
        (it.build() as? BuildResult.Success)?.result
      }
    )
  }

  @Suppress("SpreadOperator")
  private fun validateAndBuild(onValid: () -> OfferChattelsIncluded): BuildResult<OfferChattelsIncluded> {
    require(chattelsIncluded != null) { "OfferChattelsIncludedListBuilder has not been hydrated" }
    return buildResult().mergeAndRun(
      onValid,
      *(chattelsIncluded.map { it.build() }.toTypedArray())
    )
  }
}
