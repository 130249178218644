package com.diyoffer.negotiation.ballast

import com.copperleaf.ballast.BallastViewModelConfiguration
import com.copperleaf.ballast.InputFilter
import com.copperleaf.ballast.InputHandler
import com.copperleaf.ballast.build
import com.copperleaf.ballast.core.BootstrapInterceptor
import com.copperleaf.ballast.core.FifoInputStrategy
import com.copperleaf.ballast.withViewModel
import org.kodein.di.DI
import org.kodein.di.DirectDI
import org.kodein.di.DirectDIAware
import org.kodein.di.bindFactory
import org.kodein.di.bindProvider
import org.kodein.di.instance

/**
 * Convenience function to bind a Ballast VM configuration into DI.
 */
@Suppress("LongParameterList")
inline fun <reified Inputs : Any, reified Events : Any, reified State : Any> DI.Builder.bindVmConfig(
  name: String,
  initialState: State,
  filter: InputFilter<Inputs, Events, State>? = null,
  crossinline configApply: BallastViewModelConfiguration.TypedBuilder<Inputs, Events, State>.() -> Unit = {},
  noinline initialInputs: (suspend () -> Inputs)? = null,
  crossinline inputHandler: DirectDIAware.() -> InputHandler<Inputs, Events, State>,
) {
  // in ballast 4.0.0, singletons for configs cause ClosedChannelSendException, see
  //  https://kotlinlang.slack.com/archives/C03GTEJ9Y3E/p1699623937047949
  // use a provider instead
  bindProvider {
    createBallastViewModelConfiguration<Events, Inputs, State>(
      name,
      initialState,
      filter,
      configApply,
      initialInputs,
      inputHandler,
    )
  }
}

data class VmConfigFactoryParams<Inputs : Any, Events : Any, State : Any>(
  val configApply: BallastViewModelConfiguration.TypedBuilder<Inputs, Events, State>.() -> Unit = {},
  val initialInputs: (suspend () -> Inputs)? = null,
)

/**
 * Convenience function to bind a Ballast VM configuration into DI as a factory that takes an argument for
 * configuration parameters that may need to be specified for each use at runtime.
 */
inline fun <reified Inputs : Any, reified Events : Any, reified State : Any> DI.Builder.bindVmConfigFactory(
  name: String,
  initialState: State,
  filter: InputFilter<Inputs, Events, State>? = null,
  crossinline inputHandler: DirectDIAware.() -> InputHandler<Inputs, Events, State>,
) {
  bindFactory { params: VmConfigFactoryParams<Inputs, Events, State> ->
    createBallastViewModelConfiguration<Events, Inputs, State>(
      name,
      initialState,
      filter,
      params.configApply,
      params.initialInputs,
      inputHandler,
    )
  }
}

@PublishedApi
@Suppress("LongParameterList", "MaxLineLength")
internal inline fun <reified Events : Any, reified Inputs : Any, reified State : Any> DirectDI.createBallastViewModelConfiguration(
  name: String,
  initialState: State,
  filter: InputFilter<Inputs, Events, State>? = null,
  crossinline configApply: BallastViewModelConfiguration.TypedBuilder<Inputs, Events, State>.() -> Unit,
  noinline initialInputs: (suspend () -> Inputs)?,
  crossinline inputHandler: DirectDIAware.() -> InputHandler<Inputs, Events, State>,
) = instance<BallastViewModelConfiguration.Builder>()
  .withViewModel(
    initialState = initialState,
    inputHandler = inputHandler(),
    name = name,
  )
  .apply {
    // a FIFO strategy results in more predictable behavior example of problematic behavior with the default LIFO
    // strategy: sign-in started, repository changes loading state, sign-in vm sees state update and cancels sign-in
    // -- this can be avoided in specific cases with a sideJob and by deferring state updates but using a FIFO
    // strategy is more predictable/safer in general
    // we can optimize specific cases with LIFO, but the programmer must take care to ensure that the system works
    // correctly when state updates are made and running inputs are cancelled
    // see https://copper-leaf.github.io/ballast/wiki/feature-overview/#input-strategy
    // see https://kotlinlang.slack.com/archives/C03GTEJ9Y3E/p1679434762797699
    inputStrategy = FifoInputStrategy.typed(filter)
    if (initialInputs != null) interceptors += BootstrapInterceptor(initialInputs)
  }
  .apply(configApply)
  .build()
