package com.diyoffer.negotiation.repository.checklist

import com.copperleaf.ballast.repository.cache.Cached
import com.diyoffer.negotiation.model.*
import com.diyoffer.negotiation.model.rpcs.*
import com.diyoffer.negotiation.ui.checklist.ChecklistUI.ListingChecklistUI
import com.diyoffer.negotiation.ui.checklist.ChecklistUI.OfferChecklistUI

object ChecklistRepositoryContract {
  data class State(
    val initialized: Boolean = false,
    val party: Party? = null,
    val listings: Cached<List<ListingLoadResult.Success>> = Cached.NotLoaded(),
    val offers: Cached<List<Triple<Offer, OfferContacts, Listing.Published>>> = Cached.NotLoaded(),
    val listingChecklists: Cached<List<ListingChecklistUI>> = Cached.NotLoaded(),
    val offerChecklists: Cached<List<OfferChecklistUI>> = Cached.NotLoaded(),
    val user: SessionUser? = null,
  )

  sealed class Inputs {
    data object InitializeForSeller : Inputs()
    data class InitializeForBuyer(val offerId: Uid<Offer>) : Inputs()
    data class ListingsUpdated(
      val listings: Cached<List<ListingLoadResult.Success>>,
      val user: SessionUser,
    ) : Inputs()
    data class ListingChecklistsFetched(val listingChecklists: Cached<List<ListingChecklistUI>>) : Inputs()
    data class OffersUpdated(
      val offers: Cached<List<Triple<Offer, OfferContacts, Listing.Published>>>,
      val user: SessionUser,
    ) : Inputs()
    data class BuyerOfferUpdated(
      val offer: Triple<Offer, OfferContacts, Listing.Published>,
      val user: SessionUser,
    ) : Inputs()
    data class OfferChecklistsFetched(val offerChecklists: Cached<List<OfferChecklistUI>>) : Inputs()
  }
}
