package com.diyoffer.negotiation.ui.home

import com.copperleaf.ballast.InputHandler
import com.copperleaf.ballast.InputHandlerScope
import com.copperleaf.ballast.observeFlows
import com.copperleaf.ballast.repository.cache.Cached
import com.copperleaf.ballast.repository.cache.getCachedOrEmptyList
import com.diyoffer.negotiation.repository.checklist.ChecklistRepository
import com.diyoffer.negotiation.repository.offer.SellerOfferRepository
import com.diyoffer.negotiation.ui.home.SellerHomeScreenContract.Events
import com.diyoffer.negotiation.ui.home.SellerHomeScreenContract.Inputs
import com.diyoffer.negotiation.ui.home.SellerHomeScreenContract.State
import com.diyoffer.negotiation.ui.state.LoadingState
import kotlinx.coroutines.flow.map

typealias SellerHomeInputHandlerScope = InputHandlerScope<Inputs, Events, State>

class SellerHomeScreenInputHandler(
  private val sellerOfferRepo: SellerOfferRepository,
  private val checklistRepo: ChecklistRepository,
) : InputHandler<Inputs, Events, State> {
  override suspend fun SellerHomeInputHandlerScope.handleInput(input: Inputs) = when (input) {
    is Inputs.Fetch -> {
      val s = getAndUpdateState { it.copy(initialized = true) }
      if (!s.initialized) {
        observeFlows(
          "SellerHomeInputHandlerScope",
          sellerOfferRepo.listingOffers().map { Inputs.OffersUpdated(it) },
          checklistRepo.sellerListingChecklists().map { Inputs.ListingChecklistsUpdated(it) },
          checklistRepo.sellerOfferChecklists().map { Inputs.OfferChecklistsUpdated(it) },
        )
      } else {
        noOp()
      }
    }
    is Inputs.OffersUpdated -> {
      val (offerCount, offersLoadingState) = when (input.offers) {
        is Cached.Fetching -> {
          null to LoadingState.FETCHING
        }
        is Cached.NotLoaded -> {
          input.offers.previousCachedValue?.count() to
            if (input.offers.previousCachedValue != null) LoadingState.READY else LoadingState.NOT_LOADED
        }
        is Cached.FetchingFailed -> {
          null to LoadingState.ERROR
        }
        is Cached.Value -> {
          input.offers.getCachedOrEmptyList().count() to LoadingState.READY
        }
      }
      updateState {
        it.copy(
          offerCount = offerCount,
          offersLoadingState = offersLoadingState,
        )
      }
    }
    is Inputs.ListingChecklistsUpdated -> {
      updateState {
        it.copy(listingChecklists = input.checklists)
      }
    }
    is Inputs.OfferChecklistsUpdated -> {
      updateState {
        it.copy(offerChecklists = input.checklists)
      }
    }
  }
}
